import Button from '../../../Buttons/Button';import {DivSelect, FormInput} from '../../../forms/forms';import SvgIcon from '../../../SvgIcon';

/*
  <div className=""></div>
*/


function PhoneActions(props) {

  if (props.moved) {
    return <div className="flex-cen-end w3-animate-opacity a-mrR-24"><div className="flex-cen-cen a-h-40 a-pdL-12 a-pdR-20 cl-done-moving-info"><SvgIcon icon="check" color="green" size="20px"/><div className="a-mrL-20 lh-1 w500">Done</div></div></div>;
  } else if (props.moving == "confirmation") {
    return null;
  } else if (props.moving) {
    const confirm = () => props.colorsList.length ? props.setMove("confirmation") : null;
    const confirmColor = props.colorsList.length ? "green" : "rgba(0,128,0,.3)";
    return (
      <div className="flex-cen-end">
          <SvgIcon click={confirm}  icon="check" color={confirmColor} size="23px"  className="a-mrR-20 " key={"conf-move"+confirmColor}/>
          <SvgIcon click={()=>props.setMove(false)}  icon="close" color="red" size="23px" className="a-mrR-8" key="close"/>

      </div>
    );
  } else {
    return (
      <div className="flex-cen-end">
          <SvgIcon click={props.deleteList}  icon="delete" color="red" size="24px" className="a-mrR-22" key="del-group"/>
          <SvgIcon click={()=>props.setMove(true)}  icon="drive_file_move" color="dodgerblue" size="24px" className="a-mrR-8" key="move-col"/>

      </div>
    );
  }
}


function Actions(props) {
  if (window.innerWidth < 734) {
    return null;
  } else if (props.moving) {
    return (
      <div className="flex-cen-end">
          <DivSelect name="MovingTo" label="Move To" options={props.listsOptions} value={props.selectedList} handleOptionClick={props.doMove}
          className={"mw-150 w3-margin-right "+(props.colorsList.length ? "": "w3-hide")} margin="none"/>
          <SvgIcon click={props.cancelMoving}  icon="close" color="black" size="32px" className="" key="close"/>
          <SvgIcon click={props.cancelMoving}  icon="select_all" color="black" size="32px" className="" key="close"/>
      </div>
    );
  } else {
    return (
      <div className="cl-colors-list-actions flex-cen-cen h100 no-phone ">
          <Button click={props.deleteList}  text="Delete" color="a-btn-dodgerblue" size="a-btn-size-4  w3-margin-right" filling="a-btn-filled "/>
          <Button click={()=>props.setMove(true)}  text="Move Colors" color="a-btn-dodgerblue" size="a-btn-size-4 w3-margin-right" filling="a-btn-filled"/>
      </div>
    );
  }
}

export {Actions, PhoneActions};
