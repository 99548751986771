import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import './hero.css';
import Link from '../Link';

function HeroButton(props) {
  if (props.button) {
    return (
      <Link click={props.click} href={props.href}>
      <button className="w3-button w3-round-xxlarge w3-red w3-container-32 w3-padding-8 w3-bold"> {props.button}</button>
      </Link>
    );
  } else {
    return null;
  }
}




export default HeroButton;
