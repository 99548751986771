import React, {useState, useEffect} from 'react';
import {FormInput, DivSelect} from '../../../../forms/forms';
import SvgIcon from '../../../../SvgIcon';
/*
    <div className=""></div>
*/
const Title = (props) => {
  const [title, setTitle] = useState(null);
  const [error, setError] = useState(false);

  useEffect(()=>{if (props.name !== title) setTitle(props.name);},[props.name]);
  const validateName = () => {
    if (props.name === title) {
      props.setEditTitle(false);
      return null;
    }
    if (props.isNameUnique(title)) {
      props.handleColor("name", title);
      props.setEditTitle(false);
    } else {
      setTitle(props.name);
      setError(true);
      setTimeout(()=>setError(false),3000);
    }
  }
  if (!props.editTitle) {
    return <h4 className={"w100 flex-cen-start "+props.h4}> {props.title}</h4>;
  } else {
    return (
      <div className="">
          <div className="flx-btw">
              <InputTitle value={title}  defs={props.defs} setTitle={setTitle}  setEditTitle={props.setEditTitle}/>
              <SvgIcon icon="done" className="w3-margin-left" size="22px" color="green" hoverColor="#66b3ff" click={validateName}/>
          </div>
          <NotUniqueError show={error}/>
      </div>

    );
  }
};
export default Title;

const InputTitle = (props) => <FormInput value={props.value} margin="w3-section" defs={props.defs}
                                autoFocus={true} handleChange={(e)=>props.setTitle(e.target.value)}/>;
const NotUniqueError = (props) => props.show ? <div className="a-font-14 a-font-red w500 ">Color name already exist!</div> : null;
