import React, {useState, useEffect} from 'react'; import { FormInput} from '../../../../../forms/forms';
import {rgbToHsv,hsvToRgb, contrastColorRgbToHex, hslToCss, hsvToCss} from '../../converters'; import SvgIcon from '../../../../../SvgIcon';
import SvEdit from './SvEdit';


function HsvGraph(props) {
      const [cursorColor, setCursorColor] = useState("white");
      const [position, setPosition] = useState({x:0, y:0});
      const [background, setBackground] = useState({});
      const [editSV, setEditSV] = useState(false);
      const [dragging, setDragging] = useState(false);
      const {handleHsv, rgb, hsl, hex, hsv} = props;

      useEffect(()=>setBackground(hslToCss({h:hsl.h, s:100,l: 50, a: hsl.a})),[hsl.h, hsl.a]);  // changes the background color only when the H compoment chanages from props
      useEffect(()=>setCursorColor(contrastColorRgbToHex(rgb)),[rgb]);
      const cursorPosition = {top: position.y + "px", left: position.x + "px"};
      let dragged = null;
      useEffect(()=> {
        document.documentElement.addEventListener('mouseup', mouseUp, true);
        return ()=>document.documentElement.removeEventListener('mouseup', mouseUp,  true);
      });
      useEffect(()=> {
        document.documentElement.addEventListener('mousemove', click, true);
        return ()=>document.documentElement.removeEventListener('mousemove', click,  true);
      },[dragging, hsv.v, hsv.s]);
      // we need to rerun the event listener at every step change of s and v, because the value passed to click funtion will not be updated from the state but will remain fixed
      // while the cursor move, the click function gets from the the event the mouse actual mouse position while the mouse moves, but not the hsv value, so ion order to block cursor movements when s and v reached the limits we need to pass their actual values recalling the handler at every state change of hsv
      useEffect(()=>calcPosition(hsv),[hsv]);
      const calcPosition = (hsv)=> {
            const el = document.getElementById("rect-bl-wh");
            if (el) {
              const rect = el.getBoundingClientRect();
              const x = hsv.s * (rect.right-rect.left) / 100;
              const y =  rect.height - hsv.v * (rect.bottom-rect.top) / 100; // rect.height - value instead of value because we need the distance from the top, while value is calculated from the bottom
              // the cursor has a width and height of 20px, so we have to set the center of the cursor at the position(x,y) so traslating the position (-10,-10) .
              // To avoid the cursor to fall outside the graph's borders instead of traslating -10 we translate from 0(border left and top) to -20 (border right border bottom). We calculate the equation below, in the center of the graph the translationwill be (-10,-10)
              const yAdj =  (y - 0) * (-20 - 0) / (rect.height); // y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=0 x2=height y1=0 y2=-20
              const xAdj =  (x - 0) * (-20 - 0) / (rect.width);  // y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=0 x2=width  y1=0 y2=-20
              setPosition({x:x + xAdj, y:y + yAdj});
            } else {
              ;//setTimeout(()=>calcPosition(hsv), 500);
            }

          };


      const mouseUp = ()=> { if (dragging) setDragging(false);}
      const isMobile = window.innerWidth < 734;
      // position gets computed whenver hex changes
      const click = (e, clicked)=> {

          if (!dragging && !clicked) return;
          let x = e.clientX; let y = e.clientY;
          const rect = document.getElementById("rect-bl-wh").getBoundingClientRect();

          // If the cursor moves out of the graph on the y axes we wait the v value to be updated to 0 or 100 before blocking further outside movement, same for the x axis, because if the mouse moves too fast out of the border the cursor will stop moving before the border, because the handler is not called for every change and may be called when it's already out
          if (((y > rect.bottom+10) && (Math.round(hsv.v) === 0))||((y < rect.top-10) && (Math.round(hsv.v) === 100))) {return null;} // if the cursor left the top or bottom borders and the v value has been already updated to the border value (0, 100) we return to avoid further movements while outside the graph
          if (((x > rect.right+10) && (Math.round(hsv.s) === 100))||((x < rect.left-10) && (Math.round(hsv.s) === 0))) {return null;} // // if the cursor left the left or right borders and the s value reached the the limits (0 -100) we return to avoid cursor movent outside

          if (x > rect.right) {x = rect.right;} else if (x < rect.left) {x = rect.left;}; // if the cursor is out of border but the s value is not set to (0,100) we poistion it at the rect border
          if (y > rect.bottom) {y = rect.bottom;} else if (y < rect.top) {y = rect.top;}; // if the cursor is out of range but the v value is not set to (0,100) we poistion it at the rect border
          const yAdj =  (y - rect.top) * (-20 - 0) / (rect.height);// y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=rect.top x2=height y1=0 y2=-20
          const xAdj =  (x - rect.left) * (-20 - 0) / (rect.width);// y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=rect.left x2=width  y1=0 y2=-20
          // the cursor has a width and height of 20px, so we have to set the center of the cursor at the position(x,y) so traslating the position (-10,-10) .
          // To avoid the cursor to fall outside the graph's borders instead of traslating -10 we translate from 0(border left and top) to -20 (border right border bottom). We calculate the equation below, in the center of the graph the translationwill be (-10,-10)
          setPosition({y: y + yAdj - rect.top, x:x + xAdj - rect.left});
          const s = (x + 0.1 /* Bug in the algoritm if x = 0 the conversion goes in error, add here 0.1 to avoid x to go to 0*/ - rect.left) / rect.width * 100;
          const v =  (rect.bottom - y) / rect.height * 100 ;
          handleHsv({h:props.hsl.h,s: s, v: v, a: props.hsl.a});
          setCursorColor(contrastColorRgbToHex(hsvToRgb({h:props.hsl.h, s: s, v:v})));
        };

        const touchMove = (e)=> {
              e.stopPropagation();e.preventDefault();
              let x = e.changedTouches[0].clientX; let y = e.changedTouches[0].clientY;
              const rect = document.getElementById("rect-bl-wh").getBoundingClientRect();

              // If the cursor moves out of the graph on the y axes we wait the v value to be updated to 0 or 100 before blocking further outside movement, same for the x axis, because if the mouse moves too fast out of the border the cursor will stop moving before the border, because the handler is not called for every change and may be called when it's already out
              if (((y > rect.bottom+10) && (Math.round(hsv.v) === 0))||((y < rect.top-10) && (Math.round(hsv.v) === 100))) {return null;} // if the cursor left the top or bottom borders and the v value has been already updated to the border value (0, 100) we return to avoid further movements while outside the graph
              if (((x > rect.right+10) && (Math.round(hsv.s) === 100))||((x < rect.left-10) && (Math.round(hsv.s) === 0))) {return null;} // // if the cursor left the left or right borders and the s value reached the the limits (0 -100) we return to avoid cursor movent outside

              if (x > rect.right) {x = rect.right;} else if (x < rect.left) {x = rect.left;}; // if the cursor is out of range we poistion it at the rect border
              if (y > rect.bottom) {y = rect.bottom;} else if (y < rect.top) {y = rect.top;}; // if the cursor is out of range we poistion it at the rect border
              const yAdj =  (y - rect.top) * (-20 - 0) / (rect.height);// y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=rect.top x2=height y1=0 y2=-20
              const xAdj =  (x - rect.left) * (-20 - 0) / (rect.width);// y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=rect.left x2=width  y1=0 y2=-20
              // the cursor has a width and height of 20px, so we have to set the center of the cursor at the position(x,y) so traslating the position (-10,-10) .
              // To avoid the cursor to fall outside the graph's borders instead of traslating -10 we translate from 0(border left and top) to -20 (border right border bottom). We calculate the equation below, in the center of the graph the translationwill be (-10,-10)
              setPosition({y: y + yAdj - rect.top, x:x + xAdj - rect.left});
              const s = (x + 0.1 /* Bug in the algoritm if x = 0 the conversion goes in error, add here 0.1 to avoid x to go to 0*/ - rect.left) / rect.width * 100;
              const v =  (rect.bottom - y) / rect.height * 100 ;
              handleHsv({h:props.hsl.h,s: s, v: v, a: props.hsl.a});
              setCursorColor(contrastColorRgbToHex(hsvToRgb({h:props.hsl.h, s: s, v:v})));

            };


      const svEditBlur = (e) => {
          if (e.target.value === "") {
            hsv[e.target.name] = 0;
            handleHsv(hsv, true)
          }
          setEditSV(false);
      };

      return (
            <div className={"cl-sv-graph-cont "+(dragging ? "cl-sv-dragghing":"")} >
                <div className="col-color-rect-hsv-bord-cont" >
                    <div className="col-color-rect-bl-wh" id="rect-bl-wh" style={{background:background}} >
                       <div className="col-color-rect-white-grad" >
                           <div className="col-color-rect-black-grad" onClick={(e)=>click(e, true)}  >
                           </div>
                           <div className="col-color-rect-bl-wh-cursor "  style={cursorPosition} onTouchEnd={()=>setDragging(false)} onMouseDown={(e)=>{e.stopPropagation();e.preventDefault();setDragging(true)}} onTouchMove={touchMove} onTouchStart={(e)=>{e.stopPropagation();e.preventDefault();setDragging(true)}} >
                               <div className={"col-color-rect-bl-wh-cursor-el pointer "+(dragging ? "a-bkg-orange":"")} style={{borderColor: cursorColor}}></div>
                           </div>
                       </div>
                       <SvEdit editSV={editSV} hsv={hsv} setEditSV={setEditSV} blur={svEditBlur} handleHsv={handleHsv} />
                       <div className="col-color-rect-arr2"></div>
                       <div className="w3-row cl-col-hsv-s-range"><div className="w3-col s4 w3-left-align "><span className="cl-col-hsv-0-rot noselect">0</span></div><div className="w3-col s4 flex-cen-cen cl-col-hsv-comp-name"><div className="w3-margin-right noselect">S</div><div className="cl-range-hsv-level noselect" onClick={()=>isMobile ? null : setEditSV("s")}>{Math.round(hsv.s)+"%"}</div></div><div className="w3-col s4 w3-right-align noselect">100</div></div>
                       <div className="w3-row cl-col-hsv-v-range"><div className="w3-col s4 w3-left-align h-100">&nbsp;</div><div className="w3-col s4 flex-cen-cen cl-col-hsv-comp-name h-100"><div className="w3-margin-right noselect">V</div><div className="cl-range-hsv-level noselect" onClick={()=>isMobile ? null : setEditSV("v")}>{Math.round(hsv.v)+"%"}</div></div><div className="w3-col s4 w3-right-align h-100 flex-cen-end noselect">100</div></div>
                </div>
            </div>
         </div>
    );
}

export default HsvGraph;
