import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';
import './ulSlider.css';

// The Li does nothing it's just a container for the rowFunction propped byt the calling component who decides the structure of the Li content

function Li(props) {
    if (false) return null;
    const li = props.li;
    const index = props.index;
    const active = (((props.cols - 1) / 2 ) == props.ckey) ? true : false;


    return (
        <li>
            <RowFunction rowFunction={props.rowFunction} li={li} index={index} active={active} inViewPort={props.inViewPort}/>
        </li>

    );

}

let RowFunction = (props) => props.rowFunction ? React.cloneElement(props.rowFunction, {data: props.li, index: props.index, active: props.active, inViewPort:props.inViewPort}) : null;

export default Li;
