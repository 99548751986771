function arrayToSingleObj(arr, mapping){
    let obj = {};
    for (let m = 0; m < mapping.length ; m++) {
      if (Array.isArray(mapping[m])) {
        obj[mapping[m][0]] = arrayToSingleObj(arr[m], mapping[m][1])
      } else {
        obj[mapping[m]] = arr[m];
      }

    }
    return obj;
}

function arrayToObj(arr, mapping) {
   let out = []; let dummyObg;
   if (arr && Array.isArray(arr)) {
     for (let i = 0; i < arr.length ; i++) {
          dummyObg = {};
          for (let m = 0; m < mapping.length ; m++) {
            if (Array.isArray(mapping[m]) && Array.isArray(arr[i][m]) && Array.isArray(arr[i][m][0])) { //if the elemet contains an array with at least one oject recurse the fucntion
              dummyObg[mapping[m][0]] = arrayToObj(arr[i][m], mapping[m][1]);
            } else if (Array.isArray(mapping[m]) && Array.isArray(arr[i][m]) && !arr[i][m].length) {  // if the elemet contains an array with no ojects return an empty array!
              dummyObg[mapping[m][0]] = [];
            } else if (Array.isArray(mapping[m]) && Array.isArray(arr[i][m]) ) { // if the elemet contains only one ojects  and not an array call arrayToSingleObj func;
              dummyObg[mapping[m][0]] = arrayToSingleObj(arr[i][m], mapping[m][1]);
            }else {
              dummyObg[mapping[m]] = arr[i][m];
            }

          }
            out.push(dummyObg);
          }
          return out;
      } else {
        return null;
      }
}

export {arrayToObj, arrayToSingleObj};
