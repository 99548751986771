import React, {useState, useEffect} from 'react'; import './rangeActionsBar.css';import Button from '../../../../Buttons/Button';
import {FormInput, DivSelect} from '../../../../forms/forms';
import SvgIcon from '../../../../SvgIcon'; import Header from './Header'; import Actions from './Actions';


function SaveActions(props) {

  const [createView, setCreate] = useState(false);
  const [selectedList, setList] = useState(null);
  useEffect(()=>{if (createView) {setList(12);} else {setList(props.list)}}, [createView, props.list]);
  useEffect(()=>setCreate(false), [props.color.hex]);
  
  if (window.innerWidth < 734 && (props.visibleSections !== "color-handling")) return null;

  const listsOptions = [];for (let i = 0; i<props.lists.length; i++) {listsOptions.push([props.lists[i].name, props.lists[i].id]);}
  const listOpt = {lists:props.lists, createView: createView, selectedList: selectedList, setList: setList, listsOptions:listsOptions};
  const hasChanged = (props.name && (props.savedColor.hex !== props.color.hex));
  const notCreated = ((props.name == null) || (props.name == false));
  const info = {createView: createView, hasChanged: hasChanged, notCreated: notCreated, listOpt: listOpt, setCreate:setCreate, setList: setList};

  return (
      <div className="cl-actions-cont a-pd-14 a-container-24-s a-pdS-4-s" >
          <Header {...info} {...props.editHandlers} {...props}/>
          <Actions {...info} {...props.editHandlers} {...props} />
      </div>
  );
}

export default SaveActions;
