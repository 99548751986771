

function AngleRangeEdit(props) {
  const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
  const selGradient = gradient[selectedGradient];
  const colors = selGradient.colors;

  const handler = (e)=> {
    selGradient.angle = Number(e.target.value);
    if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
    setSclColors([...sclColors]);
  }

  if ((((selGradient.direction !== "angle") && (selGradient.type === "linear-gradient")) && (selGradient.type !== "conic-gradient")) ||  !colors || (props.selectedRange !== "angle") )  {
    return null;
  } else {
    return (
      <div className="a-container-12 a-mrB-12 p-rel" style={{zIndex:6}}>
          <div className="a-container-12 a-pdT-22 a-pdB-10 w3-round-large" style={{...props.bkg}}>
              <input type="range" className="a-pWidth-100" step={1} min={0} max={360} onChange={handler} value={selGradient.angle}/>
          </div>
      </div>

    );
  }
}

export default AngleRangeEdit;
