import React from 'react';
import './feedView.css'; import Feed from './Feed'; import '../home.css';

/*
    <div className=""></div>
*/

function FeedView(props) {
   if (props.show != "feed") {
     return null;
   } else {
     return (
         <div className="feedview w3-content w3-padding-64 ukr-home-defs">
            <Feed data={props.feed} bot={props.bot}  user={props.user} zoom={true} feedView={props.feedView} tags={props.tags}/>
         </div>
     );
   }
}


export default FeedView;
