import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../forms/forms.js";
import Button from '../../Buttons/Button';
import ReactPlayer from 'react-player';
/*
    <div className=""></div>
*/

function Videos(props) {
  if (props.show != "batchVideo") {
      return null;
  } else {
      const handleChange1 = (e) => props.editRow("video_url", e.target.value, props.rowIndex);
      const handleChange2 = (e) => props.editRow("video_caption", e.target.value, props.rowIndex);
      const handleChange3 = (e) => props.editRow("video_description", e.target.value, props.rowIndex);
      const handleDate = (date) => props.editRow("video_date", date, props.rowIndex);

      return (
          <div className="w3-row ">

                <div className=" w3-col m4"><ReactPlayer url={props.video_url}  controls={true} light={false} width="100%" height="auto"  /></div>
                <div className=" w3-col m8">
                    <FormInput name={"video_url"+props.rowIndex} label="Url" value={props.video_url} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
                    <FormInput name={"video_caption"+props.rowIndex} label="Caption" value={props.video_caption} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
                    <FormInput name={"video_description"+props.rowIndex} label="Description" value={props.video_description} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
                    <FormDate name={"video_date"+props.rowIndex} label="Date" date={props.video_date} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />

                </div>
          </div>
      );
  }
}


export default Videos;
