import React from 'react';
import Button from '../../../../Buttons/Button'; import UlSlider from '../../../../UlSlider/UlSlider';
import ReactPlayer from 'react-player';
/*
    <div className=""></div>
*/

function  CheckedIcon(props) {
  if (!props.checked) {
    return null;
  } else {

    return (
      <div className="w3-display-topright ZI10 w3-margin-top w3-margin-right">
          <Button svg="done" size="a-btn-size-40-icon"  color="a-btn-orange-1" />
      </div>

    );
  }
}

function Video(props) {
  function  handleClick() {
    props.addMedia("videos", props.data.id);
  }
  if (!props.data) {
    return null;
  } else {

    const checked = (props.selectedIDs.indexOf(props.data.id) != -1);
    const text = checked ? "Remove" : "Add";
    return (
      <div className="slide-img w3-display-container pointer" >
          <CheckedIcon checked={checked}/>
          <div className="sme-vid-caption ZI10 " onClick={handleClick}>
              {props.data.video_caption}
          </div>
          <ReactPlayer url={props.data.video_url}  controls={true} light={true} width="100%" height="100%"  />
      </div>

    );
  }
}

function Videos(props) {
   if (props.view != "videos") {
     return null;
   } else {
     return (
       <div className="w3-content-800 w3-section-64 " style={{height: "450px"}}>
              <UlSlider rows={props.rows} cols={11}  rowFunction={<Video addMedia={props.addMedia}  selectedIDs={props.selectedIDs}/>}/>
       </div>
     );
   }
}


export default Videos;
