import Reac, {useState, memo} from 'react'; import RangeRow from './RangeRow/RangeRow';import SvgIcon from '../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../converters.js';
import RangeSectionHeader from './RangeSectionHeader';
/*
    <div className=""></div>
*/

function RangeSection(props) {


   if (props.visibleSections && !["rgb-hsl","all","pickers"].includes(props.visibleSections) && (window.innerWidth < 734)) {
     return  <div className="cl-range-sections ov-hid" id={props.sectionType.toUpperCase()+"picker"}></div>;
   } else {
     const color = props.color;
     const bkg = {};
     const a = (!color.rgb.a && color.rgb.a !== 0) ? 1 : color.rgb.a;
     if (props.sectionType === "rgb") {

       bkg.r = {backgroundImage: "linear-gradient(to right," + rgbToCss({r:0, g:color.rgb.g, b:color.rgb.b, a: a}) + " 0, " + rgbToCss({r:127.5, g:color.rgb.g, b:color.rgb.b, a: a}) + " 50%, " +  rgbToCss({r:255, g:color.rgb.g, b:color.rgb.b, a: a}) + " 100%)" };
       bkg.g = {backgroundImage: "linear-gradient(to right," + rgbToCss({r:color.rgb.r, g:0, b:color.rgb.b, a: a}) + " 0, " +  rgbToCss({r:color.rgb.r, g:127.5, b:color.rgb.b, a: a}) + " 50%, " + rgbToCss({r:color.rgb.r, g:255, b:color.rgb.b, a: a}) + " 100%)" };
       bkg.b = {backgroundImage: "linear-gradient(to right," + rgbToCss({r:color.rgb.r, g:color.rgb.g, b:0, a: a}) + " 0, " + rgbToCss({r:color.rgb.r, g:color.rgb.g, b:127.5, a: a}) + " 50%, " + rgbToCss({r:color.rgb.r, g:color.rgb.g, b:255, a: a}) + " 100%)" };
       bkg.a = {backgroundImage: "linear-gradient(to right," + rgbToCss({r:color.rgb.r, g:color.rgb.g, b:color.rgb.b, a:0}) + " 0, " + rgbToCss({r:color.rgb.r, g:color.rgb.g, b:color.rgb.b, a: 0.5}) + " 50%, " + rgbToCss({r:color.rgb.r, g:color.rgb.g, b:color.rgb.b, a:1}) + " 100%)" };
     } else {
       bkg.h = {backgroundImage: "linear-gradient(to right," + hslToCss({h:0, s:color.hsl.s, l:color.hsl.l, a: a}) + " 0%, " + hslToCss({h:60, s:color.hsl.s, l:color.hsl.l, a: a}) + " 17%, " +hslToCss({h:120, s:color.hsl.s, l:color.hsl.l, a: a}) + " 33%, " + hslToCss({h:180, s:color.hsl.s, l:color.hsl.l, a: a}) + " 50%, " + hslToCss({h:240, s:color.hsl.s, l:color.hsl.l, a: a}) + " 67%, "  + hslToCss({h:300, s:color.hsl.s, l:color.hsl.l, a: a}) + " 83%, " +
       hslToCss({h:360, s:color.hsl.s, l:color.hsl.l, a: a}) + " 100%)" };
       bkg.s = {backgroundImage: "linear-gradient(to right," + hslToCss({h:color.hsl.h, s:0, l:color.hsl.l, a: a}) + ", " +  hslToCss({h:color.hsl.h, s:100, l:color.hsl.l, a: a}) + ")" };
       bkg.l = {backgroundImage: "linear-gradient(to right," + hslToCss({h:color.hsl.h, s:color.hsl.s, l:0, a: a}) + ", " + hslToCss({h:color.hsl.h, s:color.hsl.s, l:50, a: a}) + ", " + hslToCss({h:color.hsl.h, s:color.hsl.s, l:100, a: a}) + ")" };
       bkg.a = {backgroundImage: "linear-gradient(to right," + hslToCss({h:color.hsl.h, s:color.hsl.s, l:color.hsl.l, a: 0}) + ", " + hslToCss({h:color.hsl.h, s:color.hsl.s, l:color.hsl.l, a: 0.5}) + ", " + hslToCss({h:color.hsl.h, s:color.hsl.s, l:color.hsl.l, a: 1}) + ")" };

     }


     return (
       <div className="cl-range-sections ov-hid" id={props.sectionType.toUpperCase()+"picker"}>
           <RangeSectionHeader sectionType={props.sectionType} color={props.color} scarlet={props.scarlet}/>
           {props.rows.map((row, index)=> <RangeRow bkg={bkg} key={row} sectionType={props.sectionType} setTempColor={props.setTempColor} isScrolling={props.isScrolling} {...props.color} handler={(e)=>props.handler(e,false, row)} type={row} blur={(e)=>props.handler(e, true, row)} increase={props.increase}/>)}
       </div>
     );
   }
}



const areEqual = (prevProps, nextProps) =>  ((prevProps.color.hex === nextProps.color.hex) && prevProps.visibleSections === nextProps.visibleSections) ;

export default RangeSection;
