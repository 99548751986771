import {DivSelect} from '../../../../../forms/forms';

const ShapeSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const selGradient = gradient[selectedGradient];
    if (selGradient.type !== "radial-gradient") return null;
    const options = ["ellipse","circle"];
    const handler = (value)=> {
        selGradient.shape = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);
    }

      return (
        <DivSelect value={selGradient.shape} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-70"  margin="none" noIcon={true} />
    );
}

export default ShapeSelector;
