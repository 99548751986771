import React, {useEffect, useState, useRef} from 'react';
import {DivSelect} from '../../../../forms/forms';import Button from '../../../../Buttons/Button';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex,contrastHexColor, contrastColor,rgbToHsv,hsvToRgb} from '../converters.js';
import ScarletInner from './Parts/ScarletInner'; import './scarlet.css';

/*
    <div className=""></div>
*/

function Scarlet(props) {

    const [sclColors, setSclColors] = useState(null);
    const [hideControls, setControls] = useState(false);
    const [selected, select] = useState("gradient");
    const [selectedCard, selectCard] = useState(0);
    const [deleted, setDeleted] = useState([]);
    const [cardEl, setCardEl] = useState(null);
    const [selectedBtn, selectBtn] = useState(0);
    const [btnEl, setBtnEl] = useState("bkg");
    const [selectedText, selectText] = useState(0);
    const [selectedTextType, selectTextType] = useState("gradient");
    const [textEl, setTextEl] = useState(0);
    const [index, setIndex] = useState(0);
    const [selectedGradient, selectGradient] = useState(0);
    const [selectedGradientType, selectGradientType] = useState("color"); // ["color,"text"];
    const [gradEl, setGradEl] = useState(0); // if type is color there is only one element so dnex always 0, if it's text there is at least one text element
    const [bkgTouched, setBkgTouch] = useState(false);
    const bkgTouchTimer = useRef(null);
    const cards = sclColors ? sclColors[index].cards : null;
    const buttons = sclColors ? sclColors[index].buttons : null;
    const texts = sclColors ? sclColors[index].text : null;
    const gradient = sclColors ? sclColors[index].gradient : null;
    const [height, setHeight] = useState(460);
    const [fetched, setFetched] = useState(false);
    const fetchTimer = useRef(null);
    const bkg = sclColors ? sclColors[index].bkg : null;
    const contrast = bkg ? contrastHexColor(bkg) : null;
    const contrastBkg = (contrast === "#ffffff") ? {backgroundColor: "rgba(255,255,255,.3)", color: "black"} :  {backgroundColor: "rgba(0,0,0,.3)", color: "white"};
    const fetchMsg = (bol) => {if (fetchTimer) clearTimeout(fetchTimer.current); setFetched(bol ? true : "error"); fetchTimer.current = setTimeout(()=>setFetched(false),2000);};
    var sheet = document.styleSheets[0];

    //useEffect(()=>{window.addEventListener("scroll", scroll); return ()=> window.removeEventListener("scroll", scroll);});

    // on the first load we use
    useEffect(()=>handleFetch(),[]);


    useEffect(()=>  { // if a selected target is defined, at every change in the propped color we must update the targeted sclcolor element
                      if (selected && (selected==="cards") && sclColors && (cards[selectedCard][cardEl] !== (cardEl === "bkg" ? props.color.hsl: props.color.hex))) {
                        cards[selectedCard][cardEl] = (cardEl === "bkg" ? props.color.hsl: props.color.hex);
                        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
                        setSclColors([...sclColors]);
                      } if (selected && (selected==="gradient") && sclColors ) {
                        if ((selectedGradientType === "color") && (gradient[selectedGradient].colors[gradEl].color !== (props.color.rgb))) {
                          gradient[selectedGradient].colors[gradEl].color = props.color.rgb;
                          if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
                          setSclColors([...sclColors]);
                        } else if ((selectedGradientType === "text") && (gradient[selectedGradient].texts[gradEl] !== (props.color.hex))) {
                          gradient[selectedGradient].texts[gradEl] = props.color.hex;
                          if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
                          setSclColors([...sclColors]);
                        }
                      } if (selected && (selected==="text") && texts && (texts[selectedText][selectedTextType][textEl] !== props.color.hex)) {
                        texts[selectedText][selectedTextType][textEl] = props.color.hex;
                        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
                        setSclColors([...sclColors]);
                      } else if (selected && (selected==="buttons") && buttons && (buttons[selectedBtn][btnEl] !== ((btnEl === "bkg") ? props.color.hsl : props.color.hex))) {
                        buttons[selectedBtn][btnEl] = ((btnEl === "bkg") ? props.color.hsl : props.color.hex);
                        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
                        setSclColors([...sclColors]);
                      } else if (!["buttons","cards","text","gradient"].includes(selected) && sclColors && (sclColors[index][selected] !== props.color.hex)) {
                        sclColors[index][selected] = props.color.hex;
                        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
                        setSclColors([...sclColors]);
                    }
                  },[props.color.hex, props.color.hsl, props.color.hsl.a]);

    useEffect(()=>{  // Create buttons css variables at every render
        if (buttons) {
            for (let i=0; i<buttons.length; i++) {
                sheet.insertRule(`.a-btn-test-${i} {--h: ${buttons[i].bkg.h}; --s: ${buttons[i].bkg.s}%; --l: ${buttons[i].bkg.l}%; --a: ${buttons[i].bkg.a?buttons[i].bkg.a:1}; --btn-color: ${buttons[i].txt}; --btn-colorH: ${buttons[i].txtHov};}`, sheet.cssRules.length);
            }
        }
    });

    const handleFetch = () => {
        if (window.testMode) {
          finalizeSave();
          return;
        } else {
          const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
          fetch("/colors/sclGet/", data).then((res) => res.json()).then((result)=>finalizeSave(result),(error) => fetchMsg(false));
        }

    }

    const finalizeSave = (res) =>
        {
          setSclColors((res && res.length) ? res :
                          [{bkg: "#F20000", none:"#000000", gradient: [{type: "linear-gradient", shape:"ellipse", radialSize:"closest-side", direction: "to bottom",angle:0, xPos: 50, yPos:50, height: "40vh", width: "100%", texts:[], colors: [{color:{r:0,g:0,b:0,a:1}, position: 0, deg:0},{color:{r:0,g:255,b:255,a:1}, position: 50, deg:120},{color:{r:255,g:106,b:0,a:1}, position: 100, deg:240}]}],text:[{gradient:["#ffffff","#ffffff","#ffffff"], text:["#ffffff"]}], buttons: [{bkg:{h:240,s:100,l:50}, txt: "#ffffff",txtHov:"#ffffff", filling:"a-btn-filled", lHover:"l-hov-10"}], buttonsIds:[], cards: [{...card}]}]
                      );
        };
    const card = {bkg: {h:0,s:100,l:41, a:1}, txt: "#ffffff", txt2:"#ffffff"};


    const selectType = (value, element) => {
      const selectedEl = element ? element : selected;
      if (element && (element !== selected)) {select(element);} // element is not compulsory, if set we select the elemnt if not yet set
      if (selectedEl == "buttons") {
        setBtnEl(value);if (value !== "bkg") {props.setHex(buttons[selectedBtn][value]);} else {props.setHsl(buttons[selectedBtn][value]);}
      } else if (selectedEl == "cards") {
        setCardEl(value);if (value !== "bkg") {props.setHex(cards[selectedCard][value]);} else {props.setHsl(cards[selectedCard][value]);}
      } else if (selectedEl == "text") {
        setTextEl(value);props.setHex(texts[selectedText][selectedTextType][value]);
      }  else if (selectedEl == "gradient") {
        setGradEl(value);
        if (selectedGradientType === "text" && gradient.texts.length) {
          props.setHex(gradient[selectedGradient].texts[value]);
        }  else {
          props.setRgb(gradient[selectedGradient].colors[value].color);
        }
      }
      if (selected !== selectedEl) select(selectedEl);
    }


   const touchStart = (e) => {if (bkgTouchTimer.current) clearTimeout(bkgTouchTimer.current);setBkgTouch(true); bkgTouchTimer.current = setTimeout(selectSetBkg,700); };
   const touchMove = () => {if (bkgTouchTimer.current) clearTimeout(bkgTouchTimer.current);if (bkgTouched === true) setBkgTouch(false);}
   const touchEnd = (e) => {e.stopPropagation();if (bkgTouchTimer.current) clearTimeout(bkgTouchTimer.current); if (bkgTouched === true) {setBkgTouch(false);}}
   const selectSetBkg = ()=>{setBkgTouch("set"); setTimeout(()=>setBkgTouch(false), 1000);select("bkg");props.setHex(sclColors[index].bkg);};
   const animateBkg = (bkgTouched === "set") ? " w3-animate-opacity-04":"";
   if (!props.scarlet || !sclColors || !props.show) {
     return null;
   } else {
     const shared = {sclColors, setSclColors,selected, select, selectedCard, selectCard,cardEl, setCardEl,selectedBtn, selectBtn,
       btnEl, setBtnEl,selectedText, selectText,textEl, setTextEl, selectedTextType, selectTextType, index, setIndex, cards, buttons, texts, selectType, card, contrastBkg, hideControls, setControls, gradient, setGradEl, gradEl, selectedGradientType, selectGradientType, selectedGradient, selectGradient};

     return (
       <div className="scarlet-container no-shrink " style={{backgroundColor: bkg, height: height+"px", flexBasis: height+"px"}} onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} >
            <ScarletInner finalizeSave={finalizeSave} animateBkg={animateBkg} fetchMsg={fetchMsg} deleted={deleted} setDeleted={setDeleted} shared={shared} height={height} setHex={props.setHex} setRgb={props.setRgb} setHsl={props.setHsl} height={height} setHeight={setHeight} cancelBkgTouch={touchMove}/>
            <FetchMessage fetched={fetched}/>
       </div>
     );
   }
}


const FetchMessage = (props) => {
  if (!props.fetched) {
    return null;
  } else {
    const bkg = props.fetched === "error" ?  "a-bkg-red" : "a-bkg-green";
    const msg = props.fetched === "error" ? "Error" : "Saved";
    return <div className={"w3-display-topright a-mrT-60 a-mrR-16 a-pd-12 "+bkg} style={{zIndex: 1000}}>{msg}</div>
  }
}


export default Scarlet;

/*
<DivSelect value={selected} options={options} handleOptionClick={(value)=>select(value)}
defs="form-round-Lab-Med"  defColors="form-col-orange"   margin="none" className="mw-150" noIcon={true} />
*/

/* to append a css rule
var sheet = window.document.styleSheets[0];
sheet.insertRule('.a-btn-blue-1 {--h: 205.1; --s: 100%; --l: 42%; --btn-color: white; --hex: #007DD6}', sheet.cssRules.length);

*/
