import React from 'react';
import Navigation from './Navigation'; import Controls from './Controls';
import './pagination.css';import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save
/*
    <div className=""></div>
*/
function calPaginationRange(media, pages, active, maxPagesObj) {
  let paginationStart = 1;
  let paginationEnd = 1;
  let halfRange;
  const maxPages = media.isMiniPhone ? maxPagesObj.miniPhone : ( media.isSmarthPhone ? maxPagesObj.phone : (media.isTablet ? maxPagesObj.tablet : maxPagesObj.desktop));
  halfRange = (maxPages - 1) / 2;
  if (pages <= maxPages) {
    paginationStart = 1;
  }  else if ((pages - active) < halfRange) {  // if the number of pages after the active page are less than the half range we decrese paginationStart by pages - active) - halfRange to the total pages displayed are maxPages
    paginationStart = Math.max(1, active - 2 * halfRange + (pages - active)); // we set 1 has minimum start page
  } else {
    paginationStart = Math.max(1, active - halfRange);
  }
  if (pages <= maxPages) {
    paginationEnd = pages;
  } else if ((active - 1) < halfRange) { // if the pages before the active are less than half range we increse paginationEnd by the difference
    paginationEnd = Math.min(pages, active + 2 * halfRange - active + 1);
  } else {
    paginationEnd = Math.min(pages, active + halfRange);
  }
  return {start:paginationStart, end: paginationEnd};
}

function Pagination(props) {
  let media = {};
  media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  media.isSmarthPhone = useMediaQuery({ maxWidth: 734 });
  media.isMiniPhone = useMediaQuery({ maxWidth: 350 });
  media.isTablet = useMediaQuery({ maxWidth: 1068, minWidth: 735 });
  function clickHandler(page) {
    props.clickHandler(page);
  }
   if (false) {
     return null;
   } else {
     let rows = [];
     const pagination = calPaginationRange(media, props.pages, props.active,props.maxPages);
     for (let i = pagination.start; i <= pagination.end; i++) {
        rows[i] = {pageNum: i, active: (i === props.active)};
     }
     const prev = (props.active == 1) ? false : true;
     const next = (props.active < props.pages) ? true : false;

     return (
          <div className="pagination">
                <div className="pagination-container flx-btw">
                        <Navigation rows={rows} clickHandler={clickHandler} prev={prev} next={next}/>
                        <Controls controls={media.isSmarthPhone ? null : props.controls}/>
                </div>
          </div>
     );
   }
}


export default Pagination;
