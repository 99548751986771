import {DivSelect} from '../../../../../forms/forms';import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';

const BlockSelector = (props) => {
  const {sclColors, setSclColors, selected, select, selectedCard, selectCard,cardEl, setCardEl,selectedBtn, selectBtn, btnEl,setBtnEl,selectedText,
  selectText,textEl, setTextEl, selectedTextType, selectTextType, index, setIndex, cards, buttons, texts, selectType, sample, txt, btnBkg, card, gradEl, setGradEl, gradient, selectedGradientType, selectGradientType, selectedGradient} = props.shared;
  const options = [["None", "none"],["Background","bkg"],["Gradient","gradient"],["Buttons", "buttons"],["Cards", "cards"],["Text", "text"]];

  const handleClick = (value)=> {
      select(value);
      if (value === "buttons") {
        const filling = buttons[selectedBtn].filling;
        if (["a-btn-textOnHover","a-btn-bordOnHover"].includes(filling)) {
          props.setHex(buttons[selectedBtn].txt);
          if (btnEl !== "txt") setBtnEl("txt");
        } else {
          props.setHsl(buttons[selectedBtn].bkg);
          if (btnEl !== "bkg") setBtnEl("bkg");
        }
      } else if (value === "cards") {
        props.setHsl(cards[selectedCard].bkg);
        if (cardEl !== "bkg") {setCardEl("bkg");}
      } else if (value === "text") {
        selectText(0);
        if (selectedTextType !== "text") selectTextType("text");
        props.setHex(texts[0].text[0]);
        if (textEl !== 0) setTextEl(0);
      } else if (value === "gradient"){
        props.setRgb(gradient[selectedGradient].colors[0].color);
        if (gradEl !== 0) setGradEl(0);
        if (selectedGradientType !== "color") selectGradientType("color");
      } else {
        props.setHex(sclColors[index][value]);
      }


  }

  return (
      <DivSelect value={selected} options={options} handleOptionClick={handleClick} defs="form-noLab-xsmall"  defColors="form-col-orange"
        className="no-shrink a-width-110"  margin="none" noIcon={true} topDrop={false}/>
  );

}

export default BlockSelector;
