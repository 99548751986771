import React, {useState} from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import Link from '../Link';
import './ulSlider.css';
import {calcSlides, extractFromMediaObject, calcCols} from './calculators.js';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save
import ArrowsNew from '../ArrowsNew';
import Li from './Li';

function Inner(props) {
  /******* Media Queries ****/
  let media = {};
  media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  media.isSmarthPhone = useMediaQuery({ maxWidth: 734 });
  media.isTablet = useMediaQuery({ maxWidth: 1250, minWidth: 735 });
  const [touchX, setTouchX] = useState(null);
  /******* Media Queries ****/

    if (!props.rows || !Array.isArray(props.rows)) {

      return null;

    }

    // slides is an array of inline blocks with the first 5 slides, eahc block has a 100% width, the total array 500%, so 400% is hidden. It's always displayed the 3rd element except when an aniamtion is in progress
    // In animation prev the array traslates from the 4th slide to the third (the third is always the final state), in animation next the translation is from the 2nd to the third slides
    // At every handling next or prev the index of the current slide (the 3rd in the array) changes and a new slide array is build in order to aniamte from 2 to 3 or from 4 to 3
    const touchStart = (e)=> setTouchX(e.changedTouches[0].clientX);
    const touchEnd = (e)=> {
      if (touchX === null) {
        return;
      } else {
        const x = e.changedTouches[0].clientX;
        const delta = Math.abs(x - touchX);
        if (delta > 70) {
            if (x > touchX) {
              props.prev();
            } else {
              props.next();
            }
        }
        setTouchX(null);
      }

    }
    const cols = calcCols(props.cols, props.endIndex); // cols is an integer from 3 to 11 specifying how many elemets should be loaded at every loop, the bigger is the number the more elements will be preloaded so when shifting to next or prev the elemet is already loaded and the animation will not display an empty image. If the autopilot time is short or the user is expected to press ofthen the next/prev buttons cols should be hight, if there is enought time to load the images cols = 3 will only preload the next and prev images.
    const slides = calcSlides(props.index, props.endIndex, props.circle, props.rows, cols);
    const animation = (props.animate == "prev" ) ? "ul-sld-anim-prev" : ((props.animate == "next" ) ? "ul-sld-anim-next" :((props.animate == "skip" ) ? "w3-animate-opacity" : ""));
    let arrowStyle;
    if (props.rows[props.index].arrowStyle) { // if there is a custom arrowStyle for the current slide we use that
      arrowStyle = extractFromMediaObject(media, props.rows[props.index].arrowStyle);
    } else { // otherwise we pick the arrowStyle set for the whole componet
      arrowStyle = extractFromMediaObject(media, props.arrowStyle);
    }
    // if circle is off we hide the arrows when the left and right ends are met
    const showLeftArrow = ((!props.circle && props.index == 0) || props.hideArrows || (props.endIndex == 0)) ? false : true;
    const showRightArrow = ((!props.circle && props.index == props.endIndex) || props.hideArrows || (props.endIndex == 0)) ? false : true;
    const animationTime = props.animationTime ? {animationDuration: props.animationTime+"ms"} : {};
    const colsClass = " ul-sld-cols-" + cols + " ";
    const opacity = media.isSmarthPhone ? 0.3 : "";
    // This componet does only the sliding, the handling of the content of the slide is done byt the cloned function imported via props.rowFunction, so every component can design his own slides, Ulslider cares only of arranging the slides as li elements in a ul and displaying the right one while shifting left and right of the array
    return (
      <div className="uld-sld-outer" onTouchStart={touchStart} onTouchEnd={touchEnd}>
        <div className={"ul-sld-cont" + colsClass + (props.outerContainer ? props.outerContainer : "") + (!props.showOverflow ? " ov-hid" : "")}>
            <ul className={"ul-sld-ul ul-clear-inline " + animation} style={animationTime}>
                {slides.map((li, index) => <Li li={li} key={index} ckey={index} cols={cols} inViewPort={props.inViewPort} rowFunction={props.rowFunction} index={props.index}/>)}
            </ul>
            <ArrowsNew left={showLeftArrow} iconColor={props.iconColor} right={showRightArrow} handleSlideLeft={props.prev} handleSlideRight={props.next} topPosition={props.arrowTopPosition} opacity={opacity}  {...arrowStyle} key={props.startIndex}/>
        </div>
        </div>
    );

}

export default Inner;
