import React from 'react';
import './imageBlock.css';
import Link from '../../Link.js';
import Caption from './Caption';

function ImageBlock(props) {
  if (!props.src) {
    return null;
  } else {
    const container = props.className ? props.className : "cn-img-block";
    return (
        <div className={container}>
                <img className="w3-block" src={props.src} alt={props.alt}/>
                <Caption caption={props.caption} />
        </div>
    );
  }
}

export default ImageBlock;
