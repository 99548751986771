import React, {useState, useEffect} from 'react'; import './rangeActionsBar.css';import Button from '../../../../Buttons/Button';
import {FormInput, DivSelect} from '../../../../forms/forms';import SvgIcon from '../../../../SvgIcon'; import SaveActions from './SaveActions';
  import Converter from './Converter';

/*
    <div className=""></div>
*/


function RangeActionsBar(props) {
    if (window.innerWidth < 734 && !["converter","color-handling"].includes(props.visibleSections)) {
      return null;
    } else {
      return (
        <div className={"cl-range-right-bar "+(props.focused ? "conv-is-focused" : "")} id="range-act-bar" >
            <SaveActions {...props}/>
            <Converter {...props} />
        </div>
      );
    }

}






export default RangeActionsBar;
/*
<Button text="Save Changes" click={(props.pickerColor != props.activeColor) ? ()=>props.handleColor(props.pickerColor, "picker") : false} size="a-btn-tiny-5 a-btn-height-35" color="a-btn-green-1 mw-170 " />
<Button text="Discard changes" click={(props.pickerColor != props.activeColor) ? ()=>{props.setPickerView("info"); props.setPickerColor(props.activeColor)} : false}  size="a-btn-tiny-5 a-btn-height-35" color="a-btn-tomato mw-170 w3-margin-top " />
<Button text="Save as new Color" click={(props.pickerColor != props.activeColor) ?()=>props.hexToColor(props.pickerColor) : false} color="a-btn-blue-1 mw-170 w3-margin-top" size="a-btn-tiny-5 a-btn-height-35"/>
<Button text="Save to bucket" click={(props.pickerColor != props.activeColor) ?()=>props.addToBucket(props.pickerColor) : false}  size="a-btn-tiny-5 a-btn-height-35" color="a-btn-blue-1 mw-170 w3-margin-top " />
*/
