import {DivSelect} from '../../../../../forms/forms';

const MoveGradient = (props) => {
    const {gradient, selectedGradient, sclColors, index, setSclColors, selectGradient, gradEl, setGradEl} = props.shared;
    const colors = gradient[selectedGradient].colors;
    let options = [];
    for (let i=0; i<colors.length;i++) {
      options[i]= ["Move to "+i, i];
    }
    const spreadPositions = ()=> {
      const width = Math.round((100 / (colors.length -1))*10) / 10;
      for (let i = 0; i<colors.length-1; i++) {
        colors[i].position = (i * width);
      }
      colors[colors.length -1].position = 100;
    }

    const handler = (value)=> {
        const deleted = colors.splice(gradEl, 1);
        colors.splice(value, 0,deleted[0]);
        spreadPositions();
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);  setGradEl(value);
    }

      return (
        <DivSelect value={gradEl} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 "  margin="none" noIcon={true} />
    );
}

export default MoveGradient;
