import React  from 'react';
import './luke.css'; import './css/dimensions.css';  import './css/font.css';import './css/margin.css';import './css/containers.css';
import './w3.css';import './css/pdS.css';import './css/pdTB.css';import './css/pdLR-full.css';import './css/heights.css';
import './App.css';
import Link from './Link';
import CnnArticle from './CnnArt/CnnArticle';
import MenuBars from './MenuBars/MenuBars';
import Footer from './Footer';
import Home from './Home/Home'; import NewFeed from './Home/Feed/CreateEdit/NewFeed';
import Login from './Login'; import FeedView from './Home/Feed/FeedView'; import Tags from './Home/Feed/Tags/Tags';
import Metas from './Home/Metas/Metas'; import EditImages from './Images/EditImages/EditImages';import BatchTagsInFeeds from './Home/BatchTagsInFeeds/BatchTagsInFeeds';
import BatchMediaEditing from './Home/BatchMediaEditing/BatchMediaEditing'; import Samples from './Samples/Samples';

//import {} from './Pages/servicesRows';
// import {pagesList} from './Options/pagesList.js';
//npm install react-player
//import Home from './Home';
// npm install react-responsive --save
// npm i material-icons-react
// npm install --save react-twitter-embed
// font-family: BreraCondensed-Black

function is_landscape() {
  return (window.innerWidth > window.innerHeight);
}




//  "homepage": "https://www.myukraine.net/react/home/",

class App extends React.Component {
    constructor(props) {
        super(props);
        this.topBarDef = {showTopBar: true, noPadding: false, topBarHeightsObj: {l:"60",m:"60",s:"60"}, color: "a-btn-ukraine-flag-blue",
        buttonStyle:"filled-dark",  position: "fixed", overlay : false, controlledBy: null, noFilledBkg: false};
        this.state = {pages: [], pagesCounter: 0, mobileLandscape: false, showLogin: false, homeTags:[], feed:null, selectedFeedsIds: null,
                      currentMeta: null, topBar: {...this.topBarDef}};
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleFeed = this.handleFeed.bind(this);
        this.handleOrientationChanges = this.handleOrientationChanges.bind(this);
        this.handleTags = this.handleTags.bind(this);
        this.retriveMetaObjFromID = this.retriveMetaObjFromID.bind(this);
        this.handleTopBar = this.handleTopBar.bind(this);
        //this.setMetaAndTags = this.setMetaAndTags.bind(this);
    }

    handleTopBar(obj) {
      const value = obj ? obj : {};
      this.setState({topBar: {...this.topBarDef, ...value}});
    }

    handlePageClick(id) {

        let pages = this.state.pages;
        const currentScroll = document.documentElement.scrollTop;
        const meta = this.retriveMetaObjFromID(id);
        if (!id || id == "1") {
            this.setState({currentMeta: meta, homeTags: [], pages: [{id: "1", name: "Home", scroll: 0, url: "/", component: "home"}], pagesCounter: this.state.pagesCounter+1});
            if (currentScroll === 0) this.handleTopBar({color: "a-btn-ukraine-flag-blue a-0", overlay: true, noPadding: true, controlledBy: "heroHome", noFilledBkg:true});
        } else if (id == "back") {  // clicked back -> we set the srollTop to the saved value
          pages.pop();
          const meta = this.retriveMetaObjFromID(pages[pages.length-1].id);
          if (meta !== 1) this.handleTopBar();
          setTimeout(()=>(document.documentElement.scrollTop = pages[pages.length-1].scroll), 50)
          this.setState({pages: pages, currentMeta: meta, homeTags: meta.selectedTags ? meta.selectedTags : []});
        } else if (meta.component) {
          if (meta !== 1) this.handleTopBar();
          if (id != pages[pages.length-1].id){ // if we are calling the same id no need no add a duplicate page
            pages[pages.length-1].scroll = currentScroll;
            setTimeout(()=>(document.documentElement.scrollTop = 0), 10);
            pages.push({id:id, name: meta.btnName ? meta.btnName : "no name", scroll: 0, url: meta.url, component: meta.component});
            this.setState({pages: pages});
          }
        } else { // component is empty => default component home
          if (meta !== 1) this.handleTopBar();
          pages[pages.length-1].scroll = currentScroll;
          setTimeout(()=>(document.documentElement.scrollTop = 0), 10);
          pages.push({id:id, name: meta.btnName ? meta.btnName : "no name", scroll: 0, url: meta.url, component: "home"});
          this.setState({pages: pages, currentMeta: meta, homeTags: meta.selectedTags ? meta.selectedTags : []});
        }

    }



    handleOrientationChanges() {
      if ((window.innerWidth > window.innerHeight) && ( window.innerWidth < 850)) {
        this.setState({mobileLandscape: true});
    } else if ((window.innerWidth < window.innerHeight) && (window.innerWidth < 850)) {
        this.setState({mobileLandscape: false});
      }
    }

    handleLogin(user) {
      this.setState({user:user});
    }

    handleFeed(feed) {
      this.handlePageClick("feed");
      this.setState({feed: feed});
    }


    handleTags(tags) {
      if (Array.isArray(tags)) {
        this.setState({homeTags: tags});
        document.documentElement.scrollTop = 0;
      }
    }

    retriveMetaObjFromID(metaID) {
      const id = metaID ? metaID : "1";
      let meta; const metas = this.props.metas;
      for (let i = 0; i<metas.length; i++) {
         if (metas[i].id == id) {
            meta = metas[i];
          }
      }
      return meta;
    }
/*
    setMetaAndTags(metaID) {
        const meta = this.retriveMetaObjFromID(metaID);
        if (meta.metaType) {
          this.handlePageClick(meta.metaType);
        } else {
          this.setState({currentMeta: meta, homeTags: meta.selectedTags ? meta.selectedTags : [], pages:null});
        }

    }
*/

    componentDidMount() {

        if (this.props.noSelect) {
          document.oncontextmenu = function() {return false;} ;
        }
        window.addEventListener("resize", this.handleOrientationChanges, false); // when orientation changes we call handleOrientationChanges to set the new orientation in the state to trigger resizes.

        this.handlePageClick(this.props.currentMetaID);

        if (this.props.selectedTags) {
          this.setState({homeTags:this.props.selectedTags});
        }

        if (this.props.selectedFeedsIds) {
          this.setState({selectedFeedsIds:this.props.selectedFeedsIds});
        }

        this.setState({user: this.props.user});

        if (this.props.selectedFeed) { // I think not used anymore
            const page = this.pagesList["feed"];
            const pages = [{id: "home", name: "Home", scroll: 0, content: null, url: "/", component: "home"},{id: "feed", name: page.name, content: page.content, component: page.component, url: page.url}];
            this.setState({pages: pages, feed: this.props.selectedFeed});
        }
    }



      componentWillUnmount() {
        window.removeEventListener("resize", this.handleOrientationChanges, false); // when orientation changes we call handleOrientationChanges to set the new orientation in the state to trigger resizes.
      }

/*
"homepage": "https://www.myukraine.net/react/home/",
window.addEventListener("touchmove", this.blockReload, {passive: false});
window.addEventListener("scroll", this.scrollCheck, false);
window.addEventListener("touchstart", this.touchStart, false);
window.removeEventListener("scroll", this.scrollCheck, false);
window.removeEventListener("touchmove", this.blockReload, {passive: false});
window.removeEventListener("touchstart", this.touchStart, false);

componentDidUpdate(prevProps, prevState) {
    if (prevState.scrollBlock !== this.state.scrollBlock) {
      if (this.state.scrollBlock) {
        console.log("add listener");
        window.addEventListener("touchmove", this.blockReload, {passive: false});
      } else {
        console.log("remove listener");
        window.removeEventListener("touchmove", this.blockReload, {passive: false});
      }
    }
}

blockReload(e) {
    if (e.changedTouches[0].clientY > this.state.touchY) e.preventDefault();
}

scrollCheck(e) {
  if ((document.documentElement.scrollTop === 0) && !this.state.scrollBlock) {
    this.setState({scrollBlock: true});
  } else if ((document.documentElement.scrollTop > 0) && this.state.scrollBlock) {
    this.setState({scrollBlock: false});
  }

}

touchStart(e) {
  this.setState({touchY: e.changedTouches[0].clientY});
}

this.touchStart = this.touchStart.bind(this);
this.scrollCheck = this.scrollCheck.bind(this);
this.blockReload = this.blockReload.bind(this);
*/

    render() {

        const user = this.state.user
        if (!this.state.pages.length) return null;
        let currentPage = !this.state.pages ? "start" : this.state.pages[this.state.pages.length -1].component;
        //currentPage =  "colors" ;
        const content = !this.state.pages  ? {} : this.state.pages[this.state.pages.length -1].content;
        const back = (!this.state.pages || (this.state.pages.length < 2)) ? null : this.state.pages[this.state.pages.length -2]; //
        const enhanced = this.props.bot? " " : " enhanced";
        const actualTopBarHeight = (window.innerWidth > 1800) ? this.state.topBar.topBarHeightsObj.l : ((window.innerWidth > 900) ? this.state.topBar.topBarHeightsObj.m : this.state.topBar.topBarHeightsObj.s);
        const mainShare = {...this.state.topBar, setTopBar:this.handleTopBar, bot: this.props.bot, topBarHeight: this.state.topBar.showTopBar ? actualTopBarHeight : 0};
        const tbarCss = this.state.showTopBar ? "" : " no-top-bar ";
        return (
                <div className={"col-fBlu-l5 h100vh top-bar-vars "  + enhanced + (this.props.noSelect ? " noselect" : "")+tbarCss}>
                    <MenuBars  metas={this.props.metas} menus={this.props.menus} mainShare={mainShare} iconBarColor="dodgerblue" show={currentPage}  clickHandler={this.handlePageClick} topBar={this.props.topBar}
                    tagsHandler={this.handlePageClick} user={user} logHandler={this.handleLogin} pagesList={this.pagesList} bot={this.props.bot} back={back} mobileLandscape={this.state.mobileLandscape} />
                    <Login show={this.state.user.showLogin}  logHandler={this.handleLogin} user={user}/>
                    <Home show={currentPage} mainShare={mainShare} key={this.state.homeTags.toString() + "home" + this.state.pagesCounter} feeds={this.props.feeds} tags={this.props.tags} bot={this.props.bot} pageClick={this.handlePageClick} pagesList={this.pagesList} homeTags={this.state.homeTags}
                     user={user} feedView={this.handleFeed} selectedFeedsIds={this.state.selectedFeedsIds} medias={this.props.medias} meta={this.state.currentMeta}/>
                    <CnnArticle show={currentPage} clickHandler={this.handlePageClick} {...content} bot={this.props.bot} back={back}/>
                    <FeedView show={currentPage} tags={this.props.tags} feed={this.state.feed} bot={this.props.bot} back={back} user={user} feedView={this.handlePageClick}/>
                    <NewFeed show={currentPage} clickHandler={this.handlePageClick} medias={this.props.medias} bot={this.props.bot} back={back} tags={this.props.tags} />
                    <Tags show={currentPage} clickHandler={this.handlePageClick}  bot={this.props.bot} back={back} tags={this.props.tags}/>
                    <Metas show={currentPage} clickHandler={this.handlePageClick}  bot={this.props.bot} back={back} metas={this.props.metas}  tags={this.props.tags}/>
                    <BatchTagsInFeeds  show={currentPage}  key={currentPage} bot={this.props.bot} back={back} user={user} feeds={this.props.feeds} tags={this.props.tags} {...this.props.medias}/>
                    <BatchMediaEditing  show={currentPage} bot={this.props.bot} key={currentPage+"media"} back={back} user={user} tags={this.props.tags} {...this.props.medias}/>
                    <Samples show={currentPage} mainShare={mainShare} buttons={this.props.buttons} colors={this.props.colors}  back={back}/>

                </div>
                );
    }

}
//<Apl />
//<TopBarC clickHandler={this.handleTopBarClick} type="BH" back={back} color="a-btn-blue-1" pageClick={this.handlePageClick} />
export default App;
/*

fetch("/home/prova", {method: 'POST', headers: {'Content-Type': 'application/json',  },body: JSON.stringify({name: "luca", surname: "lattanzi"}),})
            .then((res)=> res.json()).then( (result)=>{
                                                      this.setState({res:result})
                                                      },
                                                      (error) => { this.setState({  error: true});
}
                                                  );
<Footer />


"build": "react-scripts build",


var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", "csscolor");
*/
