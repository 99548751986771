import React from 'react';
import Button from '../../../../Buttons/Button'; import UlSlider from '../../../../UlSlider/UlSlider';

/*
    <div className=""></div>
*/

function  CheckedIcon(props) {
  if (!props.checked) {
    return null;
  } else {

    return (
      <div className="w3-display-topright ZI10 w3-margin-top w3-margin-right">
          <Button svg="done" size="a-btn-size-40-icon"  color="a-btn-orange-1" />
      </div>

    );
  }
}

function Row(props) {
  function  handleClick() {
    props.addMedia("actions", props.id);
  }
  if (!props.id) {
    return null;
  } else {

    const checked = (props.selectedIDs.indexOf(props.id) != -1);
    const text = checked ? "Remove" : "Add";
    return (
      <div className="w3-display-container pointer w3-section w3-padding-16 w3-container w3-gray" onClick={handleClick} >
          <CheckedIcon checked={checked}/>
          <div className="" >
              <b>{props.action_btnName}</b>: {props.action_caption} ({props.actionDate})
          </div>

      </div>

    );
  }
}

function Actions(props) {
   if (props.view != "actions") {
     return null;
   } else {
     return (
       <div className="w3-content w3-section-64 ">
              {props.rows.map((row) => <Row {...row} key={row.id} addMedia={props.addMedia}  selectedIDs={props.selectedIDs} />)}
       </div>
     );
   }
}


export default Actions;
