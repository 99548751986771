import {DivSelect} from '../../../../../forms/forms';

const DirectionSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const selGradient = gradient[selectedGradient];
    const colors = selGradient.colors;
    const direction = selGradient.direction;
    const options = ["to bottom","to top", "to right", "to left","angle"];
    const handler = (value)=> {
        gradient[selectedGradient].direction = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);
    }

    if (selGradient.type !== "linear-gradient") {
      return null;
    } else {
      return (
        <DivSelect value={direction} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-100"  margin="none" noIcon={true} />
      );
    }
}

export default DirectionSelector;
