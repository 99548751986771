import React from 'react';
import "./w3.css";
import "./luke.css";
import "./App.css";
import './arrowsNew.css';
import SvgIcon from './SvgIcon';


function ArrowRow(props) {

  let icon, position, opacity, iconContainer = "arr-ic-cont";
  let style = {}
  let arrow = "noselect sld-arr-cont sld-arr-size " + (props.circle ? "w3-circle" : "w3-round ");

  if (props.noBkg) {
    arrow +=  (typeof props.noBkg === 'string') ? (" " + props.noBkg + " ") : " arr-no-bkg ";
  } else  {  // opacity is set only if color is not none, because arr-no-bkg already sets the opacity. that's why I am not using else if, because the if (props.opacity) should be checked whatever the background color custom or default  unless it's none.
        if (props.color ) {
            arrow +=  (" " + props.color);
        } else {
          arrow += " arr-bkg-blue-def ";
        }
        if (props.opacity && (typeof props.opacity === 'string')) {
          arrow +=  (" " + props.opacity);
        } else if (props.opacity && !Number.isNaN(props.opacity)) {
          style.opacity = props.opacity;
        } else {
          arrow += " arr-bkg-opacity-def ";
        }
        if (props.size == "large") {
            arrow += " sld-arr-lg ";
        } else if (props.size == "medium") {
            arrow += " sld-arr-md ";
        } else if (props.size == "small") {
            arrow += " sld-arr-sm ";
        } else if (props.size == "tiny") {
            arrow += " sld-arr-tn ";
        } else if ((props.size == null) || (props.size == false)) {
          arrow += " sld-arr-lg ";
        } else if (typeof props.size === 'string') {
          arrow += " " + props.size + " ";
        }
  }



  switch (props.position) {
    case "in":  arrow += "sld-arr-in";
      break;
    case "out": arrow += "sld-arr-out";
      break;
    case "middle": arrow += "sld-arr-mid";
      break;
    default: arrow += "sld-arr-in";
  }

  if (props.topPosition) {
    arrow += (" " + props.topPosition + " ");
  }


  if (props.direction == "left") {
     icon = "arrow_back_ios";
     arrow += " w3-display-left ";
     if (props.shadow === true) arrow += " left-shad ";
     if (props.margin) style.marginLeft = props.margin;
   } else if (props.direction == "right") {
     icon = "arrow_forward_ios";
     arrow += " w3-display-right ";
     if (props.shadow === true) arrow += " right-shad ";
     if (props.margin) style.marginRight= props.margin;
   } else { // there is no direction so the arrow is Hidden -> return null
     return null;
   }

   if (props.top) {style.top = props.top};

  return (
      <div className={arrow} onClick={props.handleSlide} style={style}>
          <div className="flex-cen-cen h-100"  key={props.direction} >
              <div className={iconContainer + " noselect"}>
                <SvgIcon icon={icon} color={props.iconColor}/>
              </div>
          </div>
      </div>
  );

}


/*

<ArrowsNew left right handleSlideLeft handleSlideRight   {...style} />
  style = {
  noBkg = bol || "cusotm class name"; Default is false, if activated deactivates the background color, if it set to true default class arr-no-bkg is used tos et a custom class enter the new class name instead of bol true
  size: "tiny/small/medium/large/customClass",  // default large. custom class: .sld-arr-lg
  circle: bol // defines whether the container is circle or rectangular
  color: "arr-bkg-blue-def" -> css default class to be renamed and changed arr-bkg-blue-def{--bkgArrCol: 30,144,255;} The variables contains a rgb color
  opacity = "arr-no-bkg/def" -> css default class to be renamed and changed arr-bkg-opacity-def {--bkgArrOpac: .4;--bkgArrOpacHov: .8;}
  position: "out"/def; other values "in", "middle"
  margin: int=5/def; margin adjustament relative to the position chosen, default 5 for in & out, 0 for middle;
  arrowColor = "rgb(68,68,68)" (def)
  shadow = bol
}
  handleSlideRight
  handleSlideLeft
  left= bol  true->show arrow, false Hide
  right = bol  true->show arrow, false Hide

  Default  classes to be renamed to customize:
  props.color ->.arr-bkg-blue-def {--bkgArrCol: 30,144,255/background color of the arrow's container in rgb format /;--iconCol: rgb(28,28,28);--iconColHover: rgb(28,28,28);--arrContShadow: rgba(0,0,0,0.4) /container's shadow color}
  props.opacity: .arr-bkg-opacity-def {--bkgArrOpac: .4/Opacity of the contaier background/;--bkgArrOpacHov: .7;}
  props.noBkg: .arr-no-bkg{--bkgArrCol: 0,0,0 /background color of the arrow's container in rgb format/;--bkgArrOpac: .0 /Opacity of the contaier background/;--bkgArrOpacHov: .0;--iconCol: rgb(28,28,28);--iconColHover: rgb(180,180,180); }
  props.size: .sld-arr-lg {--cont-height: 95px /icon container/; --cont-width: 40px/icon container/; --cont-width-circle: 48px/icon circle container width/;--icon-size: 33px;--iconLeftMarLeft: 15px/Left Arrow, Margin left adjustament of the icon to fix the space embedded in the google icons/;--iconRightMarLeft: 1px/Right Arrow/}
*/

 function ArrowsNew(props) {

      let arrows = [];

      if (props.left) {
        arrows.push(<ArrowRow direction="left" handleSlide={props.handleSlideLeft}  {...props} key="left" />);
      }
      if (props.right) {
        arrows.push(<ArrowRow direction="right" handleSlide={props.handleSlideRight} {...props} key="right" />);
      }
        return arrows;
  }



export default ArrowsNew;
