import React from 'react';
import {FormInput, FormSelect, DivSelect} from "../../forms/forms.js";
import Button from '../../Buttons/Button'; import SlidesBar from './SlidesBar';
/*
    <div className=""></div>
*/
function FormArrayInput(props) {

  function handleChange(e) {
    props.handleChange(e.target.value, props.index, props.field);
  }


  return (

        <FormInput name={props.field} label={props.label ? props.label : props.field} value={props.rows[props.index][props.field]} maxWidth="" handleChange={handleChange}  color="w3-light-gray" validate=""  />

  );
}

function HeroSlides(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className="w3-section">
              <SlidesBar {...props}/>
             <div className="w3-row w3-section">
                  <div className="w3-row">
                      <div className="w3-col m10">
                            <FormArrayInput field="title" index={props.selectedSlide} rows={props.rows} label="Title" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m2">
                            <FormArrayInput field="titleWidth" index={props.selectedSlide} rows={props.rows} label="Title Width" handleChange={props.handleSlide}/>
                      </div>
                  </div>
                  <div className="w3-row">
                      <div className="w3-col m10">
                            <FormArrayInput field="subtitle" index={props.selectedSlide} rows={props.rows} label="Subtitle" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m2">
                            <FormArrayInput field="subtitleWidth" index={props.selectedSlide} rows={props.rows} label="Subtitle Width" handleChange={props.handleSlide}/>
                      </div>
                  </div>
                  <div className="w3-row">
                      <div className="w3-col m10">
                            <FormArrayInput field="text" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m2">
                            <FormArrayInput field="textWidth" index={props.selectedSlide} rows={props.rows} label="Text Width" handleChange={props.handleSlide}/>
                      </div>
                  </div>

                  <FormArrayInput field="action" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                  <FormArrayInput field="actionBtnName" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                  <FormArrayInput field="url" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                  <div className="w3-row ">
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosSPX" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosSPY" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosSLX" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosSLY" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosTPX" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosTPY" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosTLX" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosTLY" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosDX" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>
                      <div className="w3-col m1 ">
                            <FormArrayInput field="imPosDY" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                      </div>

                  </div>
                  <FormArrayInput field="portraitUrl" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>
                  <FormArrayInput field="style" index={props.selectedSlide} rows={props.rows} label="" handleChange={props.handleSlide}/>


             </div>
             <div className="w3-section-32 w3-center ">
                  <Button text={"Delete Slide "+(props.selectedSlide + 1)} click={true} color="a-btn-blue-1" size="a-btn-size-3" id="remove"  className="" clickHandler={props.selectSlide}/>
             </div>
       </div>
     );
   }
}


export default HeroSlides;
