import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, contrastColor} from '../converters.js';
import React, {useState, memo} from 'react'; import ScBlToolTip from './ScBlToolTip'; import Level from './Level';
import SvgIcon from '../../../../SvgIcon';
// <div className=""></div>



const ScaleBlock  = (props) => {

    const col = {};
    col.rgb = props.rgb ? props.rgb : hslToRgb(props.hsl);
    col.hsl = props.hsl ? props.hsl : rgbToHsl(props.rgb);
    col.hex = rgbToHex(col.rgb);
    const weight = props.selected ? "w600" : "w300";
    const fontColor = {color: rgbToHex(contrastColor(col.rgb))};
    const hideSelected = props.selected ? "w3-hide" : "";

   return (
       <div className={"cl-scale-block-21 w3-text-white " +(props.selected ? " cl-selected-block":"")}  style={{backgroundColor: col.hex}} onClick={()=>props.selected ? null : props.setHex(col.hex)} id={"sclblock"+props.index+props.type}>
           <Level {...props}/>
           <ScBlToolTip {...props} {...col} close={()=>props.setToolTip(false)} selectedToolTip={props.selectedToolTip} index={props.index}/>
           <Text weight={weight} fontColor={fontColor} hex={col.hex}/>
           <div className={"phone-only w3-display-top a-pWdith-100 w3-center a-h-80 "+hideSelected} onClick={(e)=>{props.setToolTip(props.index);e.stopPropagation();}}>
              <SvgIcon icon="info" size="60%" color={fontColor.color}/>
           </div>
       </div>
     );

};

const Text = (props) =>  <div className={"cl-rotated-selected noselect "+props.weight} style={props.fontColor}>{props.hex.toUpperCase()}</div>;

function compare(next, prev) {
  return false;
}


export default React.memo(ScaleBlock, compare);
