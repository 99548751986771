import React from 'react';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon'; import {FormInput, FormDate} from "../../../forms/forms.js";



class EditVideo extends React.Component {
  constructor(props) {
      super(props);
      const date = (this.props.video_date && (this.props.video_date != "0000-00-00")) ? new Date(this.props.video_date) : null;
      this.state = {video_url: this.props.video_url, video_platform: this.props.video_platform, video_caption: this.props.video_caption, video_description:this.props.video_description, video_date: date};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.videoRef = React.createRef();
      this.handleDate = this.handleDate.bind(this);
  }



    handleChange(e) {
      const target = e.target;
      const value =  target.value;
      this.setState({[target.name]: value});
    }

    handleDate(date) {
      this.setState({video_date: date});
    }

    handleSubmit(e) {
      let alertMessage = "";
      if (!this.state.video_url ) {
          if (alertMessage ==  "") {
              alertMessage = "Url";
          } else {
              alertMessage = alertMessage + ", Url";
          }
          this.videoRef.current.setRedBackground();
      }

      if (alertMessage != "") {
          alertMessage = "Error: " + alertMessage + " cannot be empty!";
          //this.alertHandler(alertMessage);
          e.preventDefault();
      }
    }

    render() {

        return (
                <div className="w3-container w3-section w3-display-container w3-white">
                      <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                          <Link click={[this.props.editVideo, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>
                      </div>
                     <form action={"/feed/editVideo/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                        <FormInput name="video_url" label="Url" value={this.state.video_url} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate="" ref={this.videoRef} />
                        <FormInput name="video_platform" label="Platform" value={this.state.video_platform} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="video_caption" label="Caption" value={this.state.video_caption} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="video_description" label="Description" value={this.state.video_description} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormDate name="video_date" label="Date" date={this.state.video_date} maxWidth="" handleChange={this.handleDate}  color="w3-light-gray" validate=""  />
                        <div className="w3-margin-top-24 w3-margin-bottom ">
                            <input type="submit" value="Edit Video" className="w3-button  w3-content w3-blue h55  w3-round"/>
                        </div>
                    </form>
                </div>
        );
      }
}


export default EditVideo;
