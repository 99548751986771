import React from 'react';
import {FormInput, FormSelect} from "../../../forms/forms.js";

/*

*/

// const videos = ["videos",["id", "url","platform","caption"]];
function Row(props) {

  function handleChange1(e) {
    props.handleChange(e, props.i, "text")
  }


  return (
      <div className="w3-row">
        <FormInput name={"text"+props.i} label="Paragraph" value={props.texts[props.i].text} maxWidth="w3-col m4" handleChange={handleChange1}  color="w3-light-gray" validate=""  />


      </div>
  );
}

function Texts(props) {
   if (false) {
     return null;
   } else {
     let out = [];
     for (let i=0; i < props.count; i++) {
       out.push(<Row key={i} i={i} handleChange={props.handleChange} texts={props.texts}/>)
     }
     return out;
   }
}


export default Texts;
