import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import Link from '../Link';
import './ulSlider.css';
import {calcSlides} from './calculators.js';
import Inner from './Inner';

var isInViewport = function (elem) {
  if (!elem) return null;
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

class UlSlider  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {index: 0, animate: false, inViewPort:true};  // animate values are "next" (animating from prev to next slide), "prev" (animating from next to prev), false {no animation or animation ended}
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.scroll = this.scroll.bind(this);

      }

        endIndex = this.props.rows.length - 1;
        endAnim = this.props.animationTime ? (this.props.animationTime) : 370;
        autoTime = Number.isInteger(this.props.auto) ? (this.props.auto * 1000) : 8000;
        endAnimTimerID = null;

        autoPilot() {
          // First if auto pilot is on and there are at least two slides we clear the previous timeout and set a new timeout with handleNext,
          //because if this handler has been called by next click instead of the autopilot we have to reset the timer otherwise the timer would expire earlier
          // because running fromthe previous slide
          if (this.props.auto && (this.endIndex > 0) && this.state.inViewPort) {
            if (this.timerIDX) clearTimeout(this.timerIDX);
            this.timerIDX = setTimeout(() => this.handleNext(), this.autoTime);
          }
        }

        scroll() { // we check if the slider in in view port otwerwise we stop the autopilot
          if (!this.props.sliderID) return null;
          const element = document.getElementById(this.props.sliderID);
          if (isInViewport(element) === !this.state.inViewPort) {
              this.setState({inViewPort: !this.state.inViewPort});
              if (this.timerIDX) clearTimeout(this.timerIDX);
              this.autoPilot();
          }
        }

        handleNext() {
          this.autoPilot();
          if ((this.state.index == this.endIndex) && this.props.circle) { // if we reached the last element and circle is on we set the enxt index to 0 to start over from beginning
              this.setState({index:0, animate: "next"});
          } else if (this.state.index == this.endIndex) { // last element but circle is false so we do nothing and return null because we cannot go next
            return null;
          } else { // index gets incremented by 1 and animate set to next
            this.setState({index: this.state.index + 1, animate: "next"});
          }
          // few millisecond before the animation finishes we end it by setting animation to false and moving to the final state, to fix a strange glitch in safari
          if (this.endAnimTimerID) clearTimeout(this.endAnimTimerID);
          this.endAnimTimerID = setTimeout(() => this.setState({animate: false}), this.endAnim);
        }

        handlePrev() {
          this.autoPilot();
          if ((this.state.index == 0) && this.props.circle) { // auto is on and index is 0 so we set index back to the last element endIndex
              this.setState({index: this.endIndex, animate: "prev"});
          } else if (this.state.index == 0) { // return null because cannot prev than 0 if auto is off
            return null;
          } else {
            this.setState({index: this.state.index - 1, animate: "prev"});  // index+=-1 and animations et to prev
          }
          if (this.endAnimTimerID) clearTimeout(this.endAnimTimerID);
          this.endAnimTimerID = setTimeout(() => this.setState({animate: false}), this.endAnim);
        }

        handleSelection(index) {
            let nextIndex = index;;
            if (index == "pause") {
              nextIndex = 0;
              if (this.timerIDX) {clearTimeout(this.timerIDX)};
            } else if (index == "resume") {
              this.autoPilot();
            } else {
              this.autoPilot();
              const animation = "skip";
              this.setState({index: nextIndex, animate: animation});
              // same same as in handleNext
              if (this.endAnimTimerID) clearTimeout(this.endAnimTimerID);
              this.endAnimTimerID = setTimeout(() => this.setState({animate: false}), this.endAnim);
            }

        }

        componentWillMount() {
          if (this.props.startIndex) {
            this.setState({index: this.props.startIndex,}); // ******Attewntion Should be checked if this is the right place
          }
        }

        componentDidMount() {
          if (this.props.sliderID && this.props.auto) {
            window.addEventListener("scroll", this.scroll);
            this.autoPilot();
          }


        }



        componentWillUnmount() {
          if (this.timerIDX) clearTimeout(this.timerIDX);
          if (this.endAnimTimerID) clearTimeout(this.endAnimTimerID);
          window.removeEventListener("scroll", this.scroll);
        }

        render() {

            // if the calling component wants to render not only the image slider but also some other content changing at every slide changes, it must provide a SliderContainer row function that will receive the same data object as the slider from which it can build the content relative to that slide

            const SliderContainer = ({rowFunction}) => rowFunction ? React.cloneElement(rowFunction, {endIndex:this.endIndex, index:this.state.index, rows: this.props.rows, slider: <Inner endIndex={this.endIndex} index={this.state.index} animate={this.state.animate} next={this.handleNext} prev={this.handlePrev} inViewPort={this.state.inViewPort} {...this.props}/>, clickHandler: this.handleSelection, sliding: this.state.animate}) : null;
            if (this.props.sliderContainer) { // there is a SliderContainer row function so we render Inner inside it, passed as a prop
              return (
                  <SliderContainer rowFunction={this.props.sliderContainer} />
              );
            } else { // there is no SliderContainer row function so we render directly Inner component with the slide
              return <Inner endIndex={this.endIndex} index={this.state.index} animate={this.state.animate} next={this.handleNext} prev={this.handlePrev} inViewPort={this.state.inViewPort} {...this.props}/>;
            }

        }


}

export default UlSlider;

/*
<UlSlider rows circle arrowStyle hideArrows auto showOverflow outerContainer animationTime/ cols>
rows = [row,row,row....] Each row is an object whose properties are passed to  the row function ecpept arrowStyle that if set inside the row overrides for that row only the arrowStyle propped to the component
auto = {true || false || int (in seconds)} -> auto right slide: if true autupilot is on with def value of 8 seconds, otherwise an integer value sets the number of seconds between each right shift. False turnes off the autopilot
hideArrows={bol} hide the arrows, useful with auto on if desired
circle={bol} cicle when start and end of the array is reached
showOverflow={bol} if width is 100% and set it to true to alow overflow if needed fore the hero rotator otherwise leave it to False
outerContainer="className" : class to style the outerContainer like border, box shadow....
animationTime={int} : time to complete an animation left to right or right to left in ms. def is 400. Enter as a number not as a string
cols={int}  is an integer from 3 to 11 specifying how many elemets should be loaded at every loop, the bigger is the number the more elements will be preloaded so when shifting to next or prev the elemet is already loaded and the animation will not display an empty image. If the autopilot time is short or the user is expected to press ofthen the next/prev buttons cols should be hight, if there is enought time to load the images cols = 3 will only preload the next and prev images.
*/
