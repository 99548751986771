import React from 'react';
import {FormInput, FormSelect} from "../../forms/forms.js"; import MetaInputs from './MetaInputs'; import EditRow from './EditRow';
import NewFeedTags from '../Feed/AddMediaBatch/NewFeedTags'; import HeroSlides from './HeroSlides'; import './metas.css';
import Button from '../../Buttons/Button';import HeroRotator from '../../HeroRotator/HeroRotator';

/*
    <div className=""></div>
*/


function Metas(props) {
    if (!["editMetas","newMetas"].includes(props.show)) {
      return null;
    } else {
      return <MetasInner {...props} />;
    }
}


class MetasInner extends React.Component {
  constructor(props) {
      super(props);
      // edit.Metas is an array loaded with all propped metas to be used in editMeta state when editwhole is false => we are batch editing or deleting all the metas objects without slides and tags
      this.state = {tagsUri: "", metaTitle: "",metaDescription: "", btnName: "", component:"", editMetas: [], tags: [], editTags: false, editSlides: false, selectedSlide: 0,
      editWhole: false, slides: null, heroRotatorView: false};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.resetChange = this.resetChange.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
      this.handleTags = this.handleTags.bind(this);
      this.handleSlide = this.handleSlide.bind(this);
      this.handleTagsView = this.handleTagsView.bind(this);
      this.handleHeroSlidesView = this.handleHeroSlidesView.bind(this);
      this.handleSelectSlide = this.handleSelectSlide.bind(this);
      this.handleEditMetas = this.handleEditMetas.bind(this);
      this.handleEditWhole = this.handleEditWhole.bind(this);
      this.handleHeroRotatorView = this.handleHeroRotatorView.bind(this);
      this.focus = this.focus.bind(this);
  }

  focus(element) {
    if (element == "email") {
      this.emailRef.current.autofocus();
    }
  }

  handleChange(e) {
    const target = e.target;
    const value =  target.value;
    this.setState({[target.name]: value});
  }

  resetChange(e) {
    const target = e.target;
    this.setState({[target.name]: ""});
  }

  handleEditMetas(e,i) {
    let metas = this.state.editMetas;
    metas[i][e.target.name] = e.target.value;
    this.setState({editMetas:metas});
  }

  handleEdit(e) {
    return;
  }

  handleSubmit(e) {
    return;
  }

  handleTags(e, i) {
    const tags = this.state.tags;
    tags[i].checked = e.target.checked;
    this.setState({tags:tags});
  }



  handleTagsView(bol) {
    document.documentElement.scrollTop = 0;
    this.setState({editTags: bol});
  }

    handleHeroSlidesView(bol) {
      this.setState({editSlides: bol});
    }

    handleHeroRotatorView(bol) {
      this.setState({heroRotatorView: bol});
    }

  handleSelectSlide(index) {
    if (index === "add") {
      let slides = this.state.slides;
      slides.push({title:"", subtitle:"", titleWidth:"", subtitleWidth:"", text:"", textWidth: "", action:"", actionBtnName:"", url:"",imPosSPX: "",imPosSPY: "",imPosSLX: "",imPosSLY: "",imPosTPX: "", imPosTPY: "", imPosTLX: "", imPosTLY: "", imPosDX: "", imPosDY: "", style:""});
      this.setState({slides: slides, selectedSlide: slides.length-1});
    } else if (index == "remove") {  // remove the selected slide from slides array using the selectedSlide from satte as the index of the eleemnt to remove
      let slides = this.state.slides;
      slides.splice(this.state.selectedSlide, 1); //
      this.setState({slides: slides, selectedSlide:0});
    }else if (index !== this.state.selectedSlide) {
      this.setState({selectedSlide: index});
    }
  }

  handleSlide(value,i,field) {
    let values = this.state.slides;
    values[i][field] = value;
    this.setState({slides:values});
  }

  importTagsArray(tags, selectedIds) {
      let tagsArray = [];
      for (let i=0; i<tags.length; i++) {
        const tag = {id: tags[i].id, name: tags[i].name, checked: (selectedIds && Array.isArray(selectedIds)) ? selectedIds.includes(tags[i].id) : false};
        tagsArray.push(tag);
      }
      console.log(selectedIds);
      return tagsArray;
  }

  handleEditWhole(index) {
    if (index === false) {
      this.setState({editWhole:false});
    } else {
      const meta = this.props.metas[index];
      const slides = (meta.slides && meta.slides.length) ? meta.slides : [{title:"", subtitle:"", titleWidth:"", subtitleWidth:"", text:"", textWidth: "", action:"", actionBtnName:"", url:"",imPosSPX: "",imPosSPY: "",imPosSLX: "",imPosSLY: "",imPosTPX: "", imPosTPY: "", imPosTLX: "", imPosTLY: "", imPosDX: "", imPosDY: "", style:""}];
      const tags = this.importTagsArray(this.props.tags, meta.selectedTags);
      document.documentElement.scrollTop = 0
      this.setState({editWhole:true, slides: slides, tagsUri: meta.tagsUri, metaTitle: meta.metaTitle, metaDescription: meta.metaDescription,
        btnName: meta.btnName, component:meta.component, tags: tags, id:meta.id});
    }

  }

  calcTagsIds(tags) {
    let out = [];
    for (let i = 0; i < tags.length; i++) {
        if (tags[i].checked) {
          out.push(tags[i].id);
        }
    }
    return out;
  }

  createEmptyTagsArray() {
      let tagsArray = [];
      if (this.props.tags) {
          for (let i=0; i<this.props.tags.length; i++) {
            const tag = {id: this.props.tags[i].id, name: this.props.tags[i].name, checked: false};
            tagsArray.push(tag);
          }
      }
      this.setState({tags:tagsArray});
  }

  createEmptySlidesArray() {
    this.setState({slides: [{title:"", subtitle:"", titleWidth:"", subtitleWidth:"", text:"", textWidth: "", action:"", actionBtnName:"", url:"",imPosSPX: "",imPosSPY: "",imPosSLX: "",imPosSLY: "",imPosTPX: "", imPosTPY: "", imPosTLX: "", imPosTLY: "", imPosDX: "", imPosDY: "", style:""}]});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show !== prevProps.show) {
        if (this.props.show == "newMetas") {
          this.createEmptySlidesArray();
          this.createEmptyTagsArray();
          this.setState({tagsUri: "", metaTitle: "", metaDescription: "",btnName: "", component:"", id: null});
        }
        this.setState({editWhole: false, selectedSlide: 0, editMetas: this.props.metas});
        document.documentElement.scrollTop = 0;
    }

  }

  componentDidMount() {
      this.setState({editMetas: this.props.metas});
      this.createEmptyTagsArray();
      this.createEmptySlidesArray();
  }


  render() {

    if (this.state.heroRotatorView) {
      return (
          <div className="meta-hero-rot-preview">
                  <div className="w3-section-32 w3-margin-right-64 w3-display-topright ZI10">
                        <Button size="a-btn-size-3" color="a-btn-blue-1" text="Close" click={true} id={false} clickHandler={this.handleHeroRotatorView} />

                  </div>
                <HeroRotator rows={[this.state.slides[this.state.selectedSlide]]} iconColor="black"/>
          </div>
      );
    }
    if (this.props.show == "editMetas" && this.props.metas.length && !this.state.editWhole) { // (1)
      return (
        <div className="w3-content-1300 w3-section-32 w3-row h100vh">
            {this.state.editMetas.map((row, index) => <EditRow key={index} index={index} {...row} metas={this.state.editMetas} editMetaHandler={this.handleEditWhole} handleMetas={this.handleEditMetas} handleEdit={this.handleEdit}/>)}
        </div>
      );
    } else if (this.props.show != "newMetas" && !this.state.editWhole) { // (2) return null if we are not create or editing a page or batch editing pages
        return null;
      } else if (this.state.editTags) {// (3) Create / Editing Tags view called by either create or edit page
        return (
            <div className="w3-section-64 h100vh w3-content-1300">
                  <NewFeedTags tags={this.state.tags} selectTag={this.handleTags}/>
                  <div className="w3-section-32 w3-center ">
                        <Button size="a-btn-size-3" color="a-btn-blue-1" text="Done" click={true} id={false}  clickHandler={this.handleTagsView} />

                  </div>
            </div>
        );
      } else if (this.state.editSlides) { // (4) Create / Editing slides view called by either create or edit page
        return (
            <div className="w3-section-64 h100vh w3-content-1300">
                  <HeroSlides rows={this.state.slides} selectedSlide={this.state.selectedSlide} selectSlide={this.handleSelectSlide} handleSlide={this.handleSlide} showHeroRotator={this.handleHeroRotatorView}/>
                  <div className="w3-section-32 w3-center ">
                        <Button size="a-btn-size-3" color="a-btn-blue-1" text="Done" click={true} id={false} clickHandler={this.handleHeroSlidesView} />

                  </div>
            </div>
        );
      } else if (this.props.show === "newMetas" || this.state.editWhole) {  // (5) We end here if we are creating a nep page or if we are editing a page previously selected in (1)
        const tagsIds = this.calcTagsIds(this.state.tags);
        const action = (!this.state.editWhole) ? "/tags/createMeta" : ("/tags/editMeta/"+this.state.id);
        const submitText = (this.state.editWhole) ? "Edit Page" : "Create Page";
        return (
          <div className="w3-content-1300 w3-section-32 h100vh">
          <form action={action} method="post" onSubmit={this.handleSubmit}>
              <div className="w3-section">

              </div>
              <MetaInputs rows={this.state} handleChange={this.handleChange} />
              <input type="hidden" name="tags" value={JSON.stringify(tagsIds)} />
              <input type="hidden" name="slides" value={JSON.stringify(this.state.slides)} />
              <div className="w3-section-32 flex-cen-cen">
                    <Button size="a-btn-size-3" color="a-btn-blue-1" text="Add Tags" click={true} id={true} className="w-200 w3-margin-right-32" clickHandler={this.handleTagsView} />
                    <Button size="a-btn-size-3" color="a-btn-blue-1" text="Add Hero Slides" click={true} id={true}className="w-200 w3-margin-right-32" clickHandler={this.handleHeroSlidesView} />
                    <Button size="a-btn-size-3" color="a-btn-blue-1" text="Edit Metas" click={true} id={false} className="w-200 " clickHandler={this.handleEditWhole} />
              </div>
              <div className="w3-margin-top-24 w3-margin-bottom w3-center">
                  <input type="submit" value={submitText} className="w3-button  w3-content w3-blue h55 w-200 w3-round"/>
              </div>
          </form>
          </div>
        );
    }
  }

}





export default Metas;
