import React from 'react';


/*
    <div className=""></div>
    <div className="w3-row">
            {props.rows.map((row, index) => <Row {...row} key={index}/>)}
    </div>

function Li(props) {
    if (false) {
      return null;
    } else {
      return (

      );
    }
}
*/



function Controls(props) {
   if (!props.controls) {
     return null;
   } else {
     return (
            <div className="pagination-controls flex-cen-start">
                  <div className="pagination-info w3-margin-right-8">{props.controls}</div>
            </div>
     );
   }
}


export default Controls;
