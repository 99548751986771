import React, {useState, useEffect} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, contrastColor} from '../Range/converters';
import SvgIcon from '../../../SvgIcon';

const Icon = (props) => ( props.checked ? <SvgIcon icon="check" color="green" size="19px"/> : null);

const CheckCircle = (props) => {
  if (props.move.view) {
    const checked = props.move.colorsList.includes(props.id);
    return (
          <div className="p-abs-topRight-t20 w3-circle a-size-24 a-bkg-cornsilk flex-cen-cen" style={{border: `1px solid dodgerblue`}}>
                <Icon checked={checked} key={props.id+" "+checked.toString()}/>
          </div>
        );
  } else {
    return null;
  }
}

function Color(props) {
  const hex = props.color.hex;
  const [bkg, setBkg] = useState(hex);
  const move = props.move;
  if (props.updated === "deleted" || (props.listID != props.list)) {
    return null;
  } else {
    const hsl = rgbToHsl(hexToRgb(hex));
    hsl.l = (hsl.l > 70) ? ( hsl.l - 10) : (hsl.l + 10);
    const fontColor = rgbToHex(contrastColor(hexToRgb(hex)));
    const mouseOver = () => (bkg != hex) ? null : setBkg(hslToCss(hsl));
    const mouseOut = () => (bkg == hex) ? null : setBkg(hex);
    const handleClick = () => (move.view ? move.handleBtn(props.id) : props.edit(props.index));
    return (
        <div className="cl-color-box pointer  a-font-14-s p-rel noselect" style={{backgroundColor:bkg, color: fontColor}}  onMouseOver={mouseOver} onMouseOut={mouseOut} onClick={handleClick}>
              <div className="truncate ">{props.name}</div>
              <CheckCircle move={move} id={props.id} />
        </div>
    );
  }

}

export default Color;
