import React from 'react';
import "./centerBar.css";
import Link from '../../Link';
import BarLi from './BarLi';
import './topBar.css';
//   <div className=""><div>

function CenterBar(props) {
  if (!props.centerLeft) {
  return null;
  } else {
    return (
      <div className="c-bar h-100">

          <div className="tbar-center ">
                <ul className="ul-clear-inline flex-cen-start h-100 tbar-std" >
                  {props.centerLeft.map((li, index) => <BarLi {...li} {...props.btnProps} key={index}   />)}
                </ul>
                <ul className="ul-clear-inline flex-cen-start h-100 tbar-std" >
                  {props.centerRight.map((li, index) => <BarLi {...li} {...props.btnProps} key={index}  />)}
                </ul>
                <ul className="ul-clear-inline flex-cen-cen h-100 tbar-mobile" >
                  {props.centerMobile.map((li, index) => <BarLi {...li} {...props.btnProps} key={index}  />)}
                </ul>
          </div>

      </div>
    );
  }
}


export default CenterBar;
