import React from 'react';
import {FormInput, FormDate} from "../../../forms/forms.js";

/*

*/

// const actions = ["action",["id", "btnName", "url", "caption"]];
function Row(props) {

  function handleChange1(e) {
    props.handleChange(e.target.value, props.i, "url")
  }
  function handleChange2(e) {
    props.handleChange(e.target.value, props.i, "btnName")
  }
  function handleChange3(e) {
    props.handleChange(e.target.value, props.i, "caption")
  }
  function handleDate(date) {
    props.handleChange(date, props.i, "actionDate")
  }

  return (
      <div className="">
        <FormInput name={"action_url"+props.i} label="Url" value={props.actions[props.i].url} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
        <FormInput name={"action_btnName"+props.i} label="Btn Name" value={props.actions[props.i].btnName} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
        <FormInput name={"action_caption"+props.i} label="Caption" value={props.actions[props.i].caption} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
        <FormDate name={"actionDate"+props.i} label="Action Date" date={props.actions[props.i].actionDate} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />
      </div>
  );
}

function Actions(props) {
   if (false) {
     return null;
   } else {
     let out = [];
     for (let i=0; i < props.count; i++) {
       out.push(<Row key={i} i={i} handleChange={props.handleChange} actions={props.actions}/>)
     }
     return out;
   }
}


export default Actions;
