import React from 'react';
import {FormInput, FormSelect, FormDate} from "../../../forms/forms.js";

/*

*/

// const actions = ["action",["id", "btnName", "url", "caption"]];
function Row(props) {

  function handleChange1(e) {
    props.handleChange(e.target.value, props.i, "tweetID")
  }
  function handleChange2(e) {
    props.handleChange(e.target.value, props.i, "tweetTitle")
  }
  function handleChange3(e) {
    props.handleChange(e.target.value, props.i, "twitterUser")
  }
  function handleDate(date) {
    props.handleChange(date, props.i, "tweetDate")
  }

  return (
      <div className="">
        <FormInput name={"tweetID"+props.i} label="Tweet ID" value={props.tweets[props.i].tweetID} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
        <FormInput name={"tweetTitle"+props.i} label="Title" value={props.tweets[props.i].tweetTitle} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
        <FormInput name={"twitterUser"+props.i} label="User ID" value={props.tweets[props.i].twitterUser} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
        <FormDate name={"tweetDate"+props.i} label="Tweet Date" date={props.tweets[props.i].tweetDate} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />
      </div>
  );
}

function Tweets(props) {
   if (false) {
     return null;
   } else {
     let out = [];
     for (let i=0; i < props.count; i++) {
       out.push(<Row key={i} i={i} handleChange={props.handleChange} tweets={props.tweets}/>)
     }
     return out;
   }
}


export default Tweets;
