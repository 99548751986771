import React from 'react';
import Button from '../../Buttons/Button';import Link from '../../Link';import SvgIcon from '../../SvgIcon';import {FormInput, FormDate} from "../../forms/forms.js";

/*
    <div className=""></div>
*/

function EditBar(props) {
  if (!props.user || (props.user.admin < 5)) {
    return null;
  } else {
    return (
        <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen">
        <Link click={[props.edit, true]} className="w3-margin-right-8"><SvgIcon size="22px" icon="edit" color="rgb(60,60,60)" /></Link>
        <Link href={"/feed/delete_actionMap/"+props.id+"/"+props.feedID} className="w3-margin-right-8"><SvgIcon size="22px" icon="delete" color="rgb(60,60,60)" /></Link>
        <Link href={"/feed/delete_action/"+props.id}><SvgIcon size="22px" icon="delete" color="red" /></Link>
        </div>
    );
  }
}

class ActionRow extends React.Component {
  constructor(props) {
      super(props);
      const date = (this.props.actionDate && (this.props.actionDate != "0000-00-00")) ? new Date(this.props.actionDate) : null;
      this.state = {edit: false, action_url: this.props.action_url, action_caption: this.props.action_caption, action_btnName: this.props.action_btnName, actionDate: date};
      this.handleEdit = this.handleEdit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDate = this.handleDate.bind(this);
      this.btnNameRef = React.createRef();
      this.urlRef = React.createRef();
  }

    handleEdit(bol) {
      this.setState({edit: bol});
    }

    handleChange(e) {
      const target = e.target;
      const value =  target.value;
      this.setState({[target.name]: value});
    }

    handleDate(date) {
      this.setState({actionDate: date});
    }

    handleSubmit(e) {
      let alertMessage = "";
      if (!this.state.action_url) {
          if (alertMessage ==  "") {
              alertMessage = "Url";
          } else {
              alertMessage = alertMessage + ", Url";
          }
          this.urlRef.current.setRedBackground();
      }
      if (!this.state.action_btnName) {
          if (alertMessage ==  "") {
              alertMessage = "btnName";
          } else {
              alertMessage = alertMessage + ", btn name";
          }
          this.btnNameRef.current.setRedBackground();
      }

      if (alertMessage != "") {
          alertMessage = "Error: " + alertMessage + " cannot be empty!";
          //this.alertHandler(alertMessage);
          e.preventDefault();
      }
    }

    render() {
       if (!this.props.action_url || !this.props.action_btnName) {
         return null;
       } else if (this.state.edit) {
         return (
             <div className="w3-display-container" >
                 <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                     <Link click={[this.handleEdit, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>

                 </div>
                <form action={"/feed/editAction/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                   <FormInput name="action_btnName" label="Button Name" value={this.state.action_btnName} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate="" ref={this.btnNameRef} />
                   <FormInput name="action_url" label="Url" value={this.state.action_url} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate="" ref={this.urlRef} />
                   <FormInput name="action_caption" label="Caption" value={this.state.action_caption} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                   <FormDate name="actionDate" label="Date" date={this.state.actionDate} maxWidth="" handleChange={this.handleDate}  color="w3-light-gray" validate=""  />
                   <div className="w3-margin-top-24 w3-margin-bottom ">
                       <input type="submit" value="Edit Action" className="w3-button  w3-content w3-blue h55  w3-round"/>
                   </div>
                </form>
             </div>
         );
       } else {
         return (
           <div className="ukr-feed-action w3-display-container">
              <Button text={this.props.action_btnName} href={this.props.action_url} size="a-btn-size-3" color="a-btn-blue-1" newWindow={true} noFollow={true}/>
              <EditBar  user={this.props.user} id={this.props.id} edit={this.handleEdit} feedID={this.props.feedID}/>
          </div>
         )
       }
    }

}

function Action(props) {
   if (!props.action) {
     return null;
   } else {
     return (
       <div className="ukr-feed-text">
            {props.action.map((row,index) =><ActionRow key={index} {...row} user={props.user} feedID={props.feedID} />)}
       </div>
     );
   }
}


export default Action;
