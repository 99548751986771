function intToEsa(num) {
  let out = (parseInt(num)).toString(16);
  if (out.length == 1) {
    out = 0 + out;
  }
  return out;
}




  function rgbToHex(rgb) {
    let [a,b,c] = [intToEsa(rgb.r), intToEsa(rgb.g),intToEsa(rgb.b)];
    return ("#"+a+b+c);
  }

  function  hexToRgb(hex) {
    const value = hex;
    let rgb = {};
    rgb["r"] = parseInt(value.charAt(1)+""+value.charAt(2), 16);
    rgb["g"] = parseInt(value.charAt(3)+""+value.charAt(4), 16);
    rgb["b"]= parseInt(value.charAt(5)+""+value.charAt(6), 16);
    return rgb;
  }


      /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number}  h       The hue
     * @param   {number}  s       The saturation
     * @param   {number}  l       The lightness
     * @return  {Array}           The RGB representation
     */

const hslToRgb = (hsl) => {
    let {h,s,l} = hsl;

   s /= 100;
   l /= 100;
   const k = n => (n + h / 30) % 12;
   const a = s * Math.min(l, 1 - l);
   const f = n =>
     l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
   return {r:Math.round(255 * f(0)),g: Math.round(255 * f(8)),b: Math.round(255 * f(4)), a: hsl.a};
 };

 const hslToHex = (hsl) => rgbToHex(hslToRgb(hsl));


 function rgbToHsl(color) {  // color = {r: int, g: int, b: int}
   const r = color.r/255;
   const g = color.g/255;
   const b = color.b/255;
   let hsl = {a: color.a};
   const cMax = Math.max(r,g,b);
   const cMin = Math.min(r,g,b);
   const diff = cMax - cMin;
   hsl.l = (cMax + cMin) / 2 ;
   hsl.s = !diff ? 0 : (diff / (1 - Math.abs(2 * hsl.l - 1)));
   hsl.l = Math.round(hsl.l * 100);
   hsl.s = Math.round(hsl.s * 100);
   if (diff == 0) {
     hsl.h = 0;
   } else if (cMax == r) {
     hsl.h = Math.round(60 * (((g - b)/diff) + (((g - b)/diff) < 0 ? 6 : 0) ));
   } else if (cMax == g) {
     hsl.h = Math.round(60 * (((b - r)/diff) + 2));
   } else if (cMax == b) {
     hsl.h = Math.round(60 * (((r - g)/diff) + 4));
   }
   return hsl;
 }

 function contrastColor(color) {  // Choose black or white font color based on the background. in an out color in rgb object {r:i, g:i, b:i}
    const luminance = (0.299 * color.r + 0.587 * color.g + 0.114 * color.b)/255;
    const i = luminance > 0.6 ? 0 : 255;

    return {r:i, g:i, b:i};
 }

 function contrastColorRgbToHex(color) {  // Choose black or white font color based on the background. in an out color in rgb object {r:i, g:i, b:i}
    const luminance = (0.299 * color.r + 0.587 * color.g + 0.114 * color.b)/255;
    const i = luminance > 0.6 ? "#000000" : "#ffffff";
    return i;
 }

 function contrastHexColor(color) {
   return contrastColorRgbToHex(hexToRgb(color));
 }


 function rgbToHsv( col ) {
  const {r,g,b, a} = col;
	var h;
	var s;
	var v;

	var maxColor = Math.max(r, g, b);
	var minColor = Math.min(r, g, b);
	var delta = maxColor - minColor;

	// Calculate hue
	// To simplify the formula, we use 0-6 range.
	if(delta == 0) {
		h = 0;
	}
	else if(r == maxColor) {
		h = (6 + (g - b) / delta) % 6;
	}
	else if(g == maxColor) {
		h = 2 + (b - r) / delta;
	}
	else if(b == maxColor) {
		h = 4 + (r - g) / delta;
	}
	else {
		h = 0;
	}
	// Then adjust the range to be 0-1
	h = h/6;

	// Calculate saturation
	if(maxColor != 0) {
		s = delta / maxColor;
	}
	else {
		s = 0;
	}

	// Calculate value
	v = maxColor / 255;

	return { h: Math.round(h*360), s: Math.round(s*100), v: Math.round(v*100), a: a ? a : 1 };
};

/* accepts parameters
 * h  Object = {h:x, s:y, v:z}
 * OR
 * h, s, v
*/


function hsvToRgb(color) {
    var {h, s,v, a} = color;
    var r, g, b;
    var i;
    var f, p, q, t;

    // Make sure our arguments stay in-range
    h = Math.max(0, Math.min(360, h));
    s = Math.max(0, Math.min(100, s));
    v = Math.max(0, Math.min(100, v));

    // We accept saturation and value arguments from 0 to 100 because that's
    // how Photoshop represents those values. Internally, however, the
    // saturation and value are calculated from a range of 0 to 1. We make
    // That conversion here.
    s /= 100;
    v /= 100;

    if(s == 0) {
        // Achromatic (grey)
        r = g = b = v;
        return [
            Math.round(r * 255),
            Math.round(g * 255),
            Math.round(b * 255)
        ];
    }

    h /= 60; // sector 0 to 5
    i = Math.floor(h);
    f = h - i; // factorial part of h
    p = v * (1 - s);
    q = v * (1 - s * f);
    t = v * (1 - s * (1 - f));

    switch(i) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;

        case 1:
            r = q;
            g = v;
            b = p;
            break;

        case 2:
            r = p;
            g = v;
            b = t;
            break;

        case 3:
            r = p;
            g = q;
            b = v;
            break;

        case 4:
            r = t;
            g = p;
            b = v;
            break;

        default: // case 5:
            r = v;
            g = p;
            b = q;
    }

    return { r: Math.round(r * 255),g: Math.round(g * 255), b: Math.round(b * 255), a: a};

}


const rgbToCss = (rgb)=> ((!rgb.a && (rgb.a !== 0 )) || (rgb.a === 1 )) ? ( "RGB( " + rgb.r + ", " + rgb.g + ", " + rgb.b + " )" ) : ( "RGBA( " + rgb.r + ", " + rgb.g + ", " + rgb.b+ ", " +rgb.a + " )" );
const hslToCss = (hsl) => ((!hsl.a && (hsl.a !== 0)) || (hsl.a === 1 )) ? ( "HSL( " + hsl.h + ", " + hsl.s + "%, " + hsl.l + "% )" ) : ( "HSLA( " + hsl.h + ", " + hsl.s + "%, " + hsl.l + "% , "+ hsl.a+")" );
const hsvToCss = (hsv) => ((!hsv.a && (hsv.a !== 0)) || (hsv.a === 1 )) ? ( "HSV( " + Math.round(hsv.h) + ", " + Math.round(hsv.s) + "% , " + Math.round(hsv.v) + "%)" ) : ( "HSVA( " + Math.round(hsv.h) + ", " + Math.round(hsv.s) + "% , " + Math.round(hsv.v) + "%, "+ hsv.a+")" );

export {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, contrastColor, contrastHexColor,contrastColorRgbToHex,rgbToHsv,hsvToRgb, hsvToCss};
