import Texts from '../Texts/Texts';import Cards from '../Cards/Cards';import SclButtons from '../SclButtons/SclButtons';
import GradientBkg from '../GradientBkg/GradientBkg';
function Body(props) {
    const sPad = props.shared.hideControls ? " a-pdS-8 " : "";
    return (
      <div className={"no-scr-bar overflow-auto "+props.animateBkg+sPad} style={{height: (props.height-46-3)+"px"}}>
          <div className="">
              <Texts shared={props.shared} setHex={props.setHex} setHsl={props.setHsl} cancelBkgTouch={props.cancelBkgTouch}/>
              <Cards shared={props.shared} setHex={props.setHex} setHsl={props.setHsl} cancelBkgTouch={props.cancelBkgTouch}/>
              <SclButtons shared={props.shared} setHex={props.setHex} setHsl={props.setHsl} cancelBkgTouch={props.cancelBkgTouch}/>
              <GradientBkg shared={props.shared} setHex={props.setHex} setRgb={props.setRgb} cancelBkgTouch={props.cancelBkgTouch}/>
          </div>

      </div>
    );
}

export default Body;
