import React from 'react';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon'; import {FormInput, FormDate} from "../../../forms/forms.js";



class EditFbPost extends React.Component {
  constructor(props) {
      super(props);
      const date = (this.props.postDate && (this.props.postDate != "0000-00-00")) ? new Date(this.props.postDate) : null;
      this.state = {postUrl: this.props.postUrl, postTitle: this.props.postTitle, fbUser: this.props.fbUser, postDate: date};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDate = this.handleDate.bind(this);

  }



    handleChange(e) {
      const target = e.target;
      const value =  target.value;
      this.setState({[target.name]: value});
    }

    handleDate(date) {
      this.setState({postDate: date});
    }


    handleSubmit(e) {
        return;
        e.preventDefault();

    }

    render() {

        return (
                <div className="w3-container w3-section w3-display-container w3-white">
                      <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                          <Link click={[this.props.editFbPost, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>
                      </div>
                     <form action={"/feed/editFbPost/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                        <FormInput name="postUrl" label="Post Url" value={this.state.postUrl} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="postTitle" label="Title" value={this.state.postTitle} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="fbUser" label="Fb User" value={this.state.fbUser} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormDate name="postDate" label="Post Date" date={this.state.postDate} maxWidth="" handleChange={this.handleDate}  color="w3-light-gray" validate=""  />
                        <div className="w3-margin-top-24 w3-margin-bottom ">
                            <input type="submit" value="Edit FB Post" className="w3-button  w3-content w3-blue h55  w3-round"/>
                        </div>
                    </form>
                </div>
        );
      }
}


export default EditFbPost;
