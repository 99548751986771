import HeroRotator from '../HeroRotator/HeroRotator';
import ReactPlayer from 'react-player';import './home.css'; import CenterMain from './CenterMain'; import CenterSide from './CenterSide';import Header from './Header';
import {homeFeeds} from '../Options/homeFeeds.js';
import React from 'react';
import { FacebookProvider } from 'react-facebook';



const recapRows = [
  <span><b>24-02-22 at 3:00 UTC</b>: After an annunce on television, Russia struck dozens of Ukranian cities with missles, and started the invasion . </span>,
  <span><b>24/27-02-22</b>: The Ukrainian army repelled several Russian attacks, the resistence grows, the Putin onslaught slows.</span>,
  <span><b>25-02-22</b>: Protests in many cities around the world against the criminal invasion.</span>,
  <span><b>26-02-22</b>: Several civilian infrastructure struck and 352 civialian victims counted.</span>,
  <span><b>26/27-02-22</b>: EU and US impose heavy sanctions to Russia and ban EU airspace.</span>,
  <span><b>28-02-22</b>: The ruble crashed on markets opening, losing 25% of its value in one day.</span>,
  <span><b>04-03-22</b>: Fire breaks out at nuclear power plant in Zaporizhzhia and get enstinguished but the plant fell under Russian control.</span>,
  <span><b>05-03-22</b>: Visa and Mastercard block Russian cards, after PayPal joins the ban.</span>,
  <span><b>05-03-22</b>: Russia blocks Facebbok and Twitter and bans freedom of speech.</span>,


];
/* <div className="">  </div> */
function LeftVideo(props) {
  return (
    <video width="100%" height="auto"  controls autoPlay playsInline  muted >
        <source src="/react/home/video/nicholson.mp4" type="video/mp4"/>
    </video>
  );
}

function Home(props) {

  if (props.show != "home" && props.show != "start") return null;
  const headerTitle ="Russia, unprovoked, invades Ukraine";
  const animOpacity = (props.show == "home") ? "w3-animate-opacity-04" : "";
  return (
    <div className={animOpacity} id="home-section">
        <HeroRotator rows={props.meta ? props.meta.slides : false} bot={props.bot} iconColor="black" auto={false} mainShare={props.mainShare}/>
        <FacebookProvider appId="304383704997441" >  </FacebookProvider>
        <section className="ukr-home-body ukr-home-defs" id="invasion_latest">
              <div className="ukr-home-left"><LeftVideo /> </div>
              <div className="ukr-home-center">
                    <Header title={props.meta ? props.meta.feedsHeaderTitle : null}/>
                    <div className="ukr-home-center-main">

                        <CenterMain homeFeeds={props.feeds} medias={props.medias} feedView={props.feedView} bot={props.bot} tags={props.tags} selectedFeedsIds={props.selectedFeedsIds} user={props.user}  homeTags={props.homeTags}/>
                    </div>
                    <div className="ukr-home-center-side">
                        <CenterSide recapRows={recapRows} bot={props.bot} tags={props.tags}  user={props.user}/>
                    </div>
              </div>
              <div className="ukr-home-right">  </div>
        </section>
    </div>
  );
}

export default Home;

//<ReactPlayer url='https://media.gedidigital.it/repubblicatv/file/2022/02/26/818756/hls/818756-video-rrtv-1200-07055_master.m3u8'  controls={true} light={false}/>
