import React, {useState, useEffect} from 'react'; import './rangeActionsBar.css';import Button from '../../../../Buttons/Button';
import {FormInput, DivSelect} from '../../../../forms/forms'; import SvgIcon from '../../../../SvgIcon';


const NameImput = (props) => !props.createView ? null : <FormInput name="name" label="New Color name" value={props.name} handleChange={(e)=>props.setName(e.target.value)}/>;

const ListSelect = (props) => {
  if (!props.createView) { return null;} else {
      return ( <DivSelect options={props.listsOptions} label="List" covLabel="Select a list" name="list" value={props.selectedList} handleOptionClick={props.setList}/> ) ;}
  };

function Actions(props) {
  const [name, setName] = useState("");
  const {notCreated, createView, hasChanged, setCreate} = props;
  const selectedList = props.listOpt.selectedList;
  const mobile = window.innerWidth < 734;
  useEffect(()=>{setName(createView ? props.color.hex : "");}, [createView]);
  const actionBtnSize = mobile ? "a-btn-tiny-5 a-btn-height-35 a-btn-container-15" : "a-btn-tiny-5 a-btn-height-30 a-btn-container-15";
  const Create = () =>  (notCreated && !createView) ? <Button text="Save color" click={()=>setCreate(true)} size={actionBtnSize} color="a-btn-green-1 " className="cl-actions-btns "/> : null;
  const Update = () => (hasChanged && !createView) ? <Button text="Save" click={()=>props.handleColor("color", props.color)} size={actionBtnSize} color="a-btn-green-1" className="cl-actions-btns grow-1" /> : null;
  const Revert = () => (hasChanged && !createView) ? <Button text="Revert" click={()=>{props.setColor(props.savedColor);}} size={actionBtnSize} color="a-btn-red" className="cl-actions-btns grow-1 w3-margin-left-6" /> : null;
  const SaveAs = () => (hasChanged && !createView) ? <Button text="Save as new color" click={()=>setCreate(true)} size={actionBtnSize} className="cl-actions-btns w100 w3-margin-top-6" color="a-btn-dodgerblue" /> : null;
  const SaveNew = () => !createView ? null : <Button text="Create color" click={()=>props.setNewColor(name, props.color, selectedList)} size="a-btn-tiny-5 a-btn-height-35" color="a-btn-green-1 " className="cl-actions-btns "/> ;
  const Cancel = () => !createView ? null : <Button text="Cancel" click={()=>setCreate(false)} size="a-btn-tiny-5 a-btn-height-35" color="a-btn-green-1 " className="cl-actions-btns"/> ;
  const HasChangedMsg = () => (hasChanged && !createView) ? <div className="cl-actions-message">Color changed from {props.savedColor.hex} to {props.color.hex}</div> : null;
  const NotCreatedMsg = () => (notCreated && !createView) ? <div className="">The color has not been saved yet!</div> : null;

  return (
    <div className="">
        <HasChangedMsg /><NotCreatedMsg />
        <NameImput name={name} setName={setName} createView={createView}/><ListSelect {...props.listOpt}/>
        <div className="flex-cen-start cl-actions-btn-cont a-wrap w3-margin-top-12 a-wrap w100"><Create/><Update/><Revert /><SaveAs /><SaveNew /> <Cancel /> </div>
    </div>

  );
}

export default Actions;
