import React, {useState, useEffect, useRef} from 'react';import ScaleBlock from './ScaleBlock'; import './mappings.css';
import {hueMap, saturationMap, lightnessMap, redMap, greenMap, blueMap} from './colorMappings.js'; import Row from './Row';
import MappingsHeader from './Header/MappingsHeader';
// <div className=""></div>


function Mappings(props) {
    const [render, setRender] = useState(true);
    const mapRef = useRef(null);
    useEffect(()=>{if (props.scarlet && (props.visibleSections=="mappings")) {mapRef.current.scrollTop=0;}},[props.scarlet,props.visibleSections]);
    useEffect(()=>{window.addEventListener("scroll", scroll); return ()=> window.removeEventListener("scroll", scroll);});
    useEffect(()=>setRender(props.scarlet ? true : false),[props.scarlet, props.visibleSection]);

    const scroll = () => {
      if (props.scarlet) return;
      const element = document.getElementById("mappings");
      if (isNearViewport(element) && !render) {
          setRender(true);
      } else if (!isNearViewport(element) && render) {
          setRender(false);
      }
    }
    if (!props.show) {
      return null;
    } else if (!["all","mappings"].includes(props.visibleSections)) {
        return <div className="" id="mappings"></div>;
    } else if (!render) {
        return <div className={"a-mrT-120 a-pdS-80 a-mrT-0-s a-pdB-500 "+(window.innerWidth < 734 ? "a-bkg-beige" : "a-bkg-beige")+(props.scarlet ? "":" a-pdS-60-s")} id="mappings" />;
    } else {

        const color = props.options.color;
        const mappings = [{name: "Hue", type: "h", rows: hueMap(color.hsl),selectedValue: color.hsl.h}, {name: "Saturation", type: "s", rows: saturationMap(color.hsl),
         selectedValue: color.hsl.s}, {name: "Lightness", type: "l", rows: lightnessMap(color.hsl), selectedValue: color.hsl.l},
         {name: "Red", type: "r", rows: redMap(color.rgb),selectedValue: color.rgb.r}, {name: "Green", type: "g", rows: greenMap(color.rgb),selectedValue: color.rgb.g},
         {name: "Blue", type: "b", rows: blueMap(color.rgb),selectedValue: color.rgb.b}];

        return (
            <div className={" a-mrT-120 a-pdS-80 a-mrT-0-s a-pdB-500 "+(window.innerWidth < 734 ? "a-bkg-beige " : "a-bkg-beige ")+(props.scarlet ? " overflow-auto no-scr-bar a-pdS-0-s ":" a-pdS-60-s a-pdB-140-s")+(props.visibleSections === "mappings" ? " grow-1": "")} ref={mapRef} id="mappings" >
                 <MappingsHeader color={color} scarlet={props.scarlet} {...props.mainShare}/>
                 <div className="w3-content-1200">
                      {mappings.map((row, index) => <Row {...row} key={row.type+render.toString()} options={props.options} scarlet={props.scarlet}/>)}
                 </div>
            </div>
        );
    }

}

export default Mappings;


var isNearViewport = function (elem) {
    if (!elem) return null;
    var bounding = elem.getBoundingClientRect();
    return (bounding.top <= (window.innerHeight * (window.innerWidth < 734 ? 1.15 : 1)));
  };
