import Button from '../../Buttons/Button';import {DivSelect, FormInput} from '../../forms/forms';

function NewList(props) {
   if (!props.show) return null;
   return (
       <div className="cl-colors-list-cont w3-section flex-cen-start a-wrap w3-display-container">
        <div className="w3-display-topright w3-margin-right w3-margin-top"><Button click={props.close} svg="close" color="a-btn-blue-1" iconColor="white" size="a-btn-top-icon" filling="a-btn-filled"/></div>
        <h4 className="w100">New List</h4>
        <FormInput name="name" value={props.value} handleChange={props.setListName} className="mw-200 w3-margin-right-32"/>
        <Button click={props.create} text="Save" color="a-btn-azure" size="a-btn-size-4" filling="a-btn-filled"/>
       </div>
   );
}

export default NewList;
