import React from 'react';
import './menuButton.css';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const LeftIcon = (props) => (props.icon) ? <div className="no-shrink side-menu-link-icon"><SvgIcon icon={props.icon} color={props.color}/></div> : null;
const SubMenuIcon = (props) => (props.subMenu) ? <div className=" no-shrink w3-margin-left-6 side-menu-sub-icon" ><SvgIcon color={props.color} icon="arrow_forward_ios"/></div> : null;

function MenuButton(props) {

    function handleClick() {

      if (props.meta && (props.meta != "0")) {
        props.clickHandler(props.meta);
      } else if (props.subMenu){
        props.subHandler(props.title, props.subMenu);
      }

    }

    if (!props.title || (Number(props.adminButton) && (!props.user || !props.user.admin || (Number(props.user.admin) < Number(props.adminButton))))) {
      return null;
    } else if ((props.userButton === "in") && (!props.user || !props.user.userID)) {
      return null;
    }  else if ((props.userButton === "out") && (props.user && props.user.userID)) {
      return null;
    } else {
      const subIconColor = props.subIconColor ? props.subIconColor : "rgb(150,150,150)";
      const click = (props.subMenu || (props.meta && (props.meta != "0"))) ? handleClick : false;

      return (
          <li>
              <Link className={props.className + " noselect"} href={props.url} click={click} >
                  <div className={"flex-cen-cen side-men-it noselect "+props.inconSizeAdj}>
                        <LeftIcon icon={props.icon}  color={props.iconColor ? props.iconColor : props.iconBarColor}/>
                        <div className="side-menu-text"><span>{props.title}</span></div>
                        <SubMenuIcon subMenu={props.subMenu} color={subIconColor} />
                  </div>
              </Link>
          </li>
      );
    }
}

export default MenuButton;
