import React, {useState, useEffect, useRef} from 'react'; import Button from '../../../../Buttons/Button';import {FormInput, DivSelect} from '../../../../forms/forms';
import SvgIcon from '../../../../SvgIcon'; import Error from './Error';

/*
    <div className=""></div>
*/

function Hex(props) {
    const [hex, setHex] = useState(false);
    const [changed, setChanged] = useState(false);
    const [blurred, setBlur] = useState(false);
    const myRef = React.createRef();
    useEffect(()=>{setHex(props.hex); setChanged(false)},[props.hex]);
    useEffect(()=> {if (blurred) {handleBlur();setBlur(false)};} ,[blurred]);
    // We delay the blur action setting a timeout to onBlur, so if a button is pressed that action is handled first. In particular if we press rgb btn, handlBlur of the input is called after 200ms when it will notice the from is not hex anymore so no error should be displayed and anything should be handled in background sicne hex view is not visible anymore. Without setTimeout and useeffect pressing Rgb btn would activate the input blur but prevent the click on the btn to succed

    const {setError, errorTimeout} = props;
    const hexHandler = (e)=> {const value = e.target.value; setError(false); errorTimeout(); if (value.length > 7) {setError("Hex Max lenght 7 digits");myRef.current.setRedBackground();return;} else {setHex(value);setChanged(true);}};
    const hasChanged = (changed && props.focused) ? "" : "w3-hide";
    let blurTimer = null;


    const handleBlur = (bol) => {
        const regex = /^#([A-Fa-f0-9]{6})$/;
        if (regex.test(hex)) {
            props.cancelTimer();
            props.setHex(hex);
            props.setFocused(false);
            if (props.from == "HEX") myRef.current.setBlurBackground();
        } else {
            if (props.from === "HEX") {
              props.setFocused(true);
              myRef.current.autofocus();
              setError("Wrong format, HEX = #dddddd where d=(0-9,a-f)");
              errorTimeout();
              myRef.current.setRedBackground();

            }  else {
              setHex(props.hex);
              props.cancelTimer();
              props.setFocused(false);
            }
        }
    }
    if (props.from !== "HEX") {
      return null;
    } else {
      return (
        <div className="w3-display-container a-mrT-16 a-mrT-26-s flex-cen-start a-wrap" >
              <div className="cl-conv-hex-label">Hex</div>
              <FormInput  value={hex}  className="a-pWidth-60" margin="none" defs="hex-imp-act-defs cl-range-input-col" handleChange={hexHandler}
                  ref={myRef} onFocus={()=>props.setFocused(true)} blur={()=>{blurTimer = setTimeout(()=>setBlur(true),200)}} />
              <Error error={props.error} cancelTimer={()=>{clearTimeout(blurTimer);myRef.current.autofocus();props.cancelTimer();}}/>
              <div className="w100 a-mrT-26 a-mrB-6  flex-cen-cen " >
                   <Button  text="Reset" click={()=>{props.cancelTimer();setHex("#");myRef.current.autofocus();clearTimeout(blurTimer);}} color="a-btn-blue-1"  className="a-mrR-32 a-width-150" size={props.btnSize}/>
                   <Button  text="Calculate" click={()=>null} color="a-btn-blue-1"  className={"a-width-150 "+hasChanged} size={props.btnSize}/>
              </div>

        </div>
      );
    }
}



export default Hex;
