import React from 'react';
import './caption.css';

/*
    <div className=""></div>
*/

function Caption(props) {
   if (!props.caption ) {
     return null;
   } else {
     return (
       <div className="cn-caption-container">
            <div className="cn-caption-text">{props.caption}</div>
       </div>
     );
   }
}


export default Caption;
