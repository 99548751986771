import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../forms/forms.js";
import Button from '../../Buttons/Button';

/*
    <div className=""></div>
*/

function Images(props) {
    if (props.show != "batchImg") {
        return null;
    } else {
        const handleChange1 = (e) => props.editRow("src", e.target.value, props.rowIndex);
        const handleChange2 = (e) => props.editRow("alt", e.target.value, props.rowIndex);
        const handleChange3 = (e) => props.editRow("imagePosition", e.target.value, props.rowIndex);
        const handleChange4 = (e) => props.editRow("author", e.target.value, props.rowIndex);
        const handleChange5 = (e) => props.editRow("authorUrl", e.target.value, props.rowIndex);
        const handleChange6 = (e) => props.editRow("caption", e.target.value, props.rowIndex);
        return (
            <div className="w3-row ">

                  <div className=" w3-col m4"><img src={props.src} style={{width: "100%"}}/></div>
                  <div className=" w3-col m8">
                      <FormInput name={"caption"+props.rowIndex} label="Caption" value={props.caption} maxWidth="" handleChange={handleChange6}  color="w3-light-gray" validate=""  />
                      <FormInput name={"src"+props.rowIndex} label="Src" value={props.src} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
                      <FormInput name={"alt"+props.rowIndex} label="Alt" value={props.alt} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
                      <FormInput name={"imagePosition"+props.rowIndex} label="Image Position" value={props.imagePosition} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
                      <FormInput name={"author"+props.rowIndex} label="Author" value={props.author} maxWidth="" handleChange={handleChange4}  color="w3-light-gray" validate=""  />
                      <FormInput name={"authorUrl"+props.rowIndex} label="Author Url" value={props.authorUrl} maxWidth="" handleChange={handleChange5}  color="w3-light-gray" validate=""  />

                  </div>
            </div>
        );
    }
}


export default Images;
