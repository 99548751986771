import React from 'react';
import './editImages.css';import {FormInput, FormSelect, FormDate} from "../../forms/forms.js";
//import Row from './Row';

/*
    <div className=""></div>
*/

class EditImages extends React.Component {
  constructor(props) {
      super(props);
      this.state = {count: 0, startIndex: 0, rows: []};
      //this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleDate = this.handleDate.bind(this);
      //this.handleTags = this.handleTags.bind(this);
      this.handleEdited = this.handleEdited.bind(this);
      this.focus = this.focus.bind(this);



  }


  focus(element) {
    if (element == "email") {
      this.emailRef.current.autofocus();
    }
  }

  isTagChecked(tagID, idsArray) {
    if (!idsArray || !Array.isArray(idsArray) || !idsArray.length) return false;
    for (let i=0; i<idsArray.length; i++) {
      if (idsArray[i].id == tagID) {
        return true;
      }
    }
    return false;
  }

  createTagsArray(tags, selectedIds) {

      let tagsArray = [];
      for (let i=0; i<tags.length; i++) {
        const tag = {id: tags[i].id, name: tags[i].name, checked: this.isTagChecked(tags[i].id, selectedIds)};
        tagsArray.push(tag);
      }
      return tagsArray;
  }

  createRows(count, startIndex) {
    const imagesCount = this.props.images.length;
    let editArray = this.props.images.slice(startIndex, Math.min(Number(startIndex) + Number(count), imagesCount -1));
    let rowsArray = [];
    for (var i = 0; i<editArray.length; i++) {
        let row = {};
        row.id = editArray[i].id;
        row.src = editArray[i].src;
        row.imagePosition = editArray[i].imagePosition;
        row.alt= editArray[i].alt;
        row.caption = editArray[i].caption;
        row.author = editArray[i].author;
        row.authorUrl = editArray[i].authorUrl;
        row.edited = false;
        //row.tags = this.createTagsArray(this.props.tags, editingFeeds[i].selectedTags);
        rowsArray.push(row);
    }
    this.setState({rows: rowsArray, count: count, startIndex: startIndex});

  }

  handleChange(e) {
    const target = e.target;
    const value =  target.value;
   if (target.name == "count") {
      this.createRows(value, this.state.startIndex);
    } else if (target.name == "startIndex"){
      this.createRows(this.state.count, value);
    }
  }

  handleEdited(value, feedIndex) {
    let rows = this.state.rows;
    rows[feedIndex].edited = value;
    this.setState({rows:rows});
  }



  handleDate(date) {
    this.setState({date: date});
  }



  componentDidMount() {
  ;

  }


  render() {

    if (this.props.show != "batchImgEdit") {
        return null;
      } else {
        const imgCount = this.props.images.length;
        return (

          <div className="w3-content-1300 w3-section-32 h100vh">
          <form action="//" method="post" onSubmit={this.handleSubmit}>

              <div className="w3-section w3-row">
              <FormSelect name="startIndex" label="Start Index" value={this.state.startIndex} rangeStart={0} rangeEnd={imgCount -1} maxWidth="w3-col m3" handleChange={this.handleChange} color="w3-light-gray" validate="" />
              <FormSelect name="count" label="Number of feeds" value={this.state.count} rangeStart={0} rangeEnd={50} maxWidth="w3-col m3" handleChange={this.handleChange}  color="w3-light-gray" validate="" />
              </div>

              <div className="w3-margin-top-24 w3-margin-bottom ">
                  <input type="submit" value="Edit Images" className="w3-button  w3-content w3-blue h55 w100 w3-round"/>
              </div>
          </form>
          </div>
        );
    }
  }

}

// {this.state.rows.map((row, index) => <Row {...row} key={index}  edit={this.handleEdited} feedIndex={index}/>)}


export default EditImages;
