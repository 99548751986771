import React from 'react';import Link from '../../Link';import SvgIcon from '../../SvgIcon';import {FormInput} from "../../forms/forms.js";


/*
    <div className=""></div>
*/
function EditBar(props) {
  if (!props.user || (props.user.admin < 5)) {
    return null;
  } else {
    return (
        <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen">
            <Link click={[props.edit, true]} className="w3-margin-right-8"><SvgIcon size="22px" icon="edit" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/delete_text/"+props.id}><SvgIcon size="22px" icon="delete" color="rgb(60,60,60)" /></Link>
        </div>
    );
  }
}

class TextRow extends React.Component {
  constructor(props) {
      super(props);
      this.state = {edit: false, text: this.props.text};
      this.handleEdit = this.handleEdit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.textRef = React.createRef();
  }

    handleEdit(bol) {
      this.setState({edit: bol});
    }

    handleChange(e) {
      const target = e.target;
      const value =  target.value;
      this.setState({[target.name]: value});
    }

    handleSubmit(e) {
      let alertMessage = "";
      if (this.state.text == "") {
          if (alertMessage ==  "") {
              alertMessage = "Title";
          } else {
              alertMessage = alertMessage + ", Title";
          }
          this.textRef.current.setRedBackground();
      }

      if (alertMessage != "") {
          alertMessage = "Error: " + alertMessage + " cannot be empty!";
          //this.alertHandler(alertMessage);
          e.preventDefault();
      }
    }

    render() {
       if (!this.props.text ) {
         return null;
       } else if (this.state.edit) {
         return (
             <div className="w3-display-container" >
                 <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                     <Link click={[this.handleEdit, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>

                 </div>
                <form action={"/feed/editText/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                   <FormInput name="text" label="Paragraph" value={this.state.text} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate="" ref={this.textRef} />
                   <div className="w3-margin-top-24 w3-margin-bottom ">
                       <input type="submit" value="Edit Text" className="w3-button  w3-content w3-blue h55  w3-round"/>
                   </div>
                </form>
             </div>
         );
       } else {
         return (
           <div className="w3-display-container" >
                <div className="">{this.state.text}</div> <EditBar  user={this.props.user} id={this.props.id} edit={this.handleEdit}/>
          </div>
         )
       }
    }

}

function Text(props) {
   if (!props.text) {
     return null;
   } else {
     return (
       <div className="ukr-feed-text">
            {props.text.map((row,index) =><TextRow key={index} {...row} user={props.user} />)}
       </div>
     );
   }
}


export default Text;
