import React, {useState, useEffect} from 'react';
import Link from './Link'; import svg from './svg';

function SvgIcon(props) {

  const size = props.size ? props.size : "100%";
    if (props.noShow) {
      return null;
    } else if (props.icon && (props.hoverColor  || props.className || props.click)) {
      const className = props.className ? props.className : "";
      const hoverColor = props.hoverColor ? props.hoverColor : props.color;
      return (
        <Link href={props.href} className={className} click={props.click}>
                <div style={{width: size, height: size}}>
                    {svg(props.icon, size, props.color, props.colorClass)}
                </div>
        </Link>
      );
  } else if (props.icon) {
      return svg(props.icon, size, props.color, props.colorClass);
  } else {
      return null;
  }
}

export default SvgIcon;
