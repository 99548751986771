import React from 'react';
// import Link from './Link';

function Link(props) {
    function clickHandler(e) {
      e.stopPropagation();
      if (Array.isArray(props.click)) {
        props.click[0](props.click[1]);
      } else {
        props.click();
      }

    }
    const click =  ((props.click && !window.bot) || (props.click && window.bot && !props.href)) ? props.click : null;
    const href = !props.click || (props.click && window.bot) ? props.href : null;
    const pointer = (props.href || props.click) ? "pointer " : "";
    const className = pointer + (props.className ? props.className :  "");
    const rel =props.noFollow ? "nofollow" : "";
    const target = props.newWindow ? "_blank" : "";
    if (href) {
        return <a href={href} className={className} style={props.style} rel={rel} target={target}>{props.children}</a>;
    } else if (click) {
        return <div onClick={clickHandler} className={className} style={props.style}>{props.children}</div>;
    } else {
        return <div className={className} style={props.style}>{props.children}</div>;
    }
}


export default Link;

/*
<Link className="string" href= click= >
    Children
</Link>
click = handler or [handler, handler-parameter]; handler-parameter is passed to a shared handler to uniquely identify the calling component instead of using a dedicated handler for that component
*/
