import React from 'react';
import './cnnRecap.css';
// import CnnRecap from './CnnArt/Side/CnnRecap';
/*
    <div className=""></div>
*/

function CnnRecap(props) {
   if (!props.rows) {
     return null;
   } else {
     return (
       <div className="cn-rec cn-rec-defs">
              <header className="">
                  <h2 className="cn-rec-h2">
                      {props.title}
                  </h2>
              </header>
              <div className="">
                  <div className="cn-rec-par">
                      <ul className="cn-rec-ul">
                            {props.rows.map((row, index) => <li key={index}>{row}</li>)}

                      </ul>
                  </div>
              </div>
              <div className="">
                  <div className=""></div>
              </div>
       </div>
     );
   }
}


export default CnnRecap;
