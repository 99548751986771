import React from 'react';
import './previous.css';
import SvgIcon from '../../SvgIcon';
import Link from '../../Link';

/*
    <div className=""></div>
*/


function Previous(props) {

  const title = props.previous ? props.previous : "Site Map";
  const LeftIcon = (props) => (props.previous) ? <div className="no-shrink side-menu-back-icon"><SvgIcon icon="arrow_back"  color="rgb(100,100,100)" /></div> : null;
  const handleBack = props.previous ? props.handleBack : false;
  const hover = props.previous ? "side-men-back-prev" : ""
      return (
        <div className="sid-men-prev-top-cont ">
            <div className="sid-men-prev-cont flex-cen-cen">
                <div className="grow-1">
                      <Link className="noselect"  click={handleBack} >
                          <div className={"flex-cen-cen side-men-back "+hover}>
                                <LeftIcon previous={props.previous} icon="arrow_back"  color="black"/>
                                <div className="w3-margin-left-8 grow-1 truncate"><span className="">{title}</span></div>
                          </div>
                      </Link>
                </div>

            </div>
            <hr className="previous-separator"/>
        </div>
      );
  }


export default Previous;
