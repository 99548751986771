import React, {useState, useRef} from 'react';
import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import {DivSelect} from '../../../../../forms/forms';


const Title = (props) => {
    if (props.hideControls) {
      return null;
    } else {
      return (
        <div className="a-mrB-22 a-mrL-30 a-font-14 w3-bold lh-1 a-pdS-6 a-container-10" style={{...props.contrastBkg, width:"max-content"}} onTouchStart={(e)=>e.stopPropagation()}>BUTTONS</div>
      );
    }
  };
  
const EditBtnFilling = (props) => {
    const {select, selected, buttons, selectBtn, selectedBtn, setBtnEl, btnEl, index, setSclColors, sclColors, btnBkg, contrastBkg,selectType} = props.shared;
    const fillings = [["Filled","a-btn-filled"],["Filled Dark","a-btn-filled-dark"],["Bordered","a-btn-bordered"],["Bordered Dark","a-btn-bordered-dark"],["Bordered wh-bkg","a-btn-bordered-whiteBkg"],["Border on Hover","a-btn-bordOnHover"],["Text on Hover","a-btn-textOnHover"]];
    const handleFilling = (value) => { buttons[selectedBtn].filling = value; selectType(["a-btn-textOnHover","a-btn-bordOnHover"].includes(value) ? "txt" : "bkg");  setSclColors([...sclColors]);};
    return (
        <DivSelect value={buttons[selectedBtn].filling} options={fillings} handleOptionClick={handleFilling}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-width-120"  margin="none" noIcon={true} topDrop={false}/>
    );
}
const EditBtnLighHover = (props) => {
    const {select, selected, buttons, selectBtn, selectedBtn, setBtnEl, btnEl, index, setSclColors, sclColors, btnBkg, contrastBkg,selectType} = props.shared;
    const lHoverOptions = [["L Hov 0","l-hov-0"],["L Hov 1","l-hov-1"],["L Hov 2","l-hov-2"],["L Hov 3","l-hov-3"],["L Hov 4","l-hov-4"],["L Hov 5","l-hov-5"],["L Hov 6","l-hov-6"],["L Hov 7","l-hov-7"],["L Hov 8","l-hov-8"],["L Hov 9","l-hov-9"],["L Hov 10","l-hov-10"],["L Hov 11","l-hov-11"],["L Hov 12","l-hov-12"],["L Hov 13","l-hov-13"],["L Hov 14","l-hov-14"],["L Hov 15","l-hov-15"],["L Hov 16","l-hov-16"],["L Hov 17","l-hov-17"],["L Hov 18","l-hov-18"],["L Hov 19","l-hov-19"],["L Hov 20","l-hov-20"]];
    const handleLHover = (value) => { buttons[selectedBtn].lHover = value; setSclColors([...sclColors]);};
    return (
      <DivSelect value={buttons[selectedBtn].lHover} options={lHoverOptions} handleOptionClick={handleLHover}
      defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-width-90 a-mrL-12 a-mrR-12"  margin="none" noIcon={true} topDrop={false}/>

    );
}

const DoHoverBtn = (props) => {
  const hoverTimer = useRef(null);
  const doHover = ()=> {
      if (hoverTimer.current) clearTimeout(hoverTimer.current);
      props.setHover(true);
      hoverTimer.current = setTimeout(()=>props.setHover(false),2000);
    }
  return (
    <Button text="Hover" click={doHover} size="a-btn-height-28 a-btn-font-14 a-btn-container-10 a-mrR-12" className="grow-1" color={props.forceHover ? "a-btn-green" : "a-btn-blue-1"}/>
  );
}

export {DoHoverBtn, EditBtnFilling, EditBtnLighHover, Title};
