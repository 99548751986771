import React from 'react';
import "./barLi.css";
import Link from '../../Link';
import Button from '../../Buttons/Button';
import Logo from '../../Logo';
//   <div className=""><div>

function BarLi(props) {
  let link = {};
  if (props.type === "back" && !props.back) {
      return null;
  } else if (props.type === "back" && props.back) {
    link.click = true;
    link.href = props.back.url;
    link.id = "back";
    link.icon = "arrow_back";
    link.text = props.mobile ? null : props.back.name;
  } else {
    link.click = props.meta || props.log ? true : false;
    link.href = props.url ? props.url : (props.email ? ("mailto: "+props.email) : null);
    link.id = props.meta ? props.meta : props.log;
  }

  const buttonContainer =  props.inconSizeAdj + " " + props.iconLePad  + " " + props.iconRiPad  + " " + props.iconToPad  + " " + props.iconBoPad;
  const iconColor = props.iconColor ? props.iconColor : props.barIconColor;
  const buttonStyle = props.style ? props.style : props.buttonStyle;
  let size = (props.icon && !props.text) ? (props.size ? props.size : props.buttonSize.icon) : ((props.icon && props.text) ? (props.size ? props.size : props.buttonSize.mixed) : (props.size ? props.size : props.buttonSize.text));
  if (props.type == "back" && props.isSmarthPhone) {
    size = props.size ? props.size : props.buttonSize.icon;
  } else if (props.type == "back" && !props.isSmarthPhone) {
    size = props.size ? props.size : props.buttonSize.mixed;
  }
  if ((parseInt(props.adminButton) && (!props.user || !parseInt(props.user.admin) || (parseInt(props.user.admin) < parseInt(props.adminButton)))) ||
      (props.userButton == "in" && (!props.user || !props.user.userID)) ||
          (props.userButton == "out" && (props.user && props.user.userID)))
      {
        return null;
  } else if (props.logo) {

    let click = false;
    if (link.click) {
      click = [props.clickHandler, props.meta];
    }
    return (
      <li>
          <Link className="logo-cont noselect" click={click} href={link.href} bot={props.bot}><div className={"t-bar-logo a-btn-"+props.buttonStyle}><Logo height={100} unit="%"/></div></Link>
      </li>
    );
  } else {

      return (
        <li className={buttonContainer} key={props.text}>
            <Button {...props} filling={buttonStyle} size={size} {...link}  iconColor={iconColor} noFilledBkg={props.noFilledBkg}/>
        </li>
      );
  }
}


export default BarLi;
