import React, {useEffect, useState, useRef} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb,contrastHexColor} from '../../converters.js';

const Handle = (props) => {
  const {sclColors, setSclColors, hideControls, index, select,  gradient, setGradEl, gradEl, selectedGradientType, selectGradientType, selectedGradient, selectGradient, contrastBkg} = props.shared;
  const selGradient = gradient[selectedGradient];
  const colors = selGradient.colors;
  const direction = selGradient.direction;
  const ref = useRef(null);
  const [touch, setTouch] = useState(false);
  const touchTimer = useRef(null);

  const gradIndex = props.gradIndex;

  const touchMove = (e) => {
    e.stopPropagation();
    if (touchTimer.current) clearTimeout(touchTimer.current);
    if (touch === "moving") {
      const y = e.changedTouches[0].clientY;
      const x = e.changedTouches[0].clientX;
      const rect = document.getElementById("gradient-container").getBoundingClientRect();
      let diff;
      if (direction === "to top") {
        diff = rect.bottom - y;
      } else if (direction === "to bottom") {
        diff = y - rect.top;
      } else if (direction === "to right"){
        diff = x - rect.left;
      } else if (direction === "to left") {
        diff = rect.right - x;
      }
      const dimension =  ["to top","to bottom"].includes(direction) ? rect.height : rect.width;
      let position = (Math.round(diff/dimension *100 * 10) / 10); // we want one decimal, so we multiply the value x 10, then we round it to get an integer an we divide by ten to get one decimal
      const minValue = (gradIndex === 0) ? 0 :  colors[gradIndex-1].position + 5 ;
      const maxValue = (gradIndex === colors.length-1) ? 100 : colors[gradIndex+1].position - 5;

      if (position < minValue) {
        position = minValue;
      } else if (position > maxValue) {
        position = maxValue;
      }
      colors[gradIndex].position = position;
      setSclColors([...sclColors]);
    } else if (touch) {
      setTouch(false);
    }

  }

  const touchStart = (e)=> {

    e.stopPropagation();
    setTouch("started");
    if (touchTimer.current) clearTimeout(touchTimer.current);
    touchTimer.current = setTimeout(()=>setTouch("moving"), 500);

  };

  const touchEnd = (e)=> {
    e.stopPropagation();
    if (touchTimer.current) clearTimeout(touchTimer.current);
    setTouch(false);
  };
  const contrastColor = contrastColorRgbToHex(props.color);
  const click = (e)=>{e.stopPropagation();select("gradient");setGradEl(gradIndex);selectGradientType("color"); props.setRgb(props.color)};
  const style = { zIndex:2,position: "absolute", border:`1px dashed ${contrastColor}`};

  if ((selGradient.type !== "linear-gradient") || (direction === "angle")) {
    return null;
  } else if (direction === "to bottom") {
    style.top = props.position+"%";
    style.height = "6px"
    style.marginTop= (props.position * -6/100) + "px";
    style.width = "100%";
  } else if (direction === "to top") {
    style.bottom = props.position+"%";
    style.height = "6px"
    style.marginBottom= (props.position * -6/100) + "px";
    style.width = "100%";
  } else if (direction === "to left") {
    style.right = props.position+"%";
    style.width = "12px";
    style.height = "100%"
    style.marginRight= (props.position * -12/100) + "px";
  } else if (direction === "to right") {
    style.left = props.position+"%";
    style.width = "12px";
    style.height = "100%"
    style.marginLeft= (props.position * -12/100) + "px";

  }
  if (touch === "moving") {
    style.backgroundColor = contrastColor;
  }
  return (
      <div className="no-touch" style={style} onClick={click}  onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} ref={ref}></div>
  );
};

export default Handle;


/*let top;
if (gradIndex === 0) {
  top = props.position ? props.position+"%" : "0%";
} else if (gradIndex === (gradient.length -1)) {
  top = props.position ? props.position+"%" : "100%";
} else {
  const width = Math.round((100 / (colors.length -1))*10) / 10;
  top = props.position ? props.position+"%" : (gradIndex * width) + "%";
}
*/
