import React from 'react';
import {FormInput, FormSelect} from "../../forms/forms.js";

/*

*/


function MetaInputs(props) {
   if (false) {
     return null;
   } else {
     return (
         <div className="w3-row w3-section">
           <FormInput name="tagsUri" label="Tags URI" value={props.rows.tagsUri} maxWidth="w3-col " handleChange={props.handleChange}  color="w3-light-gray" validate=""  />
           <FormInput name="metaTitle" label="Ttitle" value={props.rows.metaTitle} maxWidth="w3-col w3-section" handleChange={props.handleChange}  color="w3-light-gray" validate=""  />
           <FormInput name="metaDescription" label="Meta description" value={props.rows.metaDescription} maxWidth="w3-col w3-section" handleChange={props.handleChange}  color="w3-light-gray" validate=""  />
           <FormInput name="btnName" label="Button Name" value={props.rows.btnName} maxWidth="w3-col w3-section" handleChange={props.handleChange}  color="w3-light-gray" validate=""  />
           <FormInput name="component" label="Component" value={props.rows.component} maxWidth="w3-col w3-section" handleChange={props.handleChange}  color="w3-light-gray" validate=""  />
         </div>
     );
   }
}


export default MetaInputs;
