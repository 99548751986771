import React, {useEffect, useState, useRef} from 'react';
import SvgIcon from '../../../../../SvgIcon';

function MovingHandle(props) {
    const {touch, setTouch} = props;
    const touchTimer = useRef(null);
    const handleRef = useRef(null);

    useEffect(()=> {
      handleRef.current.addEventListener('touchmove', touchMove, {passive: false});
      return ()=>{if (handleRef.current) handleRef.current.removeEventListener('touchmove', touchMove, {passive: false})};
    });



    const touchStart = (e)=> {
      e.stopPropagation();
      setTouch("started");
      if (touchTimer.current) clearTimeout(touchTimer.current);
      touchTimer.current = setTimeout(()=>setTouch("moving"), 500);

    };

    const touchEnd = (e)=> {
      e.stopPropagation();
      if (touchTimer.current) clearTimeout(touchTimer.current);
      setTouch(false);
    };


    const touchMove = (e)=> {
      e.stopPropagation();
      e.preventDefault();  // works onbly with active events: {passive: false} in the addevent
      if (touchTimer.current) clearTimeout(touchTimer.current);
      if (touch === "moving") {
        props.setHeight( window.innerHeight - e.changedTouches[0].clientY + 20);
      } else if (touch) {
        setTouch(false);
      }
    };

    return (
        <div  ref={handleRef}  className={"w3-display-topleft a-pWidth-100 noselect a-h-30 "+((touch === "moving") ? "":"")}   style={{zIndex: "100", top:"-17px"}} onTouchStart={touchStart} onTouchEnd={touchEnd}>
            <div className={"w3-display-topright a-container-2 noselect a-bkg-white "+((touch === "moving") ? "":"w3-hide")} style={{marginTop:"-10px", height: "25px"}}  >
                <SvgIcon icon="drag_handle" color="dodgerblue" size="30px" className="noselect"/>
            </div>
        </div>
    );
}

export default MovingHandle;
