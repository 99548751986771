import SvgIcon from '../SvgIcon';

export const isObj = (val) => (typeof val === 'object' && !Array.isArray(val) && val !== null) ;

export function Tooltip(props) {
  const {tooltip, media} = props;
  let value = null;
  if (!tooltip) {
    return null;
  } else if (!isObj(tooltip)) {
      value = tooltip;
  } else if (media.isMobile) {
      if (tooltip.s) {value = tooltip.s} else {return null;}
  } else if (media.isTablet) {
    if (tooltip.m) {value = tooltip.m} else {return null;}
  } else if (!media.isTablet) {
    if (tooltip.l) {value = tooltip.l} else {return null;}
  }
  const className = "a-btn-tooltip " + (props.colorClass ? props.colorClass : "a-bkg-black a-font-white");
  return (<div className={className}>{value}</div>);

}

export function Text(props) {
  if (props.text && !props.dropMenu) {
    return <div className={props.icon ? "w3-margin-left-6" : ""}>{props.text}</div>
  } else {
    return null;
  }
}

export function ButtonIcon(props) {
  if ((!props.svg && !props.icon) || props.dropMenu) {
    return null;
  } else {
    return (
      <div className="a-btn-icon-container noselect">
          <SvgIcon icon={props.svg ? props.svg : props.icon} color={props.color} colorClass="icon-btn-color" />
      </div>
    );
  }
}





const adjIconSize = (value)=>{
  let i = value;
  if (i >= 50) {
    i = 50;
  } else if (i >30) {
    const rem = i%2;
    if (rem !== 0) i--;
  }
  return i;
}

const adjIconPad = (value)=>{
  let i = value;
  if (i >= 30) {
    i = 30;
  } else if (i <30) {
    const rem = i%2;
    if (rem !== 0) i++;
  }
  return i;
}



export function iconSizeAndPadding(icon) {
    let iconSize = " ";
    if (isObj(icon)) {
        if (!isObj(icon.size)) {
          iconSize += " an-icon-"+adjIconSize(icon.size)+" ";
        } else if (icon.size) {
          iconSize += " an-icon-"+adjIconSize(icon.size.l)+" ";
          if (icon.size.m) iconSize += " an-icon-"+adjIconSize(icon.size.m)+"-t ";
          if (icon.size.s) iconSize += " an-icon-"+adjIconSize(icon.size.s)+"-s ";
        }
        if (icon.lPad) iconSize+= " an-icon-lPad-"+adjIconPad(icon.lPad)+" ";
        if (icon.rPad) iconSize+= " an-icon-rPad-"+adjIconPad(icon.rPad)+" ";
        if (icon.tPad) iconSize+= " an-icon-tPad-"+adjIconPad(icon.tPad)+" ";
        if (icon.bPad) iconSize+= " an-icon-bPad-"+adjIconPad(icon.bPad)+" ";
    }
  return iconSize;
}

const adjustHeight = (height)=> {
  if (height > 30 && height<= 60) {
    if (height % 2 !== 0) height--;
  } else if (height > 60 && height< 100){
    const rem = height % 5;
    if (rem !== 0) {
      height += (5 - rem);
    }
  }
  return height;
}

export function calcHeight(height) {
  let heightClass = " ";
  if (isObj(height)) {

    heightClass += (" a-height-" + adjustHeight(height.l)+" ");
    if (height.m) heightClass += (" a-height-" + adjustHeight(height.m)+"-t ");
    if (height.s) heightClass += (" a-height-" + adjustHeight(height.s)+"-s ");
  } else if (height){
    heightClass += (" a-height-" + adjustHeight(height)+" ");
  }
  return heightClass;
}

const adjustWidth = (value)=>{
  let i = value;
  if (i > 300 ) {
    const rem = i%50;
    if (rem !== 0) {
      i+= (50 - rem);
    }
  } else {
    const rem = i%10;
    if (rem !== 0) {
      i+= (10 - rem);
    }
  }
  return i;
}

const adjustCont = (value)=>{
  let i = value;
  if (i<200 && i>70 ) {
    const rem = i%10;
    if (rem !== 0) {
      i+= (10 - rem);
    }
  } else if (i<=70 && i>40) {
    const rem = i%5;
    if (rem !== 0) {
      i+= (5 - rem);
    }
  }
  return i;
}

export function calcContainer(value) {
  let outClass = " ";
  if (isObj(value)) {
  outClass += (" a-container-" + adjustCont(value.l)+" ");
  if (value.m) outClass += (" a-container-" + adjustCont(value.m)+"-t ");
  if (value.s) outClass += (" a-container-" + adjustCont(value.s)+"-s ");
} else if (value){
    outClass += (" a-container-" + adjustCont(value)+" ");
  }
  return outClass;
}

export function calcWidth(value) {
    let widthClass = " ";
    if (isObj(value)) {
    widthClass += (" a-width-" + adjustWidth(value.l)+" ");
    if (value.m) widthClass += (" a-width-" + adjustWidth(value.m)+"-t ");
    if (value.s) widthClass += (" a-width-" + adjustWidth(value.s)+"-s ");
  } else if (value){
    widthClass += (" a-width-" + adjustWidth(value)+" ");
  }
  return widthClass;
}

const adjBrd = (value)=>{
  let i = value;
  if (i >= 50) {
    return 50;
  } else if (i <50) {
    const rem = i%5;
    if (rem !== 0) {
      i+= (5 - rem);
    }
    return i;
  } else {
    return 0;
  }
}

export function calcBorderRadius(value, media) {
  let brdRad = " ";
  if (!value && value !== 0) return brdRad;
  if (isObj(value)) {
    brdRad += ((media.isMobile && value.s) ? "a-btn-brdRad-"+adjBrd(value.s) : ((media.isTablet && value.m) ? "a-btn-brdRad-"+adjBrd(value.m) : "a-btn-brdRad-"+adjBrd(value.l)));
  } else {
    brdRad += "a-btn-brdRad-"+adjBrd(value);
  }
  return brdRad+" ";
}

export function calcBorderSize(value, media, filling) {
  let brd = " ";
  const adj = (i) => [0,1,2,3,4].includes(i) ? i : 1;
  if (!value && value !== 0) {
    if (["a-btn-bordered", "a-btn-bordered-dark", "bordered", "bordered-dark", "a-btn-bordOnHover", "bordOnHover"].includes(filling)) {
      return " a-btn-brdSize-1 "; // for bordered fillings if border is not set we add a 1px border since it's compulsory
    } else {
      return brd;
    }
  }
  if (isObj(value)) {
    brd += ((media.isMobile && value.s) ? "a-btn-brdSize-"+adj(value.s) : ((media.isTablet && value.m) ? "a-btn-brdSize-"+adj(value.m) : "a-btn-brdSize-"+adj(value.l)));
  } else {
    brd += "a-btn-brdSize-"+adj(value);
  }
  return brd+" ";
}
