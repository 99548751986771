function Logo(props) {
  const unit = props.unit ? props.unit : "";
  if (!props.width && !props.height) return null;
  const width = "auto";
  const height = (props.height ? props.height : props.width * (44.305/177.982)) + unit;
  const color = props.color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 47.091 11.722"
      className="icon-btn-color"
    >
      <defs>
        <linearGradient id="linearGradient27283">
          <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          x1="35.165"
          x2="82.256"
          y1="63.428"
          y2="63.428"
          gradientTransform="matrix(1.10396 0 0 1.10396 0 -11.614)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient27283"
        ></linearGradient>
      </defs>
      <g transform="translate(-35.165 -58.619)">
        <text
          className="icon-btn-color"
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="38.323"
          y="59.226"
          fill={color}
          fillOpacity="1"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="0.293"
          fontFamily="sans-serif"
          fontSize="8.179"
          transform="scale(.90583 1.10396)"
        >
          <tspan
            className="icon-btn-color"
            style={{
              InkscapeFontSpecification: "'Chalkduster, Normal'",
              fontVariantLigatures: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantEastAsian: "normal",
            }}
            x="38.323"
            y="59.226"
            fill={color}
            fillOpacity="1"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeWidth="0.293"
            fontFamily="Chalkduster"
            fontSize="8.179"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            My Ukraine
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default Logo;
// #FFD700
