import React, {useEffect, useState, useRef} from 'react'; import {DivSelect, FormInput} from '../../../../../forms/forms';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import SvgIcon from '../../../../../SvgIcon'; import './level.css';


function RangeLevel(props) {
    const [rangeWidth, setRangeWidth] = useState(0);
    const [decArrHover, setDecArr] = useState(false);
    const [incArrHover, setIncArr] = useState(false);
    const [arrowsClicked, setArrowsClicked] = useState(false);
    const leftArrTimer = useRef(0);
    const rightArrTimer = useRef(0);

    useEffect(()=>{
      if (window.innerWidth < 734) {
        setRangeWidth(window.innerWidth - props.paddings);
      } else {
        const el = document.getElementById("cl-range"+props.type);
        if (el) {
          const width = el.getBoundingClientRect().width;
          setRangeWidth(width);
        }
      }
    });

    useEffect(()=>{if (decArrHover) setDecArr(false); if (incArrHover) setIncArr(false);},[props.leaveCount]);
    const {type, unit, col, value, max, adjType} = props;

    const thumbSize = window.innerWidth < 734 ? 20 : 18;
    const adj =  - thumbSize * (value)/ max + thumbSize / 2; // the thumb has a size so when while moving to the range limit the actual position will be in the center of the thumb in the range center, instead on the left range limit 0 will be positioned on the left hedge of the thum, so we calculate a linear equation knowing that the adj will be 0 in the center and +- 0.5 thumbSize on the limit
    const levelPos = {left: ((rangeWidth  * (value)/ max)) + adj +"px"};
    const rangeAdj = (value / max) < 0.15 ? " cl-range-start" : ((value / max) > 0.85 ? " cl-range-end" : ""); // if we are close to the range limit we move both the inc dec action icons to to the larger side to avoid overflow
    const addIconSize = window.innerWidth < 734 ? "21px" : "16px";
    const isMobile = window.innerWidth < 734;
    const hide = ((props.adjType === "a") || props.isSliding )? " w3-hide" : "";

    const leftClick = () => {

      if (col[adjType] > 0) {
        if (leftArrTimer.current) clearTimeout(leftArrTimer.current);
        setDecArr(true); setArrowsClicked(true); props.increase(type, false);
        leftArrTimer.current = setTimeout(()=>{setDecArr(false);setArrowsClicked(false);}, 1000);
      }
    }

    const rightClick = ()=> {
      if (col[adjType] < max) {
        if (rightArrTimer.current) clearTimeout(rightArrTimer.current);
        setIncArr(true);setArrowsClicked(true); props.increase(type, true);
        rightArrTimer.current = setTimeout(()=>{setIncArr(false);setArrowsClicked(false);}, 1000);
      }
    }

    if (value === " ") {
      levelPos.display = "none";
    }
    if (props.editValue) {
      return null;
    } else {
      return (
        <div className={"cl-range-in-level pointer noselect "+(arrowsClicked ? "cl-lev-clicked":"")} style={levelPos}>
            <div className={arrowsClicked ? "w3-animate-opacity-04":""}  onClick={()=>isMobile ? null : props.setEditValue({levelPos:levelPos, adj:(col[adjType]/ max) > 0.5})} onMouseEnter={()=>props.setLeaveCount(props.leaveCount+1)}>{value +unit}</div>
            <div className="cl-range-in-level-arrow"/>
            <div className={"cl-range-arr-left-action "+rangeAdj+hide} onClick={leftClick} onMouseEnter={()=>setDecArr(true)} onMouseLeave={()=>setDecArr(false)}>
                  <SvgIcon icon="remove" size={addIconSize} color={decArrHover ? "red":"dodgerblue"} key={decArrHover+"left"+type} />
            </div>
            <div className={"cl-range-arr-right-action "+rangeAdj+hide} onClick={rightClick} onMouseEnter={()=>setIncArr(true)} onMouseLeave={()=>setIncArr(false)}>
                  <SvgIcon icon="add" size={addIconSize} color={incArrHover ? "red":"dodgerblue"} key={incArrHover+"right"+type}/>
            </div>
        </div>
      );
    }

}

export default RangeLevel;
