function lightnessMap(hsl) {
    let out = [];
    const {h,s,l} = hsl;
    for (let i=0; i<=100; i+=5) {
        if (i < l) {
          out.push({hsl:{h:h, s:s, l:i}, selected: false});
        } else if (i==l) {
          out.push({hsl: {h:h, s:s, l:l}, selected: true});
        } else if (i>l && i-5<l){
          out.push({hsl: {h:h, s:s, l:l}, selected: true});

        } else {
          out.push({hsl: {h:h, s:s, l:i}, selected: false});
        }
    }
    return out;
}

function saturationMap(hsl) {
    let out = [];
    const {h,s,l} = hsl;
    for (let i=0; i<=100; i+=5) {
        if (i < s) {
          out.push({hsl: {h:h, s:i, l:l}, selected: false});
        } else if (i==s) {
          out.push({hsl: {h:h, s:s, l:l}, selected: true});
        } else if (i>s && i-5<s){
          out.push({hsl: {h:h, s:s, l:l}, selected: true});

        } else  {
          out.push({hsl: {h:h, s:i, l:l}, selected: false});
        }

    }
    return out;
}

function hueMap(hsl) {
    let out = [];
    const {h,s,l} = hsl;
    for (let i=0; i<=360; i+=18) {
        if (i < h) {
          out.push({hsl: {h:i, s:s, l:l}, selected: false});
        } else if (i==h) {
          out.push({hsl: {h:h, s:s, l:l}, selected: true});
        } else if (i>h && i-18<h){
          out.push({hsl: {h:h, s:s, l:l}, selected: true});

        } else {
          out.push({hsl: {h:i, s:s, l:l}, selected: false});
        }

    }
    return out;
}

function redMap(rgb) {
    let out = [];
    const {r,g,b} = rgb;
    for (let i=0; i<=255; i+=12.75) {
        if (i < r) {
          out.push({rgb: {r:Math.round(i), g:g, b:b}, selected: false});
        } else if (i==r) {
          out.push({rgb: {r:r, g:g, b:b}, selected: true});
        } else if (i>r && i-12.75<r){
          out.push({rgb: {r:r, g:g, b:b}, selected: true});
        } else {
          out.push({rgb: {r:Math.round(i), g:g, b:b}, selected: false});
        }
    }
    return out;
}

function greenMap(rgb) {
    let out = [];
    const {r,g,b} = rgb;
    for (let i=0; i<=255; i+=12.75) {
        if (i < g) {
          out.push({rgb: {r:r, g:Math.round(i), b:b}, selected: false});
        } else if (i==g) {
          out.push({rgb:{r:r, g:g, b:b}, selected: true});
        } else if (i>g && i-12.75<g){
          out.push({rgb:{r:r, g:g, b:b}, selected: true});
        } else {
          out.push({rgb:{r:r, g:Math.round(i), b:b}, selected: false});
        }
    }
    return out;
}

function blueMap(rgb) {
    let out = [];
    const {r,g,b} = rgb;
    for (let i=0; i<=255; i+=12.75) {
        if (i < b) {
          out.push({rgb:{r:r, g:g, b:Math.round(i)}, selected: false});
        } else if (i==b) {
          out.push({rgb:{r:r, g:g, b:b}, selected: true});
        } else if (i>b && i-12.75<b){
          out.push({rgb:{r:r, g:g, b:b}, selected: true});
        } else {
          out.push({rgb:{r:r, g:g, b:Math.round(i)}, selected: false});
        }
    }
    return out;
}

export {hueMap, saturationMap, lightnessMap, redMap, greenMap, blueMap};
