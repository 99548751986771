import React from 'react';
import {FormInput, FormSelect} from "../../../forms/forms.js"; import TagInputs from './TagInputs'; import EditRow from './EditRow';



class Tags extends React.Component {
  constructor(props) {
      super(props);
      this.state = {nTags: 1, tags:[{name: ""}], editTags:null};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.resetChange = this.resetChange.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
      this.handleEditTags = this.handleEditTags.bind(this);
      this.handleTagsCount = this.handleTagsCount.bind(this);
      this.handleTags = this.handleTags.bind(this);
      this.focus = this.focus.bind(this);
      //this.titleRef = React.createRef();


  }

/*
  componentDidMount() {
    if (this.props.nonso) {
      const emailErr = this.props.show[0];
      const passwordErr = this.props.show[1];
      if (emailErr) {
        this.emailRef.current.setRedBackground();
      }
      if (passwordErr){
        this.passwordRef.current.setRedBackground();
      }
    }

  }
*/

  focus(element) {
    if (element == "email") {
      this.emailRef.current.autofocus();
    }
  }




  handleChange(e) {
    const target = e.target;
    const value =  target.value;
    this.setState({[target.name]: value});
  }



  resetChange(e) {
    const target = e.target;
    this.setState({[target.name]: ""});
  }

  handleCount(e,field, count) {
    const target = e.target;
    const value =  target.value;
    let newImgArray;
    if (value < this.state[count]) {
      newImgArray = this.state[field].slice(0,value);
    } else if (value > this.state[count]) {
      for (let i=0; i < (value - this.state[count]);i++) {
        newImgArray = this.state[field];
        newImgArray.push({});
      }
    } else {
      return null;
    }
    this.setState({[field]: newImgArray, [count]: value});
  }

  handleTagsCount(e) {
    this.handleCount(e,"tags", "nTags");
  }


  handleTags(e,i,field) {
    let tags = this.state.tags;
    tags[i][field] = e.target.value;
    this.setState({tags:tags});
  }

  handleEditTags(e,i,field) {
    let tags = this.state.editTags;
    tags[i][field] = e.target.value;
    this.setState({editTags:tags});
  }

  handleEdit(e) {
    return;
  }

  handleSubmit(e) {
    return;
    let alertMessage = "";
    if (this.state.title == "") {
        if (alertMessage ==  "") {
            alertMessage = "Title";
        } else {
            alertMessage = alertMessage + ", Title";
        }
        this.titleRef.current.setRedBackground();
    }

    if (alertMessage != "") {
        alertMessage = "Error: " + alertMessage + " cannot be empty!";
        //this.alertHandler(alertMessage);
        e.preventDefault();
    }
  }


  componentDidMount() {

      this.setState({editTags: this.props.tags});

  }






  render() {
    if (this.props.show == "editTags" && this.props.tags.length) {
      return (
        <div className="w3-content-1300 w3-section-32 w3-row h100vh">
            {this.state.editTags.map((row, index) => <EditRow key={index} index={index} {...row} tags={this.state.editTags} handleTags={this.handleEditTags} handleEdit={this.handleEdit}/>)}
        </div>
      );
    } else if (this.props.show != "newTags") {
        return null;
      } else {
        return (
          <div className="w3-content-1300 w3-section-32 h100vh">
          <form action="/tags/createTags" method="post" onSubmit={this.handleSubmit}>
              <div className="w3-section">
              <FormSelect name="nTags" label="Number of tags" value={this.state.nTags} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleTagsCount}  color="w3-light-gray" validate="" />
              </div>
              <TagInputs rows={this.state.tags} handleChange={this.handleTags} count={this.state.nTags}/>
              <div className="w3-margin-top-24 w3-margin-bottom ">
                  <input type="submit" value="Create Tags" className="w3-button  w3-content w3-blue h55 w100 w3-round"/>
              </div>
          </form>
          </div>
        );
    }
  }

}




export default Tags;
