import React, {useState, useEffect, useRef} from 'react';import RowInput from './RowInput';
import Button from '../../../../Buttons/Button';import SvgIcon from '../../../../SvgIcon';import Error from './Error';

/*
    <div className=""></div>
*/

function Triplet(props) {

  const from = props.from.toLowerCase();
  let timer = useRef(0);
  const [refocus, setRefocus] = useState(1);
  const handleFocus = (bol) => {
      if (timer) {
        clearTimeout(timer.current);
      }
      if (bol) {
        props.setFocused(bol);
      } else {
        timer.current = setTimeout(()=>props.setFocused(false),500);
      }
  };


   if (props.from === "HEX") {
     return null;
   } else {
     const triplet = (props.from === "RGB") ? ["r","g","b"] :  ((props.from === "HSL") ? ["h","s","l"] :  ["h","s","v"]);
     //onst max = ["r","g","b", "gray"].includes(type) ? 255 : ((["h","hue"].includes(type)) ? 360 : 100);
     return (
       <div className="flex-cen-cen a-mrT-16 a-mrT-26-s a-wrap">
          {triplet.map((row) => <RowInput key={row} type={row} handlers={props.handlers} from={from} {...props.color} setError={props.setError} errorTimeout={props.errorTimeout}  setFocused={handleFocus} cancelTimer={props.cancelTimer} focused={props.focused} refocus={refocus}/>)}
          <Error error={props.error}  cancelTimer={()=>{const focus = props.focused; setTimeout(()=>{handleFocus(focus); setRefocus(refocus+1);}, 350);}}/>
          <div className="w100 a-mrT-26 a-mrB-6 flex-cen-cen " >
               <Button  text="Reset" click={()=>{props.handlers.hex("#000000");setTimeout(()=>handleFocus(from),200);}} color="a-btn-blue-1"  className="a-mrR-32 a-width-150" size={props.btnSize}/>
               <Button  text="Calculate" click={()=>null} color="a-btn-blue-1"  className={"a-width-150"+(props.focused ? "":" w3-hide")} size={props.btnSize}/>
          </div>
       </div>
     );
   }
}



export default Triplet;
