import React from 'react';import ReactPlayer from 'react-player';import EditBar from './EditBar';

function Video(props) {
  if (!props.video_url) return null;
  return (
    <div className="w100 h-100 w3-display-container">
            <EditBar  user={props.user}  field="_video" media={props} handler={props.editVideo} id={props.id} feedID={props.feedID}/>
            <ReactPlayer url={props.video_url}  controls={true} light={props.light} width="100%" height="100%"  />
    </div>
  );
}

export default Video;
