
import React from 'react';
import "../../w3.css";
import "../../luke.css";
import "../../App.css";
import Link from '../../Link';
import CnnHeader from './CnnHeader'; import HeaderImg from './HeaderImg'; import HeaderNoImg from './HeaderNoImg';

function ArticleHeader(props) {
    if (false) {
        return null;
    } else {
      const RowFunction = ({rowFunction}) => rowFunction ? React.cloneElement(rowFunction, {...props}) : null;
        switch (props.type) {
          case "cnn": return <CnnHeader {...props} />;
            break;
          case "img": return <HeaderImg {...props} />;
            break;
          case "no-img": return <HeaderNoImg {...props} />;
            break;
          case "custom": return <RowFunction rowFunction={props.component} />;
            break;
          default: return <CnnHeader {...props} />

        }
    }
  }

export default ArticleHeader;
