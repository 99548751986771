import Button from '../../../../../Buttons/Button';
/*
    <div className=""></div>
*/

/* Attention, height is 50 but there is a 190px overflow to allow the divselect to open. z index is 0 unless the div select is open.
  Following elements, or preceding if divselect opens upwards, need to have position relative with at lest z-index 1 otherwise elements will not be clickable because since  edit buttons overflows on them, it will be clicked instead having higher priority. z-index 0 wins over no positioniong.
*/
function EditButtons(props) {
  if (props.hideControls) {
    return null;
  } else {
    const others = props.others ? props.others : [];
    return (
      <div className={props.noMargin ? "" : "a-mrT-32" } style={{height:"50px", overflow: "visible",position: "relative"}} >
          <div className="a-container-12  scroll-x-auto no-scr-bar"  style={{position: props.topDrop?"absolute":"relative", height:"190px", bottom:props.topDrop?0:"auto", display:"flex", alignItems:props.topDrop?"flex-end":"flex-start"}}>
              <div className=" flex-cen-start a-container-16 a-pdS-12 w3-round-large max-content p-rel " style={{...props.bkg, marginLeft:"auto",marginRight:"auto", zIndex:6}} onTouchStart={(e)=>e.stopPropagation()}>
                    {props.children}
                    <Button text={"Add "+props.name} click={props.add} color="a-btn-blue-1"  className="max-content a-mrR-12" size="a-btn a-btn-height-26 a-btn-container-15 a-btn-font-13"/>
                    <Button text={"Delete "+props.name+" "+props.selected} click={props.delete} color="a-btn-red" className="max-content"  size="a-btn a-btn-height-26 a-btn-container-15 a-btn-font-13 "/>
                    {others.map((row, index)=><OtButton {...row} key={index}/>)}

              </div>
          </div>
      </div>
    );
  }

}

const OtButton = (props) => {
  if (!props.name) {
    return null;
  } else {
    return <Button text={props.name} click={props.handler} color="a-btn-blue-2"   size="a-btn a-btn-height-26 a-btn-container-15 a-btn-font-13 a-mrL-12"/>
  }
}

export default EditButtons;
