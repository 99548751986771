import React from 'react';
import EmbeddedPic from "./Body/EmbeddedPic";
import HeaderNoImg from './Header/HeaderNoImg'; import Ulist from './Body/Ulist'; import CnnPar from './Body/CnnPar'; import ImageBlock from './Body/ImageBlock';

function BodyRow(props) {
    switch (props.type) {
      case "par": return <CnnPar {...props} />;
        break;
      case "emb": return <EmbeddedPic {...props} />;
        break;
      case "img": return <ImageBlock  {...props}/>;
        break;
      default: return null;
    }
}

export default BodyRow;
