import Link from '../../../../../Link';

const ALink = (props) => {

    const bkg = (props.id === props.selected) ? {backgroundColor: "orange", color: "white"} : {};
    const click = ()=> {
                        if (props.id && !props.handler) {
                          if (props.scarlet) {
                            props.setVisibleSections(props.id);
                            props.setSelected(props.id);
                          } else if (["converter","color-handling"].includes(props.visibleSections)) {
                            props.setVisibleSections("all");
                            setTimeout(()=>scrollTo(props.id), 70);
                          } else {
                            scrollTo(props.id);
                          }
                        } else {
                          if (props.id) props.setSelected(props.id);
                          props.handler();
                        }
                      };

    const scrollTo = (id) => {
          const el = document.getElementById(id);
          let top = el.getBoundingClientRect().top;
          document.documentElement.scrollTop += (top - (60 + window.innerWidth * 0.26));
          setTimeout(()=>{
              top = el.getBoundingClientRect().top;
              document.documentElement.scrollTop += (top - (60 + window.innerWidth * 0.26));
              props.setSelected(id);
              },100);
        }; // mapping has a zero heightwhen scroll to so we call a timeout after 100 ms to adjust the position after the emelment has rendered and has a height. Another way would be to set an height to the unredered mapping so when it renders we avoid the scrolling shift

  const className = " w3-bold a-font-14 a-pdS-8 a-pdS-6-xs width-max lh-1 noselect a-container-26 a-container-18-xs "+props.titleColor;
  return (<Link click={click} className={className} style={bkg}> {props.name}</Link>);
};

export default ALink;
