import React, { useState } from 'react'; import MenuButton from './MenuButton';
import Button from '../../../Buttons/Button';import {FormInput, DivSelect} from "../../../forms/forms.js"; import DropMenu from './DropMenu';

/*
    <div className=""></div>
*/








function Inputs(props) {


  function addDropButton(catIndex) {
    props.setDropButton("addButtonxxx", props.selectedDropMenu, "addButtonxxx", catIndex)
  }

   if ((props.selectedDropMenu !== null) && (props.selectedDropMenu !== false)) {
     return (
         <DropMenu   metas={props.metas} rows={props.buttons[props.selectedDropMenu].dropMenu} addDropButton={addDropButton} setDropButton={props.setDropButton}
         {...props.dropHandlers} />
     );
   } else {
     return (
         <div className="">
             {(!props.buttons || !props.buttons.length) ? null : props.buttons.map((row, index) =>
               <MenuButton {...row} key={index+"menuButt"+props.selectedBar} btnIndex={index} length={props.buttons.length} setButton={props.setButton} metas={props.metas} moveButton={props.moveButton} selectDropMenu={props.selectDropMenu}/>
             )}

             <div className="w3-section w3-container-8">
                   <Button text="Add Button" type="text" click={true} color="a-btn-orange-2" size="a-btn-size-2" id="addButtonxxx"  className=""  clickHandler={props.setButton}/>
             </div>

         </div>
     );
   }
}


export default Inputs;
