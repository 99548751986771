import Reac, {useState} from 'react'; import SvgIcon from '../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, hsvToCss, contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../converters.js';

/*
    <div className=""></div>
*/
function CopyCssColor(props) {
  if ((props.type === "pickers") || props.scarlet) {
    return null
  } else {
    return (
      <div className={" w3-display-topright flex-cen-start "+ (props.copiedMsg ? "w3-animate-opacity" : "")} style={{left: "100%", width: "150px", top: "-6px"}}>
            <SvgIcon icon="content_copy" size="15px" color="dodgerblue" hoverColor="#00BFFF" click={props.clipboard} key={props.color.hex}/>
            <div className={"w3-margin-left w3-text-red a-font-12 "+ (props.copiedMsg ? "" : "w3-hide")}>Copied</div>

      </div>
    )
  }
}

function RangeSectionHeader(props)  {
    const [copiedMsg, setCopiedMsg] = useState(false);
    const cssColor = sectionTitle(props.sectionType, props.color);
    const clipboard = () => navigator.clipboard.writeText(cssColor).then(()=>{setCopiedMsg(true);setTimeout(()=>setCopiedMsg(false), 1500);});
    if (props.scarlet) {
      return null
    } else {
      return (
          <h3 className="cl-range-section-title noselect " onClick={clipboard}>
              <span className="noselect">{cssColor}</span>
              <CopyCssColor copiedMsg={copiedMsg} color={props.color} clipboard={clipboard} type={props.sectionType} scarlet={props.scarlet}/>
          </h3>
      )
    }

}

const sectionTitle = (type, color) => {
    let title;
    switch (type) {
      case "rgb":  title =  rgbToCss(color[type]);
        break;
      case "hsl":  title =  hslToCss(color[type]);
        break;
      case "hsv":  title =  hsvToCss(color[type]);;
        break;
      case "pickers":  title = "Color Pickers";
        break;

      default: title = null;

    }
    return title;
};

export default RangeSectionHeader;
