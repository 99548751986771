import React, {useState, useEffect, useRef} from 'react';import {FormInput} from '../../../../forms/forms';

function RowInput(props) {
    const [blurred, setBlur] = useState(false);
    useEffect(()=> {if (blurred) {handleBlur();setBlur(false)};} ,[blurred]);
    useEffect(()=>{if (((props.focused === props.from) && ["r","h"].includes(props.type)) || (props.focused === props.type)) { myRef.current.autofocus();}},[props.hex, props.focused, props.refocus]);
    useEffect(()=> {if (props.focused !== props.type) myRef.current.setBlurBackground();});
    const myRef = React.createRef();
    const {type, from} = props;
    const max = ["r","g","b"].includes(type) ? 255 : ((type === "h") ? 360 : 100);
    let blurTimer = null;

    function handler(e) {
        props.cancelTimer();
        const value = e.target.value;
        const length = value.length;
        let int = parseInt(value, 10);
        let numCheck = numeric(value);

        if (!numCheck) {
          myRef.current.setRedBackground();
          props.setError("Only numbers allowed!");
          props.errorTimeout();
      } else if (!Number.isInteger(int) && (value !== "")) {
          myRef.current.setRedBackground();
          props.setError("Only numbers allowed!");
          props.errorTimeout();
        } else if (Number.isInteger(int) && (int < 0 || int > max) ) {
          myRef.current.setRedBackground();
          props.setError("Only numbers in 0 - "+max+" range!");
          props.errorTimeout();
        } else {
          if (from !== "hsv") {
            if (value == "") {
              e.target.value = 0;
            }
            props.handlers[from](e.target.value,false, e.target.name);
          } else {
            if (value == "") {
              if (e.target.name == "s") {int = 0.1;} else {int = 0;}
            }
            props.hsv[e.target.name] = int;
            props.handlers[from](props.hsv, true);
          }

        }
    }

    const handleBlur = () => {
      props.cancelTimer();

      props.setFocused(false);
    };

    const focus = ()=> setTimeout(()=>props.setFocused(type), 200);

    return (
      <div className="cl-triplet-row flex-cen-cen ">
          <div className="cl-triplet-row-label">{type.toUpperCase()}</div>
          <FormInput name={type} value={Math.round(props[from][type])} handleChange={handler} margin="no-marg" className="" defs="hex-imp-triplet-defs cl-range-input-col grow-1"
                ref={myRef} onFocus={focus}  blur={()=>{blurTimer = setTimeout(()=>setBlur(true),20)}}/>

      </div>
    );

}


export default RowInput;

function numeric(value) {
    const length = value.length;
    for (let i=0; i<length; i++) {
      if (!["","0","1","2","3","4","5","6","7","8","9"].includes(value.charAt(i))) {
        return false;
      }
    }
    return true;
}
