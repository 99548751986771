import {DivSelect} from '../../../../../forms/forms';

const HeightSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const selGradient = gradient[selectedGradient];
    const colors = selGradient.colors;
    const height = selGradient.height;
    const options = ["30vh","40vh","50vh","60vh","70vh","85vh","100vh","133vh","166vh","200vh","250vh","300vh","400vh","768px","1024","1080px"];
    if (!options.includes(height)) options.push(height);

    const handler = (value)=> {
        gradient[selectedGradient].height = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
        setSclColors([...sclColors]);
    }

      return (
        <DivSelect value={height} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-70"  margin="none" noIcon={true} />
    );
}

export default HeightSelector;
