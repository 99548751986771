import SvgIcon from '../../../../SvgIcon';

function Error(props) {
  if (!props.error) {return null} else {
    return (
      <div className="w100 a-mrT-24">
          <div className="cl-inp-converter-error flex-cen-start w3-display-container">
                <SvgIcon icon="error" size="40px" color="red"/>
                <div className="a-mrL-16">{props.error}</div>
                <div className="w3-display-topright a-mrR-6 a-mrT-6" ><SvgIcon icon="clear" size="16px" color="red" click={props.cancelTimer}/></div>
          </div>
      </div>

      );
  }
}

export default Error;
