import React from 'react';
import "../w3.css";
import "../luke.css";
import "./formContainer.css"; import './form.css';


function Required(props) {
  if (props.show && !props.value) {
    return (
      <div className="w3-display-topright required w3-red maxW100 w3-padding-2 w3-padding-right w3-text-white w3-small w3-container-8">
          Required
      </div>
    );
  } else {
    return null;
  }
}

function CoveringLabel(props) {

  if (!props.clicked && (!props.value && (props.value !== 0)) && props.label) {
    return (
      <div className="frm-cov-lab w3-display-middle" onClick={props.handleClick}> {props.label} </div>
    );
  } else {
    return null;
  }

}


function FormContainer(props) {
    const pointer = (props.noPointer) ? " " : " pointer ";
    const className = props.className ? props.className : " ";
    const inputDiv = (props.color == "frm-error-color") ? " input-divN-err " : " input-divN ";
    const margin = (props.margin) ? (" " + props.margin + " ") : " w3-section ";
    const Label = (props) => (props.value || props.clicked || (props.value===0)) ? <label className="frm-label">{props.label}</label> : <span className="frm-label">&nbsp;</span>;
    let defs = props.defs ? (props.defs + " " ) : "form-defs ";
    const covLabel = props.covLabel ? props.covLabel : props.label;
    defs += (props.defColors ? props.defColors : "form-def-col");

    function handleClick(e) {
      e.stopPropagation();
      props.handleClick();
    }

    return (

            <div className={props.className + " " + margin + " "+ defs} >

                <div className={"w3-display-container noselect flex-cen-start " + pointer + inputDiv + props.color } style={{maxWidth: props.maxWidth}}>
                    <Required show={props.required} value={props.value} />
                    <CoveringLabel value={props.value} label={covLabel} handleClick={handleClick} clicked={props.clicked}/>
                    <Label  {...props}/>
                    {props.children}
                </div>
            </div>
            );
}

export default FormContainer;

/*
<FormContainer  {...options} {...optionsWithDefs} handleClick={handler}/>

handler() sends no value, just informs the calling component taht the Covering Label has been clicked, so the last changes the status to clicked and passes it back tp Form container to remove the covering label and show the snall label
const options = {
    color = "class"; // defines bkg and text colors
    clicked = bol; if true the covering label is diplayed otherwise the small label. If no label is set it has no value since no label at all is displayed
    value = {props.value}; the calling componet passes the input value in order to remove the clicked tag and the covering label if the input has already a value, because these are supposed to be showed only with no value.
};
const optionsWithDefs = {
    defs = "form-defs"; // CSS vars definiting size and style
    defColors = "form-def-col"; CSS vars definiting the colors
    label = ""; // if not set no label is displayed and the top padding is not added to the input
    covLabel = ""; if not set takes the value from label if set otwerwise is null
    noPointer = false; // if set to true cursor is not set as noPointer
    className = ""; // classname of the outermost container can be used to set the grid
    margin = " w3-section "; // vertical margins. Put the margin here and not in className to ovveride def margin w3-section
    maxWidth = ""; // value in px of the inner input, doesn't include padding added with className because it's external
    required = false; if set to true there will be a required sticker until the user starts digit
};




*/
