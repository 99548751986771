import React from 'react';
import {FormInput} from "../../../forms/forms.js";
import Button from '../../../Buttons/Button';

/*
    <div className=""></div>
*/

function EditRow(props) {
  function handleChange(e) {
    props.handleTags(e, props.index, "name")
  }

   if (false) {
     return null;
   } else {
     return (
       <div className="w3-section w3-col m4">
       <form action={"/tags/editTag/"+props.id} method="post" onSubmit={props.handleEdit}>


              <div className="w3-margin-top-24 w3-margin-bottom w3-row ">
               <FormInput name="name" label="Tag" value={props.tags[props.index].name} maxWidth="w3-col m8" handleChange={handleChange}  color="w3-light-gray" validate=""  />
               <div className="w3-col m4 flex-cen-start"><Button type="submit" text="Edit" color="a-btn-blue-1 w3-margin-right-8" size="a-btn-top"  click={true} /><Button href={"/tags/deleteTag/"+props.id} svg="delete" color="a-btn-blue-1" size="a-btn-top-icon"  /></div>

           </div>

       </form>

       </div>
     );
   }
}


export default EditRow;
