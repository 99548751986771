import React, {useEffect, useState, useRef} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb,contrastHexColor} from '../../converters.js';
import PositionRangeThumb from './PositionRangeThumb';

/*     <div className=""></div>     */

function PositionRangeEdit(props) {
  const {gradient, sclColors, index, setSclColors, gradEl, selectedGradient} = props.shared;
  const selGradient = gradient[selectedGradient];
  const [sliding, setSliding] = useState(false);
  const colors = selGradient.colors;
  const handler = (e)=> {
    colors[gradEl].position = Number(e.target.value);
    if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
    setSclColors([...sclColors]);
  }

  let colorLevels = "";
  for (let i=0; i<colors.length; i++) {
    const cssPos = (selGradient.type === "conic-gradient") ? ((colors[i].deg / 360 * 100)+"%") : (colors[i].position+"%"); // we transform conic gradient to linear, so degrees ranged 0-360 need to be converted to 0-100%
    colorLevels += ","+rgbToCss(colors[i].color)+" "+cssPos;
  }
  const cssBkg = `linear-gradient(to right ${colorLevels})`;

  // Color position range edit display for all except linear-gradient with no angle direction because we have already the guide to change position
  if ((selGradient.direction !== "angle" && (selGradient.type === "linear-gradient")) || !colors || (props.selectedRange !== "position"))  {
    return null;
  } else {
    return (
      <div className="a-container-12 a-mrB-12 " style={{position:"relative",zIndex:6,}}>
          <div className="a-container-12 a-pdT-26 a-pdB-12 w3-round-large" style={{...props.bkg}}>
                <div className={"range-gradient  "+(sliding ? "sliding-gradient":"")} style={{backgroundImage: cssBkg}}>
                    {colors.map((row, index)=><PositionRangeThumb {...row} key={index} index={index} shared={props.shared} setRgb={props.setRgb} thumbIsSliding={setSliding} sliding={sliding}/>)}
                </div>
          </div>

      </div>

    );
  }
}



export default PositionRangeEdit;
