import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";import "../App.css";
import MaterialIcon, {colorPalette} from 'material-icons-react';
import FormContainer from "./FormContainer";
import DivContainer from "./DivContainer";
import {validateError} from "./forms";

/* <form action="/CI/log/in" method="post" onSubmit={this.handleSubmit}>
<FormInput name="email" type=text_Default value={this.state.email} label="E-Mail" centerLabel=false_default labelColor=blue_dedault
handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="email" alertHandler={this.alertHandler}
maxWidth=notComp grid=notComp ref={this.emailRef}/>
*/
class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {color: "frm-blur-color", clicked: false};
        this.myRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.autofocus = this.autofocus.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }


    autofocus() {
        this.myRef.current.focus();
    }

    setRedBackground() {
        this.setState({color: "frm-error-color"});
    }

    setBlurBackground() {
        this.setState({color: "frm-blur-color"});
    }

    handleLabelClick() {
      this.setState({clicked: true});
      this.autofocus();
    }

    handleChange(e) {
        this.setState({color: "frm-focus-color", clicked: true});
        if (this.props.validate && e.target.value && (this.props.validate != "password") && (this.props.validate != "email")) {
        // We validate the email and passport on Blur and not on cange because the check has to be done when the full input has been enetered
            const error = validateError(this.props.validate, e.target.value);
            if (error) {
                this.setRedBackground();
                //this.props.alertHandler(error, this.props.name);
            } else {
                this.props.handleChange(e);
            }
        } else {
             this.props.handleChange(e);
        }
    }


    handleBlur(e) {
        if (this.state.color != "frm-error-color") {
            this.setState({color: "frm-blur-color", clicked: false});
        } else {
            this.setState({clicked: false});
        }
        if (this.props.blur) {
          this.props.blur(e);
        }
        if (this.props.validate && e.target.value && ((this.props.validate == "password") || (this.props.validate == "email"))) {
            const error = validateError(this.props.validate, e.target.value);
            if (error) {
                this.setRedBackground();
                //this.props.alertHandler(error, this.props.name);
                this.props.resetChange(e);
            }
        }

    }

    handleFocus(e) {
      const color = (this.state.color == "frm-error-color") ? "frm-error-color" : "frm-focus-color";
      this.setState({clicked: true, color: color});
      if (this.props.onFocus) this.props.onFocus(e);
    }

    componentDidMount() {
      if (this.props.autoFocus) this.autofocus();
    }

    render() {
        const type = this.props.type ? this.props.type : "text";
        const inputPad = this.props.label ? "input-top-pad" : "";
        if (this.props.mode == "view") {
          return (
                <DivContainer content={this.props.value} type={type}  color={this.state.color} label={this.props.label}

                maxWidth={this.props.maxWidth} margin={null} />
          );
        } else {
          return (
                  <FormContainer   color={this.state.color} value={this.props.value} clicked={this.state.clicked} defColors={this.props.defColors} defs={this.props.defs}  label={this.props.label}
                  covLabel={this.props.covLabel} margin={this.props.margin} labelColor={this.props.labelColor} noPointer={this.props.noPointer} className={this.props.className}
                   margin={this.props.margin}  maxWidth={this.props.maxWidth}  handleClick={this.handleLabelClick} required={this.props.required}>
                        <input type={type} name={this.props.name} value={this.props.value} onChange={this.handleChange}  onBlur={this.handleBlur}
                        onFocus={this.handleFocus} className={"frm-inputN grow-1 "+inputPad} ref={this.myRef}  />
                  </FormContainer>
          );
        }

    }
}

export default FormInput;

/*
<FormInput name="" value="" {...options} {...formContOptions} handleChange={handleBlur}/>

const formContOptions = { // All these options have defs so are notcompulsory
    defs = "form-defs"; // CSS vars definiting size and style
    defColors = "form-def-col"; CSS vars definiting the colors
    label = ""; // if not set no label is displayed and the top padding is not added to the input
    covLabel = ""; if not set takes the value from label if set otwerwise is null
    noPointer = false; // if set to true cursor is not set as noPointer
    className = ""; // classname of the outermost container can be used to set the grid
    margin = " w3-section "; // vertical margins. Put the margin here and not in className to ovveride def margin w3-section
    maxWidth = ""; // value in px of the inner input, doesn't include padding added with className because it's external
    required = false; if set to true there will be a required sticker until the user starts digit
};

const options = {
  type: "text"; // default
  validta:
}


*/
