import React, {useState, useEffect} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, contrastColor} from '../Range/converters';
import '../colors.css';import Button from '../../../Buttons/Button';import {DivSelect, FormInput} from '../../../forms/forms';import SvgIcon from '../../../SvgIcon';
import {Actions, PhoneActions} from './actions'; import Color from './Color';
/*
  <div className=""></div>
*/



function ColorsList(props) {

    let move = {};
    [move.view, move.setView] = useState(false);
    [move.colorsList, move.setColorsList] = useState([]);
    const [selectedList, setSelectedList] = useState("");
    const [scrolled, setScroll] = useState(false);
    const [moved, setMoved] = useState(false);
    move.handleBtn = (id)=> {const list = [...move.colorsList]; const index = list.indexOf(id);if (index === -1)  {list.push(id);} else {list.splice(index, 1);} move.setColorsList(list)};
    useEffect(()=> window.addEventListener("scroll", scroll),[]);
    const listsOptions = [];
    const scroll = ()=> {
      if ((document.documentElement.scrollTop > 0) && !scrolled)  {
        setScroll(true);
      } else if ((document.documentElement.scrollTop == 0) ) {
        setScroll(false);
      }
    };
    for (let i = 0; i<props.lists.length; i++) {listsOptions.push([props.lists[i].name, props.lists[i].id]);}
    const colors = props.colors;
    const moveBtnHandler = () => {if (move.view) {move.setView(false); move.setColorsList([]); setSelectedList(null);} else {move.setView(true);}};
    const doMove = (value) => {props.moveColors(move.colorsList, value);setMoved(true); setTimeout(()=>setMoved(false), 2000);moveBtnHandler();};
    const cancelMoving = () => {move.setView(false); move.setColorsList([]); setSelectedList(null);};
    const selectAll = ()=> {const list = []; for (let i=0; i<colors.length; i++) {if (colors[i].list == props.show.id) list.push(colors[i].id)} move.setColorsList(list);}
    const actionsHandlers ={doMove: doMove, deleteList: props.deleteList, setMove: move.setView, cancelMoving: cancelMoving, listsOptions: listsOptions,
    selectedList: selectedList, colorsList: move.colorsList, moving: move.view, deselectAll: ()=>move.setColorsList([]), selectAll: selectAll, moved:moved };
    const back = ()=> {props.showColorsMenu(); move.setView(false); move.setColorsList([]);}
    const border = scrolled ? "cl-header-border" :  "";
    const Hide = () => (move.view === "confirmation") ? <div className="cl-list-hide-all">&nbsp;</div> : null;
    const MovingText = () =>
      {if (!move.view || (move.view === "confirmation")) {return null;} else { return (<div className="flex-cen-cen a-pWidth-100 a-pdL-8 a-mrB-n6-s a-mrT-6">
           <div className="a-font-14-s w500 lh-1 grow-1 a-font-chocolate">Seleziona i colori da spostare <span className="a-font-teal a-pdL-3 a-font-13-s"> ({move.colorsList.length}) </span></div>
           <SvgIcon click={selectAll}  icon="select_all" color="chocolate" size="22px"  className="a-mrR-22" key="selall"/>
           <SvgIcon click={()=>move.setColorsList([])}  icon="deselect" color="chocolate" size="22px"  className="a-mrR-8" key="desall"/>
      </div>)}}
    ;
    const Destination = () => { if (move.view !== "confirmation") {return null;} else {
      return (
          <div className="flex-cen-cen a-pWidth-100 a-mrB-10 a-mrT-26 a-container-16">
              <DivSelect name="MovingTo" label="Move To" options={listsOptions} value={selectedList} handleOptionClick={doMove}
              className="mw-200 a-mrR-32 " defs="form-round-Lab-Med" margin="none"/>
              <SvgIcon click={()=>move.setView(true)} icon="close" color="red" size="32px" className="a-mrR-8" key="cancel-confirmation"/>
          </div>
      );
    }};

   if (!props.colors || !colors.length || !props.show) {
     return null;
   } else {
     return (
       <div className="w3-content-1200  cl-colors-list-cont a-pd-20 a-pd-0-s a-pdS-0-s">
            <div className={"flex-cen-cen phone-only a-pdS-16-s cl-list-sticky-header a-wrap p-rel a-container-4 "+border} >
                <SvgIcon icon="arrow_back" color="dodgerblue" size="26px" click={back} className="a-mrL-8 a-width-26"/>
                <h3 className="cl-colors-list-title grow-1 a-container-20 a-font-18-s  truncate">{props.show.name }</h3>
                <PhoneActions  {...actionsHandlers}/>
                <MovingText />
                <Destination />
            </div>

            <div className="flx-btw cl-list-header-cont a-mrB-24 a-mrB-14-s no-phone">

                  <h3 className="cl-colors-list-title  ">
                        <FormInput value={props.show.name} defs="cl-list-name-defs form-col-info-def" handleChange={props.setName} coveringLabel={props.show.name}/>
                  </h3>
                  <Actions  {...actionsHandlers}/>
            </div>

            <div className={"flex-cen-cen a-wrap p-rel a-container-4-s"}>
                {colors.map((row, index)=><Color key={row.id ? row.id : index} index={index} listID={props.show.id} move={move} {...row} edit={props.edit}/>)}
                <Hide />
            </div>

       </div>
     );
   }
}




export default ColorsList;
//
