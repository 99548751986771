import React, { useState } from 'react';
import Button from '../../../Buttons/Button';import {FormInput, DivSelect} from "../../../forms/forms.js";
/*
    <div className=""></div>
*/

function BarSelector(props) {
  if (!props.barKeys) {
    return null;
  } else {

    return (
      <DivSelect field="selectedBar" value={props.selectedBar}  options={props.barKeys} handleOptionClick={props.setBar}
      defs="form-round-noLab" defColors="form-col-orange" iconColor="white" className="w-200" margin="none"/>
    );
  }
}

function SelectBar(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className="flx-btw w3-section">
           <BarSelector {...props}/>
       </div>

     );
   }
}


export default SelectBar;
