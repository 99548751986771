import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../forms/forms.js";
import Button from '../../Buttons/Button'; import Images from './Images'; import Videos from './Videos'; import Actions from './Actions'; import Tweets from './Tweets';
import FbPosts from './FbPosts';
/*
    <div className=""></div>
*/








function Row(props) {
    function handleEdit(e) {
      props.edit(!props.edited, props.rowIndex);
    }
     const text = props.edited ? "Ignore": "Edit";
     const deleteUrl = {batchImg: "/feed/delete_image/", batchVideo: "/feed/delete_video/", batchAction: "/feed/delete_action/", batchFbPost: "/feed/delete_fbPost/", batchTweet:"/feed/delete_tweet/"};

     return (
         <div className="w3-section w3-border w3-border-red w3-padding w3-container">
             <input type="hidden" name={"rowID"+props.rowIndex} value={props.id}/>
             <input type="hidden" name={"editRow"+props.rowIndex} value={props.edited ? "true" : "false"}/>
             <div className= "w3-display-container">
                   <Images {...props} />
                   <Videos {...props} />
                   <FbPosts {...props} />
                   <Actions {...props} />
                   <Tweets {...props} />
                   <div className="btf-edit-btn">
                        <Button text={text} click={true} clickHandler={handleEdit}  color="a-btn-blue-1" size="a-btn-size"/>
                        <Button text="Delete" href={deleteUrl[props.show] + props.id} className="w3-section" color="a-btn-blue-1" size="a-btn-size"/>
                   </div>

             </div>




         </div>

     );

}


export default Row;
