import SvgIcon from '../../../../../SvgIcon';

const ActionsButtons = (props) => {
  const {sclColors, setSclColors, selected, select, selectedCard, selectCard,cardEl, setCardEl,selectedBtn, selectBtn, btnEl,
    setBtnEl,selectedText, selectText,textEl, setTextEl, selectedTextType, index, setIndex, cards, buttons, texts, selectType, sample, txt, btnBkg, card, setControls, hideControls} = props.shared;
  const handleSave = () => {
  const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify({colors: sclColors, deleted: props.deleted}),};
  fetch("/colors/sclSave/", data).then(
                                          (res) => res.json()
                                        ).then(
                                          (result)=>{props.finalizeSave(result);props.fetchMsg(true);}, (error) => props.fetchMsg(false)
                                        );
  }

  const addSet = ()=> {sclColors.push({bkg: "#F20000", none:"#000000", gradient: {direction: "to bottom", height: "100vh", texts:[], colors: [{color:{r:0,g:0,b:0,a:1}, position: 0},{color:{r:0,g:255,b:255,a:1}, position: 50},{color:{r:0,g:0,b:0,a:1}, position: 100}]},text:[{gradient:["#ffffff","#ffffff","#ffffff"], text:["#ffffff"]}], buttons: [{bkg:{h:240,s:100,l:50}, txt: "#ffffff",txtHov:"#ffffff", filling:"a-btn-filled", lHover:"l-hov-10"}], buttonsIds:[], cards: [{...card}]});
                        setIndex(sclColors.length - 1);setSclColors([...sclColors]); select("none");
                      };
  const removeSet = ()=> {
    if (sclColors.length > 1) {
      if (sclColors[index]["id"]) props.deleted.push(sclColors[index]["id"]);
      sclColors.splice(index,1);setSclColors([...sclColors]); setIndex(0); select("none");
    }
  };

  return (
    <div className="flex-cen-cen">
        <SvgIcon icon="add" click={addSet} color="white"  className="a-mrL-20" size="22px"/>
        <SvgIcon icon="delete" click={removeSet} color="white" className="a-mrL-16 " size="22px"/>
        <SvgIcon icon="save" click={handleSave} color="white" className="a-mrL-16 " size="22px"/>
        <SvgIcon icon="visibility" click={()=>setControls(false)} color="white" className="a-mrL-16 " size="22px" noShow={!hideControls}/>
        <SvgIcon icon="visibility_off" click={()=>setControls(true)} color="white" className="a-mrL-16 " size="22px" noShow={hideControls}/>
    </div>
  );
}

export default ActionsButtons;
