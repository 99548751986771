import {DivSelect} from '../../../../../forms/forms';import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';


const TypeSelector = (props) => {
  const {selected, btnEl, textEl, cardEl, buttons, selectedBtn,texts, selectedText, selectedTextType, selectType, gradient, selectedGradient, gradEl, selectedGradientType} = props.shared;
  if (selected === "gradient" && ((selectedGradientType === "text")  && (gradient.texts.length === 0))) return null;
  const selectedType = (selected === "buttons") ? btnEl : ((selected === "text") ? textEl : ((selected === "gradient") ? gradEl : cardEl));
  const filling = buttons[selectedBtn].filling;
  const buttonsOptions = ["a-btn-bordered","a-btn-bordered-dark","a-btn-bordered-whiteBkg"].includes(filling) ? ["bkg"] :((filling == "a-btn-textOnHover") ? ["txtHov","txt"] : ( (filling === "a-btn-bordOnHover") ? ["txt"]: ["bkg","txt"]));
  const textOptions = [...Array(texts[selectedText][selectedTextType].length).keys()] ;
  const gradientOptions = selectedGradientType === "text" ? [...Array(gradient[selectedGradient].texts.length).keys()] : [...Array(gradient[selectedGradient].colors.length).keys()] ;
  const typeOptions = (selected === "buttons") ? buttonsOptions : ((selected === "text") ? textOptions : ((selected === "gradient") ? gradientOptions : ["bkg","txt","txt2"]));

  return (
    <DivSelect value={selectedType} options={typeOptions} hide={!["cards","buttons","text","gradient"].includes(selected)} handleOptionClick={selectType}
    defs="form-noLab-xsmall"  defColors="form-col-orange"   margin="none" className={"a-width-80 a-mrL-4 w3-animate-opacity-04"} noIcon={true} topDrop={false} key={selectedType+"selectedType"}/>

  );
}

export default TypeSelector;
