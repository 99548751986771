import React, { useState } from 'react';
import Button from '../../Buttons/Button';import {FormInput, DivSelect} from "../../forms/forms.js";
import './ulArraySelectBar.css';
/*
    <div className=""></div>
*/

function Subs(props) {
    const deleteClick = (props.selectedSub > 0);
    if (props.addMenu == "addView") { // we are in add view, submenu input displayed
      return (
        <div className="ulArBar-right flx-btw">
            <FormInput name="newSub" covLabel="Add Submenu" value={props.newSub} key={"newSub"+props.selectedSub} defs="form-round-noLab bordered inp-size-15" margin="none" className="grow-1" handleChange={props.handleNewSub} />
            <Button svg="close" click={true} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id={false} key={"closeMenu"+props.selectedSub} className="w3-margin-left" clickHandler={props.handleAddMenu}/>
            <Button svg="save" click={props.newSub ? true : false} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id="addView" key={"addSub"+props.selectedSub} className="w3-margin-left" clickHandler={props.handleAddMenu}/>
        </div>
      );
    } else if (props.addMenu == "renameView") { // we are in add view, submenu input displayed
      return (
        <div className="ulArBar-right flx-btw">
            <FormInput name="newSub" covLabel="Rename" value={props.newSub} key={"editSub"+props.selectedSub} defs="form-round-noLab bordered inp-size-15" margin="none" className="grow-1" handleChange={props.handleNewSub} />
            <Button svg="close" click={true} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id={false} key={"closeMenu"+props.selectedSub} className="w3-margin-left" clickHandler={props.handleAddMenu}/>
            <Button svg="save" click={props.newSub ? true : false} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id="renameView" key={"renameSub"+props.selectedSub} className="w3-margin-left" clickHandler={props.handleAddMenu}/>
        </div>
      );
    } else { // case add == true => sub selection and add e del buttons
      const renameClick = props.selectedSub ? true : false;
      return (
        <div className="ulArBar-right flx-btw">
            <DivSelect field="selectedSub" value={props.selectedSub}  options={props.subKeys} handleOptionClick={props.handleSubMenu}
            defs="form-round-noLab" defColors="form-col-orange" iconColor="white" className="grow-1" margin="none"/>
            <Button svg="add" click={true} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id="add" key="addmenu" className="w3-margin-left" clickHandler={props.handleAddMenu}/>
            <Button svg="edit" click={renameClick} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id="rename" key="renameMenu" className="w3-margin-left" clickHandler={props.handleAddMenu}/>
            <Button svg="delete" click={deleteClick} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id="remove" key="deletemenu" className="w3-margin-left" clickHandler={props.handleSubMenu}/>
        </div>
      );
    }
}

function EditMenus(props) {
    const [rename, setRename] = useState(false);
    function addEmptyMenu() {
      props.createEmptyMenu();
      setRename(true);
    }
    if (!props.menuKeys) {
      return null;
    } else {


      if (!rename) {
        return (
            <div className="ulArBar-right flx-btw">
                <DivSelect field="selectedMenu" value={props.selectedMenu} key="selectedMenu" options={props.menuKeys} handleOptionClick={props.menuSelection}
                defs="form-round-noLab" defColors="form-col-orange" iconColor="white" className="editMenus " margin="none"/>
                <Button svg="edit" click={true} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id={true} key="renameMen-men" className="w3-margin-left" clickHandler={setRename}/>
                <Button svg="add" click={true} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id={true} key="addMenu-men" className="w3-margin-left" clickHandler={addEmptyMenu}/>
            </div>
        );
      } else {
        return (
          <div className="ulArBar-right flx-btw">
              <FormInput name="name"  value={props.menuName} key={"renameMenu"+props.selectedMenu} defs="form-round-noLab bordered inp-size-15" margin="none" className="grow-1" handleChange={props.handleMenuName} />
              <Button svg="done" click={true} color="a-btn-blue-1" iconSize="" size="a-btn-size-3-icon" id={false} key="done-men" className="w3-margin-left" clickHandler={setRename}/>
          </div>
        );
      }

    }
}


function UlArraySelectBar(props) {
     const [addMenu, setAddMenu] = useState(false);

     function handleAddMenu(bol) {
       if (bol === "addView") {
         props.handleSubMenu("add");
         setAddMenu(false);
       } else if (bol === "renameView") {
         props.handleSubMenu("rename");
         setAddMenu(false);
       }else if (bol === false){
         setAddMenu(bol);
         props.handleReset("newMenu");
       } else if (bol == "add"){
          setAddMenu("addView");
       } else if (bol == "rename"){
          setAddMenu("renameView");
       }
     }


     if (!props.rows) {
       return null;
     } else {
       const categories = props.rows.categories;
       let buttons = [];
       if (categories && Array.isArray(categories)) {
           for (let i=0; i<categories.length; i++) {
             const title = (categories[i] && categories[i].title) ? categories[i].title  : ("No name "+ i);
             const btnColor = (props.index === i) ? "a-btn-orange-1" :  "a-btn-blue-1";
             buttons.push(<Button text={title} click={true} color={btnColor} size="a-btn-size-3" id={i} key={i} className="w3-margin-right" clickHandler={props.handleCategory}/>);
           }
       }
       buttons.push(<Button text="Add new" click={true} color="a-btn-green" size="a-btn-size-3" id="add" key="add" className="w3-margin-right" clickHandler={props.handleCategory}/>);



       return (
           <div className="">

                <div className="flx-btw w3-section">
                    <div className="flex-cen-start grow-1">
                         {buttons}
                    </div>
                    <EditMenus  menuKeys={props.menuKeys} createEmptyMenu={props.createEmptyMenu} handleMenuName={props.handleMenuName} menuSelection={props.handleMenus} selectedMenu={props.selectedMenu} menuName={props.menuName}/>
                    <Subs addMenu={addMenu} newSub={props.newSub} handleNewSub={props.handleChange} handleAddMenu={handleAddMenu} subKeys={props.subKeys} handleSubMenu={props.handleSubMenu} selectedSub={props.selectedSub}/>
                </div>

           </div>


       );
   }
}


export default UlArraySelectBar;
