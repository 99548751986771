import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";
import FormContainer from "./FormContainer";



class FormSelect extends React.Component {
    constructor(props) {
        super(props);
        const color = this.props.color ? this.props.color : "w3-light-gray";
        this.state = {color: color, clicked: this.props.clicked};
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);
    }
    handleChange(e) {
        this.setState({color: "w3-pale-green", clicked: true});
        this.props.handleChange(e);
    }

    handleFocus() {
      const color = (this.state.color == "w3-pale-red") ? "w3-pale-red" : "w3-pale-green";
      this.setState({clicked: true, color: color});
    }
    handleBlur(e) {
      if (this.state.color != "w3-pale-red") {
          const color = this.props.color ? this.props.color : "w3-light-gray";
          this.setState({color: color, clicked: false});
      } else {
          this.setState({clicked: false});
      }
    }

    handleLabelClick() {
      this.setState({clicked: true});
    }

    render() {
        const selectArrow = this.props.selectArrow ? "selectArr" : " ";

        let child = [];
        let i = 0;
        if (this.props.rangeStart>=0 && this.props.rangeEnd) {
            for (var start = this.props.rangeStart; start <= this.props.rangeEnd; start++) {
                child[i] = <option value={start} key={i}>{start}</option>;
                i++;
            }
        } else {
            child = this.props.children;
        }
        return (
                <FormContainer grid={this.props.grid} required={this.props.required} value={this.props.value} maxWidth={this.props.maxWidth} color={this.state.color} label={this.props.label} centerLabel={this.props.centerLabel} labelColor={this.props.labelColor} margin={this.props.margin} clicked={this.state.clicked} handleClick={this.handleLabelClick}>
                <select type="text" name={this.props.name} value={this.props.value} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} className={"w3-select w3-center h33 " + selectArrow}>
                {child}
                </select>
                </FormContainer>
                );
    }
}





export default FormSelect;
