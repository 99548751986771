import React, {useEffect, useState, useRef} from 'react'; import {FormInput} from '../../../../../forms/forms';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import SvgIcon from '../../../../../SvgIcon'; import './hex.css';import Link from '../../../../../Link';
import HexSectionsMenu from './HexSectionsMenu'; import RgbHslSub from './RgbHslSub'; import HexTitle from './HexTitle';
/*
    <div className=""></div>
*/



function Hex(props) {

  const [hexEdit, setHexEdit] = useState(false);
  const [selected, select] = useState("hex");
  const [error, setError] = useState(false);
  const timer = useRef(null);
  const myRef = React.createRef();
  const mobile = window.innerWidth < 734;
  useEffect(() => {if (hexEdit === 0) { if (timer.current) clearTimeout(timer.current); timer.current = setTimeout(()=>setError(false), 10000);setHexEdit(false); setError("Wrong format, HEX = #dddddd where d=(0-9,a-f)");}});
  useEffect(() => (!hexEdit) ? null : myRef.current.autofocus(), [hexEdit]);
  if ((mobile && !props.mobile) || (!mobile && props.mobile)) return null;
  const contrast = {};
  contrast.contrastColor = contrastColorRgbToHex(props.color.rgb);
  contrast.titleColor = (contrast.contrastColor == "#ffffff") ? "cl-contrast-white" : "cl-contrast-black";
  contrast.titleColorHex = (contrast.contrastColor == "#ffffff") ? "blue" : "HSL( 50, 100%, 75% )";
  contrast.bkgContrast = (contrast.contrastColor == "#ffffff") ? "cl-bkg-contrast-white" : "cl-bkg-contrast-black";
  contrast.hoverColor = (contrast.contrastColor == "#ffffff") ? "hsl(360,100%,88%)" : "hsl(0,0%,40%)";


  const handleBlur = (bol) => {
      const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (regex.test(hexEdit)) {
          props.setHex(hexEdit);
          setHexEdit(false);
      } else {
        setHexEdit(0);
      }
}
  if (!hexEdit) {
    const lightMode = props.scarlet || (props.visibleSections==="converter");
    return (
          <div className={"cl-range-sections cl-hex-cont  "+(lightMode ? "scarlet-open " : "")} >
              <div className={"cl-hex-cont-inner w3-display-container h-100 "+(lightMode ? " flex-col flx-btw" : "")} style={{background:rgbToCss(props.color.rgb)}}>
                  <RgbHslSub color={props.color} {...contrast} lightMode={lightMode} selected={selected}/>
                  <div className={"a-pWidth-100 w3-center "+(lightMode ? "flex-cen-cen grow-1": "w3-display-middle a-mrT-3-s")} >
                      <HexTitle {...contrast} showTopBar={props.showTopBar} color={props.color} hexEdit={hexEdit} selected={selected} select={select} setHexEdit={setHexEdit} error={error} lightMode={lightMode}/>
                  </div>
                  <HexSectionsMenu titleColor={contrast.titleColor} lightMode={lightMode} titleColor={contrast.titleColor}
                   setScarlet={props.setScarlet} {...contrast} scarlet={props.scarlet} setVisibleSections={props.setVisibleSections} visibleSections={props.visibleSections} showColorsMenu={props.showColorsMenu}/>
              </div>

          </div>
    );
  } else { // Hex Edit View - Not shown on Mobile
    return (
      <div className="cl-range-sections flex-cen-cen cl-hex-cont " style={{background:rgbToCss(props.color.rgb), color: contrastColorRgbToHex(props.color.rgb)}}>

              <div className="w3-display-container" style={{width: "70%"}}>
                  <FormInput  value={hexEdit}  className="" margin="none" defs="hex-imp-defs cl-range-input-col" handleChange={(e)=>setHexEdit(e.target.value)} ref={myRef}/>
                  <div className="cl-hex-imp-act-icons flex-cen-start">
                      <div className="pointer" onClick={handleBlur}><SvgIcon size="22px" icon="done" color="green" /></div>
                      <div className="w3-margin-left pointer" onClick={()=>setHexEdit(false)}><SvgIcon key="clear" size="22px" icon="clear" color="red" /></div>
                  </div>

              </div>

      </div>

    );
  }
}





export default Hex;
