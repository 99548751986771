import React, {useEffect} from 'react';
import UlSlider from '../UlSlider/UlSlider';
import Hero from './Hero';
import './heroRotator.css';
import FriendsHero from './FriendsHero';
import DevicesHero from './DevicesHero';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save


// <HeroRotator dataArray={} height/>
// height def = {inner: "250px", absolute: "700px"};
/* dataArray array od data oblecys: {h1 h2 h1style(obj obt) h2style(obj opt) imgUrl:"" imgSize color, link:"" click:"" grid contentWidth(str opt)}
imgUrl is the background image in the absolute container - OPTIONAL
imgSize str, def 100% 100%, alternatives "cover", "contain"....
color is the background color in the absolute container - OPTIONAL
h1 e h2 is the text of the title - DONT USE WHEN GRID IS SET!
grid = [el, el, el...] el={imgUrl, h1, h3, width(str opt), imgWidth(str opt), padding(str opt - only for h1 e h2), h1style(obj opt) h2style(obj opt)} h1 e h2 not set if imgUrlis set.
*/
function HeroRotator(props) {

  let media = {};
  useEffect(()=>{
    window.addEventListener("scroll", scroll); return ()=> {window.removeEventListener("scroll", scroll);}
  });
  media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  media.isSmarthPhone = useMediaQuery({ maxWidth: 734 });
  media.isTablet = useMediaQuery({ maxWidth: 1068, minWidth: 735 });

  const scroll = (e)=> {
    const tBar = props.mainShare;
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop <= (0.5*window.innerHeight)){
      const ratio =  Math.round(scrollTop / (0.5*window.innerHeight) * 10);
      const color = " a-btn-ukraine-flag-blue"+" a-"+ratio;
      if ((tBar.color != color) || (tBar.overlay != (ratio < 2)) || (tBar.noPadding !=true) || (tBar.controlledBy!="heroHome"))
      tBar.setTopBar({color: color, overlay: ratio < 2, noPadding: true, controlledBy: "heroHome", noFilledBkg: true});
    } else if (scrollTop > (0.5*window.innerHeight)){
      if (tBar.controlledBy === "heroHome") tBar.setTopBar();
    }
  }

    if (!props.rows)return null;


  const cssCustom = {shadow: "-", borderRadius: "-"};
  const arrowStyle = {mobilePortrait:  {margin: "5px", position: "in", size: "small", circle: true,shadow:true, height: "hero-front-height"}, mobileLandscape: null, tabletPortrait: null, tabletLandscape: null, desktop: {margin: "40px", position: "in", size: "large", circle: false,shadow:true, height: "hero-front-height"}};
  const arrowStyle2 = {mobilePortrait: null, mobileLandscape: null, tabletPortrait: null, tabletLandscape: null, desktop: {margin: "180px", position: "in", size: "large", circle: true,shadow:true}};
  const arrowStyle3 = {mobilePortrait: null, mobileLandscape: null, tabletPortrait: null, tabletLandscape: null, desktop: {margin: "180px", position: "in", size: "large", circle: false,shadow:false, color: "arr-bkg-orange"}};
  //const barPadding = media.isLandscape && media.isSmarthPhone ? 0 : 55;
  const height ={height: window.innerHeight + "px"}; //{height: (((window.innerWidth < 800) && (window.innerWidth > window.innerHeight)) ? "100vh" : ((window.innerHeight - 55) + "px"))};

 // ,{color: "hsl(0,0%,50%)", id: "friends", component: friends},{color: "hsl(5,5%,50%)", id: "der", component: devices}
    return (
        <div className={"hero-rotator-container " + (props.defs ? props.defs : "hero-defs fd-grid-defs")} style={height} id="Hero_Rotator">
        {/* hero-rotator-container sets the width and the height of the whole slider. The Height of the container is set to the height of the front element, but the uislider inside is taller
           so it will overflow in the y direction in the container but not in the x direction because width isset to 100% and overflow is hidden so Uislider cannot overflow his size,
           but hero container has overflow visible to allow overflow-ysince the children is tallers. This allows the next section to overlap the hero rotator under the front section */}
          <UlSlider  rows={props.rows} iconColor={props.iconColor} circle={true} sliderID="Hero_Rotator" rowFunction={<Hero slideClick={props.slideClick}  height={height} />} auto={props.auto} showOverflow={false} arrowTopPosition="hero-rot-arrow-top" arrowStyle={arrowStyle} cols={11} />

        </div>
    );

}



export default HeroRotator;
