import React, {useState, useRef} from 'react';
import Button from '../../../../../Buttons/Button';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex,contrastHexColor, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
/*

*/

const ButtonsMap = (props) => {

  const {select, selected, buttons, selectBtn, selectedBtn, setBtnEl, btnEl, index, setSclColors, sclColors, btnBkg, contrastBkg,selectType} = props.shared;

  const btnClick = (btnIndex) => {
    const filling = buttons[btnIndex].filling;
    if ((selected !== "buttons") || (selectedBtn !== btnIndex)) {
      select("buttons");selectBtn(btnIndex);
      if (["a-btn-textOnHover","a-btn-bordOnHover"].includes(filling)) {
        selectType("txt");
        props.setHex(buttons[btnIndex].txt);
      } else {
        selectType("bkg");
        props.setHsl(buttons[btnIndex].bkg);
      }

    } else {  // The button is already selected, clicling on it only changes the types if the buttons has several, if it has only one type noting happens
      let type;
      if (["a-btn-filled","a-btn-filled-dark"].includes(filling)) {
        type = btnEl === "txt" ? "bkg" : "txt";
      } else if (["a-btn-bordered","a-btn-bordered-dark","a-btn-bordOnHover","a-btn-bordered-whiteBkg"].includes(filling)) {
        return; // only one color type available so nothing happens
      } else if (filling == "a-btn-textOnHover") {
        type = btnEl === "txt" ? "txtHov" : "txt";
      }

      setBtnEl(type);
      props[(type !== "bkg") ? "setHex" : "setHsl"](buttons[btnIndex][type]);

    }

    if (!buttons[btnIndex].filling) {
      buttons[btnIndex].filling = "a-btn-filled";
      if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
      setSclColors([...sclColors]);
    }
    if (!buttons[btnIndex].lHover) {
      buttons[btnIndex].lHover = "l-hov-10";
      if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
      setSclColors([...sclColors]);
    }
  }

  return (
    <div className=" ">
          <div className="a-container-32 scarlet-btns-container a-pdT-4" id="scl-btns-cont">
              <div  className="flex-cen-start " style={{width: "max-content"}}  onTouchStart={(e)=>e.stopPropagation()} id="scl-btns-inner-cont"> {/*Touch start on The buttons container should not propagate to scarlet bkg select*/}
                    {!buttons ? null : buttons.map((row, index) => <div className="a-pdT-12 a-mrR-12" style={{borderTop: "2px solid " +((index==selectedBtn) ? hslToCss(row.bkg) : "transparent")}}><Button text={"A btn "+index} color={"a-btn-test-"+index} click={()=>btnClick(index)} key={index+"btn"} className={row.lHover} forceHover={props.forceHover && (index === selectedBtn)} filling={row.filling} size="a-btn a-btn-height-40 a-btn-container-25 a-btn-font-14 " /></div>)}
              </div>
          </div>
    </div>
  );
};

export default ButtonsMap;
