import React from 'react';
import "./sideBars.css";
import Link from '../../Link';
import BarLi from './BarLi';
//   <div className=""><div>

function LeftBar(props) {
    if (!props.left) {
      return <div className="side-bar-left h-100"> </div>
    } else {
      return (
        <div className="side-bar-left h-100 ">
              <ul className="ul-clear-inline flex-cen-start h-100 tbar-std" >
                {props.left.map((li, index) => <BarLi {...li} {...props.btnProps} key={index} dropRight={props.dropRight} />)}
              </ul>
              <ul className="ul-clear-inline flex-cen-start h-100 tbar-mobile" >
                {props.leftMobile.map((li, index) => <BarLi {...li} {...props.btnProps}  mobile={true} key={index} dropRight={props.dropRight}   />)}
              </ul>
        </div>
      );
    }

}


export default LeftBar;
