import {DivSelect} from '../../../../../forms/forms';

const WidthSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const width = gradient[selectedGradient].width;
    const options = ["50","100%","150%","200%","250%","300%","400%", "1024px","1400px","1920px"];
    if (!options.includes(width)) options.push(width);

    const handler = (value)=> {
        gradient[selectedGradient].width = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
        setSclColors([...sclColors]);
    }

      return (
        <DivSelect value={width} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-70"  margin="none" noIcon={true} />
    );
}

export default WidthSelector;
