import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import './hero.css';
import Background from './Background'; import Front from './Front';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save

/* <div className=""></div> */



function Hero(props) {
  let media = {};
  media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  media.isSmarthPhone = useMediaQuery({ maxWidth: 734 });
  media.isTablet = useMediaQuery({ maxWidth: 1400, minWidth: 735 });

  const styleMap = {heroTop: {titleContainer:"hero-top-title-container", gradient: "hero-top-gradient", h1Style: "hero-top-h1", h2Style: "hero-top-h3"},
   heroBtm: {titleContainer:"hero-btm-title-container", gradient: "hero-btm-gradient", h1Style: "hero-btm-h1", h2Style: "hero-btm-h3"}};
  const style = props.data.style && styleMap[props.data.style] ? styleMap[props.data.style] : {};
/******* Media Queries ****/
  return (
      <div className="hero-top-container " style={props.height}>
          <div className="hero-container" style={props.height}>
                <Background {...props.data} {...media} height={props.height} imgUrl={props.data.url}/>
                <Front {...props.data} {...style} slideClick={props.slideClick} {...media} index={props.index} height={props.height} h1={props.data.title} h3={props.data.subtitle}/>
          </div>
      </div>
  );
}

export default Hero;
