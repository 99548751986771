import Button from '../../Buttons/Button';

/*
    <div className=""></div>
*/

function ColorsMenu(props) {

    const List = (props) =>  (props.updated != "deleted") ? <Button click={()=>props.set(props.index)} active={props.activeList === props.index}
    activeColor="a-btn-orange-1" text={props.name} color="a-btn-blue-1 " size="a-btn-tiny-5 w3-margin-right-8"
    filling={"a-btn-filled "+(props.blinking?"w3-animate-opacity":"")}/> : null;
    if (!props.showLarge) { //
        return (
              <div className="w3-section-24 no-phone w3-content-1200"> {/* This small top bar menu appears only in tablet and desktop on every colors page when the large colors menu is not open*/}
                  <Button text="New List" click={props.newList} activeColor="a-btn-orange-1" color="a-btn-green " size="a-btn-tiny-5 w3-margin-right-8" filling="a-btn-filled"/>
                  {props.lists.map((row, index)=><List set={(i)=>props.openList({showList: i, newListsView: null, editColor: false})} index={index} {...row}
                   activeList={props.activeList} key={row.id}/>)}
                   <Button text="View all" click={props.showColorsMenu}  color="a-btn-blue-1 " size="a-btn-tiny-5 w3-margin-right-8" filling="a-btn-filled"/>
              </div>
        );
    } else {
      return (
        <div className=" a-pdS-32  a-pdS-24-s ">
        <div className="a-container-8-s w3-content-1200">
              <div className="w3-display-container">
                    <h3 className="a-font-32 a-font-24-s a-container-8">Color groups</h3>
                    <div className="w3-display-right ">
                        <Button text="New"  click={props.newList} iconSize="16px" color="a-btn-green " size="a-btn-tiny-5" filling="a-btn-filled" className=""/>
                    </div>
              </div>
              <div className="flex-cen-start a-wrap a-section-32 a-section-16-s">
                  {props.lists.map((row, index)=><ListLarge set={(i)=>props.openList({showList: i, newListsView: null, editColor: false, showColorsMenu: false})} index={index} {...row}
                   activeList={props.activeList} key={row.id}/>)}
              </div>
        </div>
        </div>
      );
    }

}


function ListLarge(props) {
    return (
          <div className="a-section-16 a-section-8-s a-pWidth-50-s a-container-8-s a-container-16 lh-1-2 a-pWidth-20" >
              <div className="pointer a-bkg-white  cl-men-list-item a-pd-12-s a-pd-18" onClick={()=>props.set(props.index)}>
                  <h3 className="a-font-16-s a-font-18 w3-center  w600 a-mrB-8 truncate">{props.name}</h3>
                  <div className=" a-font-14-s a-font-15">Css colors are native to all browser and can be used by name</div>
              </div>

          </div>
    );
}

export default ColorsMenu;
