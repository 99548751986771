import React, {useEffect, useState, useRef} from 'react';
import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';

function HexTitle(props) {
    const [copiedMsg, setCopiedMsg] = useState(false);
    const {selected, select} = props;
    const css = selected === "hex" ? props.color.hex.toUpperCase() : ((selected === "rgb") ? rgbToCss(props.color.rgb) : hslToCss(props.color.hsl));
    const clipboard = () => navigator.clipboard.writeText(css).then(()=>{setCopiedMsg(true);setTimeout(()=>setCopiedMsg(false), 1500);});
    const mobile = window.innerWidth < 734;
    const size = " a-font-32 "+(props.lightMode ? " a-vw-font-4-5-s ": " a-vw-font-6-s ");
    const noTopBarMargin = !props.showTopBar ? " a-mrT-11 ": "";
    return (
        <h2 className={"lh-1  pointer noselect w3-display-container "+props.titleColor+size+noTopBarMargin} style={{display:"inline-block"}}>
            <div className="">
                <span className={selected === "hex" ? "noselect":"w3-hide"} onClick={()=>select("rgb")}><span>HEX  </span><span className="w3-padding-left-16 noselect">{css}</span></span>
                <span className={"w3-padding-left-16 noselect "+(selected === "rgb" ? "":"w3-hide")} onClick={()=>select("hsl")}>{css}</span>
                <span className={"w3-padding-left-16 noselect "+(selected === "hsl" ? "":"w3-hide")} onClick={()=>select("hex")}>{css}</span>
            </div>
            <div className="flex-cen-start w3-display-topleft" style={{height:mobile ? "20px" : "20px",left:"100%",transform: mobile ?"translate(8px, -10px)" :"translate(16px, -12px)"}}>
                <SvgIcon className="no-phone a-mrR-16" icon="edit" size="18px" color={props.contrastColor} hoverColor={props.hoverColor} click={()=>{props.setHexEdit(props.color.hex);props.setError(false);}}key={props.color.hex} />
                <div className={" w3-display-container flex-cen-cen "+ (copiedMsg ? "w3-animate-opacity" : "")}>
                      <SvgIcon icon="content_copy" size={mobile ? "14px" : "18px"} color={props.contrastColor} hoverColor={props.hoverColor} click={clipboard} key={props.color.hex} className={!copiedMsg ? "" : "w3-hide"}/>
                      <div className={"hex-clipboard-copied "+ (copiedMsg ? "" : "w3-hide")}>Copied</div>
                </div>

            </div>
            <Error error={props.error}/>
      </h2>
      );
}


export default HexTitle;

function Error(props) {
  if (!props.error) {return null} else {return <div className="cl-inp-hex-col-err flex-cen-start"><SvgIcon icon="error" size="40px" color="red"/><div className="w3-margin-left-8">{props.error}</div></div>;}
}
