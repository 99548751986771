import React from 'react';
import './cnnPar.css';
import Link from '../../Link';import EmbeddedPic from "./EmbeddedPic";
import ImageBlock from './ImageBlock';

function ParagraphRow(props) {
      const click = props.row.id ? [props.clickHandler, props.row.id] : false;
      const className = "an-inline " + (props.row.className ? props.row.className : "");
      return(
        <Link className="an-inline " click={click}>{props.row.text} </Link>
      );

}

function CnnParTitle(props) {
    if (!props.title) {
      return null;
    } else {
      return (
          <h2 className="cn-h2-header-title">{props.title}</h2>
      );
    }
}

function CnnParSubTitle(props) {
    if (!props.subtitle) {
      return null;
    } else {
      return (
          <h3 className={"cn-h3-header-title " + (props.embedded ? "embeddedDown" : "")}>{props.subtitle}</h3>
      );
    }
}

function CnnPar(props) {

    if (!props.text && !props.title && !props.subtitle) {
      return null;
    } else {
      const RowFunction = ({rowFunction}) => rowFunction ? React.cloneElement(rowFunction, {...props}) : null;

        return (
            [
              <CnnParTitle title={props.title} key="title"/>,

              <p className="cn-body-parag noselect" key="text">

                    <CnnParSubTitle subtitle={props.subtitle} embedded={props.embedded}/>
                    <EmbeddedPic {...props.embedded} key="embedded pic"/>
                    <ImageBlock {...props} />
                    {Array.isArray(props.text) ? props.text.map((row,index)=><ParagraphRow row={row} clickHandler={props.clickHandler} key={index}/>) : props.text}
              </p>
            ]
        );


    }
}

export default CnnPar;
