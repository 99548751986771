import React from 'react';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon'; import {FormInput} from "../../../forms/forms.js";



class EditImage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {src: this.props.src, alt: this.props.alt, author: this.props.author, authorUrl: this.props.authorUrl,
        imagePosition: this.props.imagePosition};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.imageRef = React.createRef();
  }



    handleChange(e) {
      const target = e.target;
      const value =  target.value;
      this.setState({[target.name]: value});
    }

    handleSubmit(e) {
      let alertMessage = "";
      if (this.state.src == "") {
          if (alertMessage ==  "") {
              alertMessage = "Src";
          } else {
              alertMessage = alertMessage + ", Src";
          }
          this.imageRef.current.setRedBackground();
      }

      if (alertMessage != "") {
          alertMessage = "Error: " + alertMessage + " cannot be empty!";
          //this.alertHandler(alertMessage);
          e.preventDefault();
      }
    }

    render() {


        const objectPosition = this.state.imagePosition ? {objectPosition: this.state.imagePosition} : {};
        const formFactor = this.state.portrait ? "img-portrait" : "img-landscape";
        return (
                <div className="w3-container w3-section w3-display-container w3-white">
                      <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                          <Link click={[this.props.editImage, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>
                      </div>
                     <form action={"/feed/editImage/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                        <FormInput name="src" label="Src" value={this.state.src} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate="" ref={this.imageRef} />
                        <FormInput name="alt" label="Alt" value={this.state.alt} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="imagePosition" label="Image Position" value={this.state.imagePosition} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="author" label="Author" value={this.state.author} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="authorUrl" label="Author Url" value={this.state.authorUrl} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <div className="w3-margin-top-24 w3-margin-bottom ">
                            <input type="submit" value="Edit Image" className="w3-button  w3-content w3-blue h55  w3-round"/>
                        </div>
                    </form>
                </div>
        );
      }
}


export default EditImage;
