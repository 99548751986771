import React from 'react';
import Link from '../../Link';
import './metadata.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';

function AuthorPic(props) {
    if (props.src) {
      return (
          <Link className="an-inline">
              <div className="auth-pic">
                  <img class="auth_image" alt={props.author} src={props.src}  />
              </div>
          </Link>
      );
    } else {
      return null;
    }
}

function LastUpdated(props) {
  if (props.updated) {
    return (
      <div className="acFu-main-title-updated">
          <div className="flex-cen-start acFu-last-updated">
              <div className="acFu-time-icon">
                  <MaterialIcon icon="access_time" size="tiny"/>
              </div>
              <div className="w3-container-8 acFu-last-updated">
                    {props.updated}
              </div>
          </div>
      </div>
    );
  } else {
    return null;
  }
}


function Metadata(props) {
  if (!props.author && !props.updated ) {
    return null;
  } else {
    const Author = (props) => props.author ? (<div className="acFu-author">By <span className="w3-text-indigo w3-bold"> {props.author}</span></div>) : null;
    const container = "w3-clear " + (props.src ? " meta" : "meta-nopics"); // If no author we remove meta class that sets the minimum heightand we had a 4px mixel top amrgin
    return(
        <div className={container}>
            <AuthorPic src={props.src} />
            <div className="meta-text">
                <Author author={props.author}/>
                <LastUpdated updated={props.updated}/>
            </div>
        </div>
    );
  }
}

  export default Metadata;

// <Metadata {...metadata} />
// const medatada = {updated:"", src:"", author:""};
