import React from 'react';import Button from '../../Buttons/Button';import {FormInput, DivSelect} from "../../forms/forms.js"; import FormMenuBtn from './FormMenuBtn';

/*
    <div className=""></div>
*/

function FormTitle (props) {
    function handleChange(e) {
      props.handleChange(e.target.value, props.index, props.field);
    }
    return (
          <FormInput name={props.field} label={props.label ? props.label : props.field} value={props.rows.categories[props.index][props.field]} className={props.className} maxWidth="" handleChange={handleChange}  color="w3-light-gray" validate=""  />
    );
}

function FormMenuBtnDivSelect(props) {
    function handleChange(value) {
      props.handleChange(value, props.index, props.field);
    }


    return (
          <DivSelect name={props.field} label={props.label ? props.label : props.field} cols={props.cols} colsDisp={props.colsDisp} iconsSelect={props.iconsSelect} value={props.rows.categories[props.index][props.field]} rangeStart={props.rangeStart}
          rangeEnd={props.rangeEnd} options={props.options} handleOptionClick={handleChange} className={props.className} color="w3-light-gray" validate=""  />
    );
}



function UlInput(props) {
  function handleMenuRowsArray(action) {
    props.handleMenuRowsArray(action, props.index, null)
  }
   if (!props.rows || !props.rows.categories  || !props.rows.categories.length) {
     return null;
   } else {
     const title = (props.rows[props.index] && props.rows[props.index].title) ? ("Delete "+ props.rows[props.index].title ) : ("Delete No name "+ (props.index +1));
     const deleteBtn = !props.rows.categories.length ? null : <Button text={title} click={true}  color="a-btn-blue-1" size="a-btn-size-3" id="remove"  className="w3-margin-left  w3-left" clickHandler={props.handleCategory}/>;
     const submitText = (props.selectedMenu == "newMenu") ? "Crete new menu" : "Edit Menu";


    return (
       <div className="w3-section-48">
             <div className="w3-row">
                  <FormTitle field="title" index={props.index} rows={props.rows} key={"title"+props.index+"-"+props.selectedSub} className="w3-col m3 w3-container-8"
                  label="Category Name" handleChange={props.handleTitle}/>
                  <FormMenuBtnDivSelect field="adminButton" index={props.index} key={"admin-cat"+props.index+"-"+props.selectedSub} {...props} className="w3-col m2 w3-container-8"
                  rangeStart={0} rangeEnd={5} label="Admin" handleChange={props.handleTitle}/>
                  {deleteBtn}
             </div>
              <div className="w3-section ">
             {!props.rows.categories[props.index].buttons ? null : props.rows.categories[props.index].buttons.map((row, index) => <FormMenuBtn ulIndex={index} key={"row"+index+"-"+props.index+"-"+props.selectedSub} subKeys={props.subKeys} index={props.index} {...row} metas={props.metas} handleMenuBtnRow={props.handleMenuBtnRow} selectedSub={props.selectedSub} handleMenuRowsArray={props.handleMenuRowsArray}/>)}
              <div className="w3-section w3-container-8">
                    <Button text="Add Button" type="text" click={true} color="a-btn-orange-2" size="a-btn-size-2" id="addRow"  className="" clickHandler={handleMenuRowsArray}/>
              </div>
             </div>

            


       </div>
     );
   }
}


export default UlInput;
