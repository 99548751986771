import React, {useState} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import SvgIcon from '../../../../../SvgIcon';

function CopyIcon(props) {
  const [copiedLeft, setCopiedLeft] = useState(false);
  const [copiedRight, setCopiedRight] = useState(false);
  const cssColor = (type) => (type === "rgb") ? rgbToCss(props.color.rgb) : ( (type === "hsl") ? hslToCss(props.color.hsl) : props.color.hex.toUpperCase());
  const clipboard = (type) => navigator.clipboard.writeText(cssColor(type).toUpperCase()).then(()=>{
    if (props.position == "left") {
      setCopiedLeft(true);setTimeout(()=>setCopiedLeft(false), 1500);
    } else {
      setCopiedRight(true);setTimeout(()=>setCopiedRight(false), 1500);
    }
  });
  if (window.innerWidth >= 734 ) {
    return null;
  } else {
    return  (
      <>
        <span className="noselect" onClick={()=>clipboard(props.type)}>{!copiedLeft && !copiedRight ? (props.type=="hex"?"HEX ":"") + cssColor(props.type) : null}</span>
        <div className={" w3-animate-opacity "+ (((props.position === "left") && copiedLeft) || ((props.position === "right") && copiedRight) ? "" : "w3-hide")}>Copied to clipboard!</div>
      </>
    );
  }
}

function RgbHslSub(props) {
  if (props.lightMode) return null;
  const rgbHslCont = "a-container-6 a-pdS-4-s  w3-round w3-display-container "+props.bkgContrast;
  return (
    <div className={"phone-only  flx-btw  lh-1 a-font-12 a-pWidth-100 a-mrB-9 w600 "+props.titleColor} >
        <div className={rgbHslCont + " truncate"} style={{maxWidth: "50%", marginLeft: "-7px"}}>
            <CopyIcon type={(props.selected=="rgb")?"hex":"rgb"} color={props.color} titleColor={props.titleColorHex} position="left"/>
        </div>
        <div className={rgbHslCont+ " truncate"} style={{maxWidth: "50%", marginRight: "-7px"}}>
            <CopyIcon type={(props.selected=="hsl")?"hex":"hsl"} titleColor={props.titleColorHex}  color={props.color} position="right"/>
        </div>
    </div>
  );
}

export default RgbHslSub;
