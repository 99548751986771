import React, {useState, useEffect, useRef} from 'react';
import HsvRect from './HsvRect/HsvRect';  import Hex from './Hex/Hex';
import RangeSection from './RangeSection'; import './sections.css'; import PickersSections from './PickersSections';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../converters.js';
import SectionsHeader from './SectionsHeader';

// <div className=""></div>

function Sections(props) {

  const {handlers, color, hsvOpt} = props;
  const shared = {visibleSections: props.visibleSections, setVisibleSections:props.handlers.setVisibleSections, increase: handlers.increase, color: color,
     isScrolling: handlers.isScrolling, scarlet: props.scarlet};

if (!props.show) {
  return null;
} else {
  return (
      <>
          <Hex setHex={handlers.hex} visibleSections={props.visibleSections} {...props.mainShare} color={props.color} mobile={true} {...shared} scarlet={props.scarlet} setVisibleSections={props.setVisibleSections} showColorsMenu={handlers.showColorsMenu}  setScarlet={props.setScarlet}/>
          <SectionsInner shared={shared} {...props}/>
      </>
  );
}


}

const SectionsInner = (props) => {
  const {handlers, color, hsvOpt, shared} = props;
  const secRef = useRef(null);
  useEffect(()=>{if (props.scarlet && (props.visibleSections=="pickers")) {secRef.current.scrollTop=0;}},[props.scarlet,props.visibleSections]);
  if (!["all","pickers"].includes(props.visibleSections)) {
    return null;
  } else {
    return (
      <div className={"grow-1 p-rel "+(props.scarlet ? " ": "a-pdT-40-s a-pdB-30-s") + (window.innerWidth < 734 ? " a-bkg-lightcyan" : "")} id="pickers" ref={secRef}>
          <SectionsHeader scarlet={props.scarlet} color={props.color} mainShare={props.mainShare}/>
          <div className="flx-btw-str a-wrap ">
              <div className="cl-range-layout ">
                  <Hex setHex={handlers.hex}  color={props.color}  {...shared}  setVisibleSections={props.setVisibleSections} showColorsMenu={handlers.showColorsMenu} setScarlet={props.setScarlet}/>
                  <RangeSection rows={["r","g","b","a"]} sectionType="rgb"  handler={handlers.rgb} {...shared}/>
                  <RangeSection rows={["h","s","l","a"]} sectionType="hsl"  handler={handlers.hsl} {...shared}/>
              </div>
              <div className="cl-range-layout  ">
                  <PickersSections  handleRgb={handlers.rgb} handleHsl={handlers.hsl} {...shared}/>
                  <HsvRect  {...color} handleHsl={handlers.hsl}   {...hsvOpt} {...shared}/>
              </div>
          </div>
      </div>
    );
  }
}


export default Sections;
