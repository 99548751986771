import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import Link from '../Link';
import ArticleHeader from './Header/ArticleHeader';
import Button from '../Buttons/Button';
import {Paragraph} from '../Blocks/Blocks'; import BodyRow from './BodyRow';
import './cnAr.css';


/*
    <div className=""></div>
*/





export function Rows(props) {
  if (!props.rows) {
    return null;
  } else {
    return (
      <div className="w3-section-40 cn-defs">
          {props.rows.map((row,index) => <BodyRow {...row} key={index} clickHandler={props.clickHandler}/>)}
      </div>
    );
  }
}

function CnnArticle(props) {
      if (props.show != "cn-ar") {
        return null;
      } else {
        const url = (props.bot && props.back.url) ? props.back.url : null;
        const click = props.bot ? false : true;
        return (
          <div className="w3-animate-opacity cn-art-cont cn-defs" key={props.header.title}>
             <ArticleHeader {...props.header} clickHandler={props.clickHandler}/>
             <div className="w3-row cn-body">
                <div className="w3-col cn-main">
                    <Rows rows={props.rows} clickHandler={props.clickHandler}/>
                </div>
                <div className="w3-col cn-side">
                </div>
             </div>
          </div>

        );
      }
}

export default CnnArticle;
/*
const paragraph = "The wholesale cost of natural gas has surged to record highs in the United Kingdom, France, Spain, Germany and Italy. Bills for households and businesses are already soaring, and could go even higher as cold weather sets in and more fuel is needed for electricity generation and heating systems.";
const title = {title:"British Prime Minister Boris Johnson says the world needs to 'grow up' and deal with climate change", img:"//cdn.cnn.com/cnnnext/dam/assets/190111130933-luke-mcgee-profile-image-small-11.jpg", author:"Niamh Kennedy", date:"September 23, 2021"};
const image = {src:"https://dynaimage.cdn.cnn.com/cnn/q_auto,w_900,c_fill,g_auto,h_506,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F210916160005-10-worlds-safest-city-2021.jpg"};
const rows = [{type:"par",text:paragraph},{type:"par",title:"What's happening",text:paragraph},{type:"par",text:paragraph},{type:"emb",src:"https://cdn.cnn.com/cnnnext/dam/assets/210921042835-01-climate-renewables-fossil-fuels-medium-plus-169.jpg", captionHead:"Related article:", caption:"Europe's gas crisis is also a renewables crisis, but there are ready solutions"},{type:"par",text:paragraph}];

<HeaderNoImg />,
<Ulist rows={[{text: "Prova uno porcs", click:true},{text: "Prova uno porcs"},{text: "Prova uno porcs"}]} />,

*/
