

function AtPositionRange(props) {
  const {gradient, sclColors, index, setSclColors, gradEl, selectedGradient} = props.shared;
  const selGradient = gradient[selectedGradient];
  const colors = selGradient.colors;
  const handler = (e)=> {
    selGradient[e.target.name] = Number(e.target.value);
    if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
    setSclColors([...sclColors]);
  }


  if ((selGradient.type === "linear-gradient") || !colors || (props.selectedRange !== "atPosition"))  {
    return null;
  } else {
    return (
      <div className="a-container-12 a-mrB-12 p-rel" style={{zIndex:6}}>
          <div className="a-container-12 a-pdT-22 a-pdB-12 w3-round-large flx-btw" style={{...props.bkg}}>
              <input type="range" name="xPos" className="a-pWidth-48" step={1} min={0} max={100} onChange={handler} value={selGradient.xPos}/>
              <input type="range" name="yPos" className="a-pWidth-48" step={1} min={0} max={100} onChange={handler} value={selGradient.yPos}/>
          </div>
      </div>
    );
  }
}

export default AtPositionRange;
