import React from 'react';
import Pagination from './Pagination';
import Li from './Li';

/*
<div className="">
</div>
*/


class PaginationWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {currentPage: 1};
        this.handlePage = this.handlePage.bind(this);
    }


    handlePage(page) {
      const length = this.props.rows.length;
      const pagesNumber = Math.ceil( length / this.props.rowsPerPage);
      let currentPage;
      if (page == "prev") {
         currentPage = (this.state.currentPage > 1) ? (this.state.currentPage - 1) : 1;
      } else if (page == "next") {
        currentPage = (this.state.currentPage < pagesNumber) ? (this.state.currentPage + 1) : this.state.currentPage;
      } else {
        currentPage = page;
      }
      document.getElementById(this.props.scrollID).scrollIntoView();
      this.setState({currentPage: currentPage});

    }

    render() {

        const length = this.props.rows.length;
        const pagesNumber = Math.ceil( length / this.props.rowsPerPage);
        const startIndex = (this.state.currentPage - 1) * this.props.rowsPerPage;
        const endIndex = Math.min(startIndex + this.props.rowsPerPage, length);
        const currentPages = this.props.rows.slice(startIndex, endIndex);
        const controls = "Showing "+ (startIndex + 1) + "-"+(endIndex)+" / "+length;
        return (
          <div className={this.props.wrapperClass}>
            <div className="w3-margin-top w3-margin-bottom-32"><Pagination pages={pagesNumber} active={this.state.currentPage} clickHandler={this.handlePage} controls={controls} maxPages={this.props.maxPages} /></div>
            <ul  className={"ul-clear w3-animate-opacity " + this.props.ulClass} key={this.state.currentPage}>
                {currentPages.map((row, index) => <Li {...row} key={index} rowFunction={this.props.rowFunction}/>)}
            </ul>
            <div className="w3-section-32"><Pagination pages={pagesNumber} active={this.state.currentPage} clickHandler={this.handlePage} controls={controls} maxPages={this.props.maxPages} /></div>
          </div>
        );
    }

}

export default PaginationWrapper;

/*
<PaginationWrapper ulClass="" wrapperClass="" rows=[] rowsPerPage={int} rowFunction={} scrollID=""/>

*/
