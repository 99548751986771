import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; import  {arrayToObj, arrayToSingleObj} from './indexFunctions.js';
import App from './App';



const images = ["images",["id","src","imagePosition","alt","caption", "author","authorUrl"]];
const paragraps = ["text",["id","text"]];
const videos = ["videos",["id", "video_url","video_platform","video_caption","video_description","video_date"]];
const actions = ["action",["id", "action_btnName", "action_url", "action_caption","actionDate"]];
const tweets = ["tweets", ["id", "tweetID", "tweetTitle", "twitterUser","tweetDate"]];
const fbPosts = ["fbPosts", ["id", "postUrl", "postTitle", "fbUser","postDate"]];
const feedsMapping = ["id","title","subtitle","date","credit", "imagesIDs", "videosIDs",paragraps, "actionsIDs", "selectedTags", "tweetsIDs", "fbPostsIDs"];
const login = ["name","userID","admin","showLogin"];
const imagesMap = ["id","src","imagePosition","alt","caption", "author","authorUrl", "selectedTags"];
const videosMap = ["id", "video_url","video_platform","video_caption","video_description","video_date", "selectedTags"];
const fbPostsMap = ["id", "postUrl", "postTitle", "fbUser","postDate", "selectedTags"];
const tweetsMap = ["id", "tweetID", "tweetTitle", "twitterUser","tweetDate", "selectedTags"];
const actionsMap = ["id", "action_btnName", "action_url", "action_caption","actionDate", "selectedTags"];
const slides = ["slides", ["id", "title", "titleWidth", "subtitle", "subtitleWidth", "text", "textWidth", "action", "actionBtnName", "url", "imPosSPX", "imPosSPY","imPosSLX", "imPosSLY","imPosTPX", "imPosTPY", "imPosTLX", "imPosTLY", "imPosDX", "imPosDY", "portraitUrl", "style"]];
const sideMenButtons = ["buttons", ["id", "title", "icon", "iconColor", "inconSizeAdj","target", "meta", "url", "subMenu", "adminButton", "userButton", "categoryID"]];
const categories = ["categories",["id", "title", "adminButton","icon", "subMenuID", sideMenButtons]];
const dropButtons = ["buttons", ["id", "text", "icon","target","meta","url","email","adminButton","userButton","dropCatID","updateCount"]];
const dropMenu = ["dropMenu", ["id", "title", "titleIcon",dropButtons]];
const topBarButton = ["buttons", ["id", "text", "logo","alt","icon","inconSizeAdj","iconLePad","iconRiPad","iconToPad","iconBoPad","target","meta","log","url","email","color","style","size", "tooltip","adminButton","userButton","onTablet","dropColT", "dropColD","dropDirection", "dropWidth","sectionID","updateCount","type", "dropSize", "dropColor",dropMenu]];
const buttons = ["id", "name","type","borderRad","style","fontWeight","container","height","fontSize", "colorID","updated"];
let props = {};


props.noSelect = false;
props.feeds = arrayToObj(window.server[0], feedsMapping);
props.bot = window.server[1];
props.bpage = window.server[2];
props.selectedTags = window.server[3]; // window.server[3].split('-') : null;
const user = arrayToObj(window.server[4], login);
props.user = user[0];
props.selectedFeed = arrayToObj(window.server[5], feedsMapping)[0];
props.tags = arrayToObj(window.server[6], ["id","name"]);
props.selectedFeedsIds = window.server[7];
props.metas = arrayToObj(window.server[8], ["id","tagsUri","metaTitle","metaDescription", "btnName","component", slides, "selectedTags"]);
props.medias = {};
props.medias.images = arrayToObj(window.server[9][0], imagesMap);
props.medias.videos = arrayToObj(window.server[9][2], videosMap);
props.medias.fbPosts = arrayToObj(window.server[9][1], fbPostsMap);
props.medias.tweets = arrayToObj(window.server[9][3], tweetsMap);
props.medias.actions = arrayToObj(window.server[9][4], actionsMap);
props.currentMetaID = window.server[10];
props.menus = arrayToObj(window.server[11], ["id", "name", ["subMenus", ["id", "name", "sideMenuID", categories]]]);
props.topBar = arrayToObj(window.server[12], ["id", "name", topBarButton]);
props.buttons = arrayToObj(window.server[13], buttons);

window.testMode = false;



ReactDOM.render(
  <React.StrictMode>
    <App  {...props} />
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/* to append a css rule
var sheet = window.document.styleSheets[0];
sheet.insertRule('.a-btn-blue-1 {--h: 205.1; --s: 100%; --l: 42%; --btn-color: white; --hex: #007DD6}', sheet.cssRules.length);

*/
