import React, {useState, useEffect} from 'react';
import Button from '../../Buttons/Button';

function SaveButton(props) {
    const [show, setShow] = useState(true);
    useEffect(()=>{
        const width = window.innerWidth;
        const height = window.innerHeight;
        const resize = () => {
          if ((width < 500) &&  (height < width * 1.5) && show) {
             setShow(false);
          } else if (((width >= 500) || (height >= width * 1.5)) && !show) {
            setShow(true);
          }
        };
        if (width < 734 ) {
            window.addEventListener("resize", resize); return ()=> window.removeEventListener("resize", resize);
        }
    });

    if (!show) {
      return null;
    } else if (props.fetched) {
      const bkg = props.fetched === "error" ?  "a-bkg-red" : "a-bkg-green";
      const msg = props.fetched === "error" ? "Error" : "Saved";
      return <div className={"cl-save-pos a-pdS-10 w3-round a-container-35  w3-animate-opacity a-font-white a-font-16 "+bkg} >{msg}</div>;
    } else {
      return (
          <div className=" cl-save-pos ">
               <Button  text="Save" click={props.handleSave} color="a-btn-blue-1"  className="w3-margin-left-48 a-width-150 a-width-100-s" size="a-btn-size-4"/>
          </div>
      );
    }
}

export default SaveButton;
