import React from 'react';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
/*
    <div className=""></div>
    <div className="w3-row">
            {props.rows.map((row, index) => <Row {...row} key={index}/>)}
    </div>
*/


function NavLi(props) {
    if (false) {
      return null;
    } else {
      const handleClick = props.active ? null : [props.clickHandler, props.pageNum];
      return (
          <li className={"pagination-page-item" + (props.active ? " pagination-item-active" : "")}>
              <Link className="pagination-page-link"  click={handleClick}>{props.pageNum}</Link>
          </li>
      );
    }
}

function Previous(props) {
  let className = "pagination-prev ";
  let handleClick = [props.clickHandler, "prev"];
  if (!props.prev) {
      className += "pagination-disabled";
      handleClick = null;
  }
  return (
      <Link className={className} click={handleClick}>
              <div className="pag-nav-cont">
              <SvgIcon icon="navigate_before"  color="rgb(0, 113, 194)"/>
              </div>

      </Link>
    );
}

function Next(props) {
  let className = "pagination-next ";
  let handleClick = [props.clickHandler, "next"];
  if (!props.next) {
      className += "pagination-disabled";
      handleClick = null;
  }
  return (
      <Link className={className} click={handleClick}>
              <div className="pag-nav-cont">
                  <SvgIcon icon="navigate_next"  color="rgb(0, 113, 194)"/>
              </div>


      </Link>
    );
}




function Navigation(props) {
   if (!props.rows) {
     return null;
   } else {
     return (
          <div className="pagination-navigation">
              <div className="flex-cen-start w3-bold h-100">
                    <Previous clickHandler={props.clickHandler} prev={props.prev} />
                    <ul className="ul-clear"><div className="pagination-pages flex-cen-cen">{props.rows.map((row, index) => <NavLi {...row} key={index} clickHandler={props.clickHandler}/>)}</div></ul>
                    <Next clickHandler={props.clickHandler} next={props.next} />
              </div>
          </div>
     );
   }
}


export default Navigation;
