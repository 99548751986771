import React, {useEffect, useState} from 'react';
import SvgIcon from '../../../../../SvgIcon'; import Inner from './Inner';
import ScarletSectionHeader from '../Parts/ScarletSectionHeader'; import EditButtons from '../Parts/EditButtons';

/*
    <div className=""></div>
*/

function Texts(props) {
    const {select, selected, texts, selectText, selectedText, setTextEl, textEl, selectedTextType, selectTextType,index, setSclColors, sclColors, selectType, txt, contrastBkg, hideControls} = props.shared;

    const deleteText = ()=> {
      if (texts.length > 1 ) {
        texts.splice(selectedText, 1); if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]); selectText(0);
      }
    }

    const addText = ()=> {
      texts.push({gradient:["#ffffff","#ffffff","#ffffff"], text:["#ffffff"]});if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";setSclColors([...sclColors]);select("text"); selectText(selectedText+1); setTextEl(0); selectTextType("text"); props.setHex("#ffffff");
  }

/*
  useEffect(()=> {
    document.documentElement.addEventListener('touchend', touchEnd, true);
    return ()=>document.documentElement.removeEventListener('touchend',  touchEnd, true);
  },[touch]);
*/

//

  const addRow = ()=> {
    const length = texts[selectedText].text.length;
    texts[selectedText].text.push(texts[selectedText].text[length-1]); // we create a new row, duplicating the color of the last one
    setSclColors([...sclColors]); setTextEl(length);
  }

  const sectionPad = hideControls ? "a-section-30" : "a-pdT-20 ";
  if (selected === "gradient") return null;
  return (
    <div className={sectionPad} >
        <ScarletSectionHeader selected={selectedText} select={selectText} length={texts.length} bkg={contrastBkg} name="Texts" cancelBkgTouch={props.cancelBkgTouch} hideControls={hideControls}/>
        <Inner shared={props.shared} setHex={props.setHex} setHsl={props.setHsl} cancelBkgTouch={props.cancelBkgTouch}/>
        <EditButtons add={addText} name="Text" bkg={contrastBkg} selected={selectedText} delete={deleteText} cancelBkgTouch={props.cancelBkgTouch} others={[{name:"Add Row", handler:addRow}]} hideControls={hideControls}/>
        <hr className={"a-h-1 a-mrT-20 "+(hideControls?"w3-hide":"")} style={contrastBkg} />
    </div>
  );
}




export default Texts;
