import React from 'react';
import './centerMain.css';import Feed from './Feed/Feed';
import PaginationWrapper from '../Pagination/PaginationWrapper';import { useMediaQuery } from 'react-responsive';
import SortBar from '../Bars/SortBar'; import TagsBar from './TagsBar';
/*
    <div className=""></div>
*/
/*extLinks: [{text: <span>Cnn: Che cazzo vuoi?</span>, url: "http://www.cnn.com"},{text: <span>Cnn: Che cazzo vuoi?</span>, url: "http://www.cnn.com"}]*/
class CenterMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedTags: [], showTagsBar: false};
        this.handleTags = this.handleTags.bind(this);
        this.handleTagsBar = this.handleTagsBar.bind(this);

    }

    handleTags(tag) {
        let tags = this.state.selectedTags;
        const index = tags.indexOf(tag);
        if (index < 0) {
          tags.push(tag);
        } else {
          tags.splice(index, 1);
        }
        this.setState({selectedTags: tags});

    }

    handleTagsBar() {
      this.setState({showTagsBar: !this.state.showTagsBar});
    }

    componentDidMount() {
      if (this.props.homeTags.length) {
        this.setState({selectedTags: this.props.homeTags});
      }
    }

    


    render() {
      let media = {};
      media.isSmarthPhone = window.innerWidth < 734;
      media.isMiniPhone = window.innerWidth < 350;
      media.isTablet = (window.innerWidth > 734 && window.innerWidth < 1068);
      let rows = [];
      if (this.props.selectedFeedsIds && Array.isArray(this.props.selectedFeedsIds)) {
        for (let j=0; j<this.props.homeFeeds.length; j++) {
          if (this.props.selectedFeedsIds.includes(this.props.homeFeeds[j].id)) {
            rows.push(this.props.homeFeeds[j]);
          }
        }
      } else if (!this.state.selectedTags.length) {
        rows = this.props.homeFeeds;
      } else {
        rows = tagsFilteredRows(this.props.homeFeeds, this.state.selectedTags);
      }

     if (!Array.isArray(this.props.homeFeeds) || this.props.homeFeeds.length == 0) {
       return null;
     } else {
       const maxFeeds = media.isMiniPhone ? 7 : ( media.isSmarthPhone ? 10 : (media.isTablet ? 15 : 20));
       const rowsPerPage = (this.props.bot && this.props.selectedFeedsIds) ? this.props.homeFeeds.length : maxFeeds;  // nmx row per page doesn't affect bot when in selected feeds view, applies on nomral user or bot when in all feeds view
       const maxPages = {miniPhone:5,phone: 7, tablet:13, desktop: 15};
       return (
         <div className="w3-margin-top">
              <SortBar rows={[{text:"Zelensky", click:[this.handleTags, "4"], selected: this.state.selectedTags.includes("4")}, {text:"Assembly speeches", click:[this.handleTags,
                 "20"], selected: this.state.selectedTags.includes("20")}, {text:"Genocide", click:[this.handleTags,
                    "1"], selected: this.state.selectedTags.includes("1")},{type:"more", click:[this.handleTagsBar], selected: this.state.showTagsBar}]}/>
              <TagsBar tags={this.props.tags} selected={this.state.selectedTags} select={this.handleTags} show={this.state.showTagsBar} close={this.handleTagsBar}/>
              <PaginationWrapper ulClass="ukr-center-main-cont" wrapperClass="" maxPages={maxPages} rows={rows} rowsPerPage={rowsPerPage} scrollID="invasion_latest"
              rowFunction={<Feed tags={this.props.tags} {...this.props.medias} feedView={this.props.feedView} user={this.props.user}/>}  />
         </div>

       );
     }
    }

}

function calcTagIdsArray(arr) {
  let out = [];
  if (!Array.isArray(arr)) return null;
  for (let i=0; i<arr.length; i++) {
    out.push(arr[i].id);
  }
  if (out.length) {
    return out;
  } else {
    return null;
  }
}

function tagsFilteredRows(rows, selectedTags) {
  let out = [];
  for (let i=0; i<rows.length; i++) {
    const tagIDs = rows[i].selectedTags;
      if (tagIDs && (selectedTags.every((tag) => tagIDs.includes(tag)))) {
        out.push(rows[i]);
      }
  }
  return out;
}

export default CenterMain;
