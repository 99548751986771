import React, {useState, useEffect} from 'react'; import ColorsList from './ColorsList/ColorsList'; import Button from '../../Buttons/Button';
import BucketView from './BucketView'; import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToHsv, rgbToCss, hslToCss, contrastColorRgbToHex, contrastColor} from './Range/converters';
import {DivSelect, FormInput} from '../../forms/forms';import RangeEdit from './Range/RangeEdit'; import ColorsMenu from './ColorsMenu'; import Write from './Write';
import SaveButton from './SaveButton'; import NewList from './NewList'; import Logo from '../../Logo';
/*
    <div className=""></div>
*/

class Colors extends React.Component {
  constructor(props) {
      super(props);
      this.state = {rows: [], index: 0, editColor: false, bucket: [], showBuckCol: false, showList: null, lists: [], newListsView: false, showColorsMenu: true, fetched: false};
      this.handleSubmit = this.handleSubmit.bind(this);  this.handleChange = this.handleChange.bind(this);this.handleColor = this.handleColor.bind(this);
      this.handleDeleteColor = this.handleDeleteColor.bind(this);this.handleBucket = this.handleBucket.bind(this);this.handleNewColor = this.handleNewColor.bind(this);
      this.handleNewLists = this.handleNewLists.bind(this);  this.handleMovingColors = this.handleMovingColors.bind(this);this.handleDeleteList = this.handleDeleteList.bind(this);
      this.handleListName = this.handleListName.bind(this); this.handleSave = this.handleSave.bind(this); this.isNameUnique = this.isNameUnique.bind(this);
      this.fetchTimer = null;
  }

  handleChange(e) {
      const target = e.target;  const value =  target.value;
      this.setState({[target.name]: value});
    }

   // Colors Menu is always visible on Tablet and Desktop, on Mobile is open only on launch to select the first color and needs to be open after
    //()=>this.setState({showColorMenu: bol});

  fetchMsg(bol) {
    if (this.fetchTimer) clearTimeout(this.fetchTimer);
    this.setState({fetched: bol ? true : "error"});
    this.fetchTimer = setTimeout(()=>this.setState({fetched: false}),2500);
  }

  handleColor(field, value) {
    const rows = this.state.rows;
    const color = rows[this.state.index];
    if (field == "color") {  // picker sebds only the hex value so we do the conversions
      color.color = {...value};
    } else  {
      color[field] = value;
    }
    color["updated"] = "updated";
    this.setState({rows: rows});
  }




  handleDeleteColor() {
    const rows = this.state.rows;
    if (this.state.showBuckCol) {
        const bucket = this.state.bucket;
        bucket.splice(bucket.indexOf(this.state.showBuckCol),1);
        this.setState({bucket: bucket, showBuckCol: false});
    } else {
        const color = rows[this.state.index];
        if (color["id"]) {
          color["updated"] = "deleted";
        } else {
          rows.splice(this.state.index,1);
        }
        this.setState({rows:rows, index:0, editColor: false});
    }

  }
/*
  handleNewColor(hex) {
    const rows = this.state.rows;
    const rgb = hexToRgb(hex);
    const color = {hex: hex, rgb: rgb, hsl: rgbToHsl(rgb)};
    rows.push({name:"New color "+hex, color: color});
    const bucket = this.state.bucket;
    if (this.state.showBuckCol == hex) {
      bucket.splice(bucket.indexOf(this.state.showBuckCol),1);
    }
    this.setState({rows: rows, index:rows.length-1, editColor: true, showBuckCol: false, bucket: bucket});
  }
*/
  handleNewColor(name, color, list) {
    const rows = this.state.rows;
    rows.push({name:name, color:color, list: list});
    this.setState({rows: rows, index:rows.length-1, editColor: true, newListsView: false, showList: false});
  }

  handleBucket(hex, open) {
    let bucket = this.state.bucket;
    if (!bucket.includes(hex)) {
        bucket.push(hex);
        if (open) {
          this.setState({bucket:bucket, showBuckCol: hex , editColor: false, index: null});
          document.documentElement.scrollTop = 0;
        } else {
          this.setState({bucket:bucket});
        }
    } else if (bucket.includes(hex) && open) {
        this.setState({showBuckCol:  hex, editColor: false, index: null});
        document.documentElement.scrollTop = 0;
    }


  }

  addLists(list) {
    const lists = this.state.lists;
    list.blinking = true;
    lists.push(list);
    const object = lists[lists.length-1];
    this.setState({lists: lists});
    setTimeout(()=>{object.blinking = false; this.setState({lists:lists});}, 1000);
  }

  handleMovingColors(colors, listID) {
      const rows = this.state.rows;
      for (let i = 0; i<rows.length;i++) {
        if (colors.includes(rows[i].id)) {
          rows[i].list = listID;
          rows[i].updated = "updated";
        }
      }
      this.setState({rows:rows});
  }

  handleDeleteList() {
      const lists = this.state.lists;
      const selectedList = lists[this.state.showList];
      selectedList.updated = "deleted";
      this.setState({lists: lists, showList: null});
  }

  handleNewLists() {
      const name = this.state.newListName;
      this.setState({newListsView: false, newListName: null});
      fetch("/home/newList/"+name).then((res) => res.json()).then((result)=>{ this.addLists(result) }, (error) => this.setState({ error: error}));
  }

  handleSubmit(e) {
    return;
  }

  finalizeSave(result) {
  /*  const selected = this.state.savedIndex;
    let index = 0;
    for (let i=0; i<result.colors.length; i++) {
      if (selected.id) {
        if (result.colors[i].id === selected.id) index = i;
        break;
      } else {
        if (result.colors[i].name === selected.name) index = i;
        break;
      }
    }*/
    this.setState({rows: result.colors, lists: result.lists});
  }

  handleSave() {
    const out = {colors: this.state.rows, lists: this.state.lists};
    const selected = {id:this.state.rows[this.state.index].id, name: this.state.rows[this.state.index].name};
    this.setState({savedIndex: selected});
    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(out),};
    fetch("/colors/save/", data).then((res) => res.json()).then((result)=>{ this.finalizeSave(result); this.fetchMsg(true) }, (error) =>  this.fetchMsg(false));
  }

  handleListName(e) {
    const lists = this.state.lists;
    const selectedList = lists[this.state.showList];
    selectedList.name = e.target.value;
    selectedList.updated = "updated";
    this.setState({lists: lists});
  }

  buildTestData() {
    const data = JSON.parse('{"colors":[{"id":"1","name":"Alice blue","color":{"hex":"#f0f8ff","rgb":{"r":"240","g":"248","b":"255"},"hsl":{"h":"208","s":"100","l":"97"},"hsv":{"h":208,"s":6,"v":100,"a":1}},"list":"1"},{"id":"2","name":"Amber","color":{"hex":"#ffbf00","rgb":{"r":"255","g":"191","b":"0"},"hsl":{"h":"45","s":"100","l":"50"},"hsv":{"h":45,"s":100,"v":100,"a":1}},"list":"12"},{"id":"3","name":"antique white","color":{"hex":"#faebd7","rgb":{"r":"250","g":"235","b":"215"},"hsl":{"h":"34","s":"78","l":"91"},"hsv":{"h":34,"s":14,"v":98,"a":1}},"list":"1"},{"id":"4","name":"aqua","color":{"hex":"#00ffff","rgb":{"r":"0","g":"255","b":"255"},"hsl":{"h":"180","s":"100","l":"50"},"hsv":{"h":180,"s":100,"v":100,"a":1}},"list":"1"},{"id":"5","name":"Aqua Ap","color":{"hex":"#039e8f","rgb":{"r":"3","g":"158","b":"143"},"hsl":{"h":"174","s":"96","l":"32"},"hsv":{"h":174,"s":98,"v":62,"a":1}},"list":"11"},{"id":"6","name":"aquamarine","color":{"hex":"#7fffd4","rgb":{"r":"127","g":"255","b":"212"},"hsl":{"h":"160","s":"100","l":"75"},"hsv":{"h":160,"s":50,"v":100,"a":1}},"list":"1"},{"id":"7","name":"azure","color":{"hex":"#f0ffff","rgb":{"r":"240","g":"255","b":"255"},"hsl":{"h":"180","s":"100","l":"97"},"hsv":{"h":180,"s":6,"v":100,"a":1}},"list":"1"},{"id":"8","name":"Azure Ap","color":{"hex":"#027fff","rgb":{"r":"2","g":"127","b":"255"},"hsl":{"h":"210","s":"100","l":"50"},"hsv":{"h":210,"s":99,"v":100,"a":1}},"list":"11"},{"id":"9","name":"beige","color":{"hex":"#f5f5dc","rgb":{"r":"245","g":"245","b":"220"},"hsl":{"h":"60","s":"56","l":"91"},"hsv":{"h":60,"s":10,"v":96,"a":1}},"list":"1"}],"lists":[{"id":"11","name":"Apple"},{"id":"1","name":"Css Colors"},{"id":"12","name":"Others"}]}');

    this.setState({rows: data.colors, lists: data.lists});
  }

  fetchColors() {
    if (window.testMode) {
      this.buildTestData();
    } else {
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
      fetch("/colors/getColors/", data).then((res) => res.json()).then((result)=>{ this.finalizeSave(result) }, (error) => this.fetchMsg(false));
    }

  }

  isNameUnique(name) {
    const rows = this.state.rows;
    const index = this.state.index;
    for (let i = 0; i<rows.length;i++) {
      if (i !== index) {
        if (rows[i].name.replace(/ /g, "").toLowerCase() === name.replace(/ /g, "").toLowerCase()) return false;
      }
    }
    return true;
  }


  componentDidMount() {
    this.fetchColors();
    /*
      if (this.props.colors) {
        for (let i=0; i<this.props.colors.length; i++) {
          delete this.props.colors[i].color.btn;
          delete this.props.colors[i].id;
          delete this.props.colors[i].class;
          this.props.colors[i].color.hsv = rgbToHsv(this.props.colors[i].color.rgb);
        }

        this.setState({rows:this.props.colors});

      }
      if (this.props.colorsLists) {
        this.setState({lists:this.props.colorsLists});
      }
*/

  }
/*
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.rows == this.state.rows && nextState.index == this.state.index && nextState.editColor == this.state.editColor) {
      return false;
    } else {
      return true;
    }
  }
*/
  render() {
      const rows = this.state.rows;
      if (!rows || !rows.length) return null;
      console.log("render "+rows);
      const color = this.state.index >= 0 ? rows[this.state.index] : {};
      const activeColor = this.state.editColor ? color.color.hex : (this.state.showBuckCol ? this.state.showBuckCol : null);
      const selectedList = (this.state.showList >= 0) ?  this.state.lists[this.state.showList] : false;
      const showColorsMenu = ()=>this.setState({showColorsMenu: true, editColor: false, showList: null});
      const editColor = (i)=>{this.setState({index:i, editColor: true, showList: false}); document.documentElement.scrollTop = 0;};
      const editHandlers = {delete:this.handleDeleteColor, handleColor: this.handleColor, setNewColor: this.handleNewColor,
        addToBucket: (hex, open)=>this.handleBucket(hex, open), isNameUnique: this.isNameUnique};
      const colorSecPadding = this.state.editColor ? "" : "a-pdB-30";//  No padding bottom needed in range Edit view because each section inside as its own color and pading, a pdB would add in the end a further padding with different color
      return (
        <div className={colorSecPadding}>
              <ColorsMenu showLarge={this.state.showColorsMenu} newList={()=>this.setState({newListsView: true, showList: null})} lists={this.state.lists}
              openList={(state)=>this.setState(state)} activeList={this.state.showList} showColorsMenu={showColorsMenu} />
              <NewList show={this.state.newListsView} close={()=>this.setState({newListsView: false})} create={this.handleNewLists} value={this.state.newListName} setListName={(e)=>this.setState({newListName:e.target.value})}/>
              <ColorsList show={selectedList} setName={this.handleListName} deleteList={this.handleDeleteList} moveColors={this.handleMovingColors} colors={this.state.rows} lists={this.state.lists} showColorsMenu={showColorsMenu} edit={editColor} close={()=>this.setState({showList: null})}/>
              <BucketView  colors={this.state.bucket} show={this.state.showList} close={()=>this.setState({showList: false})} select={(hex)=>{this.setState({showBuckCol: hex, editColor: false});document.documentElement.scrollTop = 0;}}/>
              <RangeEdit {...color} mainShare={this.props.mainShare} show={this.state.editColor} editHandlers={editHandlers}  lists={this.state.lists} showColorsMenu={showColorsMenu}/>
              <SaveButton handleSave={this.handleSave} fetched={this.state.fetched}/>
              <Write show={false} rows={rows}/>
              <Button color="r-grad-1" filling="a-btn-2-rGrad" clickColor="a-btn-orange-1" width={200} height={41} text={"mannegiga a te"} className="" fontSize={26}
              click={()=>null}  className="a-btn-brdRad-50 a-mrL-32"/>

        </div>
      );
  }

}




export default Colors;







/* const cl = JSON.parse(`{...} `)
const entries = Object.entries(cl);
for (let i = 0; i<entries.length;i++) {
  const rgb = HexToRgb(entries[i][1]);
  const hsl = RgbToHsl(rgb);
  this.props.colors.push({name: entries[i][0], color: {hex:entries[i][1], rgb:rgb, hsl:hsl} });
}



fetch("/home/prova", {method: 'POST', headers: {'Content-Type': 'application/json',  },body: JSON.stringify({name: "luca", surname: "lattanzi"}),})
            .then((res)=> res.json()).then( (result)=>{
                                                      this.setState({res:result})
                                                      },
                                                      (error) => { this.setState({  error: true});
}
                                                  );
Spinner
@keyframes spinner-border{to{transform:rotate(1turn)}}.spinner-border{display:inline-block;width:2rem;height:2rem;vertical-align:-.125em;border:.25em solid;border-right:.25em solid transparent;border-radius:50%;animation:spinner-border .75s linear infinite}


rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%);



<EditColor editColor={this.state.editColor} activeColor={activeColor} selBucket={this.state.showBuckCol} {...color}  handleField={this.handleColor} editHandlers={editHandlers} done={()=>this.setState({editColor: false, showBuckCol: false})} key={activeColor} lists={this.state.lists}/>

<Button click={this.handleNewColor} activeColor="a-btn-orange-1" text="New Color" color="a-btn-azure " size="a-btn-size-4 w3-margin-right" filling="a-btn-filled"/>
<Button click={()=>this.setState({showList: "bucket", newListsView: false})} activeColor="a-btn-orange-1" text="Bucket" color="a-btn-azure" size="a-btn-size-4 w3-margin-right" filling="a-btn-filled"/>

*/
