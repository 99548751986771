import SvgIcon from '../../../../../SvgIcon';

function ScarletSectionHeader(props) {
  const {selected, select, name, length, bkg} = props;
  if (props.hideControls) {
    return null;
  } else {
    const mrB = props.noMargin ? "" : "a-mrB-32";
    return (

          <div className={"flx-btw a-mrL-30 a-mrR-30 a-font-14 w3-bold lh-1 "+mrB}  style={{position: "relative", zIndex: 1}}>
                <div className="a-pdS-6 a-container-10" style={{...bkg, width:"max-content"}} onTouchStart={(e)=>e.stopPropagation()}>{name}</div>
                <div className="flex-cen-cen" onTouchStart={(e)=>e.stopPropagation()}>
                    <div className=" a-mrR-8 a-pdL-4 a-pdR-4 a-pdT-5 a-pdB-2 pointer w3-round " style={{height: "26px", ...bkg}} onClick={(e)=>{e.stopPropagation();if (selected > 0) select(selected-1); console.log("left click")}} >
                        <SvgIcon icon="arrow_back_ios" color={selected > 0 ? "dodgerblue" : "gray"} size="16px" key={selected+"iconleft"+name}/>
                    </div>
                    <div className="a-pdS-6 a-container-10 w3-animate-opacity" style={{...bkg, width:"max-content"}} key={selected+"scl-counter-disp"}>{(selected+1) + " / "+length}</div>
                    <div className="a-mrL-8 a-pdR-3 a-pdL-5 a-pdT-5 a-pdB-2 pointer w3-round " style={{height: "26px", ...bkg}} onClick={(e)=>{e.stopPropagation();if (selected<length-1) {select(selected+1);}}} >
                        <SvgIcon icon="arrow_forward_ios" color={selected<length-1 ? "dodgerblue" : "gray"} size="16px" key={selected+"iconright"+name}/>
                    </div>
                </div>

          </div>

    );
  }

}

export default ScarletSectionHeader;
