import {DivSelect} from '../../../../../forms/forms';import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';


const TextTypeSelector = (props) => {
    const {selected,  selectedTextType, selectTextType, selectedGradientType, selectGradientType} = props.shared;
      const value = (selected === "text") ? selectedTextType : selectedGradientType;
      const options = (selected === "text") ? ["text","gradient"] :["color","text"];
      const handler = (selected === "text") ? selectTextType : selectGradientType;

      return (
          <DivSelect value={value} hide={!["text","gradient"].includes(selected)} options={options} handleOptionClick={handler}
            defs="form-noLab-xsmall"  defColors="form-col-orange"   margin="none" className={"a-width-80 a-mrL-4 w3-animate-opacity-04"} noIcon={true} topDrop={false} key={"selectedTextType"}/>
      );

}

export default TextTypeSelector;
