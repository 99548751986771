import {DivSelect} from '../../../../../forms/forms';

const RadialSizeSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const selGradient = gradient[selectedGradient];
    if (selGradient.type !== "radial-gradient") return null;
    const options = ["closest-side","farthest-side","closest-corner","farthest-corner"];
    const handler = (value)=> {
        selGradient.radialSize = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);
    }

      return (
        <DivSelect value={selGradient.radialSize} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-120"  margin="none" noIcon={true} />
    );
}

export default RadialSizeSelector;
