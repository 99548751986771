import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../forms/forms.js";
import Button from '../../Buttons/Button';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
/*
    <div className=""></div>
*/

function Tweets(props) {
  if (props.show != "batchTweet") {
      return null;
  } else {
    const handleChange1 = (e) => props.editRow("tweetTitle", e.target.value, props.rowIndex);
    const handleChange2 = (e) => props.editRow("tweetID", e.target.value, props.rowIndex);
    const handleChange3 = (e) => props.editRow("twitterUser", e.target.value, props.rowIndex);
    const handleDate = (date) => props.editRow("tweetDate", date, props.rowIndex);

    return (
        <div className="w3-row ">

              <div className=" w3-col m5"><TwitterTweetEmbed tweetId={""+props.tweetID+""} />&nbsp;</div>
              <div className=" w3-col m7">
                  <FormInput name={"tweetTitle"+props.rowIndex} label="Title" value={props.tweetTitle} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
                  <FormInput name={"tweetID"+props.rowIndex} label="Tweet ID" value={props.tweetID} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
                  <FormInput name={"twitterUser"+props.rowIndex} label="User" value={props.twitterUser} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
                  <FormDate name={"tweetDate"+props.rowIndex} label="Date" date={props.tweetDate} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />

              </div>
        </div>
    );
}
}


export default Tweets;
