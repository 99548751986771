import React, {useEffect, useState} from 'react'; import {DivSelect, FormInput} from '../../../../../forms/forms';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import SvgIcon from '../../../../../SvgIcon'; import RangeLevel from './RangeLevel'; import './rangeRow.css'; import RangeLevelInput from './RangeLevelInput';

/*    <div className=""></div>   */

function RangeRow(props) {
  const [editValue, setEditValue] = useState(false);
  const [render, setRender] = useState(true);
  useEffect(()=>{window.addEventListener("scroll", scroll); return ()=> window.removeEventListener("scroll", scroll);});
  const [p, setP] = useState(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [value, setValue] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);
  const paddings = 12 * 2 /*external paddings*/ + 22 * 2 /*range row paddings*/;
  const scroll = () => { // we check if the slider in in view port otwerwise we stop the autopilot
    const element = document.getElementById("rangeRow"+type+(props.inSvgRect ? "rect" : ""));
    if (isNearViewport(element) && !render) {
        setRender(true);
    } else if (!isNearViewport(element) && render) {
        setRender(false);
    }
  }

  const type = props.type;
  const adjType = (type == "gray") ? "r" : ((type == "hue") ? "h" : type); // in case of type gray or hue we selected r and h as type for the value
  const col = (type === "gray") || (props.sectionType === "rgb") ? props.rgb : props.hsl;
  let proppedValue = "";
  if (type == "gray") {
    proppedValue = ((col.r == col.g) && (col.r==col.b)) ? col.r : " ";
  } else if (type == "hue") {
    proppedValue = (col.s == 100 && col.l == 50)  ? col.h : " ";
  } else {
    proppedValue = ((type === "a") && !col[type] && (col[type] !== 0)) ? 1 : col[type]; // if a is not set the value will be set to 1
  }
  useEffect(()=>{
    if (render) { if (proppedValue !== value) setValue(proppedValue);}
  },[props.sectionType === "rgb" ? {...props.rgb} : {...props.hsl}, props.hex, render]);

  const isSliding = ((p && (p !== "scrolling")) || mouseDown) ? "rg-ph-sliding" : "";
  const max = ["r","g","b", "gray"].includes(type) ? 255 : ((["h","hue"].includes(type)) ? 360 : (type === "a" ? 1 : 100));
  const unit = ["r","g","b", "a", "gray"].includes(type) ? "" : ((["h","hue"].includes(type)) ? "°" : "%");
  const titleCol = (type == "r") ? "red" : ((type == "g") ? "#b3ff99" : ((type == "b") ? "#0000ff" : "#f2ff99" ));
  const thumbPosAdj = -20 / max * value;
  const thumbPhonePos = {left: (window.innerWidth - paddings) / max * value + thumbPosAdj};
  let titlePosition;
  if (editValue) {
    titlePosition = editValue.adj ? {left: 0} : {right: 0};
  } else {
    titlePosition =  ((value / max) >= 0.5) ? {left: 0} : {right: 0};
  }



  const handleSwipe = (e, inValue, scroll) => {
    //if (window.innerWidth < 734) return null;
    const value =  e.target.value;
    setValue(value); // we immediately set the internal state value of the the range input
    props.handler(value, false, type);

  }
   const shared = {type:type, editValue:editValue, setEditValue:setEditValue, value:value, max: max, paddings: paddings};

   const touchMove = (e) => {
     if (p === "scrolling") return null;
     const pageY = e.changedTouches[0].pageY;
     const pageX = e.changedTouches[0].pageX;
     if ( p && (p !== "sliding") && ((Math.abs(p.y - pageY) > 30) || ( Math.abs(p.y - pageY) > (Math.abs(p.x - pageX) / 2)))) {
       setP("scrolling");
       return null;
     } else {
       if (p) setP("sliding");
     }
     const position = e.changedTouches[0].pageX - paddings / 2;
     const calcValue = (adjType !== "a") ? Math.round(position / (window.innerWidth - paddings) * max ) : Math.round(position / (window.innerWidth - paddings) * max * 100) / 100;
     const value = calcValue < 0 ? 0 : (calcValue > max ? max : calcValue);
     setValue(value);
     props.handler(value, false, type);
   }

   const mobileClick = (e) => {
     const position = e.pageX - paddings / 2;
     const calcValue = (adjType !== "a") ? Math.round(position / (window.innerWidth - paddings) * max ) : Math.round(position / (window.innerWidth - paddings) * max * 100) / 100;
     const value = calcValue < 0 ? 0 : (calcValue > max ? max : calcValue);
     setValue(value);
     props.handler(value, false, type);
     if (p) {setP(null);}
   }

   const touchEnd = (e) => {
     if (p) {
       setP(null);
       return null;
     }
     const pageY = e.changedTouches[0].pageY;
     const pageX = e.changedTouches[0].pageX;
     if ( p && ((Math.abs(p.y - pageY) > 25) || ( Math.abs(p.y - pageY) > (Math.abs(p.x - pageX) / 2) ))) return null;
     const position = e.changedTouches[0].pageX - paddings/2;
     const calcValue =(adjType !== "a") ? Math.round(position / (window.innerWidth - paddings) * max ) : Math.round(position / (window.innerWidth - paddings) * max * 100) / 100;
     const value = calcValue < 0 ? 0 : (calcValue > max ? max : calcValue);
     setValue(value);
     props.handler(value, false, type);
     setP(null);


   }

  //  <h4>{type.toUpperCase()}</h4>
  return (
    <div className={"cl-range-row-container ov-hid "+isSliding} id={"rangeRow"+ type + (props.inSvgRect ? "rect" : "")} onMouseLeave={()=>setLeaveCount(leaveCount + 1)}>
        <div className="flex-cen-start w3-display-container cl-range-row noselect ">
            <div className="w3-display-container grow-1 a-pdT-20 a-pdT-16-s noselect ">
                <RangeLevel  {...shared} unit={unit} adjType={adjType} leaveCount={leaveCount} setLeaveCount={setLeaveCount} isSliding={isSliding} col={col}  increase={props.increase} isSliding={isSliding} />
                <input name={type} id={"cl-range"+type} className="w100 range no-phone" type="range" min={0} max={max} step={adjType === "a" ? 0.01 : 1}  value={value}  onChange={handleSwipe}    style={props.bkg[type]} onMouseDown={()=>setMouseDown(true)} onMouseUp={()=>setMouseDown(false)} onMouseEnter={()=>setLeaveCount(leaveCount + 1)} />
                <div className="phone-only range-phone " style={props.bkg[type]} onTouchStart={(e)=>setP({x:e.changedTouches[0].pageX,y:e.changedTouches[0].pageY})} onTouchMove={touchMove} onTouchEnd={touchEnd} onClick={mobileClick}>
                    <div className="range-phone-thumb" style={thumbPhonePos}></div>
                </div>
                <UnitsBottomBar unit={unit} max={max} />
            </div>
            <RangeLevelInput  {...shared} blur={props.blur} handler={props.handler}/>
            <h4 className="cl-range-input-title " style={titlePosition} onMouseEnter={()=>setLeaveCount(leaveCount + 1)} >{type.toUpperCase()}</h4>
        </div>

    </div>
  );
}



const UnitsBottomBar = (props) => (
  <div className="w100 w3-display-container w3-hide-small">
      <div className="cl-range-lev-range-num">{"0" + props.unit}</div>
      <div className="cl-range-lev-range-num">{Math.round(props.max/2*10) /10 + props.unit}</div>
      <div className="cl-range-lev-range-num">{props.max  + props.unit}</div>
  </div>
);

export default RangeRow;

var isNearViewport = function (elem) {
    if (!elem) return null;
    var bounding = elem.getBoundingClientRect();
    return ((bounding.top <= (window.innerHeight * 1)) && (bounding.bottom > (window.innerHeight * 0)));
  };
