import {DivSelect} from '../../../../../forms/forms';

const RangeSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;

    const selGradient = gradient[selectedGradient];
    const type = selGradient.type;
    let value = props.selectedRange;
    const options = ["No selection"];
    if (((selGradient.direction === "angle") && (selGradient.type === "linear-gradient")) || (selGradient.type === "conic-gradient")) {
      options.push("angle");
    } else {
      if (value === "angle") value = "No selection";
    }
    if (selGradient.type !== "linear-gradient") {
      options.push("atPosition");
    } else {
      if (value === "atPosition") value = "No selection";
    }
    if ((selGradient.direction === "angle") || (selGradient.type !== "linear-gradient")) {
      options.push("position");
    } else {
      if (value === "position") value = "No selection";
    }

    if ((selGradient.direction !== "angle") && (selGradient.type === "linear-gradient")) return null;

      return (
        <DivSelect value={value} options={options} handleOptionClick={props.setRange} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-100"  margin="none" noIcon={true} />
      );

}

export default RangeSelector;
