import React, {useEffect, useState} from 'react';
import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import CardRow from './CardRow';
import ScarletSectionHeader from '../Parts/ScarletSectionHeader'; import EditButtons from '../Parts/EditButtons';

/*
    <div className=""></div>
*/

function Cards(props) {
  const {select, selected, cards, selectedCard, selectCard, index, setSclColors, sclColors, setCardEl, cardEl, card, contrastBkg, selectType, hideControls} = props.shared;

  const deleteCard = (e)=> {
    if (cards.length > 1) {
      cards.splice(selectedCard, 1); if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);  selectCard(0);
    }
  }
  const addCard = ()=> {
    cards.push({...card});  if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]); select("cards");  selectCard(selectedCard+1); setCardEl("bkg"); props.setHex("#cf0000");
  }
  const cardsSettlers = {select, setCardEl, selectCard, deleteCard, selectType, setHsl: props.setHsl};
  const sectionPad = hideControls ? "a-section-30" : "a-pdT-20 ";
  if (selected === "gradient") return null;
  
  return (
    <div className={sectionPad} >
        <ScarletSectionHeader selected={selectedCard} select={selectCard} length={cards.length} bkg={contrastBkg} name="Cards" hideControls={hideControls}/>
        <CardRow  key={index} shared={props.shared}  setHsl={props.setHsl} cancelBkgTouch={props.cancelBkgTouch} deleteCard={deleteCard}/>
        <EditButtons add={addCard} name="Card" bkg={contrastBkg} selected={selectedCard} delete={deleteCard} hideControls={hideControls}/>
        <hr className={"a-h-1 a-mrT-20 "+(hideControls?"w3-hide":"")} style={contrastBkg}/>
    </div>
  );
}

export default Cards;
