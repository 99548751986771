import React from 'react'; import Inputs from './Inputs'; import SelectBar from './SelectBar'; import './editTopBar.css';import Button from '../../../Buttons/Button';
import  {arrayToObj, arrayToSingleObj} from '../../../indexFunctions.js';

var EditTopBar = (props) => (props.show === "editTopBar") ? <Inner {...props} /> : null;

/*
    <div className=""></div>
*/

class Inner extends React.Component {
  constructor(props) {
      super(props);
      this.state = {rows: [], selectedBar: 4,  selectedDropCat:0, selectedDropMenu: null, deleted: {buttons: [], dropCat: [], dropButtons: []}};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleBar = this.handleBar.bind(this);
      this.handleButton = this.handleButton.bind(this);
      this.handleDropButton = this.handleDropButton.bind(this);
      this.handleDropCat = this.handleDropCat.bind(this);
      this.handleDropCategory = this.handleDropCategory.bind(this);
      this.handleDeleteDropCat = this.handleDeleteDropCat.bind(this);
      this.handleAddDropCat = this.handleAddDropCat.bind(this);
      this.handleDropMenuSelection = this.handleDropMenuSelection.bind(this);
      this.handleMoveButton = this.handleMoveButton.bind(this);
      this.handleMoveDropButton = this.handleMoveDropButton.bind(this);
      this.handleSave = this.handleSave.bind(this);

  }

  handleChange(e) {
    const target = e.target;
    const value =  target.value;
    this.setState({[target.name]: value});
  }
  handleBar(value) {
    this.setState({selectedBar: value, selectedDropMenu: null});  // we have to close the drop menu when changing bar because the open one refers to the closed bar
  }

  handleButton(value, index, field) {
    let rows = this.state.rows;
    let button = rows[this.state.selectedBar].buttons[index];
      const deleted = this.state.deleted;
    if ((value == "deleteButton") && (field == "deleteButton")) {
      if (button.id)  {
        deleted.buttons.push(button.id);
      }
      rows[this.state.selectedBar].buttons.splice(index, 1);
    } else if (value == "addButtonxxx")  {
      rows[this.state.selectedBar].buttons.push({target:"meta", updateCount: 0});
    } else if (field == "target" && value === "dropMenu")  {  // If we set the button as a drop menu we create an a dropMenu property int he button containing an Array of drop column Objects
      button["dropMenu"] = [{title: "",  buttons: [{}]}];
      button[field] = value;     // We initialize the array with a single empty drop column object and later we can add more column with a array push
      if (button.id) {
        button.updated = true;
      }
    } else if (field == "target" && value !== "dropMenu")  {  // If we change the target to something different from dropMenu we set the dropMenu property to null deleting the array of drop columns objects
      button["dropMenu"] = null;
      button[field] = value;
      if (button.id) {
        button.updated = true;
      }
      this.setState({selectedDropMenu: null});

     } else {
      button[field] = value;
      if (button.id) {
        button.updated = true;
      }
    }
    this.setState({rows: rows, deleted: deleted});
    this.props.updateTopBar();
  }

  handleDropButton(value, field, dropBtnIndex) {
    const rows = this.state.rows;
    const callingButton = rows[this.state.selectedBar].buttons[this.state.selectedDropMenu];
    const dropCat = callingButton.dropMenu[this.state.selectedDropCat];
    const buttons = dropCat.buttons;
    const button = buttons[dropBtnIndex];
    const deleted = this.state.deleted;
    if ((value == "deleteButton") && (field == "deleteButton")) {
      if (button.id)  deleted.dropButtons.push(button.id);
      buttons.splice(dropBtnIndex, 1);
    } else if (value == "addButtonxxx")  {
      if (dropCat.id)  dropCat.updated = true;
      if (callingButton.id)  callingButton.updated = true;
      buttons.push({updateCount: 0});
    } else {
      button[field] = value;
      if (dropCat.id)  dropCat.updated = true;
      if (callingButton.id)  callingButton.updated = true;
      if (button.id)  button.updated = true;
    }
    this.setState({rows: rows, deleted: deleted});
    this.props.updateTopBar();
  }

  handleDropCat(field, value) {
    const rows = this.state.rows;
    const callingButton = rows[this.state.selectedBar].buttons[this.state.selectedDropMenu];
    const dropCat = callingButton.dropMenu[this.state.selectedDropCat];
    dropCat[field] = value;
    if (callingButton.id)  callingButton.updated = true;
    if (dropCat.id) dropCat.updated = true;
    this.setState({rows});
    this.props.updateTopBar();
  }

  handleDropCategory(index) {
    this.setState({selectedDropCat: index});
    //this.props.rows.push({});
  }

  handleAddDropCat() {
    const rows = this.state.rows;
    const categories = rows[this.state.selectedBar].buttons[this.state.selectedDropMenu].dropMenu;
    categories.push({title: "", buttons:[{}]});
    this.setState({rows: rows, selectedDropCat: categories.length-1});
    this.props.updateTopBar();
  }

  handleDeleteDropCat() {
    const rows = this.state.rows;
    const deleted = this.state.deleted;
    const categories = rows[this.state.selectedBar].buttons[this.state.selectedDropMenu].dropMenu;
    if (categories.length < 2) {
      this.handleButton(null, this.state.selectedDropMenu, "target");  // this command will set parent target to null, delete the last submenu category and set selected dropmenu to null to close the drop view.
    }
    const dropCat = rows[this.state.selectedBar].buttons[this.state.selectedDropMenu].dropMenu[this.state.selectedDropCat];
    if (dropCat.id)  {
      deleted.dropCat.push(dropCat.id);
    }
    categories.splice(this.state.selectedDropCat, 1);
    this.setState({rows: rows, selectedDropCat: 0, deleted: deleted});
    this.props.updateTopBar();


  }

  handleDropMenuSelection(index) {
    this.setState({selectedDropMenu: index});
  }

  handleMoveButton(obj) {
      let rows = this.state.rows;
      let buttons = rows[this.state.selectedBar].buttons;
      const length = buttons.length;
      const button = buttons[obj.index];
      if (obj.direction === "up" && (obj.index > 0) && (length > 0)){

        button.updateCount++;
        buttons.splice(obj.index, 1);
        buttons.splice(obj.index-1, 0, button);
      } else if (obj.direction === "down" && (obj.index < (length-1))){
        button.updateCount++;
        buttons.splice(obj.index, 1);
        buttons.splice(obj.index+1, 0, button);
      }
      if (button.id) {
        button.updated = true;
      }
      this.setState({rows});
  }

  handleMoveDropButton(obj) {
      let rows = this.state.rows;
      const buttons = rows[this.state.selectedBar].buttons[this.state.selectedDropMenu].dropMenu[this.state.selectedDropCat].buttons;
      const length = buttons.length;
      const button = buttons[obj.index];
      if (obj.direction === "up" && (obj.index > 0) && (length > 0)){
        button.updateCount++;
        buttons.splice(obj.index, 1);
        buttons.splice(obj.index-1, 0, button);
      } else if (obj.direction === "down" && (obj.index < (length-1))){
        button.updateCount++;
        buttons.splice(obj.index, 1);
        buttons.splice(obj.index+1, 0, button);
      }
      if (button.id) {
        button.updated = true;
      }
      this.setState({rows});
  }

  handleSubmit(e) {
    return;
  }

  finalizeSave(result) {
    const dropButtons = ["buttons", ["id", "text", "icon","target","meta","url","email","adminButton","userButton","dropCatID","updateCount"]];
    const dropMenu = ["dropMenu", ["id", "title", dropButtons]];
    const topBarButton = ["buttons", ["id", "text", "logo","alt","icon","inconSizeAdj","iconLePad","iconRiPad","iconToPad","iconBoPad","target","meta","log","url","email","color","style","size", "tooltip","adminButton","userButton","onTablet","dropColT", "dropColD","sectionID","updateCount","type", dropMenu]];
    const topBar = arrayToObj(result, ["id", "name", topBarButton]);
    this.props.topBar.splice(0, this.props.topBar.length);
    this.props.topBar.push(...topBar);
    this.setState({rows: this.props.topBar, deleted: {buttons: [], dropCat: [], dropButtons: []}});
    alert("saving complete");
    this.props.updateTopBar();

  }


  handleSave() {
    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify({rows: this.state.rows, deleted: this.state.deleted}),};
    fetch("/menu/topBar/", data).then((res) => res.json()).then((result)=>{ this.finalizeSave(result) }, (error) => alert(error));
  }

  componentDidMount() {
      this.setState({rows: this.props.topBar});
  }



  render() {

      let selBarOptions = {};
      const rows = this.state.rows;
      if (!rows.length) return null;

      selBarOptions.barKeys = [];
      for (let i=0; i<rows.length; i++) {selBarOptions.barKeys.push([rows[i].name, i])};
      selBarOptions.selectedBar = this.state.selectedBar;
      const sharedDropHandlers = {selectDropMenu: this.handleDropMenuSelection, selectedDropMenu: this.state.selectedDropMenu};
      const dropHandlers = {deleteDropCat: this.handleDeleteDropCat, addDropCat: this.handleAddDropCat, setDropCategory: this.handleDropCategory,
        setDropCat:this.handleDropCat, selectedDropCat: this.state.selectedDropCat, moveDropButton:this.handleMoveDropButton, ...sharedDropHandlers};
      const id = this.props.id ? this.props.id : "";
      const inputOptions = {moveButton: this.handleMoveButton, setDropButton: this.handleDropButton, setButton: this.handleButton, selectedBar: this.state.selectedBar,
      metas: this.props.metas};
      return (
        <div className="w3-content-1300 w3-section-32 h100vh">
                <SelectBar {...selBarOptions} setBar={this.handleBar}/>
                <Inputs buttons={rows[this.state.selectedBar].buttons}  dropHandlers={dropHandlers} {...sharedDropHandlers} {...inputOptions}/>
                <div className="w3-section-64 w3-center ">
                      <Button click={this.handleSave} text="Save"  color="a-btn-blue-1"  className="w3-margin-left-48 mw-250" size="a-btn-size-4"/>
                </div>

        </div>
      );
  }

}


export default EditTopBar;
