import React, {useRef} from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css"; import './divSelect.css';
import SvgIcon from '../SvgIcon';import svg from '../svg';
import FormContainer from "./FormContainer";

const Overlay = (props) => (props.open ? <div className="frm-overlay" onClick={(e)=>{e.stopPropagation();props.clickHandler();}} onTouchStart={(e)=>{e.stopPropagation();setTimeout(props.close,500);}}/> : null);

function RightIcon(props) {
    if (!props.value || props.noIcon) return null;
    const color = props.color ? props.color : "black";
    if (!props.open) {
        return (
                <div className="frm-drop-icon" key="down" >
                    <div className="flex-cen-start">
                        <div>
                            <SvgIcon icon="arrow_drop_down" size="18px" color={color}/>
                        </div>
                    </div>
                </div>
        );
    } else {
        return (
                <div className="frm-drop-icon" key="up">
                <div className="flex-cen-start">
                <div>
                <SvgIcon icon="arrow_drop_up" size="18px" color={color}/>
                </div>
                </div>
                </div>
                );
    }
}
function SelectIcon(props) {
    const name = props.option[0];
    const svg = props.option[1];
    function handleClick(e) {
        e.stopPropagation();
        props.handleClick(name);
    }

    return <div className="w3-border-bottom frm-selectItem flex-cen-start truncate" onClick={handleClick} >{svg} <div className="w3-padding-left-16"> {name} </div></div>
}

function SelectOption(props) {

    const name = props.option[0];
    const value = props.option[1];
    const color = props.colorSelector ? {background: value ? value : "DodgerBlue"} : {};
    function handleClick(e) {
        e.stopPropagation();
        props.handleClick(value);
    }

    return <div className="w3-border-bottom frm-selectItem flex-cen-start truncate" onClick={handleClick} style={color}> {name} </div>
}

function SelectOptions(props) {
    const myRef = useRef(null);
    const delta = 80;
    const touchMove = (e) => {
        const rect = myRef.current.getBoundingClientRect();
        const x = e.changedTouches[0].clientX;
        const y = e.changedTouches[0].clientY;
        if ((x > (rect.right + delta)) || (x < (rect.left - delta)) || (y > (rect.bottom + delta)) ||  (y < (rect.top - delta))) {
          props.close();
        }
    };

    if (props.open) {
        const options =  props.iconsSelect ? props.options.map((option) => <SelectIcon option={option} key={option[0]}  handleClick={props.handleClick} />) : props.options.map(option => <SelectOption option={option} key={option[1]} colorSelector={props.colorSelector} handleClick={props.handleClick} />);
        return (
            <div className="div-select-optionN w3-bar-block no-over-scroll" onTouchMove={touchMove} ref={myRef}>
                {options}
            </div>
        );
    } else {
        return null;
    }
}

class DivSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {color:  "frm-blur-color", clicked: false};
        this.handleClick = this.handleClick.bind(this);
        this.handleOptionClick = this.handleOptionClick.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);
        this.handleOverlay = this.handleOverlay.bind(this);
    }
    handleClick(e) {
        e.stopPropagation();
        let state;
        if (!this.state.clicked) {
            state = {color:  "frm-focus-color", clicked: true};
        } else {
            state = {color:  "frm-blur-color", clicked: false};
        }
        this.setState(state);
    }

    setRedBackground() {
        this.setState({color: "frm-error-color"});
    }

    handleLabelClick() {
      this.setState({clicked: true});
    }

    handleOptionClick(value) {
        this.setState({clicked: false});
        this.props.handleOptionClick(value);
    }

    handleOverlay() {
      if (this.state.clicked) {
        this.setState({clicked: false});
      }
    }




    render() {
        if (this.props.hide) return null;
        const inputPad = this.props.label ? "input-top-pad" : "";
        let cols = !this.props.cols ? "div-sel-col-1 " : "div-sel-col-"+this.props.cols+" ";
        cols+= (!this.props.colsDisp ? "div-sel-disp-0" : "div-sel-disp-"+this.props.colsDisp);
        const topDrop = this.props.topDrop ? " div-select-top" : "";
        let options = [];let valueName = "";
        if (this.props.iconsSelect) {
          options = svg();
          valueName = this.props.value;
        } else if (this.props.options && Array.isArray(this.props.options[0])) {
            options = this.props.options;
        } else if (this.props.options) {
            for (var m=0; m < this.props.options.length; m++) {
                options.push([this.props.options[m], this.props.options[m]]);
              }
        } else if (this.props.rangeStart>= 0 && this.props.rangeEnd>=0) {
            let i = 0;
            for (var start = this.props.rangeStart; start <= this.props.rangeEnd; start++) {
                options[i] = [start, start];
                i++;
            }
        } else {
          return null;
        }


        // The bp value is an id, but we need to display a name so we find the the name corresponding to the value ad assign it to a name const

        if ((this.props.value !== null) &&  (this.props.value !== false) && !this.props.iconsSelect) {
          for (var i=0; i < options.length; i++) {
            const optionsArray = options[i];
            if (optionsArray[1] == this.props.value) {
              valueName = optionsArray[0];
            }
          }
        }


        return (
                <FormContainer color={this.state.color} value={this.props.value} clicked={this.state.clicked} defColors={this.props.defColors} defs={this.props.defs}  label={this.props.label}
                covLabel={this.props.covLabel} margin={this.props.margin} labelColor={this.props.labelColor} noPointer={this.props.noPointer} className={this.props.className + " "+cols}
                 margin={this.props.margin}  maxWidth={this.props.maxWidth}  required={this.props.required} handleClick={this.handleLabelClick}>
                <div onClick={this.handleClick} className={"flex-cen-start w100 "+inputPad + topDrop}>
                    <div className="frm-inputN selectMaxWidthN grow-1 truncate">
                    {valueName}
                    </div>
                    <RightIcon open={this.state.clicked} value={this.props.value} color={this.props.iconColor} noIcon={this.props.noIcon}/>
                    <SelectOptions open={this.state.clicked} handleClick={this.handleOptionClick} options={options}
                     iconsSelect={this.props.iconsSelect} colorSelector={this.props.colorSelector} close={()=>this.setState({clicked: false})}/>
                     <Overlay clickHandler={this.handleOverlay} open={this.state.clicked} close={()=>this.setState({clicked: false})}/>
                </div>
                <input type="hidden" name={this.props.name} value={this.props.value} />
                </FormContainer>
                );
    }
}

export default DivSelect;
