import React from 'react';
import './storedMedia.css';import Button from '../../../../Buttons/Button'; import Images from './Images'; import Videos from './Videos';
import Actions from './Actions';import Tweets from './Tweets';import FbPosts from './FbPosts';
/*
    <div className=""></div>
*/




function StoredMedia(props) {
   if (!props.view ) {
     return null;
   } else  {
     return (
       <div className="h100vh">
          <div className="w3-section-32 w3-container">
              <Button text="Done" click={true} clickHandler={props.close} id={false} color="a-btn-blue-1" size="a-btn-size-3"/>
          </div>
          <Images view={props.view} rows={props.images} addMedia={props.addMedia} selectedIDs={props.storedMedia.images}/>
          <Videos view={props.view} rows={props.videos} addMedia={props.addMedia} selectedIDs={props.storedMedia.videos}/>
          <Actions view={props.view} rows={props.actions} addMedia={props.addMedia} selectedIDs={props.storedMedia.actions}/>
          <FbPosts view={props.view} rows={props.fbPosts} addMedia={props.addMedia} selectedIDs={props.storedMedia.fbPosts}/>
          <Tweets view={props.view} rows={props.tweets} addMedia={props.addMedia} selectedIDs={props.storedMedia.tweets}/>
       </div>
     );
   }
}


export default StoredMedia;
