
export default function Write(props) {
  if (!props.show) return null;
  const title = "icon-bPad";
  const title2 = "";
  const css = "--icon-bPad";
  const css2 = "--height";
  const unit = "";
  let out = "", outT = "", outS = "", outXS = "";
  for (let i=2; i<=30; i+=2) {
    const imp = (i === 0) ? " !important":"";
    out+=  `.an-${title}-${i} {${css}: ${i/100}${unit+imp}; }`;
    outT+= `.an-${title}-${i}-t {${css}: ${i/100}${unit+imp}; } `;
    outS+= `.an-${title}-${i}-s {${css}: ${i/100}${unit+imp}; } `;


  }
  return (
    <div>
          <div  className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(out)}>{out}</div>
          <div className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(outT)}>{outT}</div>
          <div className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(outS)}>{outS}</div>
    </div>
  );
}


/*


export default function Write(props) {
  if (!props.show) return null;
  const title = "pdL";
  const title2 = "pdR";
  const css = "padding-left";
  const css2 = "padding-right";
  const unit = "px";
  let out = "", outT = "", outS = "", outXS = "";
  for (let i=0; i<=20; i+=1) {
    const imp = (i === 0) ? "!important":"";
    out+=  `.a-${title}-${i} {${css}: ${i}${unit+imp};}.a-${title2}-${i} {${css2}: ${i}${unit+imp};}`;
    outT+= `.a-${title}-${i}-t {${css}: ${i}${unit+imp};}.a-${title2}-${i} {${css2}: ${i}${unit+imp};} `;
    outS+= `.a-${title}-${i}-s {${css}: ${i}${unit+imp};}.a-${title2}-${i} {${css2}: ${i}${unit+imp};} `;


  }
  return (
    <div>
          <div  className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(out)}>{out}</div>
          <div className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(outT)}>{outT}</div>
          <div className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(outS)}>{outS}</div>
    </div>
  );
}


*/


/*
pdS

export default function Write(props) {
  if (!props.show) return null;
  const title = "pdS";
  const title2 = "L Hov ";
  const css = "padding-top";
  const css2 = "padding-bottom";
  let out = "", outT = "", outS = "", outXS = "";
  for (let i=80; i<=200; i+=10) {
    out+= `.a-${title}-${i} {${css}: ${i}px; ${css2}: ${i}px;} `;
    outT+= `.a-${title}-${i}-t {${css}: ${i}px; ${css2}: ${i}px;} `;
    outS+= `.a-${title}-${i}-s {${css}: ${i}px; ${css2}: ${i}px;} `;


  }
  return (
    <div>
          <div  className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(out)}>{out}</div>
          <div className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(outT)}>{outT}</div>
          <div className="a-container-16 a-section-16" onClick={()=>navigator.clipboard.writeText(outS)}>{outS}</div>
    </div>
  );
}

*/

/*


for (let i=75; i<=120; i+=5) {
  out+= `.a-${title}-${i} {${css}: ${i}px;} `;
  outT+= `.a-${title}-${i}-t {${css}: ${i}px;} `;
  outS+= `.a-${title}-${((i/10).toString().replace(".","-"))}-s {${css}: ${i/10}vw;} `;
  outXS+= `.a-${title}-${((i/10).toString().replace(".","-"))}-xs {${css}: ${i/10}vw;} `;

}

out+= `.a-${title}-${i} {${css}: ${i}px;${css2}: ${i}px;} `;
outT+= `.a-${title}-${i}-t {${css}: ${i}px;${css2}: ${i}px;} `;
outS+=`.a-${title}-${i}-s {${css}: ${i}px;${css2}: ${i}px;} `;
outXS+= `.a-${title}-${i}-xs {${css}: ${i}px;${css2}: ${i}px;} `;

*/
