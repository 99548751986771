const Variation = (props) => {
  const {hexHeight,menuHeight,scrollMargin,topBarHeight, selected, setSelected, setSticky, stuck} = props.mapHeShare;
  const {scarlet, type, name} = props;

  const fontSize = stuck ?  " a-font-14-s " : " a-font-15-s ";
  const variationClick = ()=>{
    const el = document.getElementById(type+"mappingRow");
    if (!scarlet) {
        if (el) {
          scrollInto(el);
          setTimeout(()=>scrollInto(el),200); // w3 need to recall scrollInto after 200ms because when the first row loads the hsv graph will become visible and will sligtly change the scroll, to we adjust after 200 the scroll since now top will consider also the hsvRect
        }

    } else {
        if (el) el.scrollIntoView();
        const he = document.getElementById("mappings");
        he.scrollTop -= (type === "b" ? 0 : menuHeight+10); // The last row type "b" doesn't need top margin because being the last element when mappings is fully scrolled "b" row will be still far from the top, unless scarlet is enlarged too much, but we don't account that case because mappings will be so small that it makes no sense
    }
    setSelected(type);setSticky(true)
  }

  const scrollInto = (el) => {
     const top = el.getBoundingClientRect().top;
     document.documentElement.scrollTop += (top - (topBarHeight + window.innerWidth * hexHeight + menuHeight+scrollMargin/*20 is a margin*/));
  }


  return (
    <div className={"cl-var-title-elem noselect lh-1 w500 "+(selected==type ? "cl-var-title-selected ": "") + fontSize} id={"variation"+type} onClick={variationClick}> {name}</div>
  );
}

export default Variation;
