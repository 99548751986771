import React from 'react';

/*
    <div className=""></div>
*/
const Date = (props) => (props.date ? <div className="ukr-feed-date">{props.date}</div> : null);
const H4 = (props) => (props.subtitle ? <h4>{props.subtitle}</h4> : null);

function handleNewLines(string) {
    let arr = []; let str = string; let out = [];
    while (str.indexOf("<br>") != -1) {
      const index = str.indexOf("<br>");
      const sub = str.slice(0, index);
      arr.push(sub);
      str = str.slice(index+4);
    }
    arr.push(str);

    for (let i = 0; i<arr.length; i++) {
      out.push(<span key={i}>{arr[i]}</span>);
    }
    return out;
}


function FeedHeader(props) {
   if (!props.title) {
     return null;
   } else {
     const date = () => (props.date ? <div className="ukr-feed-date">{props.date}</div> : null);
     const h4 = () => props.subtitle ? <h4>{props.subtitle}</h4> : null;
     return (
       <header className="ukr-feed-header">

           <Date date={props.date} />
           <h3>{handleNewLines(props.title)}</h3>
           <H4 subtitle={props.subtitle} />
       </header>
     );
   }
}


export default FeedHeader;
