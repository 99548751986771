import React from 'react';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon'; import {FormInput, FormDate} from "../../../forms/forms.js";



class EditTweet extends React.Component {
  constructor(props) {
      super(props);
      const date = (this.props.tweetDate && (this.props.tweetDate != "0000-00-00")) ? new Date(this.props.tweetDate) : null;
      this.state = {tweetID: this.props.tweetID, tweetTitle: this.props.tweetTitle, twitterUser: this.props.twitterUser, tweetDate: date};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDate = this.handleDate.bind(this);
      this.videoRef = React.createRef();
  }

    handleDate(date) {
      this.setState({tweetDate: date});
    }

    handleChange(e) {
      const target = e.target;
      const value =  target.value;
      this.setState({[target.name]: value});
    }

    handleSubmit(e) {
        return;
        e.preventDefault();

    }

    render() {

        return (
                <div className="w3-container w3-section w3-display-container w3-white">
                      <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                          <Link click={[this.props.editTweet, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>
                      </div>
                     <form action={"/feed/editTweet/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                        <FormInput name="tweetID" label="Tweet ID" value={this.state.tweetID} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="tweetTitle" label="Platform" value={this.state.tweetTitle} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormInput name="twitterUser" label="Caption" value={this.state.twitterUser} maxWidth="" handleChange={this.handleChange}  color="w3-light-gray" validate=""  />
                        <FormDate name="tweetDate" label="Post Date" date={this.state.tweetDate} maxWidth="" handleChange={this.handleDate}  color="w3-light-gray" validate=""  />
                        <div className="w3-margin-top-24 w3-margin-bottom ">
                            <input type="submit" value="Edit Tweet" className="w3-button  w3-content w3-blue h55  w3-round"/>
                        </div>
                    </form>
                </div>
        );
      }
}


export default EditTweet;
