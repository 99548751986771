import React, { Component} from 'react'; import EditBar from './EditBar';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';import EditFbPost from '../Edit/EditFbPost';




class Row extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editFbPost: false};
        this.handleEditFbPost = this.handleEditFbPost.bind(this);

    }

    handleEditFbPost(fbPost) {
      this.setState({editFbPost: fbPost});
    }

    render() {

        if (!this.props.row || !this.props.rendered) {
          return null;
        } else if (this.state.editFbPost) {
          return <EditFbPost {...this.state.editFbPost}  editFbPost={this.handleEditFbPost}/>
        } else {
          return (
                <div className="ukr-feed-tweet" >
                <EditBar  user={this.props.user}  field="_fbPost" media={this.props.row} handler={this.handleEditFbPost} id={this.props.row.id} feedID={this.props.feedID}/>
                      <FacebookProvider appId="304383704997041">
                        <EmbeddedPost href={this.props.row.postUrl} width="550" />
                      </FacebookProvider>
                 </div>
          )
        }
    }

}

function FbPost(props) {
  if (!props.fbPosts) {
    return null;
  } else {
    return props.fbPosts.map((row, index) => <Row  row={row} key={index} user={props.user} feedID={props.feedID} rendered={props.rendered}/>);
  }
}


export default FbPost;
