import React from 'react';
import './batchMediaEditing.css';import {FormInput, FormSelect, FormDate} from "../../forms/forms.js";import Row from './Row';
import Button from '../../Buttons/Button'; import TagsBar from '../TagsBar';

/*
    <div className=""></div>
*/

class BatchTagsInFeeds extends React.Component {
  constructor(props) {
      super(props);
      this.state = {count: 50, startIndex: 0, rows: [], show: null, selectedTags: [], tagsFiltersView: false};
      //this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleRows = this.handleRows.bind(this);
      this.handleEdited = this.handleEdited.bind(this);
      this.handleTagsFilterView = this.handleTagsFilterView.bind(this);
      this.handleTagsFilter = this.handleTagsFilter.bind(this);

  }

  proppedRows = {batchImg: this.props.images, batchVideo: this.props.videos, batchAction: this.props.actions, batchFbPost: this.props.fbPosts, batchTweet: this.props.tweets};
  formActionsdArray = {batchImg: "/media/batchImages/", batchVideo: "/media/batchVideos/", batchAction: "/media/batchActions/", batchFbPost: "/media/batchFbPosts/", batchTweet:"/media/batchTweets/"};

  fixDates(row) {
      if (this.props.show == "batchVideo") {
        row.video_date =  (row.video_date && (row.video_date != "0000-00-00")) ? new Date(row.video_date) : null;
      } else if (this.props.show == "batchTweet") {
        row.tweetDate =  (row.tweetDate && (row.tweetDate != "0000-00-00")) ? new Date(row.tweetDate) : null;
      } if (this.props.show == "batchFbPost") {
        row.postDate =  (row.postDate && (row.postDate != "0000-00-00")) ? new Date(row.postDate) : null;
      } if (this.props.show == "batchAction") {
        row.actionDate =  (row.actionDate && (row.actionDate != "0000-00-00")) ? new Date(row.actionDate) : null;
      }
  }

  createRows(count, startIndex) {
    let proppedRows = this.proppedRows[this.props.show];
    if (this.state.selectedTags.length) {
        proppedRows = tagsFilteredRows(proppedRows, this.state.selectedTags);
    }
    const proppedRowsCount = proppedRows.length;
    let editingArray = proppedRows.slice(startIndex, Math.min(Number(startIndex) + Number(count), proppedRowsCount));
    let rowsArray = [];

    for (var i = 0; i<editingArray.length; i++) {
        let row = JSON.parse(JSON.stringify(editingArray[i]));;
        row.edited = false;
        this.fixDates(row);
        rowsArray.push(row);
    }
    this.setState({rows: rowsArray, count: count, startIndex: startIndex, totRows: proppedRowsCount});

  }

  handleChange(e) {
    const target = e.target;
    const value =  target.value;
   if (target.name == "count") {
      this.createRows(value, this.state.startIndex);
    } else if (target.name == "startIndex"){
      this.createRows(this.state.count, value);
    }
  }

  handleRows(field, value, index) {
    let rows = this.state.rows;
    rows[index][field] = value;
    this.setState({rows:rows});
  }

  handleEdited(value, index) {
    let rows = this.state.rows;
    rows[index].edited = value;
    this.setState({rows:rows});
  }

  handleTagsFilterView() {
    this.setState({tagsFiltersView: !this.state.tagsFiltersView});
  }


  handleTagsFilter(tag) {
      let tags = this.state.selectedTags;
      const index = tags.indexOf(tag);
      if (index < 0) {
        tags.push(tag);
      } else {
        tags.splice(index, 1);
      }
      this.setState({selectedTags: tags, notTagged: false});
      this.bacthMediaTimer = setTimeout(()=>this.createRows(this.state.count, this.state.startIndex), 100);


  }

/*
componentDidUpdate() {
  if (this.state.show != this.props.show) {
    this.setState({rows: [], count: 0, startIndex: 0, show: this.props.show});
  }
}
*/


  componentDidMount() {
    const batchOptions = ["batchImg", "batchVideo", "batchAction", "batchTweet", "batchFbPost"];
    if (batchOptions.includes(this.props.show)) {
        this.createRows(this.state.count, this.state.startIndex);
      }
  }

  componentWillUnmount() {
    if (this.bacthMediaTimer) clearTimeout(this.bacthMediaTimer);
  }


  render() {
    const batchOptions = ["batchImg", "batchVideo", "batchAction", "batchTweet", "batchFbPost"];
    if (!batchOptions.includes(this.props.show)) {
        return null;
      } else  {
        const proppedRowsCount = this.state.totRows;
        const tagsFilterBtnText = !this.state.tagsFiltersView ? "Show Tags filter" : "Hide Tags filter";
        return (

          <div className="w3-content-1300 w3-section-32 h100vh">
          <form action={this.formActionsdArray[this.props.show]} method="post" onSubmit={this.handleSubmit}>

              <div className="w3-section w3-row">
              <FormSelect name="startIndex" label="Start Index" value={this.state.startIndex} rangeStart={0} rangeEnd={proppedRowsCount -1} maxWidth="w3-col m3" handleChange={this.handleChange} color="w3-light-gray" validate="" />
              <FormSelect name="count" label="Number of feeds" value={this.state.count} rangeStart={0} rangeEnd={50} maxWidth="w3-col m3" handleChange={this.handleChange}  color="w3-light-gray" validate="" />
              <div className="w3-col m2 w3-container-32 w3-large w3-padding-16 w3-bold">{proppedRowsCount} Tot</div>
              <Button  text={tagsFilterBtnText} click={true} clickHandler={this.handleTagsFilterView}  color="a-btn-blue-1" size="a-btn-size" className="w3-col-m2 w3-right "/>
              </div>
              <TagsBar show={this.state.tagsFiltersView} close={this.handleTagsFilterView} tags={this.props.tags} selected={this.state.selectedTags} select={this.handleTagsFilter}/>
              {this.state.rows.map((row, index) => <Row {...row} key={row.id}  edit={this.handleEdited} editRow={this.handleRows} rowIndex={index} show={this.props.show}/>)}
              <div className="w3-margin-top-24 w3-margin-bottom ">
                  <input type="submit" value="Edit" className="w3-button  w3-content w3-blue h55 w100 w3-round"/>
              </div>
          </form>
          </div>
        );
      }


  }

}

function tagsFilteredRows(rows, selectedTags) {
  let out = [];
  for (let i=0; i<rows.length; i++) {
    const tagIDs = rows[i].selectedTags;
      if (tagIDs && (selectedTags.every((tag) => tagIDs.includes(tag)))) {
        out.push(rows[i]);
      }
  }
  return out;
}


export default BatchTagsInFeeds;
