import React from 'react';
import './batchTagsInFeeds.css';import {FormInput, FormSelect, FormDate} from "../../forms/forms.js";import Row from './Row';
import Button from '../../Buttons/Button'; import TagsBar from '../TagsBar';
/*
    <div className=""></div>
*/

class BatchTagsInFeeds extends React.Component {
  constructor(props) {
      super(props);
      this.state = {count: 50, startIndex: 0, rows: [], selectedTags: [], tagsFiltersView: false, notTagged: false};
      //this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleTags = this.handleTags.bind(this);
      this.handleEdited = this.handleEdited.bind(this);
      this.handleTagsFilterView = this.handleTagsFilterView.bind(this);
      this.handleTagsFilter = this.handleTagsFilter.bind(this);
      this.handleSetNoTagsFilter = this.handleSetNoTagsFilter.bind(this);
      this.focus = this.focus.bind(this);



  }

  proppedRows = {batchTags: this.props.feeds, batchImgTag: this.props.images, batchVideoTag: this.props.videos, batchActionTag: this.props.actions, batchTweetTag: this.props.tweets, batchFbPostTag: this.props.fbPosts};
  titleFieldArray = {batchTags: "title", batchImgTag: "caption", batchVideoTag: "video_caption", batchActionTag:"action_caption", batchTweetTag: "tweetTitle", batchFbPostTag: "postTitle"};
  urlFieldArray = {batchTags: null, batchImgTag: "src", batchVideoTag: "video_url", batchActionTag: "action_url", batchTweetTag: "tweetID", batchFbPostTag: "postUrl"};
  formActionsdArray = {batchTags: "/feed/batchFeedTagsEdit/", batchImgTag: "/tags/batchImagesTagsEdit/", batchVideoTag: "/tags/batchVideosTagsEdit/", batchFbPostTag: "/tags/batchFbPostsTagsEdit/", batchActionTag: "/tags/batchActionsTagsEdit/", batchTweetTag: "/tags/batchTweetsTagsEdit/"};
  focus(element) {
    if (element == "email") {
      this.emailRef.current.autofocus();
    }
  }
/*
  isTagChecked(tagID, idsArray) {
    if (!idsArray || !Array.isArray(idsArray) || !idsArray.length) return false;
    for (let i=0; i<idsArray.length; i++) {
      if (idsArray[i].id == tagID) {
        return true;
      }
    }
    return false;
  }
*/
  createTagsArray(tags, selectedIds) {

      let tagsArray = [];
      for (let i=0; i<tags.length; i++) {
        const tag = {id: tags[i].id, name: tags[i].name, checked: (selectedIds && Array.isArray(selectedIds)) ? selectedIds.includes(tags[i].id) : false};
        tagsArray.push(tag);
      }
      return tagsArray;
  }

  createRows(count, startIndex) {
    let proppedRows = this.proppedRows[this.props.show];
    if (this.state.notTagged) {
        proppedRows = noTagsRows(proppedRows);
    } else if (this.state.selectedTags.length) {
        proppedRows = tagsFilteredRows(proppedRows, this.state.selectedTags);
    }
    const titleField = this.titleFieldArray[this.props.show];
    const urlField = this.urlFieldArray[this.props.show];
    const proppedRowsCount = proppedRows.length;
    let editingArray = proppedRowsCount > count ? proppedRows.slice(startIndex, Math.min(Number(startIndex) + Number(count), proppedRowsCount)) :proppedRows;
    let rowsArray = [];

    for (var i = 0; i<editingArray.length; i++) {
        let row = {};
        row.title = editingArray[i][titleField];
        row.url = !urlField ? null : editingArray[i][urlField];
        row.id = editingArray[i].id;
        row.edited = false;
        row.tags = this.createTagsArray(this.props.tags, editingArray[i].selectedTags);
        rowsArray.push(row);
    }
    this.setState({rows: rowsArray, count: count, startIndex: startIndex, totRows: proppedRowsCount});

  }

  handleChange(e) {
    const target = e.target;
    const value =  target.value;
   if (target.name == "count") {
      this.createRows(value, this.state.startIndex);
    } else if (target.name == "startIndex"){
      this.createRows(this.state.count, value);
    }
  }

  handleEdited(value, index) {
    let rows = this.state.rows;
    rows[index].edited = value;
    this.setState({rows:rows});
  }

  handleTags(e, tagIndex, index) {
    let rows = this.state.rows;
    rows[index].tags[tagIndex].checked = e.target.checked;
    this.setState({rows:rows});

  }

  handleTagsFilterView() {
    this.setState({tagsFiltersView: !this.state.tagsFiltersView});
  }

  handleSetNoTagsFilter() {
    this.setState({notTagged: !this.state.notTagged, selectedTags: []});
    setTimeout(()=>this.createRows(this.state.count, this.state.startIndex), 100);
  }

  handleTagsFilter(tag) {
      let tags = this.state.selectedTags;
      const index = tags.indexOf(tag);
      if (index < 0) {
        tags.push(tag);
      } else {
        tags.splice(index, 1);
      }
      this.setState({selectedTags: tags, notTagged: false});
      this.bacthTagsTimer =  setTimeout(()=>this.createRows(this.state.count, this.state.startIndex), 100);


  }

componentDidUpdate() {
  /*if (this.state.show != this.props.show) {
    this.setState({rows: [], count: 50, startIndex: 0, show: this.props.show});
  }*/
  return;
}



  componentDidMount() {
    const batchOptions = ["batchTags", "batchImgTag", "batchVideoTag", "batchActionTag", "batchTweetTag", "batchFbPostTag"];
    if (batchOptions.includes(this.props.show)) {
        this.createRows(this.state.count, this.state.startIndex);
      }



  }

  componentWillUnmount() {
    if (this.bacthTagsTimer) clearTimeout(this.bacthTagsTimer);
  }


  render() {
    const batchOptions = ["batchTags", "batchImgTag", "batchVideoTag", "batchActionTag", "batchTweetTag", "batchFbPostTag"];
    if (!batchOptions.includes(this.props.show)) {
        return null;
      } else  {
        const proppedRowsCount = this.state.totRows;
        const tagsFilterBtnText = !this.state.tagsFiltersView ? "Show Tags filter" : "Hide Tags filter";
        const noTagsBtnText = this.state.notTagged ? "Show all" : "Show Not Tagged";
        return (

          <div className="w3-content-1300 w3-section-32 h100vh">
          <form action={this.formActionsdArray[this.props.show]} method="post" onSubmit={this.handleSubmit}>

              <div className="w3-section w3-row">
              <FormSelect name="startIndex" label="Start Index" value={this.state.startIndex} rangeStart={0} rangeEnd={proppedRowsCount -1} maxWidth="w3-col m3" handleChange={this.handleChange} color="w3-light-gray" validate="" />
              <FormSelect name="count" label="Number of feeds" value={this.state.count} rangeStart={0} rangeEnd={50} maxWidth="w3-col m3" handleChange={this.handleChange}  color="w3-light-gray" validate="" />
              <div className="w3-col m2 w3-container-32 w3-large w3-padding-16 w3-bold">{proppedRowsCount} Feeds</div>
              <Button  text={tagsFilterBtnText} click={true} clickHandler={this.handleTagsFilterView}  color="a-btn-blue-1" size="a-btn-size" className="w3-col-m2 w3-right "/>
              <Button  text={noTagsBtnText} click={true} clickHandler={this.handleSetNoTagsFilter}  color="a-btn-blue-1" size="a-btn-size" className="w3-col-m2 w3-right w3-margin-right"/>
              </div>
              <TagsBar show={this.state.tagsFiltersView} close={this.handleTagsFilterView} tags={this.props.tags} selected={this.state.selectedTags} select={this.handleTagsFilter}/>
              {this.state.rows.map((row, index) => <Row {...row} key={index} selectTags={this.handleTags} edit={this.handleEdited} rowIndex={index} show={this.props.show}/>)}
              <div className="w3-margin-top-24 w3-margin-bottom ">
                  <input type="submit" value="Edit Tags" className="w3-button  w3-content w3-blue h55 w100 w3-round"/>
              </div>
          </form>
          </div>
        );
      }


  }

}


function tagsFilteredRows(rows, selectedTags) {
  let out = [];
  for (let i=0; i<rows.length; i++) {
    const tagIDs = rows[i].selectedTags;
      if (tagIDs && (selectedTags.every((tag) => tagIDs.includes(tag)))) {
        out.push(rows[i]);
      }
  }
  return out;
}

function noTagsRows(rows) {
  let out = [];
  for (let i=0; i<rows.length; i++) {
      const tagIDs = rows[i].selectedTags;
      if (!tagIDs || !Array.isArray(tagIDs) || !tagIDs.length) {
        out.push(rows[i]);
      }
  }
  return out;
}


export default BatchTagsInFeeds;
