import React from 'react';
import './headerNoImg.css';

/*
    <div className=""></div>
*/

function HeaderNoImg(props) {
   if (false) {
     return null;
   } else {
     return (
       <header className="header-noImg">
          <img className="he-noImg-bkgImage" alt=""/>
           <div className="he-noImg-container">
                    <div className="he-noImg-left">
                            <h1 className="">{props.title}</h1>
                            <h3 className="he-noImg-descr">{props.description}</h3>
                            <p className="he-noImg-descr-date"> {props.date}</p>
                    </div>
          </div>
      </header>
     );
   }
}


export default HeaderNoImg;
