import ActionsButtons from './ActionsButtons';import ElementSelector from './ElementSelector'; import TypeSelector from './TypeSelector';
import TextTypeSelector from "./TextTypeSelector"; import BlockSelector from "./BlockSelector";import SetsSelector from "./SetsSelector";

function Header(props) {
    const borderTop = (props.touch === "moving") ? "dodgerblue" : "transparent";

    return (
      <div className="scarlet-title-outer" onTouchStart={(e)=>e.stopPropagation()}>
          <div className="scarlet-title-container" style={{height: props.height+"px"}}>
              <div className="a-pdS-10 a-font-white flx-btw  a-container-14 " style={{backgroundColor: "rgba(0,0,0,.4)", alignItems: "flex-start", width:"max-content", minWidth: "100%", borderTop:"3px solid "+borderTop}}>
                    <BlockSelector shared={props.shared} setHex={props.setHex} setRgb={props.setRgb} setHsl={props.setHsl}/>
                    <ElementSelector shared={props.shared}  setHex={props.setHex}  setRgb={props.setRgb} setHsl={props.setHsl}/>
                    <TextTypeSelector shared={props.shared} setHex={props.setHex}  setHsl={props.setHsl}/>
                    <TypeSelector shared={props.shared} setHex={props.setHex}  setHsl={props.setHsl} />
                    <SetsSelector shared={props.shared} setHex={props.setHex} setHsl={props.setHsl}/>
                    <ActionsButtons shared={props.shared} deleted={props.deleted} finalizeSave={props.finalizeSave} fetchMsg={props.fetchMsg}/>
              </div>
          </div>
      </div>
    );
}


export default Header;
