import React, {useState, useEffect} from 'react';import ScaleBlock from './ScaleBlock'; import DisplayColor from './DisplayColor';

// <div className=""></div>

function Row (props) {
    const tooltip = {};

    [tooltip.selectedToolTip, tooltip.setToolTip] = useState(false);
    const [render, setRender] = useState(false);
    useEffect(()=>{
        // if !scarlet the window is crolling, so we attach the scroll listner to the window.
        // if scarlet the window is not scrolling, instead the sections container and the scarlet container have their own independent scrolling, so we attach the scroll listner to the sections container directly
        if (props.scarlet) {
          document.getElementById("mappings").addEventListener("scroll", scroll);
          scroll(); // we need to call scroll on load otherwise the first element will not render until scrolled
        } else {
          window.addEventListener("scroll", scroll); return ()=> window.removeEventListener("scroll", scroll);
        }
      });

    const scroll = () => { // we check if the slider in in view port otwerwise we stop the autopilot
      const element = document.getElementById(props.type+"mappingRow");
      if (isNearViewport(element, props.scarlet) && !render) {
          setRender(true);
      }
    }

    if (!render) {
      return <div className="a-mrB-32 a-mrB-0-s cl-scale-container " id={props.type+"mappingRow"} style={{border: "none"}}></div>;
    } else {
      const color = props.options.color;
      const unit = ["s","l"].includes(props.type) ? "%" : ( (props.type == "h") ? "°" : "");
      const from = ["s","l"].includes(props.type) ? "0%" : ( (props.type == "h") ? "0°" : "0");
      const to = ["s","l"].includes(props.type) ? "100%" : ( (props.type == "h") ? "360°" : "255");

      return (

        <div className={" cl-scale-container "+(props.scarlet ? "a-section-20-s":"a-section-30-s")} id={props.type+"mappingRow"}>
                <div className="cl-scale-title-container ">
                      <h4 className={"w600 a-font-20 lh-1  noselect "+(props.scarlet?"a-font-15-s":"a-font-19-s")}>{props.name} Scale</h4>
                      <h5 className={"a-font-15 a-font-16-s a-mrT-6 a-mrT-12-s cl-scale-subtitle lh-1-3-s "+(props.scarlet ? "w3-hide":"")}>
                                <span className="w3-text-blue w3-bold">{color.hex}</span> has a {props.name.toLowerCase()} value of <span className="a-font-red w3-bold">{props.selectedValue+unit}</span>
                                <br/> The {props.name.toLowerCase()} scale is calculated varying only the  {props.name.toLowerCase()} component from <span className="a-font-green w3-bold ">{from}</span> to <span className="a-font-green w3-bold">{to}</span>.
                      </h5>
                      <DisplayColor color={color} type={props.type} />
                </div>
                 <div className="cl-scale-elements-cont" onScroll={()=>tooltip.selectedToolTip === false ? null : tooltip.setToolTip(false)}>
                       <div className="flx-btw cl-scale-elements-cont-width" >
                            {props.rows.map((row, index) => <ScaleBlock key={index} index={index} {...row} type={props.type} {...tooltip} selectedValue={props.selectedValue}  {...props.options}/>)}
                       </div>
                 </div>
        </div>


      );
    }
}

export default Row;

var isNearViewport = function (elem,scarlet) {
    if (!elem) return null;
    var bounding = elem.getBoundingClientRect();
    const mappingsContHe = document.getElementById("mappings").getBoundingClientRect().height;
    const hexHeight = scarlet ? 0.2 : 0.26;
    if (scarlet) {
      return (bounding.top <= (60 + window.innerWidth * hexHeight + mappingsContHe + 150));
    } else {
      return (bounding.top <= (window.innerHeight * 1.3));
    }

  };
