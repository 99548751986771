import React, {useState} from 'react'; import Button from '../../Buttons/Button'; import {DivSelect, FormInput} from '../../forms/forms';
import BtnColors from './BtnColors'; import BtnSizes from './BtnSizes';import  {arrayToObj, arrayToSingleObj} from '../../indexFunctions.js';

/*
    <div className=""></div>
*/

class ButtonsSamples extends React.Component {
  constructor(props) {
      super(props);
      this.state = {rows:[], index: 0, colorsView: false, colors: [], lists: []};
      this.handleField = this.handleField.bind(this);
      this.newButton = this.newButton.bind(this);
      this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
      this.handleSave = this.handleSave.bind(this);
  }



    newButton() {
        let rows = this.state.rows;
        const length = rows.length;
        let button = {name:"New Button "+(length+1),style:"a-btn-filled", colorID:"1",type: "text", borderRad: "a-btn-brdRad-15", fontWeight: "a-btn-weight-500", fontSize: "a-btn-font-14",
        size: {text:"a-btn-size", icon:"a-btn-icon",iconText: "a-btn-icon-text", name: "Default"},  height: "a-btn-height-40", container: "a-btn-container-20"};
        rows.push(button);
        this.setState({rows: rows, index: rows.length-1});

    }



    handleField(field, value) {
        let rows = this.state.rows;
        rows[this.state.index][field] = value;
        rows[this.state.index].updated = "updated";
        this.setState({rows: rows});
        if (field == "colorID") {
          this.setState({colorsView: false});
        }
    }

    handleDeleteBtn() {
        let rows = this.state.rows;
        const button = rows[this.state.index];
        if (button.id) {
          rows[this.state.index].updated = "deleted";
        } else {
          rows.splice(this.state.index, 1);
        }
        this.setState({rows: rows, index:0});
    }




    getColor(id) {

      const colors = this.state.colors;
      for (let i=0; i < colors.length; i++) {
        if (colors[i].id == id) {

          return colors[i];
        }
      }
      console.log(colors[0]);
      return colors[0];
    }


    finalizeSave(result) {
      const buttons = ["id", "name","type","borderRad","style","fontWeight","container","height","fontSize", "colorID","updated"];
      this.props.buttons = arrayToObj(result, buttons);
      this.setState({rows: this.props.buttons});
      alert("saving complete");
    }

    handleSave() {

      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(this.state.rows),};
      fetch("/menu/buttons", data).then((res) => res.json()).then((result)=>{ this.finalizeSave(result) }, (error) => alert("error in saving"));
    }

    buildTestData() {
      const data = JSON.parse('{"colors":[{"id":"1","name":"Alice blue","color":{"hex":"#f0f8ff","rgb":{"r":"240","g":"248","b":"255"},"hsl":{"h":"208","s":"100","l":"97"},"hsv":{"h":208,"s":6,"v":100,"a":1}},"list":"1"},{"id":"2","name":"Amber","color":{"hex":"#ffbf00","rgb":{"r":"255","g":"191","b":"0"},"hsl":{"h":"45","s":"100","l":"50"},"hsv":{"h":45,"s":100,"v":100,"a":1}},"list":"12"},{"id":"3","name":"antique white","color":{"hex":"#faebd7","rgb":{"r":"250","g":"235","b":"215"},"hsl":{"h":"34","s":"78","l":"91"},"hsv":{"h":34,"s":14,"v":98,"a":1}},"list":"1"},{"id":"4","name":"aqua","color":{"hex":"#00ffff","rgb":{"r":"0","g":"255","b":"255"},"hsl":{"h":"180","s":"100","l":"50"},"hsv":{"h":180,"s":100,"v":100,"a":1}},"list":"1"},{"id":"5","name":"Aqua Ap","color":{"hex":"#039e8f","rgb":{"r":"3","g":"158","b":"143"},"hsl":{"h":"174","s":"96","l":"32"},"hsv":{"h":174,"s":98,"v":62,"a":1}},"list":"11"},{"id":"6","name":"aquamarine","color":{"hex":"#7fffd4","rgb":{"r":"127","g":"255","b":"212"},"hsl":{"h":"160","s":"100","l":"75"},"hsv":{"h":160,"s":50,"v":100,"a":1}},"list":"1"},{"id":"7","name":"azure","color":{"hex":"#f0ffff","rgb":{"r":"240","g":"255","b":"255"},"hsl":{"h":"180","s":"100","l":"97"},"hsv":{"h":180,"s":6,"v":100,"a":1}},"list":"1"},{"id":"8","name":"Azure Ap","color":{"hex":"#027fff","rgb":{"r":"2","g":"127","b":"255"},"hsl":{"h":"210","s":"100","l":"50"},"hsv":{"h":210,"s":99,"v":100,"a":1}},"list":"11"},{"id":"9","name":"beige","color":{"hex":"#f5f5dc","rgb":{"r":"245","g":"245","b":"220"},"hsl":{"h":"60","s":"56","l":"91"},"hsv":{"h":60,"s":10,"v":96,"a":1}},"list":"1"}],"lists":[{"id":"11","name":"Apple"},{"id":"1","name":"Css Colors"},{"id":"12","name":"Others"}]}');

      this.setState({colors: data.colors, lists: data.lists});
    }

    finalizeSaveColors(result) {
      this.setState({rows: result.colors, lists: result.lists});
    }

    fetchColors() {
      if (window.testMode) {
        this.buildTestData();
      } else {
        const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
        fetch("/colors/getColors/", data).then((res) => res.json()).then((result)=>{ this.finalizeSaveColors(result) }, (error) => alert("error fetching colors"));
      }

    }

    componentDidMount() {
      const buttons = this.props.buttons;
      this.fetchColors();
      if (buttons && Array.isArray(buttons)) {
        this.setState({rows: buttons});
      }

    }

    render() {

        const rows = this.state.rows;
        const index = this.state.index;
        const btn = rows[index];
        const selectedColor = btn ? this.getColor(btn.colorID) : null;
        const colorClass = btn && selectedColor ? "a-btn-"+selectedColor.name.toLowerCase().replace(/\s/g , "-") : "";
        const showBtn = this.state.rows.length;
        const styleOptions = [["Filled", "a-btn-filled"],["Filled Dark", "a-btn-filled-dark"],["Text on Hover","a-btn-textOnHover"],["Border on hover","a-btn-bordOnHover"],["HW","a-btn-HW"],["Bordered","a-btn-bordered"]];
        const typeOptions = [["Text","text"],["Icon","icon"],["Text + Icon", "iconText"]];
        const borderRadOptions = [["0%", "a-btn-brdRad-0"],["5%","a-btn-brdRad-5"],["10%","a-btn-brdRad-10"], ["15%","a-btn-brdRad-15"], ["20%","a-btn-brdRad-20"], ["25%","a-btn-brdRad-25"], ["30%","a-btn-brdRad-30"], ["35%","a-btn-brdRad-35"], ["40%","a-btn-brdRad-40"], ["45%","a-btn-brdRad-45"], ["50%","a-btn-brdRad-50"]];
        const weightOptions = [[400, "a-btn-weight-400"],[500, "a-btn-weight-500"],[600, "a-btn-weight-600"],[700, "a-btn-weight-700"]];
        const containerOptions = [[5,"a-btn-container-5"],[10,"a-btn-container-10"],[15,"a-btn-container-15"],[20,"a-btn-container-20"],[25,"a-btn-container-25"],[30,"a-btn-container-30"],[35,"a-btn-container-35"],[40,"a-btn-container-40"],[45,"a-btn-container-45"],[50,"a-btn-container-50"],[55,"a-btn-container-55"],[60,"a-btn-container-60"],[65,"a-btn-container-65"],[70,"a-btn-container-70"]];
        const heightOptions = [[20, "a-btn-height-20"],[22, "a-btn-height-22"],[24, "a-btn-height-24"],[26, "a-btn-height-26"],[28, "a-btn-height-28"],[30, "a-btn-height-30"],[35, "a-btn-height-35"],[40, "a-btn-height-40"],[45, "a-btn-height-45"],[50, "a-btn-height-50"],[55, "a-btn-height-55"],[60, "a-btn-height-60"],[65, "a-btn-height-65"],[70, "a-btn-height-70"],[75, "a-btn-height-75"]];
        let fontSizeOptions = [["default", null]];
        for (let i=11; i<31; i++) {fontSizeOptions.push([i+ " px", "a-btn-font-"+i]);}
        if (!btn) {
          return (
              <div className="w3-content-1300 w3-section-32 h100vh">
                    <Button click={this.newButton} text="New button" color="a-btn-azure" size="a-btn-size-4" filling="a-btn-filled"/>
              </div>
          );
        } else {
          return (
            <div className="w3-content-1300 w3-section-32 h100vh">
                    <div className="w3-section-48">
                             <div className="flex-cen-start w3-section">
                                 <Button click={()=>this.setState({colorsView: !this.state.colorsView})} text={!showBtn ? null : selectedColor.name} className="w3-margin-right" color={!showBtn ? null : colorClass} size="a-btn-size-4" filling="a-btn-filled"/>
                                 <Button click={this.newButton} text="New button" color="a-btn-blue-1" size="a-btn-size-4" filling="a-btn-filled"/>
                             </div>
                             <div className="flx-btw w3-section-32">
                                   <DivSelect value={btn.type} options={typeOptions} handleOptionClick={(value)=>this.handleField("type", value)}
                                   defs="form-round-Lab-Med" label="Button Type" defColors="form-col-orange"   margin="none" className="mw-150" noIcon={true}/>
                                   <DivSelect value={btn.borderRad} label="Border Radius" options={borderRadOptions} handleOptionClick={(value)=>this.handleField("borderRad", value)}
                                   defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-150"noIcon={true}/>
                                   <DivSelect value={btn.style} label="Button Style" options={styleOptions} handleOptionClick={(value)=>this.handleField("style", value)}
                                   defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-150"noIcon={true}/>
                                   <DivSelect value={btn.fontWeight} label="Font Weight" options={weightOptions} handleOptionClick={(value)=>this.handleField("fontWeight", value)}
                                   defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-130"noIcon={true}/>
                                   <DivSelect value={btn.container} label="Container" options={containerOptions} handleOptionClick={(value)=>this.handleField("container", value)}
                                   defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-130"noIcon={true}/>
                                   <DivSelect value={btn.height} options={heightOptions} label="Height" handleOptionClick={(value)=>this.handleField("height", value)}
                                   defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-120"noIcon={true}/>
                                   <DivSelect value={btn.fontSize} options={fontSizeOptions} label="Font Size"  handleOptionClick={(value)=>this.handleField("fontSize", value)}
                                   defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-130"noIcon={true}/>
                                   <FormInput defs="form-round-Lab-Med" name="name" value={btn.name} key={"name"+index} label="Title" defColors="form-col-orange"
                                   className="mw-130" handleChange={(e)=>this.handleField("name", e.target.value)}/>

                             </div>
                             <BtnColors setColor={this.handleField} show={this.state.colorsView} colors={this.state.colors}/>
                             <BtnSizes rows={rows} selectBtn={(i)=>this.setState({index: i})} selected={index} colors={this.state.colors}/>

                                 <div className="w3-section-64 w3-center ">
                                      <Button  text="Save" click={this.handleSave} color="a-btn-blue-1"  className="w3-margin-left-48 mw-250" size="a-btn-size-4"/>
                                      <Button  text="delete" click={this.handleDeleteBtn} color="a-btn-blue-1"  className="w3-margin-left-48 mw-250" size="a-btn-size-4"/>
                                 </div>
                    </div>
            </div>
          );
        }

      }

}

export default  ButtonsSamples;
//

/*


<DivSelect value={selected.style[btnIndex]} label="Button Style" options={styleOptions} handleOptionClick={(value)=>set[btnIndex].style(value)}
defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-150"noIcon={true}/>
<DivSelect value={selected.weight[btnIndex]} label="Font Weight" options={weightOptions} handleOptionClick={(value)=>set[btnIndex].weight(value)}
defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-130"noIcon={true}/>
<DivSelect value={selected.container[btnIndex]} label="Container" options={containerOptions} handleOptionClick={(value)=>set[btnIndex].container(value)}
defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-130"noIcon={true}/>
<DivSelect value={selected.height[btnIndex]} options={heightOptions} label="Height" handleOptionClick={(value)=>set[btnIndex].height(value)}
defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-120"noIcon={true}/>
<DivSelect value={selected.fontSize[btnIndex]} options={fontSizeOptions} label="Font Size"  handleOptionClick={(value)=>set[btnIndex].fontSize(value)}
defs="form-round-Lab-Med" defColors="form-col-orange" iconColor="white"  margin="none" className="mw-130"noIcon={true}/>


*/
