function DisplayColor(props) {

  const colorName = ["h","s","l"].includes(props.type) ? "hsl" : "rgb";
  const color = props.color[colorName];
  const [col1, col2, col3] = (colorName === "hsl") ? ["h","s","l"] : ["r","g","b"];
  const unit = (colorName === "hsl") ? "%" :  "";

  return (
      <div className="flex-cen-start cl-scale-disp-col w3-hide-small">
          <div className="w3-padding-right-6">{colorName.toUpperCase()} </div>
          <div className="a-font-26 w3-margin-bottom-2"> (</div>
          <div className={(col1 == props.type) ? "cl-scale-bord-disp-act" : "cl-scale-bord-disp"}>{color[col1]}</div>
          <div className="">,</div> <div className={(col2 == props.type) ? "cl-scale-bord-disp-act" : "cl-scale-bord-disp"}>{color[col2]+unit}</div>
          <div className="">,</div> <div className={(col3 == props.type) ? "cl-scale-bord-disp-act" : "cl-scale-bord-disp"}>{color[col3]+unit}</div>
          <div className="a-font-26 w3-margin-bottom-2"> )</div>
      </div>
  );
}

export default DisplayColor;
