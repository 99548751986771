import DropLi from './DropLi';

function DropSection(props) {
    const isTablet = props.media.isTablet;
    if (!props.buttons || !props.buttons.length) {
      return null;
    } else {
      const cols = (isTablet && props.dropColT) ? props.dropColT : (props.dropColD ? props.dropColD : 1);
      const width = {width: (100 / cols)+"%"};
      const section = (props.dropColD > 1) ? "a-btn-drop-sect-row" : "";

      return (
          <div className={section} style={width}>
              <Title title={props.title} dropColD={props.dropColD}/>
              <div className="">
                {props.buttons.map((li, index) => <DropLi {...li} key={index} clickHandler={props.clickHandler}/>)}
              </div>
          </div>
      );
    }
}

const Title = (props) => (props.title && props.dropColD > 1) ? <h3 className="a-btn-drop-sect-title truncate">{props.title}</h3> : null;

export default DropSection;
