import React, {useEffect, useState} from 'react';
import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex,contrastHexColor, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';

const CardRow = (props)=> {
  const [touch, setTouch] = useState(null);
  const {select, selected, cards, selectedCard, selectCard, index, setSclColors, sclColors, setCardEl, cardEl, card, contrastBkg, selectType} = props.shared;

  const handleTouchStart = (e)=> {
    e.stopPropagation(); // Card row occupies the whole space, so touchstart should not propagate to scarlet component and initiate the bkg select
    setTouch({x:e.changedTouches[0].clientX, y: e.changedTouches[0].clientY});e.stopPropagation();
  };

  const handleTouchEnd=(e)=>{
    e.stopPropagation();
    if (touch) setTouch(null);
  };

  const handleTouchMove = (e)=> {
    e.stopPropagation();
    const x = e.changedTouches[0].clientX;
    const y = e.changedTouches[0].clientY;
    if (!touch || (Math.abs(y - touch.y) > 20)) {
      return;
    } else {
      props.cancelBkgTouch(); // touch is moving => it's not a bkg select but a scrolling => we inform scarlet component to cancel the bkg select timeout
      const rightAllowed = selectedCard < (cards.length - 1);
      const leftAllowed = selectedCard > 0;
      if (((x - touch.x) > 50) && rightAllowed) {
        setTouch(null); // When we select the prex/next slide we set touch to null otherwise until touch end we will continue to slide left right with a single operation
        selectCard(selectedCard + 1);
      } else if (((touch.x - x) > 60) && leftAllowed) {
        setTouch(null);  // same as before
        selectCard(selectedCard - 1);
      }
    }

  };

  if (!cards) {
    return null;
  } else {
    const {bkg, txt, txt2} = cards[selectedCard];
    const select = (el, value)=> { selectType(value,"cards"); el.stopPropagation();};
    return (
      <div className="a-mrL-32 a-mrR-32 w3-display-container w3-animate-opacity-04" key={selectedCard+"selectedCard"} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <div className=" a-pdS-32 a-container-20 w3-display-container a-flex-col"  style={{backgroundColor: hslToCss(bkg), color: txt}} onClick={(e)=>select(e,"bkg")}>
                <div className="a-font-18 noselect w500 lh-1 a-mrB-12 fit-content" onClick={(e)=>select(e,"txt")} style={{color: txt}}> A card <b>{selectedCard}</b> dummy title </div>
                <div className="a-font-15 noselect w500 lh-1 fit-content" onClick={(e)=>select(e,"txt2")} style={{color: txt2}}> A card <b>{selectedCard}</b> dummy subttile </div>
                <SvgIcon icon="delete" click={props.deleteCard} color={"white"} className="a-mrR-8 a-mrT-8 w3-display-topright" size="20px" />
            </div>
      </div>
    );
  }


}

export default CardRow;
