import React from 'react';
import './centerSide.css';
import CnnRecap from '../CnnArt/Side/CnnRecap';
import LinksBox from '../Cards/LinksBox';
/*

*/

function CenterSide(props) {
   if (false) {
     return null;
   } else {

     return (
       <div className="ukr-side-cont">
            <CnnRecap title="Timeline of the Invasion" rows={props.recapRows}/>

       </div>
     );
   }
}


export default CenterSide;
