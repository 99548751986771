import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../../forms/forms.js";

/*
    <div className=""></div>
*/
function Row(props) {
  function handleChange(e) {
    props.selectTag(e, props.i);

  }

  return (
    <div className="w3-col m3 l3 trucate">
        <FormCheckBox name={"tag"+props.id} label={props.name} checked={props.checked} handleChange={handleChange} />
      </div>
  )

}


function NewFeedTags(props) {
   if (!props.tags) {
     return null;
   } else {
     return (
       <div className="w3-row">
       {props.tags.map((row,index) => <Row key={index} i={index} {...row} selectTag={props.selectTag}/>)}
       </div>
     );
   }
}


export default NewFeedTags;
