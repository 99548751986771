import React, {useState, useEffect, useRef} from 'react';
import Variation from './Variation';

function MappingsHeader(props) {
    let [selected, setSelected] = useState("h");
    let [stuck, setSticky] = useState(props.scarlet); // for the first rendering stuck is set to the bol value of props.scarlet because when scarlet is true the menu should be already starting in the stuck position
    const [hide, setHide] = useState(true);
    const [opacity, setOpacity] = useState(false);
    const [touchStartY, setTouchStartY] = useState(null);
    const hideTimer = useRef(null);
    const opacityTimer = useRef(null);
    const isPhone = window.innerWidth < 734;
    const hexHeight = props.scarlet ? 0.2 : (isPhone ? 0.26 : 0);
    const menuHeight = isPhone ? 33 : 58;
    const scrollMargin = isPhone ? 20 : 32;
    const topBarHeight = props.topBarHeight;
    const mapHeShare = {hexHeight,menuHeight,scrollMargin,topBarHeight, selected, setSelected, setSticky, stuck};

    useEffect(()=>{
        // if !scarlet the window is scrolling, so we attach the scroll listner to the window.
        // if scarlet the window is not scrolling, instead the sections container and the scarlet container have their own independent scrolling, so we attach the scroll listner to the sections container directly
        if (props.scarlet) {
          const el = document.getElementById("mappings");
          el.addEventListener("scroll", scroll);
          return ()=> {if (el) el.removeEventListener("mappings", scroll);}

        } else  {
          window.addEventListener("scroll", scroll);
          return ()=> window.removeEventListener("scroll", scroll);
        }
      });


    useEffect(()=>{setSelected("h"); if (props.scarlet) {setSticky(true);} else {setSticky(false);}},[props.scarlet]); // Every time props.scarlet changes we need to reset the stuck state before the scrolling starts, true is scarlet is true or false otherwise. Also we reset selected to "h" since mappings scrolling is set to top at every scarlet change

    useEffect(()=>{ // Adjust the orizzontal scrolling when selected variation changes, in order to keep it always visible
        if (!props.scarlet || (props.scarlet && !hide))  {  // The effect should noy run when the menu is hiddem: if scarlet is true hide must be false
          var element = document.getElementById("variation"+selected);var container = document.getElementById("mappings-scrollable-heading");
          const right = element.getBoundingClientRect().right; const left = element.getBoundingClientRect().left;
          if (right > window.innerWidth)
          {container.scrollLeft += ( right + 24 -  window.innerWidth)}
          else if (left < 20)
          {container.scrollLeft += (left - 20)}
        }
      },[props.color.hex, selected, props.scarlet, hide]);

    const variations = [{name: "Hue", type: "h"},{name: "Saturation", type: "s"},{name: "Lightness", type: "l"},{name: "Red", type: "r"},{name: "Green", type: "g"},
    {name: "Blue", type: "b"}];

    const scroll = () => {
      if (props.scarlet) {
        if (hideTimer.current) clearTimeout(hideTimer.current);
        if (opacityTimer.current) clearTimeout(opacityTimer.current);
        if (hide === "just hidden") {
          setHide(true);
          return;
        } else if (hide) {
          setHide(false);
        }
        if (opacity) setOpacity(false);
        hideTimer.current = setTimeout(()=>setHide("just hidden"), 5000);
        opacityTimer.current = setTimeout(()=>setOpacity(true), 3500);
        selectOnScroll();
      } else {
        var container = document.getElementById("mappings-scrollable-heading");
        if (container) {
            const top = container.getBoundingClientRect().top;
            if (top < (topBarHeight + window.innerWidth * hexHeight + 1)) {
              if (!stuck) setSticky(true);
              selectOnScroll();
            } else if ((top >= (topBarHeight + window.innerWidth * hexHeight + 1)) && stuck) {
              setSticky(false);
              setSelected("h");
            }
          }
      }
    }

    const menuScrolling = (e)=> { // Varition menu orizzontal scrolling => restart the menu closing timer toa void to close it while being scrolled
      if (hideTimer.current) clearTimeout(hideTimer.current);
      if (opacityTimer.current) clearTimeout(opacityTimer.current);
      if (opacity) setOpacity(false);
      hideTimer.current = setTimeout(()=>setHide("just hidden"), 5000);
      opacityTimer.current = setTimeout(()=>setOpacity(true), 3500);
    }

    const selectOnScroll = ()=> {// updates the selected menu element based on the scroll position
        const rect = (id) => {
          const el = document.getElementById(id+"mappingRow");
          if (el) {
            const rect = el.getBoundingClientRect();
            return {top: rect.top, height: rect.height}} else {return {};}
        };
        const he = document.getElementById("mappings").getBoundingClientRect().height;
        const types = ["h","s","l","r","g","b"];
        for (let i=0; i<types.length; i++) {
            const {top, height} = rect(types[i]); // bounding rect of each row
            if (isPhone) {
                const mappingsContCenter = topBarHeight + window.innerWidth * hexHeight + (props.scarlet ? (he / 2) : ((window.innerHeight - topBarHeight - window.innerWidth * hexHeight) / 2));
                if ((top <= mappingsContCenter) && (top > (((mappingsContCenter - height) < 0 ) ? (mappingsContCenter - height) :( topBarHeight + (window.innerWidth * hexHeight))))) {
                    if (selected != types[i]) setSelected(types[i]);
                }
            } else {
              if (top >= (topBarHeight+menuHeight) && top<(topBarHeight+menuHeight+scrollMargin+height)) {
                if (selected != types[i]) setSelected(types[i]);
              }
            }
        }
    };

    const border = stuck ? " map-scr-heading-brd scrollable-heading-stuck-bkg " : "";
    const menuPadding = stuck ? (props.scarlet ? " a-pdS-9-s " : " a-pdS-11-s "): " a-pdS-12-s ";
    const opacityEffect = (opacity && props.scarlet) ? " dissolve-effect ": "";
    const hideTitle = props.scarlet ? "w3-hide" : "";


    const touchMove = (e)=> {
      if (e.touches.length > 1) {
        return;
      } else {
        const y = e.changedTouches[0].clientY;
        if (y - touchStartY > 20) {
          if (hideTimer.current) clearTimeout(hideTimer.current);
          if (opacityTimer.current) clearTimeout(opacityTimer.current);
          if (opacity) setOpacity(false);
        } else if (y - touchStartY < -20) {
          if (hideTimer.current) clearTimeout(hideTimer.current);
          if (opacityTimer.current) clearTimeout(opacityTimer.current);
          if (opacity) setOpacity(false);
          if (!hide) setHide(true);
        }
      }
    }


     if (hide && props.scarlet) {
       return null;
     } else {
       return (
           <>
               <div className="">
                     <h3 className={"w3-center  a-font-40 no-phone  noselect "+hideTitle}>Variations scales for {props.color.hex}</h3>
                     <h3 className={"w3-center lh-1-2 phone-only noselect "+hideTitle}>
                          <span className="a-vw-font-9-5-s lh-1  a-font-teal lh-1 noselect">Variations scales </span><br/>
                          <span className="a-font-18  a-font-dodgerblue lh-1 w600 noselect">For {props.color.hex}</span>
                    </h3>
               </div>
               <div className={" mappings-scrollable-heading a-container-16 a-mrB-40 a-mrB-12-s lh-1 noselect  "+border+(props.scarlet ? "": " a-mrT-12-s") +
                menuPadding+opacityEffect} id="mappings-scrollable-heading" onScroll={menuScrolling} onTouchStart={(e)=>setTouchStartY(e.changedTouches[0].clientY)} onTouchMove={touchMove} >
                      <h4 className="flex-cen-cen " style={{width: "max-content"}}>{variations.map((row)=><Variation {...row} mapHeShare={mapHeShare} key={row.type} scarlet={props.scarlet}  />)}</h4>
               </div>
           </>
       )
     }
}




export default MappingsHeader;
