import React, {useState, useEffect, useRef} from 'react';import { FormInput} from '../../../../../forms/forms';
import SvgIcon from '../../../../../SvgIcon';

const SvEdit = (props) => {
  const myRef = React.createRef();
  const isMobile = window.innerWidth < 734;
  useEffect(() => (!props.editSV) ? null : focus(), [props.editSV]);
  const focus = () => myRef.current.autofocus();
  const setRedBackground = () => myRef.current.setRedBackground();
  const [error, setError] = useState(false);
  const timer = useRef(null);
  useEffect(() => {if (error) { if (timer.current) clearTimeout(timer.current); timer.current = setTimeout(()=>setError(false), 7000);setError("Only numbers 0-100!");}}, [error]);

  const svHandler = (e) => {
    let value = e.target.value, name = e.target.name;
    if (value === "") { value = 0;}
    let int = parseInt(value);
    if (name === "s" && int == 0) {int = 0.1};
    if (!isNaN(int) && (int >= 0) && (int <= 100)) {
      props.hsv[name] = int;
      props.handleHsv(props.hsv, true);
      setError(false);
    } else {
      myRef.current.setRedBackground();
      setError(true);
    }

  };
  if (!props.editSV || isMobile) {
    return null;
  } else {


    return (
            <div className="w3-display-middle cl-sv-edit-cont flex-cen-cen">
                        <div className="w3-display-left w3-margin-left cl-zi-100">{props.editSV.toUpperCase()}</div>
                        <FormInput name={props.editSV} value={Math.round(props.hsv[props.editSV])} defs="cl-sv-imp-defs cl-range-input-col" handleChange={svHandler} blur={props.blur} ref={myRef}/>
                        <div className="cl-sv-edit-done-ic pointer w3-display-right w3-margin-right" ><SvgIcon icon="done" color="green"/></div>
                        <Error error={error} />
            </div>
    );
  }
};

function Error(props) {
  if (!props.error) {return null} else {return <div className="cl-inp-sv-col-err flex-cen-start"><SvgIcon icon="error" size="40px" color="red"/><div className="w3-margin-left-8">{props.error}</div></div>;}
}

export default SvEdit;
