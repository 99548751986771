

const Level = (props) => {
    let level = null; let textColor = "";
    if (props.type == "h") {
      level = props.hsl.h - props.selectedValue;
      textColor = level > 0 ? "w3-text-green" : (level ? "w3-text-red" : "");
      level = level > 0 ? ("+" + level) : (level ? level : "");
      level += (level ? "°" : "");
    } else if (["s","l"].includes(props.type)) {
      level = props.hsl[props.type] - props.selectedValue;
      textColor = level > 0 ? "w3-text-green" : (level ? "w3-text-red" : "");
      level = level > 0 ? ("+" + level) : (level ?  level : "");
      level += (level ? "%" : "");
    } else {
      level = props.rgb[props.type] - props.selectedValue;
      textColor = level > 0 ? "w3-text-green" : (level ? "w3-text-red" : "");
      level = level > 0 ? ("+" + level) : (level ? + level : "");
    }
    return (
      <div className={"cl-variant-level noselect "+textColor}>
              {level}
      </div>
    );
}

export default Level;
