import React, {useEffect, useState, useRef} from 'react';
import Button from '../../../../../Buttons/Button';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import MovingHandle from './MovingHandle';import Header from '../Header/Header'; import Body from './Body';
/*
    <div className=""></div>
*/

function ScarletInner(props) {
  const {sclColors, setSclColors, selected, select, selectedCard, selectCard,cardEl, setCardEl,selectedBtn, selectBtn,
    btnEl, setBtnEl,selectedText, selectText,textEl, setTextEl,index, setIndex, cards, buttons, texts, selectType, sample, txt, btnBkg, card} = props.shared;
  const [touch, setTouch] = useState(false);

  let scrollTop = useRef(0);
  const scroll = ()=>{
    if (scrollTop.current !== false) {document.documentElement.scrollTop = scrollTop.current;}
  };


  const bkgRgb = hexToRgb(sclColors[index].bkg);

  const boxShadow = (touch === "moving") ? `0px -2px 8px rgba(30,144,255,.8)` : `0px -2px 5px rgba(${bkgRgb.r},${bkgRgb.g},${bkgRgb.b}, .6)`;
  return (
    <div className="w3-display-container noselect" style={{height: "49px",overflow:"visible", zIndex: "20", boxShadow: boxShadow}}>
        <MovingHandle setHeight={props.setHeight} touch={touch} setTouch={setTouch}/>
        <div className="p-rel" style={{height: props.height+"px"}}>
            <Header shared={props.shared} touch={touch} height={props.height} finalizeSave={props.finalizeSave} fetchMsg={props.fetchMsg} cancelBkgTouch={props.cancelBkgTouch} setHex={props.setHex} setHsl={props.setHsl} setRgb={props.setRgb} deleted={props.deleted}/>
            <Body shared={props.shared} setHex={props.setHex} setRgb={props.setRgb} animateBkg={props.animateBkg} setHsl={props.setHsl} height={props.height} cancelBkgTouch={props.cancelBkgTouch}/>
        </div>
    </div>
  );
}

export default ScarletInner;
