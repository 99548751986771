import React from 'react';
import './editFeed.css';import Link from '../../../Link'; import SvgIcon from '../../../SvgIcon';
import './newFeed.css';import {FormInput, FormSelect, FormDate} from "../../../forms/forms.js";  import Images from '../AddMediaBatch/Images'; import Videos from '../AddMediaBatch/Videos';
import Texts from '../AddMediaBatch/Texts'; import Actions from '../AddMediaBatch/Actions'; import NewFeedTags from '../AddMediaBatch/NewFeedTags'; import Tweets from '../AddMediaBatch/Tweets';
import FbPosts from '../AddMediaBatch/FbPosts'; import StoredMedia from './StoredMedia/StoredMedia';import Button from '../../../Buttons/Button';
/*
    <div className=""></div>
*/





class EditFeed extends React.Component {
  constructor(props) {
      super(props);
      this.state = {title: "", subtitle: "", date:new Date(), credit:"", imgCount: 0, images:[], tweetsCount:0, tweets:[],fbPostsCount:0, fbPosts:[], videosCount: 0,
       videos:[], textsCount: 0, texts:[], actionsCount: 0, actions:[], tags: null, storedMediaView: false, storedMedia: {images: [], videos: [], actions: [], tweets: [], fbPosts: []}};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleDate = this.handleDate.bind(this);
      this.resetChange = this.resetChange.bind(this);
      this.handleImageCount = this.handleImageCount.bind(this);
      this.handleVideosCount = this.handleVideosCount.bind(this);
      this.handleTextsCount = this.handleTextsCount.bind(this);
      this.handleActionsCount = this.handleActionsCount.bind(this);
      this.handleTweetsCount = this.handleTweetsCount.bind(this);
      this.handleFbPostsCount = this.handleFbPostsCount.bind(this);
      this.handleTweets = this.handleTweets.bind(this);
      this.handleTags = this.handleTags.bind(this);
      this.handleImages = this.handleImages.bind(this);
      this.handleVideos = this.handleVideos.bind(this);
      this.handleText = this.handleText.bind(this);
      this.handleActions = this.handleActions.bind(this);
      this.handleFbPosts = this.handleFbPosts.bind(this);
      this.handleStoredMedia = this.handleStoredMedia.bind(this);
      this.handleStoredMediaView = this.handleStoredMediaView.bind(this);
      this.focus = this.focus.bind(this);
      this.alertHandler = this.alertHandler.bind(this);
      this.titleRef = React.createRef();


  }

/*
  componentDidMount() {
    if (this.props.nonso) {
      const emailErr = this.props.show[0];
      const passwordErr = this.props.show[1];
      if (emailErr) {
        this.emailRef.current.setRedBackground();
      }
      if (passwordErr){
        this.passwordRef.current.setRedBackground();
      }
    }

  }
*/

  focus(element) {
    if (element == "email") {
      this.emailRef.current.autofocus();
    }
  }




  handleChange(e) {
    const target = e.target;
    const value =  target.value;
    this.setState({[target.name]: value});
  }

  handleDate(date) {
    this.setState({date: date});
  }

  resetChange(e) {
    const target = e.target;
    this.setState({[target.name]: ""});
  }

  handleCount(e,field, count) {
    const target = e.target;
    const value =  target.value;
    let values;
    if (value < this.state[count]) {
      values = this.state[field].slice(0,value);
    } else if (value > this.state[count]) {
      for (let i=0; i < (value - this.state[count]);i++) {
        values = this.state[field];
        if (field == "images") {
          values.push({src:"/react/home/img/", batch: 0});
        } else if (field == "fbPosts") {
          values.push({postDate: new Date()});
        } else if (field == "videos") {
          values.push({video_date: new Date()});
        } else if (field == "tweets") {
          values.push({tweetDate: new Date()});
        } else if (field == "actions") {
          values.push({actionDate: new Date()});
        } else {
          values.push({});
        }

      }
    } else {
      return null;
    }
    this.setState({[field]: values, [count]: value});
  }

  handleTweetsCount(e) {
      this.handleCount(e,"tweets", "tweetsCount");
  }

  handleImageCount(e) {
    this.handleCount(e,"images", "imgCount");
  }
  handleVideosCount(e) {
    this.handleCount(e,"videos", "videosCount");
  }
  handleTextsCount(e) {
    this.handleCount(e,"texts", "textsCount");
  }
  handleActionsCount(e) {
    this.handleCount(e,"actions", "actionsCount");
  }

  handleFbPostsCount(e) {
      this.handleCount(e,"fbPosts", "fbPostsCount");
  }

  handleImages(e,i,field) {
    let images = this.state.images;
    images[i][field] = e.target.value;
    this.setState({images:images});
  }

  handleTags(e, i) {
    const tags = this.state.tags;
    tags[i].checked = e.target.checked;
    this.setState({tags:tags});
  }

  handleVideos(value,i,field) {
    let values = this.state.videos;
    values[i][field] = value;
    this.setState({videos:values});
  }

  handleActions(value,i,field) {
    let values = this.state.actions;
    values[i][field] = value;
    this.setState({actions:values});
  }

  handleTweets(value,i,field) {
    let values = this.state.tweets;
    values[i][field] = value;
    this.setState({tweets:values});
  }
  handleText(e,i,field) {
    let texts = this.state.texts;
    texts[i][field] = e.target.value;
    this.setState({texts:texts});
  }

  handleFbPosts(value,i,field) {
    let values = this.state.fbPosts;
    values[i][field] = value;
    this.setState({fbPosts:values});
  }

  handleSubmit(e) {

    let alertMessage = "";
    if (this.state.title == "") {
        if (alertMessage ==  "") {
            alertMessage = "Title";
        } else {
            alertMessage = alertMessage + ", Title";
        }
        this.titleRef.current.setRedBackground();
    }

    if (alertMessage != "") {
        alertMessage = "Error: " + alertMessage + " cannot be empty!";
        //this.alertHandler(alertMessage);
        e.preventDefault();
    }
  }


  alertHandler(error, inputName = null) {
    this.props.alertHandler(error, inputName);
  }
/*
  isTagChecked(tagID, idsArray) {
    if (!idsArray || !Array.isArray(idsArray) || !idsArray.length) return false;
    let ids = [];
    for (let i=0; i<idsArray.length; i++) {
      ids.push(idsArray[i].id);
    }
    return ids.includes(tagID);
  }
*/
  createTagsArray(tags, selectedIds) {
      let tagsArray = [];
      for (let i=0; i<tags.length; i++) {
        const tag = {id: tags[i].id, name: tags[i].name, checked: (selectedIds && Array.isArray(selectedIds)) ? selectedIds.includes(tags[i].id) : false};
        tagsArray.push(tag);
      }
      return tagsArray;
  }

  handleStoredMediaView(id) {
      this.setState({storedMediaView: id});
  }

  handleStoredMedia(field, id) {
      let medias = this.state.storedMedia;
      let a = medias[field];
      const index = a.indexOf(id);
      if (index != -1) {
        a.splice(index, 1);
      } else {
        a.push(id);
      }
      medias[field] = a;
      this.setState({storedMedia: medias});

  }

  componentDidMount() {
    let tags = null;
    if (this.props.tags) {
      tags = this.createTagsArray(this.props.tags, this.props.selectedTags);
    }
    this.setState({title: this.props.title, subtitle: this.props.subtitle, date: this.props.date ? new Date(this.props.date) : new Date(), credit: this.props.credit, tags: tags})
  }



  render() {
    if (this.state.storedMediaView) {
      return <StoredMedia {...this.props.medias} addMedia={this.handleStoredMedia} storedMedia={this.state.storedMedia} view={this.state.storedMediaView} close={this.handleStoredMediaView}/>

    } else {

        return (
          <div className="feed-container w3-container">
                <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen z-10">
                    <Link click={[this.props.show, false]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>
                </div>
                <form action={"/feed/edit/" + this.props.id} method="post" onSubmit={this.handleSubmit}>
                    <div className="">
                    <FormInput name="title" label="Title" value={this.state.title} maxWidth="" handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="required" alertHandler={this.alertHandler}  ref={this.titleRef}/>
                    <FormInput name="subtitle" label="Subtitle" value={this.state.subtitle} maxWidth="" handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="" alertHandler={this.alertHandler}  />
                    <FormDate name="date" label="Date" date={this.state.date} maxWidth="new-feed-date" handleChange={this.handleDate}  color="w3-light-gray" validate="" alertHandler={this.alertHandler}  />
                    <FormInput name="credit" label="Credits" value={this.state.credit} maxWidth="new-feed-credits" handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="" alertHandler={this.alertHandler}  />
                    </div>
                    <div className="w3-section-32 flex-cen-start">
                          <div className="w3-margin-right-24 w3-large">Stored Images ({this.state.storedMedia.images.length})</div>
                          <Button text="Edit" click={true} clickHandler={this.handleStoredMediaView} id="images" color="a-btn-blue-1" size="a-btn-size-2"/>
                          <div className="w3-margin-right-24 w3-margin-left-32 w3-large">Stored Videos ({this.state.storedMedia.videos.length})</div>
                          <Button text="Edit" click={true} clickHandler={this.handleStoredMediaView} id="videos" color="a-btn-blue-1" size="a-btn-size-2"/>
                          <div className="w3-margin-right-24 w3-margin-left-32 w3-large">Stored Actions ({this.state.storedMedia.actions.length})</div>
                          <Button text="Edit" click={true} clickHandler={this.handleStoredMediaView} id="actions" color="a-btn-blue-1" size="a-btn-size-2"/>

                    </div>
                    <div className="w3-section-32 flex-cen-start">

                          <div className="w3-margin-right-24 w3-margin-left-32 w3-large">Stored Tweets ({this.state.storedMedia.tweets.length})</div>
                          <Button text="Edit" click={true} clickHandler={this.handleStoredMediaView} id="tweets" color="a-btn-blue-1" size="a-btn-size-2"/>
                          <div className="w3-margin-right-24 w3-margin-left-32 w3-large">Stored Fb Posts ({this.state.storedMedia.fbPosts.length})</div>
                          <Button text="Edit" click={true} clickHandler={this.handleStoredMediaView} id="fbPosts" color="a-btn-blue-1" size="a-btn-size-2"/>
                          <StoredMediaHiddenInput media={this.state.storedMedia} />
                    </div>
                    <div className="w3-section">
                    <FormSelect name="imgCount" label="Number of images" value={this.state.imgCount} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleImageCount} resetChange={this.resetChange} color="w3-light-gray" validate="" />
                    </div>
                    <div className="">
                    <Images images={this.state.images} imgCount={this.state.imgCount} handleChange={this.handleImages} resetChange={this.resetChange}/>
                    </div>
                    <div className="w3-section">
                    <FormSelect name="videosCount" label="Number of videos" value={this.state.videosCount} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleVideosCount} resetChange={this.resetChange} color="w3-light-gray" validate="" />
                    </div>
                    <div className="">
                    <Videos videos={this.state.videos} count={this.state.videosCount} handleChange={this.handleVideos} resetChange={this.resetChange}/>
                    </div>
                    <div className="w3-section">
                    <FormSelect name="textsCount" label="Number of paragraphs" value={this.state.textsCount} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleTextsCount} resetChange={this.resetChange} color="w3-light-gray" validate="" />
                    </div>
                    <div className="">
                    <Texts texts={this.state.texts} count={this.state.textsCount} handleChange={this.handleText} resetChange={this.resetChange}/>
                    </div>
                    <div className="w3-section">
                    <FormSelect name="actionsCount" label="Number of actions" value={this.state.actionsCount} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleActionsCount} resetChange={this.resetChange} color="w3-light-gray" validate="" />
                    </div>
                    <div className="">
                    <Actions actions={this.state.actions} count={this.state.actionsCount} handleChange={this.handleActions} resetChange={this.resetChange}/>
                    </div>
                    <div className="w3-section">
                    <FormSelect name="tweetsCount" label="Number of Tweets" value={this.state.tweetsCount} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleTweetsCount} resetChange={this.resetChange} color="w3-light-gray" validate="" />
                    </div>
                    <div className="">
                    <Tweets tweets={this.state.tweets} count={this.state.tweetsCount} handleChange={this.handleTweets} resetChange={this.resetChange}/>
                    </div>
                    <div className="w3-section">
                    <FormSelect name="fbPostsCount" label="Facebook Posts" value={this.state.fbPostsCount} rangeStart={0} rangeEnd={20} maxWidth="w-200" handleChange={this.handleFbPostsCount} resetChange={this.resetChange} color="w3-light-gray" validate="" />
                    </div>
                    <div className="">
                    <FbPosts rows={this.state.fbPosts} count={this.state.fbPostsCount} handleChange={this.handleFbPosts} resetChange={this.resetChange}/>
                    </div>
                    <NewFeedTags tags={this.state.tags} selectTag={this.handleTags}/>
                    <div className="w3-margin-top-24 w3-margin-bottom ">
                        <input type="submit" value="Edit Feed" className="w3-button  w3-content w3-blue h55 w100 w3-round"/>
                    </div>
                </form>
          </div>
        );
      }
    }


}

function StoredMediaHiddenInput(props) {
  if (props.media.images.length || props.media.videos.length || props.media.actions.length || props.media.tweets.length || props.media.fbPosts.length) {
    return <input type="hidden" name="storedMedia" value={JSON.stringify(props.media)} />;
  } else {
    return null;
  }
}

export default EditFeed;
