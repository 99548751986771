import React, { useState } from 'react';
import Button from '../../../Buttons/Button';import {FormInput, DivSelect} from "../../../forms/forms.js";
/*
    <div className=""></div>
*/
function FormMenuBtnRowInput(props) {
    function handleChange(e) {
      props.handleChange(e.target.value, props.field, props.dropBtnIndex);
    }
    if (props.targeted && (props.target != props.field)) {
        return null;
    }

    return (
          <FormInput name={props.field} label={props.label ? props.label : props.field} value={props[props.field]} maxWidth="" className={props.className} handleChange={handleChange}  color="w3-light-gray" validate=""  />
    );
}

function FormMenuDropCatInput(props) {
    function handleChange(e) {
      props.handleChange(props.field, e.target.value);
    }

    return (
          <FormInput name={props.field} label={props.label ? props.label : props.field} value={props.rows[props.catIndex].title} maxWidth="" className={props.className} handleChange={handleChange}  color="w3-light-gray" validate=""  />
    );
}


function FormMenuBtnRowDivSelect(props) {

    function handleChange(value) {
      props.handleChange(value, props.field, props.dropBtnIndex);
    }

    if (props.targeted && (props.target != props.field)) {
        return null;
    } else {
        return (
              <DivSelect name={props.field} label={props.label ? props.label : props.field} cols={props.cols} colorSelector={props.colorSelector} colsDisp={props.colsDisp} iconsSelect={props.iconsSelect} value={props[props.field]} rangeStart={props.rangeStart}
              rangeEnd={props.rangeEnd} options={props.options} handleOptionClick={handleChange} className={props.className} color="w3-light-gray" validate=""  />
        );
    }
}

function DropButton(props) {
  function handleDelete() {
      props.setDropButton("deleteButton", "deleteButton", props.dropBtnIndex);
  }

  const targetOptions = [["Url", "url"], ["Email", "email"],["Meta","meta"]];


  let metaOptions = [];
  if (props.metas) {
    for (let i=0; i<props.metas.length; i++) {
        metaOptions.push([props.metas[i].btnName, props.metas[i].id]);
    }
  }
  metaOptions.push(["", null]);
  const shared = { dropBtnIndex: props.dropBtnIndex, handleChange: props.setDropButton};
  const moveUp = (props.dropBtnIndex != 0) && (props.length) ? true : false;
  const moveDown = (props.dropBtnIndex < (props.length-1)) ? true : false;

  return (
        <div className="w3-section-32 w3-row w3-display-container w3-animate-opacity-04" key={props.catIndex+"button"+props.btnIndex+"bar"+props.dropBtnIndex+props.updateCount}>
            <FormMenuBtnRowInput  field="text" {...shared}  {...props}  className="w3-col m3 w3-container-8 " label="Button Name"/>
            <FormMenuBtnRowDivSelect field="icon" {...shared} {...props} cols={6} colsDisp={1}  iconsSelect={true} className="w3-col m2 w3-container-8" label="Button Icon" />
            <FormMenuBtnRowDivSelect field="target" {...shared} {...props} className="w3-col m2 w3-container-8" options={targetOptions} label="Target" />
            <FormMenuBtnRowDivSelect field="meta" targeted={true} {...shared}  {...props} className="w3-col m3 w3-container-8" options={metaOptions} label="Meta ID" />
            <FormMenuBtnRowInput field="url" targeted={true} {...shared} {...props} className="w3-col m3 w3-container-8"  label="Url" />
            <FormMenuBtnRowInput field="email" targeted={true} {...shared} {...props} className="w3-col m3 w3-container-8"  label="Email" />
            <FormMenuBtnRowDivSelect field="adminButton" {...shared} {...props} className="w3-col m1 w3-container-8" rangeStart={0} rangeEnd={5} label="Admin" />
            <FormMenuBtnRowDivSelect field="userButton" {...shared} {...props} className="w3-col m1 w3-container-8" options={["in","out", ""]} label="User" />

            <div className="w3-display-right" style={{marginRight: "-65px"}}>
                    <Button svg="delete" click={handleDelete} color="a-btn-orange-2" size="a-btn-size-2-icon w3-margin-left-24"   className="" />
            </div>
            <div className="w3-display-topleft h-100 etb-btn-ordering" >
                    <Button svg="arrow_upward" click={moveUp} color="a-btn-blue-1" size="a-btn-size-2-icon w3-margin-left-24" id={{index: props.dropBtnIndex, direction: "up"}} clickHandler={props.moveDropButton}  className="" />
                    <Button svg="arrow_downward" click={moveDown} color="a-btn-blue-1" size="a-btn-size-2-icon w3-margin-left-24" id={{index: props.dropBtnIndex, direction: "down"}} clickHandler={props.moveDropButton}  className="w3-margin-top-12" />
            </div>

        </div>
  );
}
function DropMenu(props) {
  const selectedCategory = props.selectedDropCat;


   if (false) {
     return null;
   } else {
     let categoryOptions = [];
     for (let i = 0; i<props.rows.length;i++) {
       if (props.rows[i].title) {
         categoryOptions.push([props.rows[i].title, i]);
       } else {
         categoryOptions.push(["No Name"+(i+1), i]);
       }
     }
     return (
       <div className="w3-section-64 w3-center ">
            <div className="w3-section-32 w3-row ">
            <FormMenuDropCatInput  field="categoryTitle" catIndex={selectedCategory}  {...props} className="w3-col m3 w3-container-8" label="Category Title" handleChange={props.setDropCat}/>
            <DivSelect field="titleIcon"  value={props.rows[selectedCategory].titleIcon} cols={6} colsDisp={1}  iconsSelect={true} className="w3-col m2 w3-container-8" handleOptionClick={(value)=>props.setDropCat("titleIcon",value)} label="Icon" />
            <DivSelect name="categorySelector" className="w3-col m3" value={selectedCategory} options={categoryOptions} handleOptionClick={props.setDropCategory} color="w3-light-gray"/>
            <Button  text="Delete" click={props.deleteDropCat} color="a-btn-blue-1"  className="w3-col m2 mw-200 w3-margin-left" size="a-btn-size-3"  />
            <Button  text="Add" click={props.addDropCat} color="a-btn-blue-1"  className="w3-col m2 mw-200  w3-margin-left" size="a-btn-size-3"  />
            </div>
            {!props.rows[selectedCategory].buttons ? null : props.rows[selectedCategory].buttons.map((row, index) => <DropButton {...row} metas={props.metas} length={props.rows[selectedCategory].buttons.length} key={index} catIndex={selectedCategory} setDropButton={props.setDropButton} moveDropButton={props.moveDropButton} dropBtnIndex={index}/>)}

            <div className="w3-section w3-container-8">
                  <Button text="Add Button" type="text" click={true} color="a-btn-orange-2" size="a-btn-size-2" id={selectedCategory}  className=""  clickHandler={props.addDropButton}/>
            </div>
            <Button  text="Done" click={true} color="a-btn-blue-1"  className="w3-section-64 mw-200" size="a-btn-size-3" id={false} clickHandler={props.selectDropMenu}/>

       </div>
     );
   }
}


export default DropMenu;
