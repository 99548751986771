import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../forms/forms.js";
import Button from '../../Buttons/Button'; import { FacebookProvider, EmbeddedPost } from 'react-facebook';
/*
    <div className=""></div>
*/

function FbPosts(props) {
  if (props.show != "batchFbPost") {
      return null;
  } else {
    const handleChange1 = (e) => props.editRow("postUrl", e.target.value, props.rowIndex);
    const handleChange2 = (e) => props.editRow("postTitle", e.target.value, props.rowIndex);
    const handleChange3 = (e) => props.editRow("fbUser", e.target.value, props.rowIndex);
    const handleDate = (date) => props.editRow("postDate", date, props.rowIndex);

    return (
        <div className="w3-row ">

              <div className=" w3-col m5">
                  <FacebookProvider appId="304383704997041">
                            <EmbeddedPost href={props.postUrl} width="450" />
                  </FacebookProvider>
                  &nbsp;
              </div>
              <div className=" w3-col m7">
                  <FormInput name={"postUrl"+props.rowIndex} label="Url" value={props.postUrl} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
                  <FormInput name={"postTitle"+props.rowIndex} label="Caption" value={props.postTitle} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
                  <FormInput name={"fbUser"+props.rowIndex} label="fb User" value={props.fbUser} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
                  <FormDate name={"postDate"+props.rowIndex} label="Date" date={props.postDate} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />

              </div>
        </div>
    );
}
}


export default FbPosts;
