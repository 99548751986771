// updated 13-09-2019

import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import DivSelect from "./DivSelect";
import FormContainer from "./FormContainer";
import FormInput from "./FormInput";
import FormTextArea from "./FormTextArea";
import FormCheckBox from "./FormCheckBox";
import FormSelect from "./FormSelect";
import FormDate from "./FormDate";
import FormRadio from './FormRadio';


function validateError(format, input) {
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mailError = "You have entered an invalid E-mail format!";
    const passwordFormat = /^(?=.*[A-Z].*[A-Z])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/;
    const passwordError = "Check Password format requirements: Min 8 characters, 2 digits, 2 upper case letters and 2 lower case letters";
    const nameFormat = /^[a-zA-z]+([a-zA-Z\s0-9]+)*$/g;
    const nameError = "Invalid format only characters digits and white spaces allowed!";
    const azOnly = /^[a-zA-z]+$/g;
    const azOnlyError = "Invalid format: only a-z characters allowed!";
    const digitsOnly = /^(\d)+$/g;
    const digitsError = "Invalid format only digits allowed!";
    const phoneFormat = /^[\+\d]?(\d)*$/g;
    const phoneError = "Invalid format: only digits and an optional + character allowed! Example +390000000 or 39000000";
    switch (format) {
        case "email":
            if (!input.match(mailFormat)) {
                return mailError;
            } else {
                return false;
            }
            break;

        case "password":
            if (!input.match(passwordFormat)) {
                return passwordError;
            } else {
                return false;
            }
            break;
        case "name": {
            const result = nameFormat.test(input);
            if (result) {
                return false;
            } else {
                return nameError;
            }}
            break;
        case "azOnly": {
                const result = azOnly.test(input);
                if (result) {
                    return false;
                } else {
                    return azOnlyError;
                }}
                break;
        case "phone": {
                const result = phoneFormat.test(input);
                  if (result) {
                      return false;
                  } else {
                      return phoneError;
                  }}
                  break;
        case "digit":
            const result = digitsOnly.test(input);
            if (result) {
                return false;
            } else {
                return digitsError;
            }
            break;
        default:
            ;
    }
}

// <FormContainer grid= maxWidth= color= label= />



// <FormCheckBox label= checked= id= handleChange= />





// <FormDate handleChange selectsStart={true} startDate endDate grid color={this.state.color} label={this.props.label} centerLabel={this.props.centerLabel} labelColor={this.props.labelColor} />

export {DivSelect, FormInput, FormTextArea, FormCheckBox, FormSelect, FormDate, FormRadio, validateError};
