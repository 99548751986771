import React from 'react';
import Button from '../../Buttons/Button';

/*
    <div className=""></div>
*/



function SlidesBar(props) {
   if (false) {
     return null;
   } else {
     let buttons = [];
     for (let i=0; i<props.rows.length; i++) {
       const btnColor = (props.selectedSlide === i) ? "a-btn-orange-1" :  "a-btn-blue-1";
       buttons.push(<Button text={"Slide "+(i+1)} click={true} color={btnColor} size="a-btn-size-3" id={i} key={i} className="w3-margin-right" clickHandler={props.selectSlide}/>);
     }
     buttons.push(<Button text="Add new" click={true} color="a-btn-green" size="a-btn-size-3" id="add" key="add" className="w3-margin-right" clickHandler={props.selectSlide}/>);
     buttons.push(<Button text="Show demo" click={true} color="a-btn-green" size="a-btn-size-3" id={true} key="rotator" className="" clickHandler={props.showHeroRotator}/>);
     return (
         <div className="flex-cen-cen w3-section">
              {buttons}
         </div>
     );
   }
}


export default SlidesBar;
