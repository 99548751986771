import React, {useState, useEffect} from 'react'; import './rangeActionsBar.css';import Button from '../../../../Buttons/Button';
import {FormInput, DivSelect} from '../../../../forms/forms'; import Title from './Title';
import SvgIcon from '../../../../SvgIcon';

/*
    <div className=""></div>
*/

const EditListSelect = (props) => {
  if (props.createView || !props.name) { return null;} else {
      return ( <DivSelect options={props.listsOptions} className="a-width-130"  noIcon={true} margin="no-marg" defs={"form-col-orange "+props.listDefs} name="list" value={props.selectedList} handleOptionClick={(value)=>props.handleColor("list",value)}/> ) ;}
  };

  const Delete  = (props) => (props.createView || props.notCreated) ? null :   <SvgIcon icon="delete"  size={props.iconSize} color="dodgerblue" hoverColor="#66b3ff" className="w3-margin-left" click={props.delete}/>;
  const Edit  = (props) => (props.createView || props.notCreated) ? null :  <SvgIcon icon="edit" size={props.iconSize} color="dodgerblue" hoverColor="#66b3ff" click={()=>props.setEditTitle(true)}/>;



function Header(props) {
    const [editTitle, setEditTitle] = useState(false);
    const {notCreated, createView, hasChanged} = props;
    const mobile = window.innerWidth < 734;
    const iconSize = mobile ? "21px" : "18px";
    const title = createView ? ("Saving "+props.color.hex) : (props.name ? props.name : props.color.hex);
    const titleLarge = (!createView && !hasChanged);
    const sizeClass = titleLarge ? {defs:"form-noLab-cl-title-large", h4: "cl-actions-heading-h4-large", listDefs: "form-noLab-med"} : {defs:"form-noLab-cl-title", h4: "cl-actions-heading-h4", listDefs: mobile ? "form-noLab-med": "form-noLab-tiny"};
    const editIcShared = {iconSize, createView, notCreated, setEditTitle};
    return (
      <div className="flx-btw cl-actions-heading a-wrap">
          <EditListSelect {...props.listOpt} name={props.name} handleColor={props.handleColor} {...sizeClass}/>
          <div className="flex-cen-cen"><Edit {...editIcShared}/> <Delete {...editIcShared} delete={props.delete}/></div>
          <Title editTitle={editTitle} setEditTitle={setEditTitle} name={props.name} title={title} isNameUnique={props.isNameUnique} handleColor={props.handleColor} {...sizeClass}/>
      </div>
    );
}

export default Header;
