import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";
import MaterialIcon, {colorPalette} from 'material-icons-react';
import FormContainer from "./FormContainer";
import {validateError} from "./forms";
import DivContainer from "./DivContainer";


// <FormRadio name values={[[label1, value1],[label2, value2]]} checked= handleChange grid color label />
class FormRadio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {color: "frm-blur-color", clicked: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);

    }



    setRedBackground() {
        this.setState({color: "frm-error-color"});
    }

    handleChange(e) {
        this.setState({color: "frm-focus-color", clicked: true});
        this.props.handleChange(e);
    }

    handleLabelClick() {
      this.setState({clicked: true});
    }





    render() {
        const type = this.props.type ? this.props.type : "text";
        const inputPad = this.props.label ? "input-top-pad" : "";
        if (this.props.mode == "view") {
          let checkedLabel = null;
          if (this.props.checked === this.props.values[0][1]) {
            checkedLabel = this.props.values[0][0];
          } else if (this.props.checked === this.props.values[1][1]){
            checkedLabel = this.props.values[1][0];
          } else {
            checkedLabel = <span> &nbsp; </span>;
          }
          return (
                <DivContainer content={this.props.value}   color={this.state.color} label={this.props.label} />
          );
        } else { // mode != "view"
          return (
              <FormContainer color={this.state.color} value={this.props.value} clicked={this.state.clicked} defColors={this.props.defColors} defs={this.props.defs}  label={this.props.label}
              covLabel={this.props.covLabel} margin={this.props.margin} labelColor={this.props.labelColor} noPointer={this.props.noPointer} className={this.props.className}
               margin={this.props.margin}  maxWidth={this.props.maxWidth}  handleClick={this.handleLabelClick} required={this.props.required}>
                      <div className="w3-row-padding">
                            <div className="w3-col s6 w3-medium">
                                  <div className="w3-clear">
                                      <div className={"w3-left w3-padding-8 "+inputPad}>
                                          <input type="radio" name={this.props.name} value={this.props.values[0][1]} checked={this.props.checked === this.props.values[0][1]} onChange={this.handleChange} />
                                      </div>
                                      <div className={"w3-left w3-padding-8 w3-container w3-text-indigo "+inputPad}>
                                          {this.props.values[0][0]}
                                      </div>
                                  </div>

                            </div>
                            <div className="w3-col s6 w3-medium">
                                  <div className="w3-clear">
                                      <div className={"w3-left w3-padding-8 "+inputPad}>
                                          <input type="radio" name={this.props.name} value={this.props.values[1][1]} checked={this.props.checked === this.props.values[1][1]}  onChange={this.handleChange} />
                                      </div>
                                      <div className={"w3-left w3-padding-8 w3-container w3-text-indigo "+inputPad}>
                                          {this.props.values[1][0]}
                                      </div>
                                  </div>

                            </div>
                      </div>

              </FormContainer>
          );
        }

    }
}

export default FormRadio;
