import React from 'react';



// The Li does nothing it's just a container for the rowFunction propped byt the calling component who decides the structure of the Li content

function Li(props) {
    if (false) return null;
    const li = props;
    const index = props.index;
    let RowFunction = ({rowFunction}) => rowFunction ? React.cloneElement(rowFunction, {data: li, index: index}) : null;

    return (
        <li>
            <RowFunction rowFunction={props.rowFunction}  />
        </li>

    );

}

export default Li;
