import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";
import MaterialIcon, {colorPalette} from 'material-icons-react';
import FormContainer from "./FormContainer";
import {validateError} from "./forms";


class FormTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {color: "frm-blur-color", clicked: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.autofocus = this.autofocus.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.myRef = React.createRef();
    }


    autofocus() {
        this.myRef.current.focus();
    }

    setRedBackground() {
        this.setState({color: "frm-error-color"});
    }

    handleLabelClick() {
      this.setState({clicked: true});
      this.autofocus();
    }

    handleFocus() {
      const color = (this.state.color == "frm-error-color") ? "frm-error-color" : "frm-focus-color";
      this.setState({clicked: true, color: color});
    }

    handleChange(e) {
        this.setState({color: "frm-focus-color", clicked: true});
        if (this.props.validate && e.target.value && (this.props.validate != "password") && (this.props.validate != "email")) {
            const error = validateError(this.props.validate, e.target.value);
            if (error) {
                this.setRedBackground();
                this.props.alertHandler(error, this.props.name);
            } else {
                this.props.handleChange(e);
            }
        } else {
             this.props.handleChange(e);
        }
    }


    handleBlur(e) {
      if (this.state.color != "frm-error-color") {
          this.setState({color: "frm-blur-color", clicked: false});
      } else {
          this.setState({clicked: false});
      }
        if (this.props.validate && e.target.value && ((this.props.validate == "password") || (this.props.validate == "email"))) {
            const error = validateError(this.props.validate, e.target.value);
            if (error) {
                this.setRedBackground();
                this.props.alertHandler(error, this.props.name);
                this.props.resetChange(e);
            }
        }

    }

    render() {
      let rows;
      const inputPad = this.props.label ? "input-top-pad" : "";
      if (!this.state.clicked) {
        rows = 1;
      } else if (this.props.rows) {
        rows = this.props.rows;
      } else {
        rows = 4;
      }


        return (
                <FormContainer color={this.state.color} value={this.props.value} clicked={this.state.clicked} defColors={this.props.defColors} defs={this.props.defs}  label={this.props.label}
                covLabel={this.props.covLabel} margin={this.props.margin} labelColor={this.props.labelColor} noPointer={this.props.noPointer} className={this.props.className}
                 margin={this.props.margin}  maxWidth={this.props.maxWidth}  handleClick={this.handleLabelClick} required={this.props.required}>
                <textarea name={this.props.name} value={this.props.value} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} className={"frm-input "+inputPad} rows={rows} ref={this.myRef} onFocus={this.handleFocus}/>
                </FormContainer>
                );
    }
}


export default FormTextArea;
