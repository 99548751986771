import React from 'react';
import Button from '../../../../Buttons/Button'; import UlSlider from '../../../../UlSlider/UlSlider';

/*
    <div className=""></div>
*/

function  CheckedIcon(props) {
  if (!props.checked) {
    return null;
  } else {

    return (
      <div className="w3-display-topright ZI10 w3-margin-top w3-margin-right">
          <Button svg="done" size="a-btn-size-40-icon"  color="a-btn-orange-1" />
      </div>

    );
  }
}

function Img(props) {
  function  handleClick() {
    props.addMedia("images", props.data.id);
  }
  if (!props.data) {
    return null;
  } else {
    const checked = (props.selectedIDs.indexOf(props.data.id) != -1);
    return (
      <div className="slide-img w3-display-container pointer" onClick={handleClick}>
          <CheckedIcon checked={checked}/>
          <img src={props.data.src} className="slide-img"/>
      </div>

    );
  }
}

function Images(props) {
   if (props.view != "images") {
     return null;
   } else {
     return (
       <div className="w3-content-800 w3-section-64 " style={{height: "450px"}}>
              <UlSlider rows={props.rows} cols={11} rowFunction={<Img addMedia={props.addMedia} selectedIDs={props.selectedIDs}/>}/>
       </div>
     );
   }
}


export default Images;
