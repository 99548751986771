import React from 'react';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon'; import EditBar from './EditBar';

function Author(props) {
  if (!props.author) {
      return  null;
  } else {
    return   <div className="ukr-feed-author">
                    <div className="feed-auth-icon-cont"><SvgIcon icon="arrow_drop_down" color="dodgerblue"/></div>
                    <Link className="an-inline" url={props.authorUrl} noFollow={true} newWindow={true}><div className="">{props.author}</div></Link>
            </div>;
  }
}



function Image(props) {
  if (!props.src) return null;
  const objectPosition = props.imagePosition ? {objectPosition: props.imagePosition} : {};
  const formFactor = props.portrait ? "img-portrait" : "img-landscape";
  return (
    <div className="w100 h-100 w3-display-container">
          <EditBar  user={props.user}  field="_image" media={props} handler={props.editImage} id={props.id} feedID={props.feedID}/>
          <img className={formFactor} src={props.src} alt={props.alt} style={objectPosition}/>
          <Author author={props.author} authorUrl={props.authorUrl}/>
    </div>
  );
}

export default Image;
