import React, {useState, useRef} from 'react';
import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import EditButtons from '../Parts/EditButtons';import {DivSelect} from '../../../../../forms/forms'; import ButtonsMap from './ButtonsMap';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex,contrastHexColor, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import {DoHoverBtn, EditBtnFilling, EditBtnLighHover, Title} from './subs';
/*

*/
function SclButtons(props) {
    const {select, selected, buttons, selectBtn, selectedBtn, setBtnEl, btnEl, index, setSclColors, sclColors, btnBkg, contrastBkg,selectType, hideControls} = props.shared;
    const [forceHover, setHover] = useState(false);

    const scrollLeft = ()=> document.getElementById("scl-btns-cont").scrollLeft = 0;
    const scrollRight = ()=> {const rect = document.getElementById("scl-btns-inner-cont").getBoundingClientRect(); document.getElementById("scl-btns-cont").scrollLeft = rect.width;  }

    const deleteBtn = ()=> {
        if (buttons.length > 1 ) {
            buttons.splice(selectedBtn, 1);if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";setSclColors([...sclColors]);  selectBtn(0);
            const filling = buttons[0].filling;
            if (["a-btn-textOnHover","a-btn-bordOnHover"].includes(filling)) {
              props.setHex(buttons[0].txt);
              if (btnEl !== "txt") setBtnEl("txt");
            } else {
              props.setHsl(buttons[0].bkg);
              if (btnEl !== "bkg") setBtnEl("bkg");
            }
            scrollLeft();
        }
    }
    const addBtn = ()=> {
      buttons.push({bkg:{h:240,s:100,l:50}, txt: "#ffffff",txtHov:"#ffffff", filling:"a-btn-filled", lHover: "l-hov-10"});
      if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated";
      setSclColors([...sclColors]);
      select("buttons");  selectBtn(selectedBtn+1); setBtnEl("bkg"); props.setHsl({h:240,s:100,l:50});
      setTimeout(()=>scrollRight(), 500);
    }

  const sectionPad = hideControls ? "a-section-30" : "a-pdT-20 ";
  if (selected === "gradient") return null;
  return (
    <div className={sectionPad} >
        <Title contrastBkg={contrastBkg} hideControls={hideControls}/>
        <ButtonsMap forceHover={forceHover} {...props} />
        <EditButtons add={addBtn} name="Button" bkg={contrastBkg} selected={selectedBtn} delete={deleteBtn} hideControls={hideControls}>
            <EditBtnFilling shared={props.shared}/>
            <EditBtnLighHover shared={props.shared}/>
            <DoHoverBtn  forceHover={forceHover} setHover={setHover}/>
        </EditButtons>
        <hr className={"a-h-1 a-mrT-20 "+(hideControls?"w3-hide":"")} style={contrastBkg}/>
    </div>
  );
}


export default SclButtons;
