import React from 'react';
import {FormInput, FormSelect} from "../../../forms/forms.js";

/*

*/

// const videos = ["videos",["id", "url","platform","caption"]];
function Row(props) {

  function handleChange1(e) {
    props.handleChange(e, props.i, "name")
  }


  return (
      <div className="w3-row">
        <FormInput name={"name"+props.i} label="Tag" value={props.rows[props.i].name} maxWidth="w3-col m6" handleChange={handleChange1}  color="w3-light-gray" validate=""  />


      </div>
  );
}

function TagInputs(props) {
   if (false) {
     return null;
   } else {
     let out = [];
     for (let i=0; i < props.count; i++) {
       out.push(<Row key={i} i={i} handleChange={props.handleChange} rows={props.rows}/>)
     }
     return out;
   }
}


export default TagInputs;
