import React from 'react';
import './headerImg.css'; import './articleHeader.css'; import '../cnAr.css';
import Metadata from './Metadata';
import Breadcrumb from './Breadcrumb';
/*
    <div className=""></div>
*/

function HeaderImg(props) {
   if (false) {
     return null;
   } else {
     const bkg = props.src ? {backgroundImage : `url(${props.src})`} : {background: "rgba(13, 117, 197, 0.92"};
     return (
       <header className="rba-header-img flex-cen-cen rba-header-img-defs cn-defs" style={bkg}>
                    <div className="breadcrumb-abs-pos">
                        <div className="breadcrumb-abs-cont">
                            <Breadcrumb clickHandler={props.clickHandler} rows={props.breadcrumb} dark={true}/>
                        </div>
                    </div>
                    <div className="rba-header-img-container">
                            <h1 className="rba-header-img-title">{props.title}</h1>
                            <h3 className="rba-header-img-descr">{props.description}</h3>
                            <Metadata {...props}/>
                    </div>

      </header>
     );
   }
}


export default HeaderImg;



// <HeaderImg title="" description="" date="" src=""/>

// import HeaderImg from './CnnArt/Header/HeaderImg';
