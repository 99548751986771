import React from 'react';
import Button from '../../../../Buttons/Button'; import UlSlider from '../../../../UlSlider/UlSlider';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

/*
    <div className=""></div>
*/

function  CheckedIcon(props) {
  if (!props.checked) {
    return null;
  } else {

    return (
      <div className="w3-display-topright ZI10 w3-margin-top w3-margin-right">
          <Button svg="done" size="a-btn-size-40-icon"  color="a-btn-orange-1" />
      </div>

    );
  }
}

function Row(props) {
  function  handleClick() {
    props.addMedia("tweets", props.id);
  }
  if (!props.id) {
    return null;
  } else {

    const checked = (props.selectedIDs.indexOf(props.id) != -1);

    return (
      <div className="w3-display-container pointer w3-section w3-padding-16 w3-container w3-gray" onClick={handleClick} >
          <CheckedIcon checked={checked}/>
          <div className="" >
            <div className="w3-margin-bottom">{props.tweetTitle} ({props.tweetDate})</div>
            <div className=""><TwitterTweetEmbed tweetId={""+props.tweetID+""} /></div>
          </div>

      </div>

    );
  }
}


function Tweets(props) {
   if (props.view != "tweets") {
     return null;
   } else {
     return (
       <div className="w3-content w3-section-64 ">
              {props.rows.map((row) => <Row {...row} key={row.id} addMedia={props.addMedia}  selectedIDs={props.selectedIDs} />)}
       </div>
     );
   }
}


export default Tweets;
