import {DivSelect} from '../../../../../forms/forms';

const AngleSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const colors = gradient[selectedGradient].colors;
    if (gradient[selectedGradient].direction !== "angle" && (gradient[selectedGradient].type !== "conic-gradient")) return null;
    const handler = (value)=> {
        gradient[selectedGradient].angle = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);
    }

      return (
        <DivSelect value={gradient[selectedGradient].angle} rangeStart={0} rangeEnd={360} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-50"  margin="none" noIcon={true} />
    );
}

export default AngleSelector;
