function calcSlides(index, endIndex, circle, rows, cols) {
      if (endIndex == 0) return rows;  // there os only one element in the array so cols must be 1 => no sliding with one element
      let out = [];
      let left = [], right = [];
      const range =  Math.floor(cols / 2) ; // cols is never even so we take the integer floor of cols/2 , so current index is at the same distance between 0 and endIndex

      if ((index >= range) && (index <= endIndex-range)) {  // index has at least range elements before and after so we just slice the array with 2range+1 elements with index at the center
          out = rows.slice(index-range, index+range+1);
      } else if (!circle) {
            if (index < range) { // The left array goes from index-range to index(include) so if index < range we had first as many null elements as range - index
              for (let i=0; i<(range - index); i++) {
                left.push(null);
              }
              for (let l=0; l<=index; l++) { // after the null elements we add the elements from 0 to index(included)
                left.push(rows[l]);
              }
            } else { // if index >= range we don't need to add null elements we just slice the array will range+1 elements
              left = rows.slice(index-range, index+1);
            }
            if (index > endIndex-range) { // we add null elements at the end of the right array to fill the missing elements
              for (let m=0; m<(endIndex - index); m++) { // first we add tp right the elemets > index up to endIndex (included)
                right.push(rows[index+m+1]);
              }
              for (let j=0; j<(range - endIndex + index); j++) {  // we put nulls to fill the misisng elements
                right.push(null);
              }
            } else { // index <= endIndex-range so we don't need null elements we just slide range elemets from the array from index+1 to idnex+range included (slice dones't include the last element so we put inex+range+<)
              right = rows.slice(index+1, index+range+1);
            }
            out = left.concat(right);
      } else if (circle) {
            if (index < range) {
              for (let r=(index-range); r<0; r++) {  // we fill the missing elemets with index-range elements from the end of the array
                left.push(rows[endIndex+r+1]);
              }
              for (let p=0; p<=index; p++) {  // after  we add the elements from 0 to index(included)
                left.push(rows[p]);
              }
            } else { // if index >= range we don't need to add null elements we just slice the array will range+1 elements
              left = rows.slice(index-range, index+1);
            }

            if (index > endIndex-range) {
              for (let s=0; s<(endIndex - index); s++) {  // we put in the right array first endIndex-index elements from the back of the array
                right.push(rows[index+s+1]);
              }
              for (let t=0; t<(range - endIndex + index); t++) {  // than we add range - endIndex + index elemets to the beginning of the right array
                right.push(rows[t]);
              }
            } else { // index <= endIndex-range so we don't need null elements we just slide range elemets from the array from index+1 to idnex+range included (slice dones't include the last element so we put inex+range+<)
              right = rows.slice(index+1, index+range+1);
            }
            out = left.concat(right);
      }
      return out;
}

function extractFromMediaObject(media, prData) {
      if (prData && prData.desktop) { // if prData contains a property desktop it means it's an object containing the 5 media style objects {mobilePortrait: style1, mobileLandscape: style2, tabletPortrait: style3, tabletLandscape: style4, desktop:style5} , so we have to extract the right style according to the calling  device
                if (media.isTablet && media.isLandscape) {
                  return prData.tabletLandscape;
                } else if (media.isTablet && media.isPortrait) {
                  return prData.tabletPortrait;
                } else if (media.isSmarthPhone && media.isPortrait) {
                  return prData.mobilePortrait;
                } else if (media.isSmarthPhone && media.isLandscape) {
                  return prData.mobileLandscape;
                } else {
                  return prData.desktop;
                }
      } else { // prData contains a single object style whatever is the device so we return it as it is without extracting the right media object
          return prData ? prData : {};
      }

}

function calcCols(proppedCols, endIndex) {
  if (endIndex == 0) return 1; // there os only one element in the array so cols must be 1 => no sliding with one element
  let cols = Math.min(proppedCols, endIndex+1);  // no need to use more cols than the elements in the array
  if  (!cols || !Number.isInteger(cols)) { // set cols to 5 if invalid integer
      cols = 5;
  } else if (cols < 3 ) {
    cols = 3;
  } else if (cols > 11) {
    cols = 11;
  } else if ((cols % 2) == 0) { // se cols è un numero pari sottrai uno per trasformarlp in dispari, cosi current index è esattamente equidistante da 0 e endIndex
    cols -= 1;
  }
  return cols;
}
export {calcSlides,extractFromMediaObject, calcCols};


/*

function calcSlides(index, endIndex, circle, rows) {
      let out = [];
      let left = [], right = [];
      if ((index >= 2) && (index <= endIndex-2)) {
          out = rows.slice(index-2, index+3);
      } else if (!circle) {
            if (index < 2) {
              left = (index == 1) ? [null, rows[0], rows[1]] : [null, null, rows[0]];
            } else {
              left = rows.slice(index-2, index+1);
            }
            if (index > endIndex-2) {
              right = (index == (endIndex - 1)) ? [rows[endIndex], null] : [null, null];
            } else {
              right = rows.slice(index+1, index+3);
            }
            out = left.concat(right);
      } else if (circle) {
            if (index < 2) {
              left = (index == 1) ? [rows[endIndex], rows[0], rows[1]] : [rows[endIndex-1], rows[endIndex], rows[0]];
            } else {
              left = rows.slice(index-2, index+1);
            }
            if (index > endIndex-2) {
              right = (index == (endIndex - 1)) ? [rows[endIndex], rows[0]] : [rows[0], rows[1]];
            } else {
              right = rows.slice(index+1, index+3);
            }
            out = left.concat(right);
      }
      return out;
}



*/
