import SvgIcon from '../../../../../SvgIcon';

const Title = (props)=> {
    const {texts, selectedText} = props.shared;
    return (
      <div className="a-flex-col max-content ov-hid" onTouchStart={()=>{setTimeout(props.cancelBkgTouch, 400);}}>
          {texts[selectedText].text.map((color, index)=><TextRow color={color} rowNum={index} shared={props.shared} deleteRow={props.deleteRow} setHex={props.setHex}/>)}
      </div>
    );

};

const TextRow = (props)=> {
  const {select, selected, texts, selectText, selectedText, setTextEl, textEl,selectedTextType, selectTextType, index, setSclColors, sclColors, selectType, txt, contrastBkg} = props.shared;
  return (
    <div className="flex-cen-start lh-1 a-mrB-14 no-mrB-last max-content ov-hid">
          <div className="a-font-20 noselect " style={{color: props.color}} onClick={(e)=>{e.stopPropagation();select("text");selectTextType("text");setTextEl(props.rowNum);props.setHex(props.color);}}> This is a fake title {props.rowNum}</div>
          <SvgIcon icon="delete" size="18px" color={props.color} className={"a-mrL-30 "+(texts[selectedText].text.length < 2 ? "w3-hide":"")} click={()=>props.deleteRow(props.rowNum)} key={"svg"+props.rowNum+props.color}/>
    </div>
  );

};

const Gradient = (props)=> {
  const {select, selected, texts, selectText, selectedText, setTextEl, textEl,selectedTextType, selectTextType, index, setSclColors, sclColors, selectType, txt, contrastBkg} = props.shared;
  const gradient = texts ? {backgroundImage: `linear-gradient(45deg, ${texts[selectedText].gradient[0]},${texts[selectedText].gradient[1]},${texts[selectedText].gradient[2]})`} : {};
  const gradClick = (e, i)=> {e.stopPropagation();select("text");selectTextType("gradient");setTextEl(i);props.setHex(texts[selectedText].gradient[i]);}
  return (
      <div className="a-mrB-14 cl-range-text-grad a-font-22 lh-1 max-content ov-hid" style={gradient} onTouchStart={()=>{setTimeout(props.cancelBkgTouch, 400);}} >
            <span onClick={(e)=>gradClick(e,0)}>A 3 colours</span> <span onClick={(e)=>gradClick(e,1)}>text </span><span onClick={(e)=>gradClick(e,2)}>gradient</span>
      </div>
  );

};

export {Title, Gradient};
