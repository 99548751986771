import {DivSelect} from '../../../../../forms/forms';import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';


const SetsSelector = (props) => {
  const {index, select, setIndex, sclColors} = props.shared;
  let indexes = [];
  const buildIndexes = ()=> {indexes = []; for (let i=0; i<sclColors.length; i++) {indexes.push(["Set "+(i+1), i]);}};
  buildIndexes();

  return (
    <DivSelect value={index} options={indexes} handleOptionClick={(value)=>{select("none");setIndex(value);}}
    defs="form-noLab-xsmall"  defColors="form-col-orange"   margin="none" className="a-width-60 a-mrL-12 " noIcon={true} topDrop={false} />
  );
}



export default SetsSelector;
