import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './sortBar.css';
import SvgIcon from '../SvgIcon';
import Link from '../Link';
/*
    <div className=""></div>
*/


function DropdownLi(props) {
  if (false) {
    return null;
  } else {
    return <li><Link className="drop-row" click={props.click} href={props.href}>{props.text}</Link></li>;
  }
}

function DropContent(props) {
    if (!props.content || !props.type)  {
      return null;
    } else {
      const dropLiArray = props.content.map((row, index) => <DropdownLi {...row} key={index} />);

      return (
          <ul className="drop-menu">
                {dropLiArray}
          </ul>
      );
    }
}
 /*
 <BarLi
 type = false  //"drop", "more"
 selected = false  //true  if the item is selected and highlited with different color
 text = "string"
 content = array of {text: "", href:"", click: func}  Drop Down Menu only if type is more or drop
 click = func  or array(func, var)// Only one between click or href can be filled . if str is defined the same handler can be shared by all links, the handler will be called as props.func(var) instead of props.func() where var identifies the link instead of using different handlers for every link
 href = "str" // Only one between click or href can be filled
 />


*/
function BarLi(props) {
    if ((!props.text && !props.type == "more") || (!props.href && !props.click )) {
      ;
    }
    let rowContainer = props.selected ? "sort-bar-li selected" : "sort-bar-li" ;
    if (props.type == "more") {
      rowContainer += " sort-bar-drDwn drDwn-more";
    } else if (props.type == "drop") {
      rowContainer += " sort-bar-drDwn ";
    }

    const moreIcon = props.selected ? "close" : "more_horiz";
    const iconColor = props.selected ? "white" : props.iconColor;
    return (
      <li className={rowContainer}>
            <Link className="sort-bar-text" click={props.click} href={props.href}>
                  {(props.type == "more") ? <SvgIcon icon={moreIcon} size="20px" color={iconColor}/> : props.text}
            </Link>
            <DropContent {...props}  />
      </li>
    );

}



function setColors(colors) {

    if (colors.border) {
      document.documentElement.style.setProperty('--border',colors.border);
    }
    if (colors.background) {
      document.documentElement.style.setProperty('--background',colors.background);
    }
    if (colors.bkgHover) {
      document.documentElement.style.setProperty('--bkgHover',colors.bkgHover);
    }
    if (colors.selectedText) {
      document.documentElement.style.setProperty('--selectedText',colors.selectedText);
    }
    if (colors.drDwnText) {
      document.documentElement.style.setProperty('--drDwnText',colors.drDwnText);
    }
    if (colors.drDwnBkg) {
      document.documentElement.style.setProperty('--drDwnBkg',colors.drDwnBkg);
    }
    if (colors.drDwnBkgHover) {
      document.documentElement.style.setProperty('--drDwnBkgHover',colors.drDwnBkgHover);
    }

}


function SortBar(props) {
   if (!true) {
      return null;
   } else {
     let iconColor = "#0071c2";
     if (props.colors) {
       setColors(props.colors);
       iconColor = props.colors.border ? props.colors.border : "#0071c2";
     }
      const style = props.margin ? {margin: props.margin} : {};
      return (
         <div className="sort-bar" style={style}>
            <ul className="sort-bar-list-cont">
                 {props.rows.map((row, index) => <BarLi {...row} key={index} iconColor={iconColor} />)}
            </ul>
         </div>
     );
   }
}


/*
<SortBar margin="cssString" rows colors/>
colors = {border: "#0071c2" (color x both border and text), background: "#fff",bkgHover: "#ebf3ff",selectedText:"#fff",drDwnText: "#333",drDwnBkg: "#fff",drDwnBkgHover: "#f5f5f5"}
rows = array of {type:, selected: ,text: ,content: ,click:, href:}
type = false  //"drop", "more"
selected = false  //true  if the item is selected and highlited with different color
text = "string"
content = array of {text: "", href:"", click: func}  Drop Down Menu only if type is more or drop. Each array element is an drop down button
click = funcName // Only one between click or href can be filled. The function name bust be passed as a prop handler from the outer component
    --- example click = openLink1, the outer component instead <SortBar rows={rows} openLink1={this.handleOpenLink1} />
href = "str" // Only one between click or href can be filled
*/

export default SortBar;

/*
<li className="sort-bar-li sort-bar-drDwn ">
      <a className="sort-bar-text">Dropdown</a>
      <ul className="drop-menu">
            <li><a>Fist One</a></li>
            <li><a>Seconfd One One</a></li>
      </ul>
</li>
<li className="sort-bar-li sort-bar-drDwn drDwn-more">
      <a className="flex-cen-cen h-100 pointer icon-drop"><MaterialIcon icon="more_horiz" size="20px" color="#0071c2"/></a>
      <ul className="drop-menu">
            <li><a>Fist One</a></li>
            <li><a>Seconfd One One</a></li>
      </ul>
</li>

*/
