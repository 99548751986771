import React from 'react';
import './luke.css';
import './w3.css';
import './login.css'; import Button from './Buttons/Button';
import {FormInput} from "./forms/forms.js";

class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {email: "", password: ""};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.resetChange = this.resetChange.bind(this);
      this.handleLogin = this.handleLogin.bind(this);
      this.handleClose= this.handleClose.bind(this);
      this.focus = this.focus.bind(this);
      this.alertHandler = this.alertHandler.bind(this);
      this.emailRef = React.createRef();
      this.passwordRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.show) {
      const emailErr = this.props.show[0];
      const passwordErr = this.props.show[1];
      if (emailErr) {
        this.emailRef.current.setRedBackground();
      }
      if (passwordErr){
        this.passwordRef.current.setRedBackground();
      }
    }

  }

  focus(element) {
    if (element == "email") {
      this.emailRef.current.autofocus();
    } else {
      this.passwordRef.current.autofocus();
    }
  }




  handleChange(e) {
    const target = e.target;
    const value =  target.value;
    this.setState({[target.name]: value});
  }

  resetChange(e) {
    const target = e.target;
    this.setState({[target.name]: ""});
  }

  handleSubmit(e) {
    let alertMessage = "";
    if (this.state.email == "") {
        if (alertMessage ==  "") {
            alertMessage = "E-Mail";
        } else {
            alertMessage = alertMessage + ", E-Mail";
        }
        this.emailRef.current.setRedBackground();
    }
    if (this.state.password == "") {
        if (alertMessage ==  "") {
            alertMessage = "Password";
        } else {
            alertMessage = alertMessage + ", Password";
        }
        this.passwordRef.current.setRedBackground();
    }
    if (alertMessage != "") {
        alertMessage = "Error: " + alertMessage + " cannot be empty!";
        //this.alertHandler(alertMessage);
        e.preventDefault();
    }
  }

  handleLogin() {
    const out = {email: this.state.email, password: this.state.password};
    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(out),};
    fetch("/log/in/", data).then((res) => res.json()).then((result)=>{ this.props.logHandler(result) }, (error) => alert("login error"));
  }


  alertHandler(error, inputName = null) {
    this.props.alertHandler(error, inputName);
  }

  handleClose() {
    const user = this.props.user;
    user.showLogin = false;
    this.props.logHandler(user);
  }




  render() {

    if (this.props.show) {
      return (
        <div>
          <div className="login-overlay w3-animate-opacity pointer" onClick={this.handleClose} />
          <div className="login w3-container w3-animate-opacity w3-card-resp w3-padding-24 w3-amber w3-round-large">
              <div className="w3-padding-16  w3-container w3-center w3-text-blue w3-bold w3-xlarge">Log In</div>

                  <FormInput name="email" label="E-Mail" value={this.state.email} handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="email" alertHandler={this.alertHandler}  ref={this.emailRef}/>
                  <FormInput name="password" type="password" label="Password" value={this.state.password} handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="" alertHandler={this.alertHandler}  ref={this.passwordRef}/>
                  <div className="w3-margin-top-24 w3-margin-bottom ">
                      <Button  text="Log In" click={this.handleLogin} color="a-btn-blue-1 w100"/>
                  </div>

              <div className="w3-text-blue w3-margin-bottom-24 flex-cen-start">
                  <input className="w3-blue w3-check" type="radio" value={null} />
                  <div className="w3-small  w3-container-8 linHe1 w3-padding-top-2">Keep me logged</div>
              </div>
              <div className="w3-row w3-text-indigo">
                  <div className="w3-col s6  w3-small w3-bold">
                  Forgot password?
                  </div>
                  <div className="w3-col s6 w3-small w3-bold w3-right-align">
                    <a className="w3-hover-text-blue" href="/CI/user/signup">
                      <div>Not a member yet?</div>
                      <div> Sign Up!</div>
                    </a>
                  </div>
              </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

}


export default Login;
