import React, {useState} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, contrastColor} from './Range/converters';
import Button from '../../Buttons/Button';
/*
    <div className=""></div>
*/

function Color(props) {
    const [bkg, setBkg] = useState(props.hex);
    const hsl = rgbToHsl(hexToRgb(props.hex));
    hsl.l = (hsl.l > 70) ? ( hsl.l - 10) : (hsl.l + 10);
    const mouseOver = () => (bkg != props.hex) ? null : setBkg(hslToCss(hsl));
    const mouseOut = () => (bkg == props.hex) ? null : setBkg(props.hex);
    const fontColor = rgbToHex(contrastColor(hexToRgb(props.hex)));
    return (
        <div className="cl-color-box pointer" style={{backgroundColor:bkg, color: fontColor}} onClick={()=>props.select(props.hex)} onMouseOver={mouseOver} onMouseOut={mouseOut}>{props.hex}</div>
    );


}

function BucketView(props) {
    const colors = props.colors;
   if ( props.show != "bucket") {
     return null;
   } else {
     return (
       <div className="w3-content-1300 w3-section-32 cl-colors-list-cont">
       <div className="flx-btw cl-list-header-cont">
             <h3 className="cl-colors-list-title">Bucket</h3>
             <div className="cl-colors-list-actions flex-cen-cen h100">
                 <Button click={props.close} svg="close" color="a-btn-azure" size="a-btn-top-icon" filling="a-btn-filled"/>

             </div>
       </div>
            <div className="flex-cen-cen a-wrap">
                {colors.map((row, index)=><Color key={index} index={index} hex={row} select={props.select}/>)}
            </div>

       </div>
     );
   }
}


export default BucketView;
