import React from 'react';
import ButtonsSamples from './ButtonsSamples/ButtonsSamples'; import Colors from './Colors/Colors';
/*
    <div className=""></div>
*/


function Samples(props) {
   if (props.show == "buttons") {
     return (
       <ButtonsSamples buttons={props.buttons} {...props.colors} mainShare={props.mainShare}/>
     );
   } if (props.show == "colors") {
     return (
       <Colors {...props.colors} mainShare={props.mainShare}/>
     );
   } else {
     return null;
   }
}


export default Samples;
