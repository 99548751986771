import React, {useState, memo, useEffect} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, contrastColor} from '../converters.js';
 import Button from '../../../../Buttons/Button'; import SvgIcon from '../../../../SvgIcon';
// <div className=""></div>
const ScBlToolTip = (props) => {
      const [clicked, setClicked] = useState(false);
      const [toolTipPos, setToolTipPos] = useState(null);
      useEffect(()=> {
        if (props.selectedToolTip === props.index) {
            const scrollBarWidth = 7;
            const elem = document.getElementById("sclToolTip "+props.index+props.type);
            const sclBlock= document.getElementById("sclblock"+props.index+props.type);
            const elRect = elem.getBoundingClientRect();
            let left = elRect.left;
            let right = elRect.right;
            const sclBockLEl = sclBlock.getBoundingClientRect();
            let tipArrowPos = sclBockLEl.left < 20 ? 8 : sclBockLEl.left - 20 + sclBockLEl.width / 2 - 6;
            if (left < 0) {
              const leftMargin = sclBockLEl.left < 20 ? 5 : 20;
              left = Math.abs(left) + leftMargin;
              setToolTipPos([left,tipArrowPos]);
            } else if (left < 20) {
              left = 20 - left;
              setToolTipPos([left,tipArrowPos]);
            } else if (right > (window.innerWidth )) {
              const rightMargin = sclBockLEl.right > (window.innerWidth - 20  + scrollBarWidth) ? 5 : 20;
              left = window.innerWidth - right - rightMargin;
              tipArrowPos = sclBockLEl.right > (window.innerWidth - 20  + scrollBarWidth) ? (240 - 20) : 240 - ((window.innerWidth - 20) - sclBockLEl.right) - sclBockLEl.width / 2 - 6;
              setToolTipPos([left,tipArrowPos]);
            } else if (right > (window.innerWidth - 13)) {
              const rightMargin = sclBockLEl.right > (window.innerWidth - 20  + scrollBarWidth) ? 5 : 20;
              left = right + rightMargin - window.innerWidth;
              tipArrowPos = sclBockLEl.right > (window.innerWidth - 20  + scrollBarWidth) ? (240 - 20) : 240 - ((window.innerWidth - 20) - sclBockLEl.right) - sclBockLEl.width / 2 - 6;
              setToolTipPos([left,tipArrowPos]);
            } else {
              setToolTipPos(null);
            }

        } else {
          setToolTipPos(null);
        }

      },[props.selectedToolTip]);
      const classClicked = (type)=>{setClicked(type); setTimeout(()=>setClicked(false), 1000)};
      const classClick = (text, type) => navigator.clipboard.writeText(text).then(()=>classClicked(type));
      const copiedAnimation = clicked ? "w3-animate-opacity" : "w3-hide";
      const animateHex = (clicked == "hex") ? "w3-animate-opacity" : "";
      const animateRgb = (clicked == "rgb") ? "w3-animate-opacity" : "";
      const animateHsl = (clicked == "hsl") ? "w3-animate-opacity" : "";
      const rgb = props.rgb;
      const hsl = props.hsl;
      const hex = props.hex;
      const rgbString = rgbToCss(rgb);
      const hslString = hslToCss(hsl);
      const showMobile = (props.selectedToolTip === props.index) ? {display: "block"} : {};
      let arrowPos = toolTipPos ? {left: toolTipPos[1]+"px", right: "auto"} : {};
      if (toolTipPos) {
        showMobile.transform = `translateX(${toolTipPos[0]}px)`;
      }
      return (
         <div className="sc-block-tooltip w3-animate-opacity " style={showMobile} id={"sclToolTip "+props.index+props.type} >
             <div className={"cl-copied-clip-msg w3-padding-top-8 "+copiedAnimation} key={clicked}>Copied</div>
             <div className="sc-block-tooltip-arrow" style={arrowPos}></div>
             <div className={"pointer w3-hover-text-blue " + animateHex} onClick={(e)=>{classClick(hex, "hex");e.stopPropagation();}}>{hex}</div>
             <div className={"pointer w3-hover-text-blue " + animateRgb} onClick={(e)=>{classClick(rgbString, "rgb");e.stopPropagation();}}>{rgbString}</div>
             <div className={"pointer w3-hover-text-blue " + animateHsl} onClick={(e)=>{classClick(hslString, "hsl");e.stopPropagation();}}>{hslString}</div>
             <div className="flex-cen-cen w3-margin-top-12">
            <div className="w3-display-topright a-mrT-12 a-mrR-12 phone-only" onClick={(e)=>{props.close();e.stopPropagation();}}><SvgIcon icon="close" size="26px" color="red" /></div>
                     <Button size="a-btn-tiny-2" color="a-btn-blue-1" text="Open" click={()=>props.setHex(hex)} className="w100" />
             </div>
        </div>
    );
};

export default ScBlToolTip;
