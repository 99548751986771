import React from 'react';import Button from '../../Buttons/Button';import {FormInput, DivSelect} from "../../forms/forms.js";

/*
    <div className=""></div>
*/


function FormMenuBtn(props) {
  function handleDelete() {
      props.handleMenuRowsArray("deleteRow", props.index, props.ulIndex)
  }

  let selectedMenuOptions = [];
  const targetOptions = [["Url", "url"], ["Meta","meta"], ["Submenu", "subMenu"]];
  const iconAdjOptions = [["", null],["+ 1px","inc1"],["+ 2px","inc2"],["+ 3px","inc3"],["+ 4px","inc4"],["- 1px","dec1"],["- 2px","dec2"],["- 3px","dec3"],["- 4px","dec4"]];
  if (props.subKeys) {
    for (let i=0; i<props.subKeys.length; i++) {
      if (props.subKeys[i][1] != props.selectedSub) {
        selectedMenuOptions.push(props.subKeys[i]);
      }
    }
  }
  selectedMenuOptions.push(["Delete", null]);
  let metaOptions = [];
  if (props.metas) {
    for (let i=0; i<props.metas.length; i++) {
        metaOptions.push([props.metas[i].btnName, props.metas[i].id]);
    }
  }
  metaOptions.push(["Delete", null]);
  const iconColors = [["default", null],["violet","#8876fe"], ["magenta", "#d71bce"],["indigo","#622aff"],["grape", "#6529f8"],  ["azure", "#027fff"],["tomato", "#f63e54"],
  ["orange", "#e66702"],["green", "#0c9d46"],["forest", "#0c8346"],["sea-green", "#099b9d"],  ["teal", "#049cb7"],["aqua", "#039e8f"],  ["moonstone", "#2294b1"],
  ["midnight", "#1b23b3"],  ["sapphire","#3d44c3"]];
  return (
    <div className="w3-section w3-row w3-display-container">

        <FormMenuBtnRowInput  field="title" index={props.index} ulIndex={props.ulIndex} {...props} className="w3-col m2 w3-container-8" label="Button Name" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="icon" index={props.index} ulIndex={props.ulIndex} {...props} cols={6} colsDisp={1}  iconsSelect={true} className="w3-col m2 w3-container-8" label="Button Icon" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="iconColor" index={props.index} colorSelector={true} ulIndex={props.ulIndex} cols={2} colsDisp={1}{...props} className="w3-col m2 w3-container-8" options={iconColors} label="Icon Color" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="inconSizeAdj" index={props.index}  ulIndex={props.ulIndex} options={iconAdjOptions} {...props} className="w3-col m2 w3-container-8" label="Icon Size Adjustment" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="target" index={props.index} ulIndex={props.ulIndex} {...props} className="w3-col m2 w3-container-8" options={targetOptions} label="Target" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="meta" targeted={true} index={props.index} ulIndex={props.ulIndex}  {...props} className="w3-col m2 w3-container-8" options={metaOptions} label="Meta ID" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowInput field="url" targeted={true} index={props.index}  ulIndex={props.ulIndex} {...props} className="w3-col m2 w3-container-8"  label="" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="subMenu" targeted={true} index={props.index} ulIndex={props.ulIndex} {...props} className="w3-col m2 w3-container-8" options={selectedMenuOptions} label="Submenu" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="adminButton" index={props.index} ulIndex={props.ulIndex} {...props} className="w3-col m1 w3-container-8" rangeStart={0} rangeEnd={5} label="Admin" handleChange={props.handleMenuBtnRow}/>
        <FormMenuBtnRowDivSelect field="userButton" index={props.index} ulIndex={props.ulIndex} {...props} className="w3-col m1 w3-container-8" options={["in","out", ""]} label="User" handleChange={props.handleMenuBtnRow}/>
        <div className="w3-display-right" style={{marginRight: "-65px"}}>
                <Button svg="delete" click={handleDelete} color="a-btn-orange-2" size="a-btn-size-2-icon w3-margin-left-24"   className="" />
        </div>






    </div>
  );
}





function FormMenuBtnRowInput(props) {
    function handleChange(e) {
      props.handleChange(e.target.value, props.index, props.field, props.ulIndex);
    }
    if (props.targeted && (props.target != props.field)) {
        return null;
    }

    return (
          <FormInput name={props.field} label={props.label ? props.label : props.field} value={props[props.field]} maxWidth="" className={props.className} handleChange={handleChange}  color="w3-light-gray" validate=""  />
    );
}




function FormMenuBtnRowDivSelect(props) {
    function handleChange(value) {
      props.handleChange(value, props.index, props.field, props.ulIndex);
    }
    if (props.targeted && (props.target != props.field)) {
        return null;
    }


    return (
          <DivSelect name={props.field} label={props.label ? props.label : props.field} cols={props.cols} colorSelector={props.colorSelector} colsDisp={props.colsDisp} iconsSelect={props.iconsSelect} value={props[props.field]} rangeStart={props.rangeStart}
          rangeEnd={props.rangeEnd} options={props.options} handleOptionClick={handleChange} className={props.className} color="w3-light-gray" validate=""  />
    );
}




export default FormMenuBtn;
