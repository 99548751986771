import React, {useState, useEffect, useRef} from 'react'; import Button from '../../../../Buttons/Button';import {FormInput, DivSelect} from '../../../../forms/forms';
import './converter.css'; import Hex from './Hex'; import Triplet from './Triplet';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss, hsvToCss, contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../converters.js';
import SvgIcon from '../../../../SvgIcon';
/*
    <div className=""></div>
*/



function Converter(props) {
    const {focused, setFocused} = props;
    const [from, setFrom] = useState("HEX");
    const [error, setError] = useState(false);
    const timer = useRef(null);
    const btnSize = "a-btn a-btn-height-40 a-btn-container-5 a-btn-font-15 a-btn-brdRad-50";
    const errorTimeout = () => {if (timer.current) clearTimeout(timer.current); timer.current = setTimeout(()=>setError(false), 10000)};
    const cancelTimer = ()=> {setError(false);if (timer.current) {clearTimeout(timer.current);} };
    if (window.innerWidth < 734 && (props.visibleSections !== "converter")) {
      return null;
     } else {
       const btnOpt = {size:(window.innerWidth < 734 ? "a-btn-tiny-5 a-btn-font-15 a-btn-height-30 a-btn-container-10": "a-btn-tiny-5 a-btn-height-25 a-btn-container-10 "), color:"a-btn-green-1", className:"cl-conv-fromBtn-width", activeColor: "a-btn-orange-1"};
       const BtnMap = () => ["HEX","RGB","HSL","HSV"].map((color)=><Button text={color} {...btnOpt} active={color === from} key={color} click={()=>{setFrom(color); setError(false);setFocused(false); }} />);
       const displayColor = {"HEX": props.color.hex.toUpperCase(), "RGB": rgbToCss(props.color.rgb), "HSL": hslToCss(props.color.hsl),"HSV": hsvToCss(props.color.hsv)};
       return (
         <div className="cl-actions-comverter-cont">
            <h3 className={"a-font-16 a-vw-font-6-6-s noselect w400 w3-center a-pdT-4 "+((props.scarlet || props.focused) ? "w3-hide":"")}>Colors Converter</h3>
            <div className={"a-pd-20 a-bkg-lavender a-section-24 w3-round "+(focused ? "a-section-14-s":"")}>
                <h4 className="cl-conv-from">From </h4>
                <div className="flx-btw">
                    <BtnMap />
                </div>
                <Hex from={from} hex={props.color.hex} setHex={props.handlers.hex} btnSize={btnSize} setError={setError} error={error} cancelTimer={cancelTimer} errorTimeout={errorTimeout} focused={focused} setFocused={setFocused} />
                <Triplet from={from} handlers={props.handlers} color={props.color}  btnSize={btnSize} setError={setError} error={error} cancelTimer={cancelTimer} errorTimeout={errorTimeout} focused={focused} setFocused={setFocused}/>
            </div>

            <div className={"phone-only a-section-24 a-bkg-khaki  w3-round a-pd-20 "+(focused ? "w3-hide a-section-12-s" : "")}>
                <ConvHead from={from} displayColor={displayColor}/>
                <SvgIcon icon="south" color="red" size="45px" className="flex-cen-cen a-section-16"/>
                <div className="a-pd-16 a-bkg-azure w3-round">
                      {["HEX","RGB","HSL","HSV"].map((type) => <ConvRow from={from} type={type} displayColor={displayColor}/>)}
                </div>


            </div>
         </div>
       );
     }
}

function ConvHead(props) {
  const [copiedMsg, setCopiedMsg] = useState(false);
  const clipboard = () => navigator.clipboard.writeText(props.displayColor[props.from]).then(()=>{setCopiedMsg(true);setTimeout(()=>setCopiedMsg(false), 1500);});
  const CopyCss = () => copiedMsg ? <div className="a-font-19 lh-1 a-font-dodgerblue a-mrL-24">Copied</div> : <SvgIcon icon="content_copy" size="20px" color="green" className="a-mrL-16 a-pdB-2 " click={clipboard}/>

  return (
    <div className="flex-cen-cen">
          <h3 className="a-font-24 a-font-dodgerblue w3-center lh-1 a-mrT-0 a-mrB-0">{(props.from==="HEX"? "HEX " : "")+props.displayColor[props.from]}</h3>
          <CopyCss />
    </div>
  );

}


function ConvRow(props) {
  const [copiedMsg, setCopiedMsg] = useState(false);
  const hexPrefix = (props.type === "HEX") ? "Hex ": "";
  const fix = (props.from === props.type) ? "w3-hide" : ( ((props.from === "HSV") && (props.type === "HSL")) ? "a-mrB-0":"");
  const clipboard = () => navigator.clipboard.writeText(props.displayColor[props.type]).then(()=>{setCopiedMsg(true);setTimeout(()=>setCopiedMsg(false), 1500);});
  const CopyCss = () => copiedMsg ? <div className="a-font-19 lh-1 a-font-dodgerblue">Copied</div> : <SvgIcon icon="content_copy" size="18px" color="green" className="a-pdB-1 " click={clipboard}/>

  return (
    <div className={"a-font-19 lh-1 a-mrB-16 a-mrB-last-0 noselect flx-btw "+fix}>
          <div> {hexPrefix + props.displayColor[props.type]}</div>
          <CopyCss />
    </div>
  );
}

export default Converter;
