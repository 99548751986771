import React from 'react';
import './embeddedPic.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../../Link';

/*
    <div className=""></div>
*/


function EmbeddedPic(props) {
  if (props.src) {
    const Caption = (props) => (props.caption) ? (<div className="embedded-caption "><span className="w3-bold">{props.captionHead} </span><span>{props.caption}</span></div>) :  null;
    const padding = props.sidePadding ? {padding: "0 " + props.sidePadding} : {};
    return (
      <div className="embedded-outer ">
            <div className={"embedded-container " + (!props.caption ? "no-caption" : "") + " " + (props.className ? props.className : "")} style={padding}>
                  <div className="embedded-image ">
                      <img src={props.src} alt={props.alt}/>

                  </div>
                  <Caption caption={props.caption} captionHead={props.captionHead}/>
            </div>
      </div>
    );
  } else {
    return null;
  }
}


export default EmbeddedPic;


// The following block should have no float, and should not be a children but a sibling and it will automaticly follow the embedded pic up to the pic total height including the caption if present
// example <EmbeddedPic ... /><div> { a paragraph} </div>
