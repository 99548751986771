import React, {useEffect, useState, useRef} from 'react'; import './gradient.css';
import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import ScarletSectionHeader from '../Parts/ScarletSectionHeader'; import EditButtons from '../Parts/EditButtons';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb,contrastHexColor} from '../../converters.js';
import Handle from './Handle'; import MoveGradient from './MoveGradient'; import DirectionSelector from './DirectionSelector';
import HeightSelector from './HeightSelector'; import AngleRangeEdit from './AngleRangeEdit'; import AngleSelector from './AngleSelector';
import PositionRangeEdit from './PositionRangeEdit';import WidthSelector from './WidthSelector'; import TypeSelector from './TypeSelector';
import ShapeSelector from './ShapeSelector';import RadialSizeSelector from './RadialSizeSelector';import AtPositionRange from './AtPositionRange';
import RangeSelector from './RangeSelector';
/*
    <div className=""></div>
*/

function GradientBkg(props) {
  const {sclColors, setSclColors, hideControls, index, select,  gradient, setGradEl, gradEl, selectedGradientType, selectGradientType, selectedGradient, selectGradient, contrastBkg} = props.shared;
  const [selectedRange, setRange] = useState("position"); // ["position","angle", "atPosition"]
  let [touches, setTouches] = useState([]);
  let [touchDirection, setTouchDirection] = useState(null);
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  useEffect(()=>{
      myRef2.current.addEventListener("touchmove", touchMove, {passive: false});
      return ()=> { if (myRef2.current) myRef2.current.removeEventListener("touchmove", touchMove, {passive: false})}
    });
  const bkg = sclColors[index].bkg;
  const selGradient = gradient[selectedGradient];
  const colors = selGradient.colors;
  const direction = selGradient.direction;
  const angle = selGradient.angle;
  const type = selGradient.type;

  if (!gradient || !Array.isArray(colors)) return null;
  let dirAndPos;
  if (type === "linear-gradient") {
    dirAndPos = direction === "angle" ? angle+"deg" : direction;
  } else if (type === "radial-gradient") {
    dirAndPos = `${selGradient.shape} ${selGradient.radialSize} at ${selGradient.xPos}% ${selGradient.yPos}%`;
  } else if (type === "conic-gradient") {
    dirAndPos = `from ${selGradient.angle}deg at ${selGradient.xPos}% ${selGradient.yPos}%`;
  }
  let others = "";
  for (let i=0; i<colors.length; i++) {
    const cssPos = (type === "conic-gradient") ? (colors[i].deg+"deg") : (colors[i].position+"%");
    others += ","+rgbToCss(colors[i].color)+" "+cssPos;
  }

  const gradientCss = `${type}(${dirAndPos} ${others})`;

  const deleteColor = (e)=> {
    if (colors.length > 1) {
      colors.splice(gradEl, 1);
      spreadPositions();
      if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);  setGradEl(0);
    }
  }

  const addColor = ()=> {
    colors.splice(gradEl+1, 0,{color:{r:0,g:0,b:0,a:1}, position: null, deg:0});
    spreadPositions();
    setGradEl(gradEl+1);selectGradientType("color");
    if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; select("gradient");
    setSclColors([...sclColors]); props.setRgb({r:0,g:0,b:0,a:1});
  }

  const addGradient = ()=> {
    gradient.push({type: "linear-gradient", shape:"ellipse", radialSize:"closest-side", direction: "to bottom",angle:0, xPos: 50, yPos:50, height: "50vh", width: "100%", texts:[], colors: [{color:{r:0,g:0,b:0,a:1}, position: 0, deg:0},{color:{r:0,g:255,b:255,a:1}, position: 50, deg:120},{color:{r:255,g:106,b:0,a:1}, position: 100}]});
    selectGradient(gradient.length -1);
    setGradEl(0);selectGradientType("color");
  }

  const deleteGradient = ()=> {
    gradient.splice(selectedGradient,1);
    selectGradient(0);
    setGradEl(0);selectGradientType("color");
  }

  const spreadPositions = ()=> {
    const width = Math.round((100 / (colors.length -1))*10) / 10;
    for (let i = 0; i<colors.length-1; i++) {
      colors[i].position = (i * width);
    }
    colors[colors.length -1].position = 100;
  }

  const handleClick = (e) => {
    console.log("gradient click");
      e.stopPropagation();
      if (!["to top","to bottom","to left","to right"].includes(direction) || (type !== "linear-gradient")) {
        select("gradient");selectGradientType("color");
        return null; // It would be too complicated to map the position with angle directions, so we only enable click for bottom, top left and right
      }
      const y = e.clientY;
      const x = e.clientX;
      const rect = document.getElementById("gradient-container").getBoundingClientRect();
      let diff;
      if (direction === "to top") {
        diff = rect.bottom - y;
      } else if (direction === "to bottom") {
        diff = y - rect.top;
      } else if (direction === "to right"){
        diff = x - rect.left;
      } else if (direction === "to left") {
        diff = rect.right - x;
      }
      const dimension =  ["to top","to bottom"].includes(direction) ? rect.height : rect.width;
      let position = (Math.round(diff/dimension *100 * 10) / 10); // we want one decimal, so we multiply the value x 10, then we round it to get an integer an we divide by ten to get one decimal
      let before = 0, after = 0;
      let choice;
      if (position <= colors[0].position) {
        choice = 0;
      } else if (position >= colors[colors.length-1].position) {
        choice = colors.length-1;
      } else {
        for (let i=0; i<colors.length-1; i++) {
            if ((position >= colors[i].position) &&  (position < colors[i+1].position)) {
              before = i; after = i+1;
            }
        }
        choice =  (position - colors[before].position) <= (colors[after].position - position) ?  before : after;
      }

      select("gradient");setGradEl(choice);selectGradientType("color"); props.setRgb(colors[choice].color);
  };

  const touchStart = (e)=> {
    e.stopPropagation();
    //console.log("start: "+ e.changedTouches.length)
    const touchPoints = e.changedTouches;
    for (let i=0; i<touchPoints.length; i++) {
      touches.push({identifier: touchPoints[i].identifier, clientX:touchPoints[i].clientX, clientY: touchPoints[i].clientY});
    }
    setTouches([...touches]);
  }
  const touchMove = (e) => {
      if (touches.length === 2) e.preventDefault();
      //console.log("move: "+ e.changedTouches.length)
      if (e.changedTouches.length === 2 && touches.length === 2) {
          const cTouches = e.changedTouches;
          const x1Diff = cTouches[0].clientX - touches[ongoingTouchIndexById(cTouches[0].identifier)].clientX;
          const x2Diff = cTouches[1].clientX - touches[ongoingTouchIndexById(cTouches[1].identifier)].clientX;
          const y1Diff = cTouches[0].clientY - touches[ongoingTouchIndexById(cTouches[0].identifier)].clientY;
          const y2Diff = cTouches[1].clientY - touches[ongoingTouchIndexById(cTouches[1].identifier)].clientY;
          const DeltaX = Math.abs(x1Diff-x2Diff);
          const DeltaY = Math.abs(y1Diff-y2Diff);
          const action = DeltaX > DeltaY ? "width" : "height";
          if (action === "width") {
            if (!touchDirection) {
              setTouchDirection("width");
            } else if (touchDirection !== "width") {
              return;
            }
            const left = (cTouches[0].clientX < cTouches[1].clientX) ? 0 : 1;
            const right =  left === 0 ? 1 : 0;
            const xChange = cTouches[right].clientX - touches[ongoingTouchIndexById(cTouches[right].identifier)].clientX - (cTouches[left].clientX - touches[ongoingTouchIndexById(cTouches[left].identifier)].clientX);
            const cWidtht = myRef.current.getBoundingClientRect().width;
            touches = [];
            for (let i=0; i<cTouches.length; i++) {
              touches.push({identifier: cTouches[i].identifier, clientX:cTouches[i].clientX, clientY: cTouches[i].clientY});
            }
            selGradient.width = (cWidtht + xChange >= 200) ? Math.round(cWidtht + xChange) : 200;
          } else {
            if (!touchDirection) {
              setTouchDirection("height");
            } else if (touchDirection !== "height") {
              return;
            }
            const top = (cTouches[0].clientY > cTouches[1].clientY) ? 0 : 1;
            const bottom = top === 0 ? 1 : 0;
            const yChange = cTouches[top].clientY - touches[ongoingTouchIndexById(cTouches[top].identifier)].clientY - (cTouches[bottom].clientY - touches[ongoingTouchIndexById(cTouches[bottom].identifier)].clientY);
            const cHeight = myRef.current.getBoundingClientRect().height;
            touches = [];
            for (let i=0; i<cTouches.length; i++) {
              touches.push({identifier: cTouches[i].identifier, clientX:cTouches[i].clientX, clientY: cTouches[i].clientY});
            }
            selGradient.height = (cHeight + yChange >= 200) ? Math.round(cHeight + yChange) : 200;
          }
          setSclColors([...sclColors]);
          setTouches([...touches]);
    }
  }

  function ongoingTouchIndexById(idToFind) {
    for (let i = 0; i < touches.length; i++) {
        const id = touches[i].identifier;
        if (id === idToFind) {
          return i;
        }
    }
    return -1;    // not found
  }

  const touchEnd = (e) => {
    const touchPoints = [...e.changedTouches];
    setTouchDirection(null);
    //console.log("ended indentifier: "+ touchPoints[0].identifier);
    for (let i=0; i<touchPoints.length; i++) {
        const l = ongoingTouchIndexById(touchPoints[i].identifier);
        if (l>-1) {
        const befLength = touches.length;
        touches.splice(l,1);
        const afterLenght = touches.length;
        //console.log("before:"+ befLength + ", after: "+afterLenght);
        setTouches([...touches]);
      }
    }

  }

  const touchCancel = (e) => {
    console.log("CANCELLED: "+ touchPoints.length);
    const touchPoints = e.changedTouches;

    for (let i=0; i<touchPoints.length; i++) {
      const l = ongoingTouchIndexById(touchPoints[i].identifier);
      if (l) {
        touches.splice(l,1);
        setTouches([...touches]);
      }
    }

  }

  const sectionPad = hideControls ? "a-section-30" : " ";
  const contrastTop =  contrastColorRgbToHex(colors[0].color);
  const contrastBottom =  contrastColorRgbToHex(colors[colors.length-1].color);
  const contrastBkgTop = (contrastTop === "#ffffff") ? {backgroundColor: "rgba(255,255,255,.3)", color: "black"} :  {backgroundColor: "rgba(0,0,0,.3)", color: "white"};
  const contrastBkgBottom = (contrastBottom === "#ffffff") ? {backgroundColor: "rgba(255,255,255,.5)", color: "black"} :  {backgroundColor: "rgba(0,0,0,.5)", color: "white"};
  const otherEditButtons = [{name: "Add color", handler: addColor}];
  if (selectedGradientType === "color") otherEditButtons.push({name:"Delete Color "+gradEl, handler: deleteColor});
  return (
      <div className={sectionPad} onTouchStart={touchStart} onTouchEnd={touchEnd} onTouchCancel={touchCancel} ref={myRef2}>

          <div className="scroll-x-auto no-scr-bar a-pdB-80" >
              <div className="lr-margin-auto p-rel" style={{background:gradientCss,  height:selGradient.height, width: selGradient.width, zIndex: 5}} id="gradient-container" onClick={handleClick}  ref={myRef}>
                    {colors.map((row,gradIndex)=><Handle {...row} gradIndex={gradIndex} shared={props.shared} setRgb={props.setRgb} key={gradIndex+"gradient-handle"}/>)}
              </div>
          </div>
          <div className="a-pdS-16 p-abs a-pWidth-100" style={{borderTop:"1px solid white", bottom:0}}>
              <PositionRangeEdit shared={props.shared} setRgb={props.setRgb} selectedRange={selectedRange} bkg={contrastBkgBottom}/>
              <AngleRangeEdit shared={props.shared} selectedRange={selectedRange} bkg={contrastBkgBottom}/>
              <AtPositionRange shared={props.shared} selectedRange={selectedRange} bkg={contrastBkgBottom}/>
              <EditButtons add={addGradient} name="Gradient" noMargin={true} topDrop={true} bkg={contrastBkgBottom} selected={selectedGradient} delete={deleteGradient} hideControls={hideControls} others={otherEditButtons}>
                  <TypeSelector shared={props.shared}/>
                  <RangeSelector shared={props.shared} selectedRange={selectedRange} setRange={setRange} />
                  <ShapeSelector shared={props.shared}/>
                  <RadialSizeSelector shared={props.shared}/>
                  <DirectionSelector shared={props.shared} />
                  <AngleSelector shared={props.shared}/>
                  <HeightSelector shared={props.shared} />
                  <WidthSelector shared={props.shared} />
                  <MoveGradient shared={props.shared}/>
              </EditButtons>
          </div>
      </div>

  );
}



/*
headers

<div className="a-pdS-20" style={{backgroundColor:rgbToCss(colors[0].color), borderBottom:"1px solid white", position: "relative", zIndex: 1}} onTouchStart={(e)=>e.stopPropagation()}>
    <ScarletSectionHeader selected={selectedGradient} noMargin={true} select={selectGradient} length={gradient.length} bkg={contrastBkgTop} name="Gradient" hideControls={hideControls}/>
</div>

*/

export default GradientBkg
