import React, {useState, useRef, useEffect} from 'react';

import "./topBar.css";
import Link from '../../Link';import SvgIcon from '../../SvgIcon';
import LeftBar from './LeftBar'; import CenterBar from './CenterBar'; import RightBar from './RightBar';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save
//   <div className=""></div>

function Overlay(props) {
    if (props.overlay === true) {
      return <div className="top-bar-container-overlay"></div>;
    } else if (props.overlay) {
      return <div className={props.overlay}></div>;
    } else {
      return null;
    }
}


function TopBar(props) {
      //media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
      const isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
      const isSmarthPhone = useMediaQuery({ maxWidth: 734 });
      const isTablet = useMediaQuery({ maxWidth: 1068, minWidth: 735 });
      /******* Media Queries ****/
      const [y, setY] = useState(null);
      const expanderRef = useRef(0);
      var sheet = document.styleSheets[0];
      const height = props.topBarHeightsObj;

      useEffect(()=> { // prevent scrolling using preventDefault while pulling down the expander
        if (!props.showTopBar)  {
          expanderRef.current.addEventListener("touchmove", prevDef, {passive: false}); // no need for a remover because the dom element is removed and so the listener
        }
      },[props.showTopBar]);

      useEffect(()=>{

        if  (typeof height === 'object' && !Array.isArray(height) && height !== null) {
            const unit = height.unit ? height.unit : "px";
            if (height.l) sheet.insertRule(`.top-bar-vars {--tBarHeight-l: ${height.l + unit} !important;}`, sheet.cssRules.length);
            if (height.m) sheet.insertRule(`.top-bar-vars {--tBarHeight-m: ${height.m + unit} !important;}`, sheet.cssRules.length);
            if (height.s) sheet.insertRule(`.top-bar-vars {--tBarHeight-s: ${height.s + unit} !important;}`, sheet.cssRules.length);
        } else {
          if (height) sheet.insertRule(`.top-bar-vars {--tBarHeight-l: ${height}px !important;}`, sheet.cssRules.length);
        }


      },[height]);

      const touchEnd = (e)=> {
          const newY = e.changedTouches[0].clientY;
          if ((y - newY) > 35) props.setTopBar(false);
          setY(null);
      };

      const prevDef = (e)=> e.preventDefault();

      const openBar = (e)=> {
          const newY = e.changedTouches[0].clientY;
          if ((y - newY) < -35) props.setTopBar(true);
          setY(null);
      };

      const topBar = {};
      for (let i=0; i<props.topBar.length; i++) {
        topBar[props.topBar[i].name] = props.topBar[i].buttons;
      }

      if ( !topBar.centerLeft || !topBar.centerRight || !topBar.left || !topBar.right) {
        return null;
      } else {
        const position = (props.position === "fixed" && !(isLandscape && isSmarthPhone)) ?  "top-bar-fixed " : ((props.position === "absolute"  && !(isLandscape && isSmarthPhone)) ? "top-bar-absolute " : "");
        const color = props.color ? props.color + " top-bar-hsla-bkg " : "top-bar-no-bkg";
        const container = "top-bar-container noselect " + position;
        const btnProps = {bot: props.bot, clickHandler: props.clickHandler, isSmarthPhone: isSmarthPhone, back: props.back, user: props.user, noFilledBkg: props.noFilledBkg};
        const topPadding = (["absolute","fixed"].includes(props.position) && !(isLandscape && isSmarthPhone) && !props.noPadding)  ? "top-bar-top-padding " : "";
        btnProps.buttonStyle = props.buttonStyle ? props.buttonStyle : "filled-dark";
        btnProps.barIconColor = props.barIconColor; // Legacy props, now color uses the color class as text button, no need to define icon color anymore
        btnProps.buttonSize = props.buttonSize ? props.buttonSize : {icon: "a-btn-top-icon", mixed: "a-btn-top-icon-text", text: "a-btn-top"};
        const leftBarDropRight = false; // if the drop down is at least the third element leave it to false, otherwise if the drop down overflows to the left set it to this to true in order to expand to the right side
        const sizes = props.sizes ? props.sizes : "top-bar-size-def";




        if (!props.showTopBar) {
          return (
                <div className="  " onTouchEnd={openBar} onClick={()=>props.setTopBar(true)} onTouchStart={(e)=>setY(e.changedTouches[0].clientY)}
                style={{position:"fixed", top:0, left: "calc(50% - 40px)",  zIndex:1000, width: "80px", height: "19px"}} ref={expanderRef}>
                      <div className="a-bkg-blue-1 a-pdB-2 flex-cen-cen lr-margin-auto" style={{borderRadius:"0 0 17px 17px",width:"40px", height:"16px"}}>
                            <SvgIcon icon="expand_more" size="18px" color="white"/>
                      </div>
                </div>
          );

        } else {
          return (
            <div className={sizes+" no-touch  " + color} onTouchEnd={touchEnd} onTouchStart={(e)=>setY(e.changedTouches[0].clientY)}>
                <div className={topPadding}>
                    <div className={container}>
                        <div className="tbar-flex-cont ">
                            <LeftBar {...topBar} btnProps={btnProps} dropRight={leftBarDropRight}  />
                            <CenterBar  {...topBar} btnProps={btnProps}  />
                            <RightBar {...topBar} btnProps={btnProps}  />
                            <Overlay overlay={props.overlay} />
                        </div>
                    </div>
                </div>
            </div>
          );
        }


      }
}


export default TopBar;



/**** Button object
If the buttons contains text and icons put fill both otherwise fill only one. Icon get displayed first. For frop donw don't put the icon name, just the text. The drop icon is automatic when dropRows is filled
{
  icon: "iconName", // if it's a d
  text: "str",


}




*****/
