import React, {useEffect, useState} from 'react'; import ALink from './ALink';
import SvgIcon from '../../../../../SvgIcon'; import './hex.css';import Link from '../../../../../Link';

const HexSectionsMenu = (props) => {
  let [selected, setSelected] = useState("pickers");
  useEffect(()=>{
    if (props.visibleSections == "all") {
      window.addEventListener("scroll", selectOnScroll);
      return ()=> window.removeEventListener("scroll", selectOnScroll);}
    },[props.visibleSections,selected] // we need to recall the scroll listener also when selected changes, because selectOnScroll doesn't get the updated value of selected when we set the state, so we have to close the handler and call t again with the updated value

  );
  useEffect(()=>{if (props.visibleSections != "all") setSelected(props.visibleSections);},[props.visibleSections]);

  const selectOnScroll = ()=> {

      const rect = (type) => {const el = document.getElementById(type);const out = {top: 0, height: 0};  if (el) { const rect = el.getBoundingClientRect(); out.top = rect.top; out.height = rect.height;} return out;}
      const types = ["pickers","mappings"];
      for (let i=0; i<types.length; i++) {
        const {top, height} = rect(types[i]);
        if ((top > (window.innerHeight * 0.5 - height)) && (top < (window.innerHeight * 0.5))) {
          if (selected != types[i]) setSelected(types[i]);
        }
      }
  };


  const links = [{name:"Pickers", id:"pickers"},{name:"Mappings", id:"mappings"},{name:(!props.scarlet ? "Activate Scarlet" : "Deactivate Scarlet"), handler: ()=>props.setScarlet(selected)}, {name: "Converters", id: "converter", handler: ()=>{props.setVisibleSections("converter"); setSelected("converter")}},{name: "Save Color", id: "color-handling", handler: ()=>{props.setVisibleSections("color-handling"); setSelected("color-handling")}},{name: "Colors", handler: props.showColorsMenu},{name:"Reload", handler: ()=>window.location.reload(true)}];
  const titleColor = props.scarlet ? "" : props.titleColor;


  return (
    <div className={"flex-cen-start a-pWidth-100 phone-only noselect no-scr-bar "+(props.lightMode ? props.bkgContrast : "w3-display-bottommiddle")} style={{overflowX: "auto"}}>
      <div className="flex-cen-start " style={{width: "max-content"}}>{links.map((row)=><ALink {...row} key={row.id} titleColor={titleColor} selected={selected} setSelected={setSelected} scarlet={props.scarlet} setVisibleSections={props.setVisibleSections} visibleSections={props.visibleSections}/>)}</div>
    </div>
  );
};


export default HexSectionsMenu;
