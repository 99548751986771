import React, {useState, useEffect, useRef} from 'react';

function SectionsHeader(props) {
  const [selected, setSelected] = useState("RGB");
  let [stuck, setSticky] = useState(props.scarlet); // for the first rendering stuck is set to the bol value of props.scarlet because when scarlet is true the menu should be already starting in the stuck position
  const [hide, setHide] = useState(true);
  const [opacity, setOpacity] = useState(false);
  const hideTimer = useRef(null);
  const opacityTimer = useRef(null);
  const hexHeight = props.scarlet ? 0.2 : 0.26;
  const topBarHeight = props.mainShare.topBarHeight;
  useEffect(()=>{

      // if !scarlet the window element is the one scrolling, so we attach the scroll listner to window.
      // if scarlet the window is not scrolling, instead the sections container and the scarlet container have their own independent scrolling, so we attach the scroll listner to the sections container directly
      if (props.scarlet) {
        const el = document.getElementById("range-sections");
        el.addEventListener("scroll", scroll);
        return ()=> {if (el) el.removeEventListener("scroll", scroll);}
      } else {
        window.addEventListener("scroll", scroll);
        return ()=> window.removeEventListener("scroll", scroll);
      }
    });
  // if (window.innerWidth > 734) return null; Error

  useEffect(()=>{setSelected("RGB");if (props.scarlet) {setSticky(true);} else {setSticky(false);}},[props.scarlet]); // Every time props.scarlet changes we need to reset the stuck state before the scrolling starts, true is scarlet is true or false otherwise
  const pickers = [{type: "RGB", id: "RGBpicker"},{type:"HSL", id:"HSLpicker"},{type: "Color-Gray", id:"Color-Graypicker"},{type:"HSV", id:"sv-rect-cont"}];


  const scroll = () => { // flags the menu as stuck when sticky position reached the fixed level in order to change bkg color of the menu and the padding
    // scroll function check the position of the menu relative to the viewPort so it's independt if scarlet is false or true.
    // the height of the hex component instead changes whether scarlet if true/false, hexHeight  is calculated in the component body.
    if (props.scarlet) {
      if (hideTimer.current) clearTimeout(hideTimer.current);
      if (opacityTimer.current) clearTimeout(opacityTimer.current);
      if (hide === "just hidden") {
        setHide(true);
        return;
      } else if (hide) {
        setHide(false);
      }
      if (opacity) setOpacity(false);
      hideTimer.current = setTimeout(()=>setHide("just hidden"), 5000);
      opacityTimer.current = setTimeout(()=>setOpacity(true), 3500);
      selectOnScroll();
    } else {
      const container = document.getElementById("pickers-scr-heading");
      if (container) {
        const top = container.getBoundingClientRect().top;
        if (top < (topBarHeight + window.innerWidth * hexHeight + 1)) {
          if (!stuck) setSticky(true);
          selectOnScroll();
        } else if ((top >= (topBarHeight + window.innerWidth * hexHeight + 1)) && stuck) {
          setSticky(false);
          setSelected("RGB");
        }
      }
    }

  }

  const selectOnScroll = ()=> { // updates the selected menu element based on the scroll position
      const rect = (id) => {
        const el = document.getElementById(id);
        if (el) {
          const rect = el.getBoundingClientRect();
          return {top: rect.top, height: rect.height}} else {return {};}
      };
      const he = document.getElementById("range-sections").getBoundingClientRect().height;
      for (let i=0; i<pickers.length; i++) {
        const {top, height} = rect(pickers[i].id);
        const sectionsContCenter = topBarHeight + window.innerWidth * hexHeight + (props.scarlet ? (he / 2) : ((window.innerHeight - topBarHeight - window.innerWidth * hexHeight) / 2));
        if ((top <= sectionsContCenter) && (top > (((sectionsContCenter - height) < 0 ) ? (sectionsContCenter - height) :( topBarHeight + (window.innerWidth * hexHeight))))) {
          if (selected != pickers[i].type) setSelected(pickers[i].type);
        }
      }
  };

  const border = stuck ? " map-scr-heading-brd scrollable-heading-stuck-bkg " : "";
  const menuPadding = stuck ? (props.scarlet ? " a-pdS-9-s " : " a-pdS-11-s "): " a-pdS-12-s ";
  const fontSize = " a-font-18 " + (stuck ? (props.scarlet ? " a-font-12-s " : " a-font-13-s ") : " a-font-15-s ");
  const opacityEffect = (opacity && props.scarlet) ? " dissolve-effect ": "";
  const Variation = (props) => <div className={"cl-var-title-elem noselect  noselect  lh-1 "+(selected==props.type ? "cl-var-title-selected ": "") + fontSize}  id={"picker-variation-"+props.type}
      onClick={ ()=>{const el = document.getElementById(props.id); const top = el.getBoundingClientRect().top;
                      if (!props.scarlet) {
                            document.documentElement.scrollTop += (top - (topBarHeight + window.innerWidth * hexHeight + 33+10));
                          } else {
                            el.scrollIntoView();
                            const he = document.getElementById("range-sections");
                            he.scrollTop -= +10;
                          }
                          setSelected(props.type);setSticky(true);}}
                              > {props.type}</div>;

  if (hide && props.scarlet) {
    return null;
  } else {
    return (
      <>
          <h3 className={"phone-only a-pWidth-100 a-vw-font-8-5-s lh-1 w3-center  noselect "+(props.scarlet ? "w3-hide":"")}>Pickers</h3>
          <div className={"phone-only  pickers-scrollable-heading a-container-24 noselect "+border+(props.scarlet ? "": " a-mrT-4-s ")+menuPadding+opacityEffect} id="pickers-scr-heading">
              <div className="flex-cen-cen " style={{width: "max-content", minWidth:"100%"}}>{pickers.map((row)=><Variation key={row.type} {...row} scarlet={props.scarlet}/>)}</div>
          </div>
      </>
    );
  }

}

export default SectionsHeader;
