import React from 'react';
import {FormInput, FormDate} from "../../../forms/forms.js";

/*

*/

// const actions = ["action",["id", "btnName", "url", "caption"]];
function Row(props) {

  function handleChange1(e) {
    props.handleChange(e.target.value, props.i, "postUrl")
  }
  function handleChange2(e) {
    props.handleChange(e.target.value, props.i, "postTitle")
  }
  function handleChange3(e) {
    props.handleChange(e.target.value, props.i, "fbUser")
  }
  function handleDate(date) {
    props.handleChange(date, props.i, "postDate")
  }

  return (
      <div className="">
        <FormInput name={"postTitle"+props.i} label="Post Title" value={props.rows[props.i].postTitle} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
        <FormInput name={"postUrl"+props.i} label="Fb Post Url" value={props.rows[props.i].postUrl} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
        <FormInput name={"fbUser"+props.i} label="FB User" value={props.rows[props.i].fbUser} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
        <FormDate name={"postDate"+props.i} label="Post Date" date={props.rows[props.i].postDate} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />
      </div>
  );
}

function FbPosts(props) {
   if (false) {
     return null;
   } else {
     let out = [];
     for (let i=0; i < props.count; i++) {
       out.push(<Row key={i} i={i} handleChange={props.handleChange} rows={props.rows}/>)
     }
     return out;
   }
}


export default FbPosts;
