import React from 'react';
import {FormInput, FormSelect} from "../../../forms/forms.js";

/*

*/

// ["id","src","imagePosition","alt","caption", "author","authorUrl"]
function Row(props) {

  function handleChange1(e) {
    props.handleChange(e, props.i, "src")
  }
  function handleChange2(e) {
    props.handleChange(e, props.i, "imagePos")
  }
  function handleChange3(e) {
    props.handleChange(e, props.i, "alt")
  }
  function handleChange4(e) {
    props.handleChange(e, props.i, "caption")
  }
  function handleChange5(e) {
    props.handleChange(e, props.i,"author")
  }
  function handleChange6(e) {
    props.handleChange(e, props.i,"authorUrl")
  }
  function handleChange7(e) {
    props.handleChange(e, props.i,"batchSuffix")
  }
  function handleBatch(e) {
      props.handleChange(e, props.i,"batch")
  }
  return (
      <div className="w3-row">
        <FormInput name={"src"+props.i} label="Src" value={props.images[props.i].src} maxWidth="w3-col m12" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
        <FormInput name={"imagePos"+props.i} label="Image Position" value={props.images[props.i].imagePos} maxWidth="w3-col m4" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
        <FormInput name={"alt"+props.i} label="Alt" value={props.images[props.i].alt} maxWidth="w3-col m4" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
        <FormInput name={"caption"+props.i} label="Caption" value={props.images[props.i].caption} maxWidth="w3-col m4" handleChange={handleChange4}  color="w3-light-gray" validate=""  />
        <FormInput name={"author"+props.i} label="Author" value={props.images[props.i].author} maxWidth="w3-col m4" handleChange={handleChange5}  color="w3-light-gray" validate=""  />
        <FormInput name={"authorUrl"+props.i} label="Autho Url" value={props.images[props.i].authorUrl} maxWidth="w3-col m4" handleChange={handleChange6} color="w3-light-gray" validate=""  />
        <FormSelect name={"batchImg"+props.i} label="Batch add" value={props.images[props.i].batch} rangeStart={0} rangeEnd={50} maxWidth="w-200" handleChange={handleBatch}  color="w3-light-gray" validate="" />
        <FormInput name={"batchImgSuffix"+props.i} label="Batch Suffix" value={props.images[props.i].batchSuffix} maxWidth="w3-col m4" handleChange={handleChange7} color="w3-light-gray" validate=""  />
      </div>
  );
}

function Images(props) {
   if (false) {
     return null;
   } else {
     let out = [];
     for (let i=0; i < props.imgCount; i++) {
       out.push(<Row key={i} i={i} handleChange={props.handleChange} images={props.images}/>)
     }
     return out;
   }
}


export default Images;
