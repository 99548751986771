import React from 'react';
import './menu.css';

import Link from '../../Link';
import CategoryTitle from './CategoryTitle';
import MenuButton from './MenuButton';


function MenuRow(props) {  // a menu is dived in menu rows, each has a category title and several buttons (li of the row)
  if (!props.buttons) {
    return null;
  } else if ((Number(props.adminButton) && (!props.user || !props.user.admin || (Number(props.user.admin) < Number(props.adminButton))))) {
      return null;
    } else {
    return (
      <div className="side-menu-row">
                <CategoryTitle title={props.title} iconBarColor={props.iconBarColor}/>
                <div className="">
                    <ul className="ul-clear">
                          <div className="">
                              {props.buttons.map((li, index) => <MenuButton iconBarColor={props.iconBarColor} {...li} key={index} subHandler={props.subHandler} user={props.user} clickHandler={props.clickHandler} tagsHandler={props.tagsHandler}/>)}

                          </div>
                    </ul>

                </div>
                <hr className="side-menu-hr w3-hide"/>
      </div>
    );
  }
}

// Each instance of Menu renders one of the two menu that are li elements of the  ol inline list of SideMenu.  One instance is rendered as previous menu and the other next menu.

function Menu(props) {
  if (!props.rows) {
    return null;
  } else {

    return (
      <div className="side-menu-container">
        {props.rows.rows.map((row, index) => <MenuRow iconBarColor={props.iconBarColor} {...row} key={index} subHandler={props.subHandler} user={props.user} clickHandler={props.clickHandler} tagsHandler={props.tagsHandler}/>)}
      </div>

    );
  }
}


export default Menu;
