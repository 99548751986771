import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import EditBar from './EditBar';import EditTweet from '../Edit/EditTweet';
/*
    <div className=""></div>
*/



class Row extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editTweet: false};
        this.handleEditTweet = this.handleEditTweet.bind(this);

    }

    handleEditTweet(tweet) {
      this.setState({editTweet: tweet});
    }


    render() {

        if (!this.props.row || !this.props.rendered) {
          return null;
        } else if (this.state.editTweet){
          return <EditTweet {...this.state.editTweet}  editTweet={this.handleEditTweet}/>
        } else {
          return (
                <div className="ukr-feed-tweet" >
                <EditBar  user={this.props.user}  field="_tweet" media={this.props.row} handler={this.handleEditTweet} id={this.props.row.id} feedID={this.props.feedID}/>
                    <TwitterTweetEmbed tweetId={""+this.props.row.tweetID+""} />
                 </div>
          )
        }
    }

}

function Tweet(props) {
  if (!props.tweets) {
    return null;
  } else {
    return props.tweets.map((row, index) => <Row  row={row} key={index} user={props.user} feedID={props.feedID} rendered={props.rendered}/>);
  }
}





export default Tweet;
