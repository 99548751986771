import React, { useState } from 'react';
import FixedMenu from './FixedMenu/FixedMenu';
import EditFixedMenu from './FixedMenu/EditFixedMenu';
import TopBar from './TopBar/TopBar';
import EditTopBar from './TopBar/EditTopBar/EditTopBar';

/*
    <div className=""></div>
*/


function MenuBars(props) {

  let [showFixedMenu, setFixedMenu] = useState(false);
  let [topBarKey, setTopBarKey] = useState(0);
  const updateTopBar = () => setTopBarKey(topBarKey+1);

  function handleFixedMenu(action) { // action is "open" or close
      setFixedMenu(action);
      if (action == "close") {
          const menTimer = setTimeout(() => setFixedMenu(false), 200);
          document.documentElement.classList.remove("o-hid");  // Re-enable scroll

      } else if (action == "open") {

          let rows = null;
          for (let i=0; i<props.menus.length; i++) {
                if (props.menus[i].name === "Fixed Menu") {
                   rows = props.menus[i].subMenus;
                }
              }
          if (rows) {

            document.documentElement.classList.add("o-hid"); // Disable scroll on content hidden by the overlay
          }
      }

  }

  function logOut() {
      fetch("/log/out/").then((res) => res.json()).then((result)=>props.logHandler(result), (error) => alert("Log out error"));
  }



  function topBarHandler(id) {
    if (id == "back") {
      props.clickHandler(id);
    } else if (id == "logOut"){
      logOut();
    } else if (id == "logIn"){
      const user = props.user;
      user.showLogin = true;
      props.logHandler(user);
    } else {
      const component = retriveMetaObjFromID(id, props.metas).component;
      if (component === "mainMenu") {
        handleFixedMenu("open");
      } else {
        props.clickHandler(id);
      }
    }

  }

/*
  let topBarOptions = {
    // color: "a-btn-ukraine-flag-blue" , // hsl color as defined in buttons colors css files
    buttonStyle:"a-btn-filled-dark", // "a-btn-filled-dark" ," a-btn-filled", "a-btn-textOnHover", "a-btn-bordOnHover", "a-btn-bordered", "a-btn-HW"
    //buttonSize: {icon: "a-btn-top-icon", mixed: "a-btn-top-icon-text", text: "a-btn-top"},
    barIconColor:"#FFD700",
    position: (props.mobileLandscape ? "  " : "fixed"), // "fixed", "absolute" , "relative" || false (Default)
    overlay : false, // or true for def className top-bar-container-overlay or a custom className
  }
*/
  return (
    <div className="">
          <TopBar clickHandler={topBarHandler} {...props.mainShare} user={props.user} key={topBarKey+" topBar"} topBar={props.topBar} back={props.back} bot={props.bot}  />
          <FixedMenu handleClose={handleFixedMenu} metas={props.metas} menus={props.menus} iconBarColor="dodgerblue" show={showFixedMenu}
          clickHandler={props.clickHandler} user={props.user}/>
          <EditFixedMenu metas={props.metas} menus={props.menus} show={props.show}/>
          <EditTopBar metas={props.metas} show={props.show} topBar={props.topBar} updateTopBar={updateTopBar} />
    </div>

  );
}

function retriveMetaObjFromID(metaID, metas) {
  const id = metaID ? metaID : "1";
  let meta;
  for (let i = 0; i<metas.length; i++) {
     if (metas[i].id == id) {
        meta = metas[i];
      }
  }
  return meta;
}

export default MenuBars;
