import React from 'react';
import './userProfile.css';
import SvgIcon from '../../SvgIcon';


function UserProfile(props) {
  if (false) {
    return null;
  } else {
    const CloseIcon = (props) => (window.innerWidth < 734) ?   <div className="flex-cen-cen h-100 no-shrink " onClick={props.closeHanlder}><SvgIcon icon="close" color="#FFD700" size="36px"/> </div>: null;

    return (
      <div className="w3-container-24 flex-cen-cen sid-men-user">
            <div className="d25 ">
                <img className="w100 w3-circle" src="https://images-eu.ssl-images-amazon.com/images/S/amazon-avatars-global/default._CR0,0,1024,1024_SX48_.png" />
            </div>
            <div className="sid-men-user-txt">
            Ciao {props.user.name ? (", " + props.user.name) : ""}
            </div>
            <CloseIcon closeHanlder={props.closeHanlder}/>

      </div>

    );
  }
}

export default UserProfile;
