import React, {useEffect, useState, useRef} from 'react';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb,contrastHexColor} from '../../converters.js';

/*     <div className=""></div>     */

const PositionRangeThumb = (props)=> {
  const [sliding, setSliding] = useState(false);
  useEffect(()=>{if (sliding === "sliding") props.thumbIsSliding(true)},[sliding]);
  const slidingTimer = useRef(null);
  const {gradient, sclColors, index, setSclColors, gradEl, selectedGradient, setGradEl, selected, selectGradientType, select} = props.shared;
  const selGradient = gradient[selectedGradient];
  const colors = selGradient.colors;
  const isConic = selGradient.type === "conic-gradient";
  const contrast = contrastColorRgbToHex(props.color);
  const thumbWidth = 18; // px
  const container = 24 * 2; // px
  const borderTotal = sliding ? 4 : 2;
  const rangeWidth = window.innerWidth - container - borderTotal;
  const range = isConic ? 360 : 100;
  const level = isConic ? props.deg : props.position;
  const displacement = thumbWidth*range/rangeWidth;
  const adj = - displacement / range * level;
  const unit = isConic ? "deg" : "%";
  const left = isConic ? ((level+adj)/360 * 100) : (level+adj);
  const style = {backgroundColor:rgbToCss(props.color), left:left+"%", border: "1px solid "+contrast};

  const touchStart = ()=> { if (slidingTimer.current) clearTimeout(slidingTimer.current); setSliding("start"); slidingTimer.current = setTimeout(()=>setSliding("sliding"), 300);}
  const touchMove = (e)=>{ if (sliding === "sliding") {doMove(e);} else {if (slidingTimer.current) clearTimeout(slidingTimer.current);setSliding(false);}};
  const touchEnd = (e)=> {if (slidingTimer.current) clearTimeout(slidingTimer.current); if (sliding === "sliding") doMove(e);if (sliding) setSliding(false);props.thumbIsSliding(false);}

  const doMove = (e) => {
    const pos = e.changedTouches[0].clientX - (container/2) - thumbWidth/2 - borderTotal / 2;
    let x = ( pos + thumbWidth/rangeWidth * pos) / (rangeWidth) * range;
    x = Math.round(x*10)/10;
    const min = 0;
    const max = isConic ? 360 : 100;
    const positionType = isConic ? "deg" : "position";
    const margin = isConic ? 15 : 5;
    if ((props.index > 0) && (x < (colors[props.index-1][positionType] + margin))) {
      x = colors[props.index-1][positionType] + margin;
    } else if ((props.index < colors.length-1) && (x > (colors[props.index+1][positionType] - margin))) {
      x = colors[props.index+1][positionType] - margin;
    }
    if (x<min){ x = 0;} else if (x>max) {x = max;}
    colors[props.index][positionType] = x ;
    if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);
  }

  const click = (e)=> {setGradEl(props.index); if (selected !== "gradient") select("gradient");selectGradientType("color"); props.setRgb(colors[props.index].color)}

  const noTouch = (sliding === "sliding") ? "no-touch" : ""; // block scrolling while swiping -- Not sure why not woking if sjyping up immediately after set("sliding"), only works if swiping left right first, then swiping up down is blocked
  return (
      <div className={"range-gradient-thumb p-rel "+noTouch} style={style} onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} onClick={click}>
          <div className="p-abs a-font-12 w500" style={{bottom:"21px", left:"50%", transform: "translateX(-50%)"}}>{Math.round(level)}</div>
      </div>
  );
};

export default PositionRangeThumb;
