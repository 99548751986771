import React from 'react';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon';

function EditBar(props) {
  if (!props.user || (props.user.admin < 5)) {
    return null;
  } else {
    return (
        <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen">
            <Link click={[props.handler, props.media]} ><SvgIcon size="22px" icon="edit" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/delete"+props.field+"Map/"+props.id+"/"+props.feedID} className="w3-margin-right-8"><SvgIcon size="22px" icon="delete" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/delete"+props.field+"/"+props.id}><SvgIcon size="22px" icon="delete" color="red" /></Link>
        </div>
    );
  }
}

export default EditBar;
