import React, {useState} from 'react';
import "./button.css"; import './fillings.css'; import './sizes.css'; import './btnColors.css'; import './topBarSizes.css';
import Link from '../Link';import DropDown from './DropDown/DropDown';import SvgIcon from '../SvgIcon'; import { useMediaQuery } from 'react-responsive';
import {Tooltip, Text, ButtonIcon, iconSizeAndPadding, isObj, calcHeight, calcBorderRadius, calcWidth, calcContainer, calcBorderSize} from './helpers';

// <div className=""></div>


function Button(props) {

    /******* Media Queries ****/
    let media = {};
    media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
    media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
    media.isMobile = useMediaQuery({ maxWidth: 734 });
    media.isTablet = useMediaQuery({ maxWidth: 1250, minWidth: 751 });
    media.max1420 = useMediaQuery({ maxWidth: 1420, minWidth: 751 });
    /******* Media Queries ****/

    let [btnClicked, setBtnClicked] = useState(false);
    const domId = "button"+Math.random();
    const type = props.btnType ? props.btnType : "button";
    let style = {};

    const calcColor = ()=> {
        let color = (props.color ? props.color : "");
        if (props.active && props.activeColor) {
          color = props.activeColor + " no-hover";
          click = false; href=null;
        } else if (btnClicked && !props.animate && props.clickColor) {
          color =  props.clickColor + " no-hover";
        }
        // bordered fillings don't set a background color so we add here a transparent background or we set a proper one if it's set via classBkg or styleBkg props
        if (["a-btn-bordered", "a-btn-bordered-dark", "bordered", "bordered-dark", "a-btn-bordOnHover", "bordOnHover"].includes(props.filling)) {
          if (props.classBkg) {
            color += (" "+props.classBkg);
          } else if (props.styleBkg) {
            style.backgroundColor = props.styleBkg;
          } else {
            color += " a-bkg-transparent ";
          }
        }
        return (color+" ");
    }

        /* Click e Href Select click as default over href unless props.bot is true   */
    let click = null; let href = null;
    const clickTimer = props.animate ? 300 : 300;
    if ((props.click === true && !props.bot) || (props.click === true && props.bot && !props.href)) {  // clickHandler contains the handler
      click = ()=>{if (props.animate || props.clickColor) {setBtnClicked(true); setTimeout(()=>setBtnClicked(false), clickTimer);} props.clickHandler(props.id)};
    } else if ((props.click && !props.bot) || (props.click && props.bot && !props.href)) { // click contains the handler, clickHandler and id are null
      click = ()=>{if (props.animate || props.clickColor) {setBtnClicked(true); setTimeout(()=>setBtnClicked(false),clickTimer);} props.click()};
    }
    if ((props.href && !props.click) || (props.href && props.click && props.bot)) {
      href = props.href;
    }
        /* Click e Href */

    const icon = props.svg ? props.svg : (!isObj(props.icon) ? props.icon : props.icon.name);
    const iconColor = props.iconColor ? props.iconColor :  (isObj(icon) ? icon.color : null);
    const text = props.text;



    if (!text && !props.icon && !props.svg) {
        return null;
    } else if ((media.isMobile && props.hideOnMobile) || (media.isTablet && props.hideOnTablet) || (!media.isMobile && !media.isTablet && props.hideOnDesktop)){
        return null;
    }  else {
          let className = "a-btn noselect ";
          className += ((props.weight && [200,300,400,500,600,700].includes(props.weight)) ? "w"+props.weight+" " : "w500 "); // font-weight
          className += [1,1.1,1.2,1.3,1.4,1.5,1.6,1.7].includes(props.lh) ? ("lh-"+props.lh.toString.replace(".","-")): "lh-1";                         // line-weight
          className += iconSizeAndPadding(props.icon);
          className += calcHeight(props.height);
          className += calcWidth(props.width);
          className += calcContainer(props.container);
          className += calcBorderRadius(props.borderRadius, media);
          className += calcColor();
          className += calcBorderSize(props.borderSize, media, props.filling);
          className +=  (props.className ?  " " +props.className : "") + " " ;
          className +=  (props.size ? props.size : (props.height ? "":" a-btn-size ")) + " ";     // old prop, has a default only if props.height not set
          className += (!props.click && !props.href) ? " no-hover a-btn-opac " : " ";     // remove hover effect and dimm the color when the button is not clicable
          className += (btnClicked && props.animate)  ? " a-btn-clicked-anim  " : " ";    // Start the trasnlateY animation if the button has been clicked and the animate prop has been set true
          className += props.filling ? ((props.filling.charAt(1) === "-" ? "" : "a-btn-") + props.filling + " ") : " a-btn-filled ";
          className += props.hoverLightness ? "l-hov-"+props.hoverLightness+" " : "";      // set the lightness change in the hover effect for supported fillings
          className += (props.forceHover ? " force-hover " : "");                          // simulate hover color via prop to test the color on not hoverable devices
          className += (props.noFilledBkg ? " no-filled-bkg ": "");                        // if topbar has the same background has the button you can disable the button bkg via props in case alpha is set otherwaise the y will sum up
          if (props.icon && !props.text && !props.width && !props.container)  className += " icon-only ";  // enable auto container for icon only buttons if width and container are not set the width will be set equal tot he height
          if (props.dropMenu) { className += " a-btn-drop "; }

          return (
                <button className={className} type={type} style={style} id={domId+"outer"}>
                    <Link className="an-inline h-100 w-100" click={click} href={href} noFollow={props.noFollow} newWindow={props.newWindow}>
                        <div className="flex-cen-cen h-100">
                            <ButtonIcon  icon={icon} color={iconColor} key={icon} dropMenu={props.dropMenu}/>
                            <Text text={text} icon={icon} dropMenu={props.dropMenu}/>
                            <DropDown text={text} domId={domId} dropMenu={props.dropMenu} clickHandler={props.clickHandler} dropColD={props.dropColD}
                            dropColT={props.dropColT} tipMar={props.tipMar} dropWidth={props.dropWidth} iconColor={iconColor} dropDirection={props.dropDirection}
                             dropDefs={props.dropDefs} media={media}/>
                        </div>
                        <Tooltip tooltip={props.tooltip} media={media} colorClass={props.tooltipColor}/>
                      </Link>
                </button>


          );

  }

}

export default Button;
