import React, {useEffect, useState, useRef} from 'react';
import SvgIcon from '../../SvgIcon'; import DropSection from './DropSection';
import Link from '../../Link';
import './dropDown.css';

// <div className=""></div>

function DropDown(props) {
    const [arrowPos, setArrowPos] = useState(0);
    const arrowSize = props.dropButtonArrowSize ? props.dropButtonArrowSize : 22; // dropDown arrow inside the button
    const dropMenuArrowSize = (props.dropMenuArrowSize  && props.dropMenuArrowSize < 15 && props.dropMenuArrowSize > 4) ? props.dropMenuArrowSize : 8; // drop menu top arrow tip toward the drop button
    const dropRef = useRef(null);
    const {dropDirection} = props;


    useEffect( ()=> { // Calculate the position of the dropdown arrow pointing to the button's drop down arrow
        const btnInnerWidth = dropRef.current ? dropRef.current.offsetWidth : 0;
        const dropWidth = (dropDirection === "full") ? window.innerWidth : (props.dropWidth ? props.dropWidth : 200); // width of the absolute positioned  drop down menu
        const dropBtn = document.getElementById(props.domId+"outer");
        const btnPaddedWidth = dropBtn ? dropBtn.offsetWidth : 0;
        const padding = (btnPaddedWidth - btnInnerWidth) / 2;
        let arrowPos = 0;
        if (props.dropDirection == "center") {
          arrowPos = Math.round(dropWidth / 2 + btnInnerWidth / 2 - (arrowSize / 2) - dropMenuArrowSize);
        } else if (props.dropDirection == "right") {
          arrowPos = Math.round(btnPaddedWidth - padding - (arrowSize / 2) - dropMenuArrowSize);
        } else if ((props.dropDirection == "left") || !props.dropDirection) {
          arrowPos = Math.round(dropWidth - padding - (arrowSize / 2) - dropMenuArrowSize);
        } else if (props.dropDirection == "full"){
          const btnRight = dropBtn.getBoundingClientRect().right;
          arrowPos = Math.round(btnRight - padding - (arrowSize / 2) - dropMenuArrowSize);
        }
        setArrowPos(arrowPos+"px");
      }
    );

  if (!props.text || !props.dropMenu) {
    return null;
  } else {
    const dropContainer = "a-btn-drop-menu " + (" tip-size-"+dropMenuArrowSize+" ") + (props.dropDefs ? props.dropDefs : "a-btn-drop-defs ") +
     " drop-"+ (props.dropDirection ? props.dropDirection : "left") + " drop-wid-" + (props.dropWidth ? props.dropWidth : 200);
    const sectionsContainer = (props.dropColD > 1) ? "a-btn-drop-sect-cont" : "";
    /*const button = document.getElementById(props.id);
    const width = button ? button.getBoundingClientRect().width : null;

    */

    return (
        <div className="flex-cen-cen h-100" ref={dropRef}>
              <div>{props.text}</div>
              <div className="w3-margin-left-4 w3-padding-top-8">
                  <SvgIcon icon="arrow_drop_down" className="x" size={arrowSize+"px"} colorClass="icon-btn-color"/>
              </div>
              <ul className={dropContainer}>
                 <div className="a-btn-drop-arrow" style={{left:arrowPos}}></div>
                 <div className={sectionsContainer}>
                    {props.dropMenu.map((row, index) =>
                      <DropSection {...row} media={props.media} clickHandler={props.clickHandler} dropColD={props.dropColD} dropColT={props.dropColT} key={index}/>)
                    }
                 </div>

              </ul>
        </div>
    );
  }

}

export default DropDown;

/*
<DropDown text="" dropRight={bol} dropRows dropDefs="a-btn-drop-defs"/>
dropRight={bol}  default is false, it measn it drops to the left: right=0 if with>100% it will overflow to the left. dropRight should be true only for drop downs located on the immediate left where there is no space to overflow to the left so we make it overflow to the right with left=0

/***************** Default class props.dropDefs=""a-btn-drop-defs""
to change defaults rename and change the def class: .a-btn-drop-defs {--dropWidth: 200px; --dropFontCol:rgb(15, 15, 18);--dropUlBkg: white; --dropUlBkgHov: #f5f5f5;--dropBrdRadius: 6px; --dropBoxShColor: rgba(0,0,0,.7);
  --toolTipSize: 6px; --toolTipRightMarg: 14px; --toolTipLeftMarg: 25%; --dropFontSize: 14px; --dropLineHe: 17px; --dropLiPadding: 14px 14px;--dropFontWeigh: 500}
*/
