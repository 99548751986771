import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import './hero.css'; import './front.css';
import GridElement from './GridElement'; import HeroButton from './HeroButton';
import Link from '../Link';
import OpacityTextBox from '../Cards/OpacityTextBox';
import '../animate.min.css';
import Button from '../Buttons/Button';

function H3(props) {
  const h2Style = props.h2Style ? props.h2Style : "hero-rot-h3";
  const width = props.subtitleWidth ? {width: props.subtitleWidth} : {};
  return ((props.h3) ? <h3 className={h2Style} style={width}> {props.h3}</h3> : null);
}

function H1(props) {
  const h1Style = props.h1Style ? props.h1Style : "hero-rot-h1";
  const width = props.titleWidth ? {width: props.titleWidth} : {};
  if (props.h1 && (props.index === 0)) {
    return <h1 className={h1Style} style={width}> {props.h1}</h1>;
  } else if (props.h1) {
    return <h2 className={h1Style} style={width}> {props.h1}</h2>;
  } else {
    return null;
  }
}
function Action(props) {
  return null;
  return (
    <div className="w3-margin-top-32"><Button text="Read more" rounded={true} filling="a-btn-bordered" width="150px" color="a-btn-blue-1"/></div>
  );
}

function Front(props) {

    const contentWidth = props.contentWidth ? {width: props.contentWidth} : {};
    const click = false //props.id ? [props.slideClick, props.id] : null;
    if (!props.grid && !props.component) {  // It's not a grid there is only a central heading with h1 and h2 and a optional button
    const titleContainer = props.titleContainer ? props.titleContainer : "hero-title-container";
    const gradient = props.gradient ? props.gradient : "";

      return (
          <Link className="hero-content-width" click={click} href={props.href} style={props.height}>
              <header className={"animatedx fadeInDownx fasterx "+ titleContainer} style={props.height}>
                    <H1 {...props}/>
                    <H3 {...props}/>
                    <Action />
                    <div className={"hero-title-gradient " + gradient}></div>
              </header>
          </Link>
      ) ;
    } else if (props.grid) { // It's a grid mapping grid array into GridElement elements
      return (
          <div className="flex-cen-cen h-100 hero-content-width w3-padding-top-32" >
              {props.grid.map((row, index) => <GridElement {...row} key={index} click={props.click} href={props.href}/>)}
          </div>
      );
    } else if (props.component) {
      const Component = props.component;
      return (
        <Link className="hero-content-width" click={click} href={props.href}>
             {props.component}
        </Link>
      );


    }
}



export default Front;

/*


<div className="hero-title-container hero-content-width">
      <h1 className={h1Style}> {props.h1}</h1>
      <h2 className={h2Style}> {props.h2}</h2>
      <HeroButton button={props.button}  href={props.href} />
</div>

*/
