import {DivSelect} from '../../../../../forms/forms';

const TypeSelector = (props) => {
    const {gradient, sclColors, index, setSclColors, selectedGradient} = props.shared;
    const options = ["linear-gradient","radial-gradient","conic-gradient"];
    const handler = (value)=> {
        gradient[selectedGradient].type = value;
        if (sclColors[index]["id"]) sclColors[index]["updated"] = "updated"; setSclColors([...sclColors]);
    }

      return (
        <DivSelect value={gradient[selectedGradient].type} options={options} handleOptionClick={handler} topDrop={true}
        defs="form-noLab-xsmall"  defColors="form-col-orange" className="a-mrR-12 a-width-120"  margin="none" noIcon={true} />
    );
}

export default TypeSelector;
