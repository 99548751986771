import React, {useEffect, useState} from 'react';
import SvgIcon from '../../../../../SvgIcon';import {Title, Gradient} from './subs.js';

function Inner(props) {
      const [touch, setTouch] = useState(null);
      const {select, selected, texts, selectText, selectedText, setTextEl, textEl, selectedTextType, selectTextType,index, setSclColors, sclColors, selectType, txt, contrastBkg} = props.shared;

      const handleTouchStart = (e)=> { setTouch({x:e.changedTouches[0].clientX, y: e.changedTouches[0].clientY});};

      const handleTouchEnd=(e)=>{
        if (touch) setTouch(null);
      };

      const handleTouchMove = (e)=> {
        e.stopPropagation();
        const x = e.changedTouches[0].clientX;
        const y = e.changedTouches[0].clientY;
        if (!touch || (Math.abs(y - touch.y) > 50)) {
          return;
        } else {
          props.cancelBkgTouch(); // touch is moving => it's not a bkg select but a scrolling => we inform scarlet component to cancel the bkg select timeout
          const rightAllowed = selectedText < (texts.length - 1);
          const leftAllowed = selectedText > 0;
          if (((x - touch.x) > 50) && rightAllowed) {
            setTouch(null); // When we select the prex/next slide we set touch to null otherwise until touch end we will continue to slide left right with a single operation
            selectText(selectedText + 1);
          } else if (((touch.x - x) > 60) && leftAllowed) {

            setTouch(null);  // same as before
            selectText(selectedText - 1);
          }
        }
      };

      const deleteRow = (index) => {
        const length = texts[selectedText].text.length;
        if (length < 2) return;
        texts[selectedText].text.splice(index,1);
        if (index >= (length-1)) {setTextEl(length-2)} else {setTextEl(index)}; // if index is not the last index we don't change the selected row, the next row will get the same idnex of the deled row. If the index is the last one we have to select the previous index, so length-2 since lenght was calculated before the splice operation
        setSclColors([...sclColors]);
      }

  return (
    <div className="a-mrL-32 a-mrR-32 w3-display-container  w3-animate-opacity-04 a-flex-col max-content ov-hid" key={selectedText+"selectedText"} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <Gradient shared={props.shared} setHex={props.setHex} selectType={selectType} cancelBkgTouch={props.cancelBkgTouch} selectedTextType={props.selectTextType} selectTextType={props.selectTextType}/>
          <Title shared={props.shared} setHex={props.setHex} cancelBkgTouch={props.cancelBkgTouch} deleteRow={deleteRow}/>

    </div>
  );
}




export default Inner;
