import React from 'react';
import './feed.css';import FeedHeader from './FeedHeader';import Action from './Action';
import Text from './Text';  import Media from './Media/Media';
import Link from '../../Link'; import EditFeed from './CreateEdit/EditFeed'; import EditImage from './Edit/EditImage'; import EditVideo from './Edit/EditVideo';
import SvgIcon from '../../SvgIcon';import Tweet from './Media/Tweet'; import FbPost from './Media/FbPost';

/*
    <div className=""></div>

*/
function EditBar(props) {
  if (!props.user || (props.user.admin < 5)) {
    return null;
  } else if (props.zoom) {
    return (
        <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen">
            <Link click={[props.edit, true]} className="w3-margin-right-8"><SvgIcon size="22px" icon="edit" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/delete/"+props.feedID} className="w3-margin-right-8"><SvgIcon size="22px" icon="delete" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/deleteWithMedia/"+props.feedID} className="w3-margin-right-8"><SvgIcon size="22px" icon="delete" color="red" /></Link>
            <Link click={[props.feedView, "back"]} className="w3-margin-right-8"><SvgIcon size="22px" icon="close" color="rgb(60,60,60)" /></Link>
        </div>
    );
  } else {
    return (
        <div className="w3-display-topright w3-margin-top w3-container flex-cen-cen">
            <Link click={[props.feedView, props.feed]} className="w3-margin-right-8"><SvgIcon size="22px" icon="zoom_in" color="rgb(60,60,60)" /></Link>
            <Link click={[props.edit, true]} className="w3-margin-right-8"><SvgIcon size="22px" icon="edit" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/delete/"+props.feedID} className="w3-margin-right-8"><SvgIcon size="22px" icon="delete" color="rgb(60,60,60)" /></Link>
            <Link href={"/feed/deleteWithMedia/"+props.feedID}><SvgIcon size="22px" icon="delete" color="red" /></Link>
        </div>
    );
  }
}

class Feed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {edit: false,editImage:false, editVideo: false, rendered: false};
        this.handleEditView = this.handleEditView.bind(this);
        this.handleEditImage = this.handleEditImage.bind(this);
        this.handleEditVideo = this.handleEditVideo.bind(this);
        this.scroll = this.scroll.bind(this);



    }

    handleEditView(bol) {
      this.setState({edit: bol});
    }

    handleEditImage(image) {
      this.setState({editImage: image});
    }

    handleEditVideo(video) {
      this.setState({editVideo: video});
    }

    scroll() { // we check if the slider in in view port otwerwise we stop the autopilot
      if (this.props.data && this.props.data.id && !this.state.rendered) {
        const element = document.getElementById("feed-cont"+this.props.data.id);
        if (isNearViewport(element)) {
            this.setState({rendered: true});
        }
      }

    }

    componentDidMount() {

        window.addEventListener("scroll", this.scroll);
    }


    componentWillUnmount() {
      window.removeEventListener("scroll", this.scroll);
    }



    render() {
        if (!this.props.data) {
          return null;
        } else if (this.state.edit) {
          return <EditFeed {...this.props} show={this.handleEditView} {...this.props.data} tags={this.props.tags}/>;
        } else if (this.state.editImage) {
          return <EditImage {...this.state.editImage}  editImage={this.handleEditImage}/>
        } else if (this.state.editVideo){
          return <EditVideo {...this.state.editVideo}  editVideo={this.handleEditVideo}/>
        }  else {
          const medias = {videos:retrieveMediaFromID(this.props.data.videosIDs, this.props.videos), images: retrieveMediaFromID(this.props.data.imagesIDs, this.props.images),
             actions: retrieveMediaFromID(this.props.data.actionsIDs, this.props.actions), tweets: retrieveMediaFromID(this.props.data.tweetsIDs, this.props.tweets),
             fbPosts: retrieveMediaFromID(this.props.data.fbPostsIDs, this.props.fbPosts)}
          const header = this.props.data.header ? this.props.data.header : {};
          const feed = {id: this.props.data.id,title: this.props.data.title,subtitle: this.props.data.subtitle,date: this.props.data.date,credit: this.props.data.credit,
             text: this.props.data.text, tagIDs:this.props.data.tagIDs, ...medias}
          return (
            <div className="feed-container" id={"feed-cont"+this.props.data.id}>
                 <EditBar  user={this.props.user} feedID={this.props.data.id} edit={this.handleEditView}  feedView={this.props.feedView} feed={feed} zoom={this.props.zoom}/>
                 <FeedHeader title={this.props.data.title} subtitle={this.props.data.subtitle} date={this.props.data.date} credits={this.props.data.credits}  />
                 <Media videos={medias.videos} images={medias.images} title={this.props.data.title} user={this.props.user}
                  editImage={this.handleEditImage} editVideo={this.handleEditVideo} feedID={this.props.data.id} rendered={this.state.rendered}/>
                 <Text text={this.props.data.text}  user={this.props.user} editTweet={this.handleEditTweet} />
                 <Tweet tweets={medias.tweets} user={this.props.user} editTweet={this.handleEditTweet} feedID={this.props.data.id} rendered={this.state.rendered}/>
                 <FbPost fbPosts={medias.fbPosts} user={this.props.user} editFbPost={this.handleEditFbPost} feedID={this.props.data.id} rendered={this.state.rendered}/>
                 <Action action={medias.actions}  user={this.props.user} feedID={this.props.data.id}/>

            </div>
          );
        }
    }

}

function retrieveMediaFromID(IDs, mediasArray) {
    let out = [];
    if (Array.isArray(mediasArray) && mediasArray.length && Array.isArray(IDs) && IDs.length) {
        for (let i=0; i<mediasArray.length; i++) {
          if (IDs.includes(mediasArray[i].id) ) {
            out.push(mediasArray[i]);
          }
        }
        return out;
    }else {
      return null;
    }

}

var isNearViewport = function (elem) {
    if (!elem) return null;
    var bounding = elem.getBoundingClientRect();
    return  bounding.top <= (window.innerHeight * 1.5);
  };

export default Feed;
