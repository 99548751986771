import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox} from "../../forms/forms.js";
import Button from '../../Buttons/Button';
/*
    <div className=""></div>
*/

function Actions(props) {
  if (props.show != "batchAction") {
      return null;
  } else {
    const handleChange1 = (e) => props.editRow("action_btnName", e.target.value, props.rowIndex);
    const handleChange2 = (e) => props.editRow("action_url", e.target.value, props.rowIndex);
    const handleChange3 = (e) => props.editRow("action_caption", e.target.value, props.rowIndex);
    const handleDate = (date) => props.editRow("actionDate", date, props.rowIndex);

    return (
        <div className="w3-row ">


              <div className=" w3-col ">
                  <FormInput name={"action_btnName"+props.rowIndex} label="Btn Name" value={props.action_btnName} maxWidth="" handleChange={handleChange1}  color="w3-light-gray" validate=""  />
                  <FormInput name={"action_url"+props.rowIndex} label="Urn" value={props.action_url} maxWidth="" handleChange={handleChange2}  color="w3-light-gray" validate=""  />
                  <FormInput name={"action_caption"+props.rowIndex} label="Caption" value={props.action_caption} maxWidth="" handleChange={handleChange3}  color="w3-light-gray" validate=""  />
                  <FormDate name={"actionDate"+props.rowIndex} label="Date" date={props.actionDate} maxWidth="" handleChange={handleDate}  color="w3-light-gray" validate=""  />

              </div>
        </div>
    );
}
}


export default Actions;
