import React, {useEffect, useState, useRef} from 'react'; import './range.css';import {DivSelect, FormInput} from '../../../forms/forms';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from './converters.js';
import SvgIcon from '../../../SvgIcon';  import RangeActionsBar from './RangeActionsBar/RangeActionsBar';
import Mappings from './Mappings/Mappings'; import Sections from './Sections/Sections';import Link from '../../../Link'; import RangeEditMenu from './RangeEditMenu';
import Scarlet from './Scarlet/Scarlet';
/*
    <div className=""></div>
*/


function RangeEdit(props) {
      const [color, setColor] = useState(null);
      const [visibleSections, setVisibleSections] = useState("all");
      const [scarlet, setScarlet] = useState(false); // values are tru (open), false (closed), null (temporarely closed until converter is focused)
      const [focused, setFocused] = useState(false);
      useEffect(()=> {const color = props.color; if (!color) return; if(!color.hsv) {color.hsv = rgbToHsv(color.rgb); color.hsv.h = color.hsl.h;}; setColor({...color});},[props.color ? props.color.hex : props.color]);  // if we selelect a new color from the parent we update the color in state with the new proppped color
      useEffect(()=>  {   if (focused) {
                            if (scarlet) setScarlet(null);
                          } else {
                            if (scarlet === null) setScarlet(true);
                          }
                      },[focused]);

      const handleScarlet = (sel)=> {
        if (!["converter","color-handling"].includes(visibleSections)) {
          if (!scarlet) {
            setVisibleSections(sel);
          } else {
            setVisibleSections("all");
            setTimeout( ()=>scrollTo(sel),100);
          }
        }
        props.mainShare.setTopBar(scarlet);
        setScarlet(!scarlet);
        document.documentElement.scrollTop = 0;
      };

      const scrollTo = (id) => {
            const el = document.getElementById(id);
            let top = el.getBoundingClientRect().top;
            document.documentElement.scrollTop += (top - (60 + window.innerWidth * 0.26));
            setTimeout(()=>{
                top = el.getBoundingClientRect().top;
                document.documentElement.scrollTop += (top - (60 + window.innerWidth * 0.26));
                },100);
          };

      const handleRgb = (val, blur, type) => {
              let value =  (val != "") ?  val : 0;
              const int = value ;
              if (type == "gray") {
                color["rgb"] = {r: int, g: int, b: int};
              } else {
                color.rgb[type] = int;
              }
              const hex = rgbToHex(color.rgb);
              const hsl = rgbToHsl(color.rgb);
              const hsv = rgbToHsv(color.rgb); hsv.h = hsl.h;
              setColor({hex: hex, rgb: color.rgb, hsl: hsl, hsv: hsv});

      };



      const setHex = (value) => {
        const rgb = hexToRgb(value);
        const hsl = rgbToHsl(hexToRgb(value));
        const hsv = rgbToHsv(rgb); hsv.h = hsl.h;
        setColor({hex: value, rgb: rgb, hsl: hsl,hsv: hsv}); document.documentElement.scrollTop = 0;

      }

      const setHsl = (value) => {
        const rgb = hslToRgb(value);
        const hex = rgbToHex(rgb);
        const hsv = rgbToHsv(rgb); hsv.h = value.h;
        setColor({hex: hex, rgb: rgb, hsl: value,hsv: hsv}); document.documentElement.scrollTop = 0;

      }

      const setRgb = (value) => {
        const rgb = value;
        const hsl = rgbToHsl(rgb);
        const hex = rgbToHex(rgb);
        const hsv = rgbToHsv(rgb); hsv.h = hsl.h;
        setColor({hex: hex, rgb: rgb, hsl: hsl,hsv: hsv}); document.documentElement.scrollTop = 0;

      }

      const handleHsl = (val,blur, type) => {
        let value =  (blur && val=="" || val==" ") ? 0 : val;
        const int = value;

        if (value == "" && !blur) {
          if (type == "hue") {
            color.hsl = {h: value, s: 100, l: 50};
          } else {
            color.hsl[type] = value;
          }
          setColor({...color});
        } else {
          if (type == "hue") {
            color.hsl = {h: int, s: 100, l: 50};
          } else {
            color.hsl[type] = int;
          }
        const rgb = hslToRgb(color.hsl); const hex = rgbToHex(rgb); const hsv = rgbToHsv(rgb);hsv.h = color.hsl.h;
        setColor({hex: hex, rgb: rgb, hsl: {...color.hsl},hsv: hsv});

      }
    };

    const handleHsv = (hsv, repositioning) => {
        const rgb = hsvToRgb(hsv);
        const hex = rgbToHex(rgb); const hsl = rgbToHsl(rgb);hsl.h = hsv.h;
        setColor({hex: hex, rgb: rgb, hsl: hsl, hsv: hsv});
        if (repositioning) {

        }
    };

    function increase(type, bol) {
        const increment = bol ? +1 : -1;
        if (["r","g","b", "gray"].includes(type)) {
          if (type == "gray") {
            const r = parseInt(color.rgb.r) + increment;
            color.rgb = {r: r, g: r, b: r};
          } else {
            color.rgb[type] = parseInt(color.rgb[type]) + increment;
          }
          const hex = rgbToHex(color.rgb); const hsl = rgbToHsl(color.rgb);
          const hsv = rgbToHsv(color.rgb); hsv.h = hsl.h;

          setColor({hex: hex, rgb: color.rgb, hsl: hsl,hsv: hsv});

        } else {
          if (type == "hue") {
            const h = parseInt(color.hsl.h) + increment;
            color.hsl = {h: h, s: 100, l: 50};
          } else {
            color.hsl[type] = parseInt(color.hsl[type]) + increment;
          }
          const rgb = hslToRgb(color.hsl); const hex = rgbToHex(rgb);
          const hsv = rgbToHsv(rgb); hsv.h = color.hsl.h;

          setColor({hex: hex, rgb: rgb, hsl: color.hsl,hsv: hsv});

        }
    }

    const handleVisibleSection = (val)=> {
          setVisibleSections(val);
          document.documentElement.scrollTop = 0;
        };

     if (!color) {
       return null;
     } else {
       const handlers = {hex: setHex,rgb: handleRgb,hsl: handleHsl,hsv: handleHsv, increase: increase, showColorsMenu: props.showColorsMenu,
          setVisibleSections: handleVisibleSection}; // const handlers = {setHex: setHex, handleRgb: handleRgb, handleHsl: handleHsl, handleHsv: handleHsv};
       const hsvOpt = {handleHsv:handleHsv};

       return (
           <div className={"cl-range-ed-container "+(!props.show ? " w3-hide ":"")+( scarlet ? "scarlet-is-open": ((visibleSections==="converter") ? "conv-is-open" : ""))+(!props.mainShare.showTopBar ? " no-top-bar ":"")}>
               <div className={"flx-btw-str a-wrap a-pWidth-100 w3-content-1200 "+(["pickers","converter","color-handling"].includes(visibleSections) ? "grow-1":"")+(scarlet ? " overflow-auto no-scr-bar":"")} id="range-sections">
                  <Sections handlers={handlers}  hsvOpt={hsvOpt} color={color} visibleSections={visibleSections} mainShare={props.mainShare}setVisibleSections={(a)=>{setVisibleSections(a);document.documentElement.scrollTop = 0;}}
                 scarlet={scarlet} setScarlet={handleScarlet} show={props.show}/>
                  <RangeActionsBar savedColor={props.color} scarlet={scarlet} focused={focused} setFocused={setFocused} show={props.show} color={color} name={props.name} list={props.list} id={props.id}  visibleSections={visibleSections} handlers={handlers} setColor={setColor}  editHandlers={props.editHandlers} lists={props.lists} key={props.color.hex}/>
               </div>
               <Mappings options={{addToBucket: props.addToBucket, color: color, setHex: setHex}} mainShare={props.mainShare} visibleSections={visibleSections} show={props.show} scarlet={scarlet} />
               <Scarlet color={color} scarlet={scarlet} setHex={setHex} setHsl={setHsl} show={props.show} setRgb={setRgb} mainShare={props.mainShare}/>
           </div>

       );
     }
}



export default RangeEdit;

//y = -20 ( x - 1/2) Y =  -20x + 10
/*


<RangeEditMenu visibleSections={visibleSections} setVisibleSections={setVisibleSections}/>

*/
