import React from 'react';
import './header.css';

/*
    <div className=""></div>
*/

function Header(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className="ukr-main-block-header">
            <header className="ukr-main-bl-head-cont">
                <h2>{props.title}</h2>
                <div className=""></div>
            </header>
       </div>
     );
   }
}


export default Header;
