import React from 'react';
import {FormInput, FormSelect, FormDate,FormCheckBox, FormRadio} from "../../forms/forms.js";
import Button from '../../Buttons/Button';
/*
    <div className=""></div>
*/

function TagRow(props) {
  function handleChange(e) {
    props.selectTags(e, props.tagIndex, props.rowIndex);

  }
  return   (
          <div className={props.rowSpan}>
              <FormCheckBox name={"r"+props.rowIndex+"t"+props.id} label={props.name} checked={props.checked} handleChange={handleChange} />
            </div>
          );
}


function RowHeading(props) {
    if (props.show == "batchImgTag") {
        return (
            <div className="w3-col m4">
                  <div className=" w3-large truncate"  key="title">{props.title}</div>
                  <div className=" w3-large truncate" key="img"><img src={props.url} style={{width: "100%"}}/></div>
            </div>
        );
    } else {
      return <div className="w3-col flex-cen-start w3-large truncate" style={{height: "40px"}}>{props.title}</div>;
    }
}

function Tags(props) {
    if (!props.edited) {
        return null;
    } else {
        const span = (props.show == "batchImgTag") ? "w3-col m8 l8" : "w3-col";
        const rowSpan = (props.show == "batchImgTag") ? "w3-col m4 l3" : "w3-col m3 l2";
        return (
          <div className={span}>
              {props.tags.map((row,index) => <TagRow key={index}  {...row} tagIndex={index} rowIndex={props.rowIndex} rowSpan={rowSpan} selectTags={props.selectTags}/>)}
          </div>
        );
    }
}


function Row(props) {
  function handleEdit(e) {
    props.edit(!props.edited, props.rowIndex);
  }

   if (false) {
     return null;
   } else {
     const text = props.edited ? "Ignore": "Edit";

     return (
       <div className="w3-section w3-border w3-border-red w3-padding w3-container">
         <input type="hidden" name={"rowID"+props.rowIndex} value={props.id}/>
         <input type="hidden" name={"editTags"+props.rowIndex} value={props.edited ? "true" : "false"}/>
         <div className="w3-row w3-display-container">
               <RowHeading {...props} />
               <div className="btf-edit-btn"><Button text={text} click={true} clickHandler={handleEdit}  color="a-btn-blue-1" size="a-btn-size"/></div>
               <Tags {...props} />
         </div>




       </div>

     );
   }
}


export default Row;
