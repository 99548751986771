import Link from '../../Link';

function DropLi(props) {
    if (false) {
      return null;
    } else {
      const click = props.click ? ()=>props.clickHandler(props.meta) : null;
      return (
         <li className="a-btn-drop-row truncate">
            <Link href={props.href} click={click}>
              {props.text}
            </Link>
         </li>
      );
    }
}

export default DropLi;
