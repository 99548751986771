import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import './hero.css';import './grid.css';
import HeroButton from './HeroButton';


function GridElement(props) {
  const width = props.width ? {width: props.width} : {};
  if (props.imgUrl) {

    return (
      <div className="hero-grid" style={width}>
          <div className=" h-100 flx-cen-btm">
              <img src={props.imgUrl} alt="Title image" className="hero-img-grid-el" />
          </div>
      </div>
    )
  } else {
    const padding = props.padding ? {padding: props.padding} : {};
    const h1Style = props.h1Style ? props.h1Style : {}, h2Style = props.h2Style ? props.h2Style : {};

    return (
      <div className="hero-grid" style={width}>
          <div className="hero-title-grid-container" style={padding}>
                <h1 className="" style={h1Style}> {props.h1}</h1>
                <h2 className="" style={h2Style}> {props.h2}</h2>
                <HeroButton button={props.button}/>
          </div>
      </div>
    );
  }
}



export default GridElement;
