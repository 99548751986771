import React, {useState, useEffect, useRef, memo} from 'react';import RangeRow from '../RangeRow/RangeRow';import RangeSectionHeader from '../RangeSectionHeader'; import HsvGraph from './HsvGraph';
import './hsvRect.css'; import {rgbToHsv,hsvToRgb, contrastColorRgbToHex, hslToCss, hsvToCss} from '../../converters';
/*
    <div className=""></div>
*/

function HsvRect(props) {

    const [render, setRender] = useState(window.innerWidth < 734 ? false : true);

    //const [copiedMsg, setCopiedMsg] = useState(false);
    const {rgb, hsl, hex, hsv} = props;
    useEffect(()=>{
        if (window.innerWidth >= 734) return;
        // if !scarlet the window is crolling, so we attach the scroll listner to the window.
        // if scarlet the window is not scrolling, instead the sections container and the scarlet container have their own independent scrolling, so we attach the scroll listner to the sections container directly
        if (props.scarlet) {
          document.getElementById("range-sections").addEventListener("scroll", scroll);
        } else {
          window.addEventListener("scroll", scroll); return ()=> window.removeEventListener("scroll", scroll);
        }
      });




    const scroll = () => { // we check if the slider in in view port otwerwise we stop the autopilot
      const element = document.getElementById("sv-rect-cont");
      if (isNearViewport(element, props.scarlet) && !render ) {
          setRender(true);
      } else if (!isNearViewport(element, props.scarlet) && render) {
          setRender(false);
      }
    }

    if ((props.visibleSections && !["all","pickers"].includes(props.visibleSections) && (window.innerWidth < 734)) || !render) {
      return <div className="cl-range-sections ov-hid" id="sv-rect-cont" style={{minHeight: (window.innerWidth * 1.5) + "px"}}></div>;
    }




    /*
    const mouseUp = (e) => {
      document.dispatchEvent(new MouseEvent("mouseup", {clientX: e.clientX,clientY: e.clientY }));
    }
  */

     const bkg = {};
     const a = (!props.hsl.a &&  (props.hsl.a !== 0)) ? 1 : props.hsl.a;
     bkg.h = {backgroundImage: "linear-gradient(to right," + hslToCss({h:0, s:props.hsl.s, l:props.hsl.l, a: a}) + " 0%, " + hslToCss({h:60, s:props.hsl.s, l:props.hsl.l, a: a}) + " 17%, " +hslToCss({h:120, s:props.hsl.s, l:props.hsl.l, a: a}) + " 33%, " + hslToCss({h:180, s:props.hsl.s, l:props.hsl.l, a: a}) + " 50%, " + hslToCss({h:240, s:props.hsl.s, l:props.hsl.l, a: a}) + " 67%, "  + hslToCss({h:300, s:props.hsl.s, l:props.hsl.l, a: a}) + " 83%, " +
     hslToCss({h:360, s:props.hsl.s, l:props.hsl.l, a: a}) + " 100%)" };
     bkg.a = {backgroundImage: "linear-gradient(to right," + hslToCss({h:props.hsl.h, s:props.hsl.s, l:props.hsl.l, a: 0}) + ", " + hslToCss({h:props.hsl.h, s:props.hsl.s, l:props.hsl.l, a: 0.5}) + ", " + hslToCss({h:props.hsl.h, s:props.hsl.s, l:props.hsl.l, a: 1}) + ")" };

       return (
          <div className="cl-range-sections ov-hid" id="sv-rect-cont">
                <RangeSectionHeader sectionType="hsv" color={{hsv: hsv}}/>
                <RangeRow bkg={bkg} hsl={hsl} setTempColor={props.setTempColor} isScrolling={props.isScrolling} hex={hex} handler={(e)=>props.handleHsl(e, false, "h")} type="h" inSvgRect={true} blur={(e)=>props.handleHsl(e, true, "h")} increase={props.increase}/>
                <RangeRow bkg={bkg} hsl={hsl} setTempColor={props.setTempColor} isScrolling={props.isScrolling} hex={hex} handler={(e)=>props.handleHsl(e, false, "a")} type="a" sectionType="hsl" inSvgRect={true} blur={(e)=>props.handleHsl(e, true, "a")} increase={props.increase}/>
                <HsvGraph {...props} />
         </div>
       );



   }







export default HsvRect;

var isNearViewport = function (elem, scarlet) {
    if (!elem) return null;
    var bounding = elem.getBoundingClientRect();
    if (scarlet) {
        const hexHeight = scarlet ? 0.2 : 0.3;
        const he = document.getElementById("range-sections").getBoundingClientRect().height;
        const sectionsBottom =  60 + window.innerWidth * hexHeight + he; // it's the window innerHeight - scarlet height
        return ((bounding.top <= (sectionsBottom * 1.1)) && (bounding.bottom > 60));

    } else {
        return ((bounding.top <= (window.innerHeight * 1.1)) && (bounding.bottom > (window.innerHeight * -0.1)));
    }

  };

  /*
  const areEqual = (prevProps, nextProps) =>  (prevProps.hex === nextProps.hex) ;

  export default memo(HsvRect, areEqual);
  */
