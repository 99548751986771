import Reac, {useState, memo} from 'react'; import RangeRow from './RangeRow/RangeRow';import SvgIcon from '../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../converters.js';
import RangeSectionHeader from './RangeSectionHeader';

function PickersSections(props) {
  const color = props.color;
  const bkg = {};
  bkg.gray = {backgroundImage: "linear-gradient(to right," + rgbToHex({r:0, g:0, b:0}) + ", " +  rgbToHex({r:127.5, g:127.5, b:127.5}) + ", " +rgbToHex({r:255, g:255, b:255}) + ")" };
  bkg.hue = {backgroundImage: "linear-gradient(to right,rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%)"};

  if (props.visibleSections && !["pickers","all"].includes(props.visibleSections) && (window.innerWidth < 734)) {
    return   <div className="cl-range-sections" id={"Color-Gray"+"picker"}></div>;
  } else {
    return (
      <div className="cl-range-sections" id={"Color-Gray"+"picker"}>
          <RangeSectionHeader sectionType="pickers" color={props.color} scarlet={props.scarlet}/>
          <RangeRow bkg={bkg} {...props.color} setTempColor={props.setTempColor} handler={props.handleRgb} isScrolling={props.isScrolling} type="gray" blur={(e)=>props.handleRgb(e, true)} increase={props.increase} scarlet={props.scarlet}/>
          <RangeRow bkg={bkg} {...props.color} setTempColor={props.setTempColor} handler={props.handleHsl} isScrolling={props.isScrolling} type="hue" blur={(e)=>props.handleHsl(e, true)} increase={props.increase} scarlet={props.scarlet}/>
      </div>
    );
  }

}

const areEqual = (prevProps, nextProps) =>  (prevProps.color.hex === nextProps.color.hex && prevProps.visibleSections === nextProps.visibleSections) ;

export default memo(PickersSections, areEqual);
