
import React from 'react';
import './articleHeader.css';import './headerImg.css';
import Metadata from './Metadata';
import Breadcrumb from './Breadcrumb';
import ImageBlock from '../Body/ImageBlock';

const Subtitle = (props) => props.description ? <h3 className="acFu-title-h3">{props.description}</h3> : null;

function CnnHeader(props) {

    if (!props.title) {
        return null;
    } else {
      const imageContainer = props.metadata ? "cn-header-image-meta" : "cn-header-image-nometa";
      return (
          <header className="rba-header-img-container rba-header-img-defs">
                <div className="w3-section-12">
                    <Breadcrumb clickHandler={props.clickHandler} rows={props.breadcrumb} />
                </div>
                <h1 className={"acFu-title "}>{props.title}</h1>
                <Subtitle description={props.description} />
                <Metadata {...props.metadata}/>
                <ImageBlock {...props.image} className={imageContainer}/>
          </header>
      );
    }
  }

  export default CnnHeader;
