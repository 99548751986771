import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";
import FormContainer from "./FormContainer";

// <DivContainer content="" grid="" label="" centerLabel={false} labelColor="w3-text-blue" color="w3-pale-gray" margin maxWidth/>
function DivContainer(props) {
    const content = (props.content) && (props.type != "password") ? props.content : <span>&nbsp;</span>;
    return (
        <FormContainer grid={props.grid} noPointer={true} margin={props.margin} label={props.label} centerLabel={props.centerLabel} labelColor={props.labelColor} color={props.color} clicked={true}>
              <div className="w3-padding">{content}</div>
        </FormContainer>
    );

}

export default DivContainer;
