import React from 'react'; import UlArraySelectBar from './UlArraySelectBar'; import UlInput from './UlInput';
import  {arrayToObj, arrayToSingleObj} from '../../indexFunctions.js'; import Button from '../../Buttons/Button';
//import './steps.css';

/*
    <div className=""></div>
*/

class EditFixedMenu extends React.Component {
  constructor(props) {
      super(props);
      this.state = {rows: null, index: 0, selectedSub: 0, newSub: null, selectedMenu: "newMenu"};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleCategory = this.handleCategory.bind(this);
      this.handleMenus = this.handleMenus.bind(this);
      this.handleSubMenu = this.handleSubMenu.bind(this);
      this.handleTitle = this.handleTitle.bind(this);
      this.handleMenuBtnRow = this.handleMenuBtnRow.bind(this);
      this.handleMenuName = this.handleMenuName.bind(this);
      this.handleMenuRowsArray = this.handleMenuRowsArray.bind(this);
      this.handleReset = this.handleReset.bind(this);
      this.createEmptyMenu = this.createEmptyMenu.bind(this);
      this.handleSave = this.handleSave.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const value =  target.value;
    this.setState({[target.name]: value});
  }

  handleMenuName(e) {
    const rows = this.state.rows;
    rows.name = e.target.value;
    this.setState({rows: rows});
  }

  handleReset(field) {
    this.setState({[field]: null});
  }

  handleSubmit(e) {
    return;
  }

  handleCategory(index) {
    if (index === "add") {
      let rows = this.state.rows;
      let categories = rows.subMenus[this.state.selectedSub].categories;
      categories.push({id: null, title: "", icon: "", buttons: [{}]});
      const newIndex = categories.length ? categories.length -1 : 0;
      this.setState({rows: rows, index: newIndex});
    } else if (index == "remove") {  // remove the selected slide from slides array using the selectedSlide from satte as the index of the eleemnt to remove
      let rows = this.state.rows;
      let categories = rows.subMenus[this.state.selectedSub].categories;
      categories.splice(this.state.index, 1); //
      this.setState({rows: rows, index:0});
    } else if (index !== this.state.index) {
      this.setState({index: index});
    }
  }

  handleMenus(value) {

    /*
    if (value == "newMenu") {  // We are switching from editing mode to new menu mode, so we import the saved new menu  from the temporary state location and set the last to null
      this.setState({rows: this.state.savedNewMenu, savedNewMenu: null,selectedMenu: value, index: 0, selectedSub:0});
    } else {
      if (prevMenu === "newMenu") {  // we are stiching to new menu to editing mode, so we save the new menu in a temporary satte and take it back when we select again new menu mode
        this.setState({savedNewMenu: this.state.rows,selectedMenu: value, index: 0, selectedSub:0});
      }

    }*/
    this.setState({selectedMenu: value, index: 0, selectedSub:0});
    this.importMenu(value);
  }

  handleSubMenu(menu) {
    if (menu === "add") {
      if (!this.state.newSub) return;
      let rows = this.state.rows;
      rows.subMenus.push({name: this.state.newSub, id: null, categories: [{id: null, title: "", icon: "", buttons: [{}]}]});
      this.setState({rows: rows, index: 0, selectedSub: rows.subMenus.length -1, newSub: null});
    } else if (menu === "rename") {
      if (!this.state.newSub) return;
      let rows = this.state.rows;
      if (rows.subMenus[this.state.selectedSub].name === "main") return;
      rows.subMenus[this.state.selectedSub].name = this.state.newSub;
      this.setState({rows: rows, newSub: null});
    } else if (menu == "remove" && this.state.selectedSub) {  // remove the selected slide from slides array using the selectedSlide from satte as the index of the eleemnt to remove
      let rows = this.state.rows;
      rows.subMenus.splice(this.state.selectedSub, 1);
      this.setState({rows: rows, index:0, selectedSub: 0});
    } else if (menu !== this.state.selectedSuba) {
      this.setState({selectedSub: menu, index: 0});
    }
  }

  handleMenuBtnRow(value, i, field, btnIndex) {
      let rows = this.state.rows;
      let menu = rows.subMenus[this.state.selectedSub];
      menu.categories[i].buttons[btnIndex][field] = value;
      if ((field === "target") && (value == "subMenu")) {
        menu.categories[i].buttons[btnIndex]["url"] = null;
        menu.categories[i].buttons[btnIndex]["meta"] = null;
      } else if ((field === "target") && (value === "url" || value === "meta")) {
        menu.categories[i].buttons[btnIndex]["subMenu"] = null;
      }
      this.setState({rows: rows});
  }

  handleMenuRowsArray(action, index, btnIndex) {
      let rows = this.state.rows;
      let menu = rows.subMenus[this.state.selectedSub];
      if (action == "addRow") {
        menu.categories[index].buttons.push({});
      } else if (action == "deleteRow") {
        menu.categories[index].buttons.splice(btnIndex, 1);
      }
      this.setState({rows: rows});
  }

  handleTitle(value, i, field) {
    let rows = this.state.rows;
    let menu = rows.subMenus[this.state.selectedSub];
    menu.categories[i][field] = value;
    this.setState({rows: rows});
  }

  getSubsIDs(rows) {
    if (!rows) {
      return null;
    } else {
      let out = [];
      for (let i = 0; i<rows.length; i++) {
        out.push([rows[i].name, i]);
      }
      return out;
    }
  }
  getMenuIDs(menus) {

      let out = [];
      for (let i = 0; i<menus.length; i++) {
        out.push([menus[i].name, i]);
      }

      return out;

  }

  importMenu(index) {
    const menu = this.state.menus[index];
    this.setState({rows: menu});
    }

  createEmptyMenu() {
    const menus = this.state.menus;
    menus.push({name:"New menu", subMenus: [{id: null, name: "main",  categories: [{id: null, title: "", icon: "", buttons: [{}]}]}]});
    this.setState({rows: menus[menus.length-1], selectedMenu: menus.length-1});
  }

  finalizeSave(result) {
    const sideMenButtons = ["buttons", ["id", "title", "icon", "iconColor", "inconSizeAdj","target", "meta", "url", "subMenu", "adminButton", "userButton", "categoryID"]];
    const categories = ["categories",["id", "title", "adminButton","icon", "subMenuID", sideMenButtons]];
    const menus = arrayToObj(result, ["id", "name", ["subMenus", ["id", "name", "sideMenuID", categories]]]);
    this.props.menus = menus;
    this.setState({menus: menus, rows: menus[0], selectedMenu: 0});
    alert("saving complete");
  }

  handleSave() {

    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(this.state.rows),};
    fetch("/menu/newMenu", data).then((res) => res.json()).then((result)=>{ this.finalizeSave(result) }, (error) => alert("error in saving"));
  }

  componentDidMount() {
    const menus = this.props.menus;
    this.setState({menus: menus, rows: menus[0], selectedMenu: 0});

  }

  render() {
      if (this.props.show !== "editFixedMenu") {
        return null;
      } else if (!this.state.menus || !this.state.rows.subMenus) {
        return null;
      }
      const subKeys = this.getSubsIDs(this.state.rows.subMenus);
      const menuKeys = this.getMenuIDs(this.state.menus);
      const deleteUrl  = this.state.rows.id ? "/menu/delete"+this.state.rows.id : null;
      return (
        <div className="w3-content-1300 w3-section-32 h100vh">
                  <UlArraySelectBar rows={this.state.rows.subMenus[this.state.selectedSub]} menuName={this.state.rows.name} handleCategory={this.handleCategory} index={this.state.index} subKeys={subKeys} selectedSub={this.state.selectedSub}
                  handleSubMenu={this.handleSubMenu} createEmptyMenu={this.createEmptyMenu} handleMenuName={this.handleMenuName} handleChange={this.handleChange} newSub={this.state.newSub} menuKeys={menuKeys} handleReset={this.handleReset} selectedMenu={this.state.selectedMenu} handleMenus={this.handleMenus}/>
                  <UlInput key={this.state.selectedMenu} rows={this.state.rows.subMenus[this.state.selectedSub]} handleRow={this.handleRow} metas={this.props.metas} index={this.state.index} handleCategory={this.handleCategory} handleTitle={this.handleTitle}
                   handleMenuBtnRow={this.handleMenuBtnRow} handleMenuRowsArray={this.handleMenuRowsArray} selectedSub={this.state.selectedSub} handleSubMenu={this.handleSubMenu} subKeys={subKeys} selectedMenu={this.state.selectedMenu} handleSubmit={this.handleSubmit} menuID={this.state.rows.id}/>
                     <div className="w3-section-32 flex-cen-cen">
                          <Button  text="Save" click={this.handleSave} color="a-btn-blue-1"  className="w3-margin-left-48 mw-250" size="a-btn-size-4"/>
                          <Button  text="Delete Menu" href={deleteUrl} color="a-btn-blue-1"  className="w3-margin-left-48 mw-250" size="a-btn-size-4"/>
                     </div>

        </div>
      );
  }

}

export default EditFixedMenu;

/*

*/
