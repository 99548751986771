import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";

import FormContainer from "./FormContainer";



function FormCheckBox(props) {

    const value = props.value ? props.value : "on";

    return (
            <div className="flex-cen-start h40">
            <div className="w3-padding-top-4 w3-container" style={{width: "56px"}}>
            <input type="checkbox" className="w3-check w3-margin-right" checked={props.checked} name={props.name} onChange={props.handleChange} />
            </div>
            <div>
            <label> {props.label}</label>
            </div>
            </div>
            );
}




export default FormCheckBox;
