import React from 'react';import Button from '../../Buttons/Button';


/*
    <div className=""></div>
*/

function BtnColors(props) {
  if (!props.show) {
    return null;
  } else {
    const BtnCol = (props)=> <div className="w3-container w3-section"><Button click={()=>props.setColor("colorID",props.id)} text={props.name} color={"a-btn-"+props.name.toLowerCase().replace(/\s/g , "-")} size="a-btn-size-4" /></div>;

    return (
      <div className=" flex-cen-cen a-wrap">
            {props.colors.map((row, index) => <BtnCol {...row} key={index} setColor={props.setColor}/>)}
    </div>
    );
  }
}


export default BtnColors;
