import React, {useState} from 'react';import Button from '../../Buttons/Button'; import './btn.css';


/*
    <div className=""></div>
*/
function Btn(props) {
    if (props.updated == "deleted") {
      return null;
    } else {
      let options = {};
      options.size = props.borderRad + " "+props.fontWeight+ " "+props.container+ " "+props.height+ " "+props.fontSize;
      options.filling=props.style;
      const color = getColor(props.colorID, props.colors);
      options.color="a-btn-"+color.name.toLowerCase().replace(/\s/g , "-");
      const jsonOptions = JSON.stringify(options);
      const clipBoard = () => {navigator.clipboard.writeText(jsonOptions);}
      return (
          <div className={"w3-padding-bottom-16 w3-display-container w3-margin-right-24 "+(props.index===props.selected ? "w3-bottombar w3-border-blue":"")}>
          <div className="w3-display-topright btnSa-code"><Button icon="code" click={clipBoard} size="btnSa-code-size" color="a-btn-orange-1" /></div>
                  <Button click={()=>{props.selectBtn(props.index);props.setCss(jsonOptions);}} text={props.name} {...options} />
          </div>
      );
    }
}

function BtnSizes(props) {
  const [css, setCss] = useState("");
   if (false) {
     return null;
   } else {
     return (
       <div className="">
           <div className="flex-cen-start">
            {props.rows.map((row, index)=> <Btn {...row} setCss={setCss} key={index} index={index} selected={props.selected} selectBtn={props.selectBtn} colors={props.colors}/>)}
           </div>
           <div className="a-section-16 a-bkg-azure a-pd-20">
              {css}
           </div>
       </div>



     );
   }
}



export default BtnSizes;


function getColor(id, colors) {
  if (!id || !colors) return "";
  for (let i=0; i < colors.length; i++) {
    if (colors[i].id == id) {
      return colors[i];
    }
  }
  return colors[0];
}
