import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import "./background.css";

/*
sets a Background image covering the whole abolute container (defs 0px to 700px height)or in case front contains an image grid instead of the background image sets a background color

there is a css gradient from (defs 250px to 700px) blurring the bkg image or color


*/

function Background(props) {
  let background = {};
  if (props.imgUrl) {
      if ((typeof props.imgUrl === 'string') || (props.imgUrl instanceof String)) {
          background.backgroundImage = `url(${props.imgUrl})`;
      } else {
          let img = "";
          if (props.isSmarthPhone && props.imgUrl.small) {
            img = props.imgUrl.small;
          } else if (props.isTablet && props.imgUrl.medium){
            img = props.imgUrl.medium;
          } else {
            img = props.imgUrl.large;
          }
          background.backgroundImage = `url(${img})`;
      }

  } else if (props.color) {
    background.backgroundColor =  props.color;
  }

    background.backgroundPosition = (props.isSmarthPhone && props.isPortrait ? (props.imPosSPX + " " + props.imPosSPY) : (props.isSmarthPhone && props.isLandscape  ? props.imPosSLX + " " + props.imPosSLY : (props.isTablet && props.isPortrait  ? props.imPosTPX + " " + props.imPosTPY : (props.isTablet && props.isLandscape  ? (props.imPosTLX + " " + props.imPosTLY) : (props.imPosDX + " " + props.imPosDY)))));


  return (
      <div className="hero-abs-container" style={props.height}>
          <div className="hero-img-cont" style={background}>

          </div >
      </div>
        );
}


export default Background;
