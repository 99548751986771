import React, { useState } from 'react';
import Button from '../../../Buttons/Button';import {FormInput, DivSelect} from "../../../forms/forms.js";

function FormMenuBtnRowInput(props) {
    function handleChange(e) {
      props.handleChange(e.target.value, props.index, props.field);
    }
    if (props.targeted && (props.target != props.field)) {
        return null;
    } else if (props.selType && (((props.field == "text") && ((props.type != "icon + text") && (props.type != "text"))))
                                || (((props.field == "icon") && ((props.type != "icon + text") && (props.type != "icon"))))
                                || (((props.field == "logo") || (props.field == "alt")) && (props.type != "logo"))
              )
   {
      return null;
    } else {
      return (
            <FormInput name={props.field} label={props.label ? props.label : props.field} value={props[props.field]} maxWidth="" className={props.className} handleChange={handleChange}  color="w3-light-gray" validate=""  />
      );
    }


}


function FormMenuBtnRowDivSelect(props) {
    const iconFields = ["icon","inconAdj"];
    function handleChange(value) {
      props.handleChange(value, props.index, props.field);
    }

    if (props.targeted && ((props.target != props.field) && (props.target != props.targeted))) {
        return null;
    } else if (props.selType && (((props.field == "text") && ((props.type != "icon + text") && (props.type != "text"))))
                                || (((iconFields.includes(props.field)) && ((props.type != "icon + text") && (props.type != "icon"))))
                                || (((props.field == "logo") || (props.field == "alt")) && (props.type != "logo"))
              )
   {
      return null;
    }  else {
        return (
              <DivSelect name={props.field} label={props.label ? props.label : props.field} cols={props.cols} colorSelector={props.colorSelector} colsDisp={props.colsDisp} iconsSelect={props.iconsSelect} value={props[props.field]} rangeStart={props.rangeStart}
              rangeEnd={props.rangeEnd} options={props.options} handleOptionClick={handleChange} className={props.className} color="w3-light-gray" validate=""  />
        );
    }
}

function IconAdj(props) {
    const iconAdjOptions = [["", null],["+ 1px","inc1"],["+ 2px","inc2"],["+ 3px","inc3"],["+ 4px","inc4"],["- 1px","dec1"],["- 2px","dec2"],["- 3px","dec3"],["- 4px","dec4"]];
    const iconLePadOptions = [["", null],["1px","iconLePad-1"],["2px","iconLePad-2"],["3px","iconLePad-3"],["4px","iconLePad-4"],["5px","iconLePad-5"],["6px","iconLePad-6"]];
    const iconRiPadOptions = [["", null],["1px","iconRiPad-1"],["2px","iconRiPad-2"],["3px","iconRiPad-3"],["4px","iconRiPad-4"],["5px","iconRiPad-5"],["6px","iconRiPad-6"]];
    const iconToPadOptions = [["", null],["1px","iconToPad-1"],["2px","iconToPad-2"],["3px","iconToPad-3"],["4px","iconToPad-4"],["5px","iconToPad-5"],["6px","iconToPad-6"]];
    const iconBoPadOptions = [["", null],["1px","iconBoPad-1"],["2px","iconBoPad-2"],["3px","iconBoPad-3"],["4px","iconBoPad-4"],["5px","iconBoPad-5"],["6px","iconBoPad-6"]];
    return (
      <div className="w3-section-32 w3-row w3-display-container w3-animate-opacity-04" key={props.btnIndex+"buttonAdj"+props.updateCount}>
          <FormMenuBtnRowDivSelect field="inconSizeAdj"  key={"inconSizeAdj"+props.btnIndex} index={props.btnIndex} {...props}   className="w3-col m2 w3-container-8" options={iconAdjOptions} label="Icon Size Adjustment" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="iconLePad" index={props.btnIndex}  {...props}  className="w3-col m2 w3-container-8" options={iconLePadOptions} label="Icon Left Pad" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="iconRiPad" index={props.btnIndex} {...props}  className="w3-col m2 w3-container-8" options={iconRiPadOptions} label="Icon Right Pad" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="iconToPad" index={props.btnIndex}  {...props}   className="w3-col m2 w3-container-8" options={iconToPadOptions} label="Icon Top Pad" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="iconBoPad" index={props.btnIndex}  {...props}   className="w3-col m2 w3-container-8" options={iconBoPadOptions} label="Icon Bottom Pad" handleChange={props.setButton}/>
          <div className="w3-col  w3-section-32 flex-cen-cen " ><Button text="Done" className="h-100" color="a-btn-blue-1" size="a-btn-size-3" click={()=>props.setIconAdj(false)} /></div>
      </div>
    );
}

function ButtonStyle(props) {
  const colorOptions = [["",null],["Orange 1", "a-btn-orange-1"]];
  const styleOptions = [["",null],["Filled", "a-btn-filled"],["Filled Dark", "a-btn-filled-dark"],["Text on Hover","a-btn-textOnHover"],["Border on hover","a-btn-bordOnHover"],["HW","a-btn-HW"],["Bordered","a-btn-bordered"]];
  const dropWidth = [["150px",150],["200px",200],["250px",250],["300px",300],["350px",350],["400px",400],["405px",450],["500px",500],["550px",550],["600px",600]];
  const tipMar = [["1%","tipMar1"], ["2%","tipMar2"], ["3%","tipMar3"], ["4%","tipMar4"], ["5%","tipMar5"], ["6%","tipMar6"], ["7%","tipMar7"], ["8%","tipMar8"], ["9%","tipMar9"], ["10%","tipMar10"], ["11%","tipMar11"], ["12%","tipMar12"], ["13%","tipMar13"], ["14%","tipMar14"], ["15%","tipMar15"], ["16%","tipMar16"], ["17%","tipMar17"], ["18%","tipMar18"], ["19%","tipMar19"], ["20%","tipMar20"], ["21%","tipMar21"], ["22%","tipMar22"], ["23%","tipMar23"], ["24%","tipMar24"], ["25%","tipMar25"], ["26%","tipMar26"], ["27%","tipMar27"], ["28%","tipMar28"], ["29%","tipMar29"], ["30%","tipMar30"], ["31%","tipMar31"], ["32%","tipMar32"], ["33%","tipMar33"], ["34%","tipMar34"], ["35%","tipMar35"], ["36%","tipMar36"], ["37%","tipMar37"], ["38%","tipMar38"], ["39%","tipMar39"], ["40%","tipMar40"], ["41%","tipMar41"], ["42%","tipMar42"], ["43%","tipMar43"], ["44%","tipMar44"], ["45%","tipMar45"], ["46%","tipMar46"], ["47%","tipMar47"], ["48%","tipMar48"], ["49%","tipMar49"], ["50%","tipMar50"], ["51%","tipMar51"], ["52%","tipMar52"], ["53%","tipMar53"], ["54%","tipMar54"], ["55%","tipMar55"], ["56%","tipMar56"], ["57%","tipMar57"], ["58%","tipMar58"], ["59%","tipMar59"], ["60%","tipMar60"], ["61%","tipMar61"], ["62%","tipMar62"], ["63%","tipMar63"], ["64%","tipMar64"], ["65%","tipMar65"], ["66%","tipMar66"], ["67%","tipMar67"], ["68%","tipMar68"], ["69%","tipMar69"], ["70%","tipMar70"], ["71%","tipMar71"], ["72%","tipMar72"], ["73%","tipMar73"], ["74%","tipMar74"], ["75%","tipMar75"], ["76%","tipMar76"], ["77%","tipMar77"], ["78%","tipMar78"], ["79%","tipMar79"], ["80%","tipMar80"], ["81%","tipMar81"], ["82%","tipMar82"], ["83%","tipMar83"], ["84%","tipMar84"], ["85%","tipMar85"], ["86%","tipMar86"], ["87%","tipMar87"], ["88%","tipMar88"], ["89%","tipMar89"], ["90%","tipMar90"], ["91%","tipMar91"], ["92%","tipMar92"], ["93%","tipMar93"], ["94%","tipMar94"], ["95%","tipMar95"], ["96%","tipMar96"], ["97%","tipMar97"], ["98%","tipMar98"], ["99%","tipMar99"]];
  return (
      <div className="w3-section-32 w3-row w3-display-container w3-animate-opacity-04" key={props.btnIndex+"buttonStyle"+props.updateCount}>
          <FormMenuBtnRowDivSelect field="color"  index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={colorOptions} label="Color" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="style"  index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={styleOptions} label="Style" handleChange={props.setButton}/>
          <FormMenuBtnRowInput  field="size" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" label="Size" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="dropColT" targeted="dropMenu" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" rangeStart={1} rangeEnd={8} label="Drop Cols Tablet" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="dropColD" targeted="dropMenu" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" rangeStart={1} rangeEnd={8} label="Drop Cols Desktop" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="dropDirection" targeted="dropMenu" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={[["Left","drop-left"],["Right","drop-right"],["Center","drop-center"]]} label="Drop Direction" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="dropWidth" targeted="dropMenu" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={dropWidth} label="Drop Width" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="dropSize" targeted="dropMenu" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={[null, 1, 2]} label="Drop Size" handleChange={props.setButton}/>
          <FormMenuBtnRowDivSelect field="dropColor" targeted="dropMenu" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={[null, 1, 2]} label="Drop Color" handleChange={props.setButton}/>
          <div className="w3-col  w3-section-32 flex-cen-cen " ><Button text="Done" className="h-100" color="a-btn-blue-1" size="a-btn-size-3" click={()=>props.setBtnStyle(false)} /></div>
      </div>
    );
}

function ButtonVisibility(props) {
    return (
      <div className="w3-section-32 w3-row w3-display-container w3-animate-opacity-04" key={props.btnIndex+"buttonVisibility"+props.updateCount}>
      <FormMenuBtnRowDivSelect field="adminButton" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" rangeStart={0} rangeEnd={5} label="Admin" handleChange={props.setButton}/>
      <FormMenuBtnRowDivSelect field="userButton" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={["in","out", ""]} label="User" handleChange={props.setButton}/>
      <FormMenuBtnRowDivSelect field="onTablet" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={["Yes", "No", null]} label="On Tablet" handleChange={props.setButton}/>
          <div className="w3-col  w3-section-32 flex-cen-cen " ><Button text="Done" className="h-100" color="a-btn-blue-1" size="a-btn-size-3" click={()=>props.setBtnVisibility(false)} /></div>
      </div>
    )
}

function MenuButton(props) {
  function handleDelete() {
      props.setButton("deleteButton", props.btnIndex,"deleteButton");
  }
  let [showIconAdj, setIconAdj] = useState(false);
  let [showBtnStyle, setBtnStyle] = useState(false);
  let [showBtnVisibility, setBtnVisibility] = useState(false);
  const targetOptions = [["Url", "url"], ["Email", "email"],["Meta","meta"], ["Drop Menu", "dropMenu"],["Log", "log"]];

  const typeOptions = ["icon", "icon + text","text","logo","back", null];

  const moveUp = (props.btnIndex != 0) && (props.length) ? true : false;
  const moveDown = (props.btnIndex < (props.length-1)) ? true : false;
  let metaOptions = [];
  if (props.metas) {
    for (let i=0; i<props.metas.length; i++) {
        metaOptions.push([props.metas[i].btnName, props.metas[i].id]);
    }
  }
  metaOptions.push(["", null]);
  if (showIconAdj) {
    return <IconAdj {...props} setIconAdj={setIconAdj}/>;
  } else if (showBtnStyle) {
    return <ButtonStyle {...props} setBtnStyle={setBtnStyle}/>;
  } else if (showBtnVisibility) {
    return <ButtonVisibility {...props} setBtnVisibility={setBtnVisibility}/>;
  } else {
    return (
        <div className="w3-section-32 w3-row w3-display-container w3-animate-opacity-04" key={props.btnIndex+"button"+props.updateCount}>
            <FormMenuBtnRowInput  field="text" index={props.btnIndex}  {...props} selType={true} className="w3-col m2 w3-container-8" label="Text" handleChange={props.setButton}/>
            <FormMenuBtnRowDivSelect field="icon" index={props.btnIndex} {...props} cols={6} selType={true} colsDisp={1}  iconsSelect={true} className="w3-col m2 w3-container-8" label="Button Icon" handleChange={props.setButton}/>
            <FormMenuBtnRowInput  field="tooltip" index={props.btnIndex} {...props} className="w3-col m1 w3-container-8" label="Tooltip" handleChange={props.setButton}/>
            <FormMenuBtnRowInput  field="logo" index={props.btnIndex}  {...props} selType={true} className="w3-col m2 w3-container-8" label="Logo" handleChange={props.setButton}/>
            <FormMenuBtnRowInput  field="alt" index={props.btnIndex}  {...props} selType={true} className="w3-col m2 w3-container-8" label="Logo Alt" handleChange={props.setButton}/>
            <FormMenuBtnRowDivSelect field="type" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={typeOptions} label="Button Type" handleChange={props.setButton}/>
            <FormMenuBtnRowDivSelect field="target" index={props.btnIndex} {...props} className="w3-col m2 w3-container-8" options={targetOptions} label="Target" handleChange={props.setButton}/>
            <FormMenuBtnRowDivSelect field="meta" targeted={true} index={props.btnIndex}  {...props} className="w3-col m2 w3-container-8" options={metaOptions} label="Meta ID" handleChange={props.setButton}/>
            <FormMenuBtnRowDivSelect field="log" targeted={true} index={props.btnIndex}  {...props} className="w3-col m2 w3-container-8" options={[["", null],["In","logIn"],["Out","logOut"]]} label="Log" handleChange={props.setButton}/>
            <FormMenuBtnRowInput field="url" targeted={true} index={props.btnIndex} {...props} className="w3-col m2 w3-container-8"  label="Url" handleChange={props.setButton}/>
            <FormMenuBtnRowInput field="email" targeted={true} index={props.btnIndex} {...props} className="w3-col m2 w3-container-8"  label="Email" handleChange={props.setButton}/>
            <div className="w3-col m1 w3-section flex-cen-cen " style={{height:"57px"}}><Button text="Set Icon Adj" className="h-100" color="a-btn-blue-1" size="a-btn-size-3" click={()=>setIconAdj(true)} /></div>
            <div className="w3-col m1 w3-section flex-cen-cen " style={{height:"57px"}}><Button text="Btn Style" className="h-100" color="a-btn-blue-1" size="a-btn-size-3" click={()=>setBtnStyle(true)} /></div>
            <div className="w3-col m1 w3-section flex-cen-cen " style={{height:"57px"}}><Button text="Visibility" className="h-100" color="a-btn-blue-1" size="a-btn-size-3" click={()=>setBtnVisibility(true)} /></div>
            <div className="w3-display-right" style={{marginRight: "-65px", marginTop:"-30px"}}>
                    <Button svg="delete" click={handleDelete} color="a-btn-orange-2" size="a-btn-size-2-icon w3-margin-left-24"   className="" />
            </div>
            <div className="w3-display-right" style={{marginRight: "-65px", marginTop:"30px"}}>
                    <Button svg="menu" click={(props.target == "dropMenu") ? true  : false} color="a-btn-blue-2" size="a-btn-size-2-icon w3-margin-left-24" id={props.btnIndex} clickHandler={props.selectDropMenu}  className="" />
            </div>
            <div className="w3-display-topleft h-100 etb-btn-ordering" >
                    <Button svg="arrow_upward" click={moveUp} color="a-btn-blue-1" size="a-btn-size-2-icon w3-margin-left-24" id={{index: props.btnIndex, direction: "up"}} clickHandler={props.moveButton}  className="" />
                    <Button svg="arrow_downward" click={moveDown} color="a-btn-blue-1" size="a-btn-size-2-icon w3-margin-left-24" id={{index: props.btnIndex, direction: "down"}} clickHandler={props.moveButton}  className="w3-margin-top-24" />
            </div>
        </div>
  );
  }

}

export default MenuButton;
