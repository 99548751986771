import React from 'react';
import './fixedMenu.css';
import Link from '../../Link';
import SideMenu from '../SideMenu/SideMenu';
import SlidingPanel from '../../Positioned/SlidingPanel';


/*
    <div className=""></div>
*/

/*
SlidingPanel provides the fixed container with the close button on the side. At the moment there is not the absolute positioning implemented.
The menu is provided by the imported SideMenu Component which doesn't provide any positioning that's why it's rendered by SlidingPanel that implements the positioning.

The higher component has to provide the open/closing functionalities.
<FixedMenu show handleClose />
show = "open/close/false" is set by the higher componet. false means the menu is closed. open means open the menu with animation and close means close and animate the closing. after closing the overlay remains on so the higher component after setting the state to "close" should change it to false with a timeout in less than a second to remove the overlay
*/

function FixedMenu(props) {
      if (!props.show) return null;
      let rows;
      const fullWidth = window.innerWidth < 500;
      for (let i=0; i<props.menus.length; i++) {
        if (props.menus[i].name === "Fixed Menu") {
           rows = props.menus[i].subMenus;
        }
      }

      function clickHandler(page) {
        props.clickHandler(page);
        props.handleClose("close"); // if the user click a menu  in SlideMenu we have to manually close the sliding pannel calling handleClose
      }


      function handleClose() {
        props.handleClose("close");
      }



    if (rows) {

       const scrollID = Math.random();
       return (
          <SlidingPanel show={props.show} width="fix-men-wid" direction="left" handleClose={props.handleClose} scrollID={scrollID} fullWidth={fullWidth}>
                  <SideMenu rows={rows} user={props.user} closeHanlder={handleClose} scrollID={scrollID} clickHandler={clickHandler} iconBarColor={props.iconBarColor} />
            </SlidingPanel>
       );
     }
}


export default FixedMenu;
