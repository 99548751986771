import React, {useEffect, useState} from 'react'; import {DivSelect, FormInput} from '../../../../../forms/forms';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';
import SvgIcon from '../../../../../SvgIcon';

/*
    <div className=""></div>
*/

function RangeLevelInput(props) {
    const [errorMsg, setErrorMsg] = useState(null);
    const myRef = React.createRef();
    const focus = ()=> myRef.current.autofocus();
    const {type, value, editValue, setEditValue, max} = props;
    useEffect(()=>{if (editValue) myRef.current.autofocus();}, [editValue]);
    const errorPos = (editValue && editValue.adj) ? " cl-err-left " : "";

    function handler(e) {
        const value = e.target.value;
        const length = value.length;
        const int = parseInt(value ? value : 0, 10);

        if (value && !["","0","1","2","3","4","5","6","7","8","9"].includes(value.charAt(length-1)) ) {
          myRef.current.setRedBackground();
          setErrorMsg("Only numbers allowed!");
          setTimeout(()=>setErrorMsg(null),2000);
        }  else if (Number.isInteger(int) && (int < 0 || int > max) ) {
          myRef.current.setRedBackground();
          setErrorMsg("Only numbers in 0 - "+max+" range!");
          setTimeout(()=>setErrorMsg(null),3000);
        } else {
          props.handler(e);
        }
    }
    if (!editValue) return null;

    return (
      <div className="cl-input-range-edit-container flex-cen-cen  noselect" onClick={(e)=>e.stopPropagation()}>
                    <div className="a-font-32 w3-margin-right-32 a-font-blue-1">{type.toUpperCase()}</div>
                    <FormInput name={type} value={value} margin="none" handleChange={handler} blur={(e)=>{props.blur(e);setEditValue(false);myRef.current.setBlurBackground();}}
                        defs="cl-range-input-defs cl-range-input-col" ref={myRef} className="cl-input-range-edit"/>
                    <div className="w3-margin-left-32"><SvgIcon color="#164E17" size="30px" click={()=>null} icon="done"/></div>
                    <ErrorMsg error={errorMsg} errorPos={errorPos}/>

      </div>
    );
}

export default RangeLevelInput;

function ErrorMsg(props) {
  if (!props.error) {return null} else {return <div className={"cl-inp-col-err flex-cen-start"+props.errorPos}><SvgIcon icon="error" size="40px" color="red"/><div className="w3-margin-left-8">{props.error}</div></div>;}
}
