import {DivSelect} from '../../../../../forms/forms';import Button from '../../../../../Buttons/Button';import SvgIcon from '../../../../../SvgIcon';
import {intToEsa,rgbToHex, hexToRgb, hslToRgb, rgbToHsl, rgbToCss, hslToCss,contrastColorRgbToHex, contrastColor,rgbToHsv,hsvToRgb} from '../../converters.js';


const ElementSelector = (props) => {
  const {sclColors, setSclColors, selected, select, selectedCard, selectCard,cardEl, setCardEl,selectedBtn, selectBtn, btnEl,setBtnEl,selectedText,
  selectText,textEl, setTextEl, selectedTextType, selectTextType, index, setIndex, cards, buttons, texts, selectType, sample, txt, btnBkg, cardgradEl, setGradEl, gradient, selectedGradientType, selectGradientType, selectedGradient, selectGradient, gradEl} = props.shared;
  const selectedElement = (selected === "buttons") ? selectedBtn : ((selected === "text") ? selectedText : ((selected === "cards") ? selectedCard : selectedGradient));
  const cardsNum = cards ? cards.length : 1;
  const btnsNum = buttons ? buttons.length : 1;
  const textsNum = texts ? texts.length : 1;
  const gradNum = gradient ? gradient.length : 1;
  const rangeEnd = (selected === "buttons") ? btnsNum - 1 : ((selected === "text") ? textsNum - 1 : (selected === "cards" ? cardsNum - 1 : gradNum -1));
  const selectElement = (value) => {
    if (selected == "buttons") {
      const filling = buttons[value].filling;
      selectBtn(value);
      if (["a-btn-textOnHover","a-btn-bordOnHover"].includes(filling)) {
        props.setHex(buttons[value].txt);
        if (btnEl !== "txt") setBtnEl("txt");
      } else {
        props.setHsl(buttons[value].bkg);
        if (btnEl !== "bkg") setBtnEl("bkg");
      }
    } else if (selected == "cards") {
      selectCard(value);props.setHsl(cards[value].bkg);
      if (cardEl !== "bkg") {setCardEl("bkg");}
    } else if (selected == "text") {
      selectText(value);
      if (selectedTextType !== "text") selectTextType("text");
      props.setHex(texts[0].text[0]);
      if (textEl !== 0) setTextEl(0);
    } else if (selected === "gradient") {
      selectGradient(value);
      if (selectedGradientType !== "color") selectGradientType("color");
      if (gradEl !== 0) setGradEl(0);
      props.setRgb(gradient[selectedGradient].colors[0].color);
    }
  }
  return (
    <DivSelect value={selectedElement} hide={!["cards","buttons","text","gradient"].includes(selected)} rangeStart={0} rangeEnd={rangeEnd} handleOptionClick={selectElement}
    defs="form-noLab-xsmall"  defColors="form-col-orange"   margin="none" className="a-width-40 a-mrL-4" noIcon={true} topDrop={false}/>

  );
}

export default ElementSelector;
