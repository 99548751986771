import React from 'react';
import './tagsBar.css';
import Button from '../Buttons/Button';
/*
    <div className=""></div>
*/

function Tag(props) {
  if (!props.id) {
    return null;
  } else {
    const color = props.selected.includes(props.id) ? "a-btn-blue-1" : "a-btn-orange-1";
    return (
      <Button text={props.name} color={color} size="a-btn-size-3" click={true} clickHandler={props.selectTag} id={props.id} className="tags-bar-btn-margins"/>
    );
  }
}

function TagsBar(props) {
   if (!props.show) {
     return null;
   } else {
     return (
       <div className="tags-bar-container">
          {props.tags.map((tag) => <Tag {...tag} key={tag.id} selectTag={props.select} selected={props.selected}/>)}
       </div>
     );
   }
}


export default TagsBar;
