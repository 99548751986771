import React from 'react';
import {FormInput} from "../../forms/forms.js";
import Button from '../../Buttons/Button';

/*
    <div className=""></div>
*/

function EditRow(props) {
  function handleChange(e) {
    props.handleMetas(e, props.index)
  }

   if (false) {
     return null;
   } else {
     return (
       <div className="w3-section w3-col m12">
              <div className="w3-margin-top-24 w3-margin-bottom w3-row ">
               <FormInput name="btnName" view={true} label="Btn Name" value={props.metas[props.index].btnName} maxWidth="w3-col m4" handleChange={handleChange}  color="w3-light-gray" validate=""  />
               <FormInput name="component" view={true} label="Component" value={props.metas[props.index].component} maxWidth="w3-col m8" handleChange={handleChange}  color="w3-light-gray" validate=""  />


               <div className="w3-col m4 flex-cen-start">
                    <Button href={"/tags/deleteMeta/"+props.id} text="Delete" color="a-btn-blue-1" size="a-btn-top w3-margin-left"  />
                    <Button text="Edit " click={true} clickHandler={props.editMetaHandler} id={props.index} color="a-btn-blue-1" size="a-btn-top w3-margin-left"  />
               </div>
           </div>
       </div>
     );
   }
}


export default EditRow;
