import React from 'react';
import ReactPlayer from 'react-player';import UlSlider from '../../../UlSlider/UlSlider';import Link from '../../../Link';import SvgIcon from '../../../SvgIcon';
import Image from './Image'; import Video from './Video';
/*
    <div className=""></div>
*/



function MediaRow(props) {

    if (!props.data || !props.rendered) {
      return null;
    } else if (props.data.video_url) {
        return (
            <Video {...props.data} inViewPort={props.inViewPort} active={props.active} user={props.user} editVideo={props.editVideo} light={props.light} feedID={props.feedID}/>
        );
   } else {
        return (
            <Image {...props.data} user={props.user} editImage={props.editImage} feedID={props.feedID}/>
        );
   }
}



function Media(props) {
   if (!props.videos && !props.images ) {
     return null;
   } else {

      const media = (props.images && !props.videos) ? props.images : ((!props.images && props.videos) ? props.videos : props.images.concat(props.videos));
      const light = !(media.length === 1);
       return (
         <div className="">
               <div className="ukr-feed-media-wrapper" id={props.title}>
                  <div className="ukr-feed-media-abs">
                    <UlSlider rows={media} cols={props.bot ? false : 5} iconColor="black" rowFunction={<MediaRow user={props.user} editImage={props.editImage}
                    editVideo={props.editVideo}  light={light} feedID={props.feedID} rendered={props.rendered}/>} sliderID={props.title}/>
                  </div>
               </div>
         </div>

       );
  }
}




export default Media;
