import React from 'react';
import "../w3.css";
import "../luke.css";
import "./form.css";
import SvgIcon from '../SvgIcon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormContainer from "./FormContainer";
import DivContainer from "./DivContainer";
// npm install react-datepicker --save



// <FormContainer grid= maxWidth= color= label= />






// <FormDate handleChange name= selectsStart={true} date={false} startDate endDate grid color={this.state.color} label={this.props.label} centerLabel={this.props.centerLabel} labelColor={this.props.labelColor} />
class FormDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {color: "frm-blur-color", cliked: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleLabelClick = this.handleLabelClick.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange(e) {
        this.setState({color: "frm-focus-color", clicked: true});
        this.props.handleChange(e);
    }
    setRedBackground() {
        this.setState({color: "frm-error-color"});
    }

    handleLabelClick() {
      this.setState({clicked: true});
    }


    handleFocus() {
      const color = (this.state.color == "frm-error-color") ? "frm-error-color" : "frm-focus-color";
      this.setState({clicked: true, color: color});
    }

    handleBlur(e) {
      if (this.state.color != "frm-error-color") {
          this.setState({color: "frm-blur-color", clicked: false});
      } else {
          this.setState({clicked: false});
      }
    }


    render() {
        let datePicker = null;
        let value = null;
        const inputPad = this.props.label ? "input-top-pad" : "";
        if (this.props.selectsStart) {
            datePicker = (<DatePicker
            className="w3-input date-width pointer" name={this.props.name}
            selected={this.props.startDate}
            selectsStart
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur}
            />);
            value = this.props.startDate;
        } else if (this.props.date) {
            datePicker = (<DatePicker className={"date-width pointer frm-input "+inputPad} name={this.props.name} selected={this.props.date}
                        onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} />);
            value = this.props.date;
        } else {
            datePicker = (<DatePicker
                          className={"date-width pointer frm-input "+inputPad} name={this.props.name}
                          selected={this.props.endDate}
                          selectsEnd
                          startDate={this.props.startDate}
                          endDate={this.props.endDate}
                          onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur}
                          />);
            value = this.props.endDate;
        }
        if (this.props.mode == "view") {
          return (
                <DivContainer margin={this.props.margin} content={value.toLocaleDateString("en-US")}  grid={this.props.grid} color={this.state.color} label={this.props.label}
                centerLabel={this.props.centerLabel} labelColor={this.props.labelColor}
                maxWidth="dateWidth" margin={null} />
          );
        } else {
          return (
                  <FormContainer color={this.state.color} value={this.props.value} clicked={this.state.clicked} defColors={this.props.defColors} defs={this.props.defs}  label={this.props.label}
                  covLabel={this.props.covLabel} margin={this.props.margin} labelColor={this.props.labelColor} noPointer={this.props.noPointer} className={this.props.className +" dateWidth"}
                   margin={this.props.margin}  maxWidth={this.props.maxWidth}  handleClick={this.handleLabelClick} required={this.props.required}>
                      {datePicker}
                  </FormContainer>
          );
        }

    } // close render
}

export default FormDate;
